<div
  class="form-field-label"
  *ngIf="label"
  [ngStyle]="{
    'margin-bottom': subtitle ? '0px' : '8px'
  }"
>
  {{ label }}<span *ngIf="isMandatory" style="color: red">*</span
  ><span> <info-icon *ngIf="infoText" [text]="infoText"></info-icon></span>
</div>

<mat-form-field
  #matSelect
  (click)="beforeOpen()"
  appearance="outline"
  style="margin-bottom: 10px; width: 100%"
>
  <mat-select
    [formControl]="formCtrl"
    multiple
    [compareWith]="compareCategoryObjects"
  >
    <mat-select-trigger>
      {{
        formCtrl.value?.length > 0
          ? formCtrl.value[0][value] == undefined
            ? formCtrl.value[1][value]
            : formCtrl.value[0][value]
          : ""
      }}<span *ngIf="formCtrl.value?.length === 1 && inBrackets">
        (#{{ formCtrl.value[0][inBrackets] }})</span
      >
      <span
        *ngIf="formCtrl.value?.length > 1 && formCtrl.value[0]?.name !== 'All'"
      >
        (+{{ formCtrl.value?.length - 1 }}
        {{ formCtrl.value?.length === 2 ? "other" : "others" }})
      </span>
      <span
        *ngIf="formCtrl.value?.length > 1 && formCtrl.value[0]?.name === 'All'"
      >
        {{ allWord }}
      </span>
    </mat-select-trigger>
    <!-- <mat-option
      *ngIf="(options ?? []).length > 0 && showAll && disabled?.length === 0"
      #allSelected
      (click)="toggleAllSelection()"
      [value]="selectAll"
      >All Locations
    </mat-option> -->

    <mat-option
      (click)="onSelected()"
      *ngFor="let option of options; let i = index"
      [value]="options[i]"
      [disabled]="disabled[i]"
    >
      {{ option[value] ?? ""
      }}<span *ngIf="inBrackets"> (#{{ option[inBrackets] }})</span>
    </mat-option>
  </mat-select>
</mat-form-field>
