import { User, Conversation } from './index';

export interface ChatMessage {
  nanoid?: string;
  _id?: string;
  conversationId?: string | Conversation;
  author?: string | User;
  text?: string;
  seen?: boolean;
  authorType?: string;
  createdAt?: string;
}
