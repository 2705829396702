import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constants } from 'src/app/app.constants';
import { ImageCropDialogComponent } from '../image-crop-dialog/image-crop-dialog.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css'],
})
export class FileUploadComponent {
  @Input() rounded = false;

  files: any[] = [];

  newFileContents: any[] = [];

  fileUploaded = false;
  fileOK = false;
  @Input() readerResult?: string | ArrayBuffer;
  fileAccept = '.png, .jpg, .jpeg';

  @Input() maintainAspectRatio = true;
  @Input() enabled = true;
  @Input() labelAlign = 'left';
  @Input() aspectRatio = 16 / 9;
  @Input() maxFileSize = 10485760;
  @Input() resizeToHeight = 500;
  @Output() fileSelected: EventEmitter<any> = new EventEmitter();

  constructor(
    public dialog: MatDialog,
    public fb: FormBuilder,
    private constants: Constants,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  click(): void {
    if (!this.enabled) {
      return;
    }
    const dialogRef = this.dialog.open(ImageCropDialogComponent, {
      data: {
        aspectRatio: this.aspectRatio,
        maxFileSize: this.maxFileSize,
        resizeToHeight: this.resizeToHeight,
        maintainAspectRatio: this.maintainAspectRatio,
      },
      width: '500px',
      maxHeight: '85vh',
      autoFocus: false,
      disableClose: true,
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialogRef.afterClosed().subscribe((result?: any) => {
      if (result) {
        this.fileUploaded = true;
        this.fileOK = true;
        this.readerResult = result.fileContent;

        let fileArray: any[] = [];

        fileArray.push({
          content: result.fileContent,
          name: result.fileName,
          mime: `image/png`,
        });

        this.files = fileArray;
        this.fileSelected.emit(fileArray);
      }
    });
  }

  removeFile(index: number): void {
    this.files.splice(index, 1);
    if (!this.files.length) {
      this.fileUploaded = false;
      this.fileOK = false;
    }
  }
}
