import { Injectable } from '@angular/core';
const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

import * as fs from 'file-saver';

@Injectable()
export class ExcelService {
  constructor() {}

  public exportExcelFile(data: any[], fileName: string) {
    const blob = new Blob([new Uint8Array(data)], { type: EXCEL_TYPE });
    fs.saveAs(blob, fileName);
  }
}
