import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'single-input',
  templateUrl: './single-input-dialog.component.html',
  styleUrls: ['./single-input-dialog.component.css'],
})
export class SingleInputDialogComponent implements OnInit {
  form!: FormGroup;
  formControl!: FormControl;
  loading = false;
  error = false;

  constructor(
    public matDialogRef: MatDialogRef<SingleInputDialogComponent>,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.formControl = new FormControl({
      value: '',
      disabled: false,
    });

    this.form = this.fb.group({
      input: this.formControl,
    });
  }

  primaryTapped() {
    if (this.form?.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const value = this.formControl.value;

    this.matDialogRef.close({
      value: value,
      changed: true,
    });
  }
}
