import { SendAs, Merchant, Reward, File } from './index';

export enum Shapes {
  square = 'square',
  circle = 'circle',
  rounded = 'rounded',
}

export enum CardType {
  point = 'point',
  stamp = 'stamp',
  openEndedPoint = 'openEndedPoint',
}

export interface StampCardPromo {
  active?: boolean;
  originalStamps?: Number;
  newStamps?: Number;
  end?: Date;
  timezone?: string;
}

export interface StampCard {
  _id?: string;
  nanoid?: string;
  title?: string;
  description?: string;
  terms?: string;
  numberOfStamps?: number;
  joinStamps?: number;
  stampsPerScan?: number;
  merchant?: string | Merchant;
  active?: boolean;
  locations?: any[];
  ref?: number;
  colors?: {
    text: string;
    card: string;
    stampFill: string;
    stampFillEmpty: string;
    stampOutline: string;
    icons: string;
    qrCode: string;
    cardGradient?: {
      from: string;
      to: string;
      value: number;
      angle: number;
    };
    progressBar: string;
    progressBarBg: string;
  };
  icons?: {
    opacity: number;
    stamp: {
      mobile: string;
      web: string;
      filled: boolean;
    };
    reward: {
      mobile: string;
      web: string;
      filled: boolean;
    };
  };
  rewardIcons?: [
    {
      stamp?: number;
      mobile?: string;
      web?: string;
      filled?: boolean;
    }
  ];
  points?: {
    totalPoints: number;
    joinPoints: number;
    maxPoints: number;
    pointsPerScan: number;
    expiry: {
      active: boolean;
      days: number;
    };
  };
  shape?: Shapes;
  showLogo?: boolean;
  soundOn?: boolean;
  stampImage?: File;
  rewardImage?: File;
  rewards?: [{ stamp: number; reward: Reward; points?: number }];
  customLogo?: File;
  restrictions?: {
    daily: {
      enabled: boolean;
      value: number;
      asset: string;
    };
    interval: {
      enabled: boolean;
      value: number; //minutes
      asset: string;
    };
  };
  displayAs?: SendAs;
  stampPromo?: StampCardPromo;
  cardType?: CardType;
  _canManage?: boolean;
  info?: string;
}
