<dialog-header
  [title]="integration.label + ' Integration'"
  [image]="integration.image"
  (onClose)="matDialogRef.close()"
></dialog-header>

<div
  mat-dialog-content
  style="
    padding-bottom: 20px;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    background-color: var(--app-container);
  "
>
  <mat-tab-group
    mat-align-tabs="start"
    mat-stretch-tabs="false"
    disableRipple="true"
    [animationDuration]="0"
    [(selectedIndex)]="selectedIndex"
    (selectedTabChange)="tabChanged($event.index)"
  >
    <mat-tab label="Overview">
      <div class="form-field" style="margin-top: 10px">
        <p class="dialog-body" [innerHTML]="overviewText"></p>
      </div>

      <app-info-box
        *ngIf="!hasIntegration"
        [canDismiss]="false"
        [title]="
          'Please contact us to enable the ' +
          integration.label +
          ' Integration'
        "
      ></app-info-box>
    </mat-tab>

    <mat-tab label="Settings" *ngIf="hasIntegration">
      <!-- Squarespace -->
      <div
        style="margin-top: 20px"
        *ngIf="integration.value == integrations.squarespace"
      >
        <form [formGroup]="form">
          <div
            class="form-field"
            style="
              margin-top: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
            "
          >
            <app-primary-button
              [disabled]="loading || saving"
              [title]="'CONNECT TO SQUARESPACE'"
              image="../../../assets/squarespace_logo_small.png"
              (tapped)="connectOauth()"
              color="black"
              border="1px solid var(--app-timber)"
            ></app-primary-button>
            <p
              style="margin-top: 5px; font-style: italic; font-size: 14px"
              *ngIf="squarespaceConfig?.oauth?.id"
            >
              Last connected:
              <span style="color: blueviolet">{{
                squarespaceConfig?.oauth?.updatedOn | date : constants.longDate
              }}</span>
            </p>
          </div>

          <div
            *ngIf="squarespaceConfig?.oauth?.id"
            class="form-field"
            style="
              margin-top: 20px;
              display: flex;
              justify-content: space-between;
            "
          >
            <div class="form-field-label">
              Invite customers without a Remy account via email<span>
                <info-icon [text]="inviteUsersTooltip"></info-icon>
              </span>
              <link-text
                (onTapped)="viewExampleMail()"
                text="View sample email"
              ></link-text>
            </div>
            <mat-slide-toggle
              [disabled]="false"
              [checked]="inviteUsersSquarespace"
              (change)="inviteUsersSquarespace = !inviteUsersSquarespace"
            >
            </mat-slide-toggle>
          </div>

          <div *ngIf="squarespaceConfig?.oauth?.id">
            <div class="form-field">
              <div class="form-field-label" style="color: var(--app-timber)">
                Configurations
              </div>

              <div style="margin-bottom: 20px">
                <div
                  class="social-container"
                  *ngFor="let item of squarespaceConfig?.configurations"
                  style="
                    margin-top: 10px;
                    display: flex;
                    justify-content: space-between;
                  "
                >
                  <div style="display: flex; align-items: center">
                    <div
                      class="form-field-label"
                      style="
                        margin-bottom: 0px !important;
                        color: black;
                        font-size: 16px;
                      "
                    >
                      {{ rowLabel(item) }}
                    </div>
                  </div>

                  <div>
                    <mat-icon
                      style="
                        color: var(--app-timber);
                        cursor: pointer;
                        margin-right: 5px;
                      "
                      (click)="editConfiguration(item)"
                      >edit</mat-icon
                    >
                    <mat-icon
                      style="color: red; cursor: pointer"
                      (click)="deleteConfiguration(item._id!)"
                      >cancel</mat-icon
                    >
                  </div>
                </div>
              </div>

              <div
                *ngIf="canAddConfiguration"
                class="add-container"
                (click)="addConfiguration()"
              >
                <div class="popup-subtitle" style="color: var(--app-text-grey)">
                  + Add a Configuration
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <!-- Acuity -->
      <div
        style="margin-top: 20px"
        *ngIf="integration.value == integrations.acuity"
      >
        <form [formGroup]="form">
          <div class="form-field" style="margin-top: 20px">
            <div
              class="form-field-label"
              style="color: var(--app-timber); margin-bottom: 0px"
            >
              Webhook URL
            </div>
            <link-text
              (onTapped)="showInfoDialog(titleOneAcuity, bodyOneAcuity)"
              text="What should I do with this?"
            ></link-text>

            <div style="display: flex; align-items: center; cursor: pointer">
              <input
                type="text"
                class="form-input"
                [formControl]="webhookFormControl"
              />
              <div class="copy" (click)="copyWebhookUrl()">
                <mat-icon class="copy-icon">{{ "content_copy" }}</mat-icon
                ><span>Copy</span>
              </div>
            </div>
          </div>

          <div class="form-field" style="margin-top: 20px">
            <div
              class="form-field-label"
              style="
                color: var(--app-timber);
                margin-bottom: 0px;
                margin-top: 20px;
              "
            >
              User ID<span style="color: red">*</span>
            </div>

            <link-text
              (onTapped)="showInfoDialog(titleThreeAcuity, bodyThreeAcuity)"
              text="Where can I find this?"
            ></link-text>

            <div style="display: flex; align-items: center">
              <input
                type="text"
                class="form-input"
                [formControl]="acuityUserID"
              />
            </div>
          </div>

          <div class="form-field" style="margin-top: 20px">
            <div
              class="form-field-label"
              style="
                color: var(--app-timber);
                margin-bottom: 0px;
                margin-top: 20px;
              "
            >
              API Key<span style="color: red">*</span>
            </div>

            <link-text
              (onTapped)="showInfoDialog(titleTwoAcuity, bodyTwoAcuity)"
              text="Where can I find this?"
            ></link-text>

            <div style="display: flex; align-items: center">
              <input
                type="text"
                class="form-input"
                [formControl]="acuityAPIKey"
              />
            </div>
          </div>

          <div
            class="form-field"
            style="
              margin-top: 20px;
              display: flex;
              justify-content: space-between;
            "
          >
            <div class="form-field-label">
              Invite customers without a Remy account via email<span>
                <info-icon [text]="inviteUsersTooltip"></info-icon>
              </span>
              <link-text
                (onTapped)="viewExampleMail()"
                text="View sample email"
              ></link-text>
            </div>
            <mat-slide-toggle
              [disabled]="false"
              [checked]="inviteUsersAcuity"
              (change)="inviteUsersAcuity = !inviteUsersAcuity"
            >
            </mat-slide-toggle>
          </div>

          <div *ngIf="acuityConfig?.apiKey">
            <div class="form-field">
              <div class="form-field-label" style="color: var(--app-timber)">
                Configurations
              </div>

              <div style="margin-bottom: 20px">
                <div
                  class="social-container"
                  *ngFor="let item of acuityConfig?.configurations"
                  style="
                    margin-top: 10px;
                    display: flex;
                    justify-content: space-between;
                  "
                >
                  <div style="display: flex; align-items: center">
                    <div
                      class="form-field-label"
                      style="
                        margin-bottom: 0px !important;
                        color: black;
                        font-size: 16px;
                      "
                    >
                      {{ rowLabel(item) }}
                    </div>
                  </div>

                  <div>
                    <mat-icon
                      style="
                        color: var(--app-timber);
                        cursor: pointer;
                        margin-right: 5px;
                      "
                      (click)="editConfiguration(item)"
                      >edit</mat-icon
                    >
                    <mat-icon
                      style="color: red; cursor: pointer"
                      (click)="deleteConfiguration(item._id!)"
                      >cancel</mat-icon
                    >
                  </div>
                </div>
              </div>

              <div
                *ngIf="canAddConfiguration"
                class="add-container"
                (click)="addConfiguration()"
              >
                <div class="popup-subtitle" style="color: var(--app-text-grey)">
                  + Add a Configuration
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <!-- Shopify -->
      <div
        style="margin-top: 20px"
        *ngIf="integration.value == integrations.shopify"
      >
        <form [formGroup]="form">
          <div class="form-field" style="margin-top: 20px">
            <div
              class="form-field-label"
              style="color: var(--app-timber); margin-bottom: 0px"
            >
              Webhook URL
            </div>
            <link-text
              (onTapped)="showInfoDialog(titleOne, bodyOne)"
              text="What should I do with this?"
            ></link-text>

            <div style="display: flex; align-items: center; cursor: pointer">
              <input
                type="text"
                class="form-input"
                [formControl]="webhookFormControl"
              />
              <div class="copy" (click)="copyWebhookUrl()">
                <mat-icon class="copy-icon">{{ "content_copy" }}</mat-icon
                ><span>Copy</span>
              </div>
            </div>
          </div>

          <div class="form-field" style="margin-top: 20px">
            <div
              class="form-field-label"
              style="
                color: var(--app-timber);
                margin-bottom: 0px;
                margin-top: 20px;
              "
            >
              Webhook Signing Key<span style="color: red">*</span>
            </div>

            <link-text
              (onTapped)="showInfoDialog(titleTwo, bodyTwo)"
              text="Where can I find this?"
            ></link-text>

            <div style="display: flex; align-items: center">
              <input
                type="text"
                class="form-input"
                [formControl]="shopifySigningKey"
              />
            </div>
          </div>

          <div
            class="form-field"
            style="
              margin-top: 20px;
              display: flex;
              justify-content: space-between;
            "
          >
            <div class="form-field-label">
              Invite customers without a Remy account via email<span>
                <info-icon [text]="inviteUsersTooltip"></info-icon>
              </span>
              <link-text
                (onTapped)="viewExampleMail()"
                text="View sample email"
              ></link-text>
            </div>
            <mat-slide-toggle
              [disabled]="false"
              [checked]="inviteUsersShopify"
              (change)="inviteUsersShopify = !inviteUsersShopify"
            >
            </mat-slide-toggle>
          </div>

          <div *ngIf="shopifyConfig?.signingKey">
            <div class="form-field">
              <div class="form-field-label" style="color: var(--app-timber)">
                Configurations
              </div>

              <div style="margin-bottom: 20px">
                <div
                  class="social-container"
                  *ngFor="let item of shopifyConfig?.configurations"
                  style="
                    margin-top: 10px;
                    display: flex;
                    justify-content: space-between;
                  "
                >
                  <div style="display: flex; align-items: center">
                    <div
                      class="form-field-label"
                      style="
                        margin-bottom: 0px !important;
                        color: black;
                        font-size: 16px;
                      "
                    >
                      {{ rowLabel(item) }}
                    </div>
                  </div>

                  <div>
                    <mat-icon
                      style="
                        color: var(--app-timber);
                        cursor: pointer;
                        margin-right: 5px;
                      "
                      (click)="editConfiguration(item)"
                      >edit</mat-icon
                    >
                    <mat-icon
                      style="color: red; cursor: pointer"
                      (click)="deleteConfiguration(item._id!)"
                      >cancel</mat-icon
                    >
                  </div>
                </div>
              </div>

              <div
                *ngIf="canAddConfiguration"
                class="add-container"
                (click)="addConfiguration()"
              >
                <div class="popup-subtitle" style="color: var(--app-text-grey)">
                  + Add a Configuration
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <!-- Square -->
      <div
        style="margin-top: 20px"
        *ngIf="integration.value == integrations.square"
      >
        <form [formGroup]="form">
          <div
            class="form-field"
            style="
              margin-top: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
            "
          >
            <app-primary-button
              [disabled]="loading || saving"
              [title]="'CONNECT TO SQUARE'"
              image="../../../assets/square-logo.png"
              (tapped)="connectOauth()"
              color="black"
              border="1px solid var(--app-timber)"
            ></app-primary-button>
            <p
              style="margin-top: 5px; font-style: italic; font-size: 14px"
              *ngIf="squareConfig?.oauth?.updatedOn"
            >
              Last connected:
              <span style="color: blueviolet">{{
                squareConfig?.oauth?.updatedOn | date : constants.longDate
              }}</span>
            </p>
          </div>

          <div *ngIf="squareConfig?.oauth?.updatedOn">
            <div
              *ngIf="squareConfig?.oauth?.updatedOn"
              class="form-field"
              style="
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
              "
            >
              <div class="form-field-label">
                Automatically create customers in Square<span>
                  <info-icon [text]="addCustomersTooltip"></info-icon>
                </span>
              </div>
              <mat-slide-toggle
                [disabled]="false"
                [checked]="addSquareCustomers"
                (change)="addSquareCustomers = !addSquareCustomers"
              >
              </mat-slide-toggle>
            </div>

            <div
              class="form-field"
              style="
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
              "
            >
              <div class="form-field-label">
                Invite customers without a Remy account via email<span>
                  <info-icon [text]="inviteUsersTooltip"></info-icon>
                </span>
                <link-text
                  (onTapped)="viewExampleMail()"
                  text="View sample email"
                ></link-text>
              </div>
              <mat-slide-toggle
                [disabled]="false"
                [checked]="inviteUsersSquare"
                (change)="inviteUsersSquare = !inviteUsersSquare"
              >
              </mat-slide-toggle>
            </div>

            <div class="form-field">
              <div class="form-field-label" style="color: var(--app-timber)">
                Configurations
              </div>

              <div style="margin-bottom: 20px">
                <div
                  class="social-container"
                  *ngFor="let item of squareConfig?.configurations"
                  style="
                    margin-top: 10px;
                    display: flex;
                    justify-content: space-between;
                  "
                >
                  <div style="display: flex; align-items: center">
                    <div
                      class="form-field-label"
                      style="
                        margin-bottom: 0px !important;
                        color: black;
                        font-size: 16px;
                      "
                    >
                      {{ rowLabel(item) }}
                    </div>
                  </div>

                  <div>
                    <mat-icon
                      style="
                        color: var(--app-timber);
                        cursor: pointer;
                        margin-right: 5px;
                      "
                      (click)="editConfiguration(item)"
                      >edit</mat-icon
                    >
                    <mat-icon
                      style="color: red; cursor: pointer"
                      (click)="deleteConfiguration(item._id!)"
                      >cancel</mat-icon
                    >
                  </div>
                </div>
              </div>

              <div
                *ngIf="canAddConfiguration"
                class="add-container"
                (click)="addConfiguration()"
              >
                <div class="popup-subtitle" style="color: var(--app-text-grey)">
                  + Add a Configuration
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <!-- Zapier -->
      <div
        style="margin-top: 20px"
        *ngIf="integration.value == integrations.zapier"
      >
        <form [formGroup]="form">
          <div class="form-field" style="margin-top: 20px">
            <div
              class="form-field-label"
              style="color: var(--app-timber); margin-bottom: 0px"
            >
              API Key
            </div>
            <link-text
              (onTapped)="showInfoDialog(titleOneZapier, bodyOneZapier)"
              text="What should I do with this?"
            ></link-text>

            <div style="display: flex; align-items: center; cursor: pointer">
              <input
                type="text"
                class="form-input"
                [formControl]="zapierAPIKeyFormControl"
              />
              <div class="copy" (click)="copyApiKey()">
                <mat-icon class="copy-icon">{{ "content_copy" }}</mat-icon
                ><span>Copy</span>
              </div>
            </div>
          </div>

          <div
            class="form-field"
            style="
              margin-top: 20px;
              display: flex;
              justify-content: space-between;
            "
          >
            <div class="form-field-label">
              Invite customers without a Remy account via email<span>
                <info-icon [text]="inviteUsersTooltip"></info-icon>
              </span>
              <link-text
                (onTapped)="viewExampleMail()"
                text="View sample email"
              ></link-text>
            </div>
            <mat-slide-toggle
              [disabled]="false"
              [checked]="inviteUsersZapier"
              (change)="inviteUsersZapier = !inviteUsersZapier"
            >
            </mat-slide-toggle>
          </div>

          <div *ngIf="zapierConfig?.signingKey">
            <div class="form-field">
              <div class="form-field-label" style="color: var(--app-timber)">
                Configurations
              </div>

              <div style="margin-bottom: 20px">
                <div
                  class="social-container"
                  *ngFor="let item of zapierConfig?.configurations"
                  style="
                    margin-top: 10px;
                    display: flex;
                    justify-content: space-between;
                  "
                >
                  <div style="display: flex; align-items: center">
                    <div
                      class="form-field-label"
                      style="
                        margin-bottom: 0px !important;
                        color: black;
                        font-size: 16px;
                      "
                    >
                      {{ rowLabel(item) }}
                    </div>
                  </div>

                  <div>
                    <mat-icon
                      style="
                        color: var(--app-timber);
                        cursor: pointer;
                        margin-right: 5px;
                      "
                      (click)="editConfiguration(item)"
                      >edit</mat-icon
                    >
                    <mat-icon
                      style="color: red; cursor: pointer"
                      (click)="deleteConfiguration(item._id!)"
                      >cancel</mat-icon
                    >
                  </div>
                </div>
              </div>

              <div
                *ngIf="canAddConfiguration"
                class="add-container"
                (click)="addConfiguration()"
              >
                <div class="popup-subtitle" style="color: var(--app-text-grey)">
                  + Add a Configuration
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <!-- Epos Now -->
      <div
        style="margin-top: 20px"
        *ngIf="integration.value == integrations.eposnow"
      >
        <form [formGroup]="form">
          <div class="form-field" style="margin-top: 20px">
            <div
              class="form-field-label"
              style="
                color: var(--app-timber);
                margin-bottom: 0px;
                margin-top: 20px;
              "
            >
              Authorization Token<span style="color: red">*</span>
            </div>

            <link-text
              (onTapped)="
                showInfoDialog(accessTokenTitleEposnow, accessTokenBodyEposnow)
              "
              text="Where can I find this?"
            ></link-text>

            <div style="display: flex; align-items: center">
              <input
                type="text"
                class="form-input"
                [formControl]="eposNowAccessToken"
              />
            </div>
          </div>

          <div class="form-field" style="margin-top: 20px">
            <div
              class="form-field-label"
              style="color: var(--app-timber); margin-bottom: 0px"
            >
              Webhook URL
            </div>

            <link-text
              (onTapped)="showInfoDialog(titleOneEposnow, bodyOneEposNow)"
              text="What should I do with this?"
            ></link-text>

            <div style="display: flex; align-items: center; cursor: pointer">
              <input
                type="text"
                class="form-input"
                [formControl]="webhookEposNowFormControl"
              />
              <div class="copy" (click)="copyWebhookUrl()">
                <mat-icon class="copy-icon">{{ "content_copy" }}</mat-icon
                ><span>Copy</span>
              </div>
            </div>
          </div>

          <div
            class="form-field"
            style="
              margin-top: 20px;
              display: flex;
              justify-content: space-between;
            "
          >
            <div class="form-field-label">
              Automatically create customers in EposNow<span>
                <info-icon [text]="addCustomersTooltip"></info-icon>
              </span>
            </div>
            <mat-slide-toggle
              [disabled]="false"
              [checked]="addEposNowCustomers"
              (change)="addEposNowCustomers = !addEposNowCustomers"
            >
            </mat-slide-toggle>
          </div>

          <div
            class="form-field"
            style="
              margin-top: 20px;
              display: flex;
              justify-content: space-between;
            "
          >
            <div class="form-field-label">
              Invite customers without a Remy account via email<span>
                <info-icon [text]="inviteUsersTooltip"></info-icon>
              </span>
              <link-text
                (onTapped)="viewExampleMail()"
                text="View sample email"
              ></link-text>
            </div>
            <mat-slide-toggle
              [disabled]="false"
              [checked]="inviteUsersEposNow"
              (change)="inviteUsersEposNow = !inviteUsersEposNow"
            >
            </mat-slide-toggle>
          </div>

          <div *ngIf="eposnowConfig?.accessToken">
            <div class="form-field">
              <div class="form-field-label" style="color: var(--app-timber)">
                Configurations
              </div>

              <div style="margin-bottom: 20px">
                <div
                  class="social-container"
                  *ngFor="let item of eposnowConfig?.configurations"
                  style="
                    margin-top: 10px;
                    display: flex;
                    justify-content: space-between;
                  "
                >
                  <div style="display: flex; align-items: center">
                    <div
                      class="form-field-label"
                      style="
                        margin-bottom: 0px !important;
                        color: black;
                        font-size: 16px;
                      "
                    >
                      {{ rowLabel(item) }}
                    </div>
                  </div>

                  <div>
                    <mat-icon
                      style="
                        color: var(--app-timber);
                        cursor: pointer;
                        margin-right: 5px;
                      "
                      (click)="editConfiguration(item)"
                      >edit</mat-icon
                    >
                    <mat-icon
                      style="color: red; cursor: pointer"
                      (click)="deleteConfiguration(item._id!)"
                      >cancel</mat-icon
                    >
                  </div>
                </div>
              </div>

              <div
                *ngIf="canAddConfiguration"
                class="add-container"
                (click)="addConfiguration()"
              >
                <div class="popup-subtitle" style="color: var(--app-text-grey)">
                  + Add a Configuration
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <!-- SumUp -->
      <div
        style="margin-top: 20px"
        *ngIf="integration.value == integrations.sumup"
      >
        <form [formGroup]="form">
          <div class="form-field" style="margin-top: 20px">
            <div
              class="form-field-label"
              style="color: var(--app-timber); margin-bottom: 0px"
            >
              Webhook URL
            </div>

            <link-text
              (onTapped)="showInfoDialog(titleOneSumup, bodyOneSumup)"
              text="What should I do with this?"
            ></link-text>

            <div style="display: flex; align-items: center; cursor: pointer">
              <input
                type="text"
                class="form-input"
                [formControl]="webhookFormControl"
              />
              <div class="copy" (click)="copyWebhookUrl()">
                <mat-icon class="copy-icon">{{ "content_copy" }}</mat-icon
                ><span>Copy</span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <div class="form-field" style="margin-top: 20px">
                <div
                  class="form-field-label"
                  style="
                    color: var(--app-timber);
                    margin-bottom: 0px;
                    margin-top: 20px;
                  "
                >
                  Verification Token<span style="color: red">*</span>
                </div>

                <link-text
                  (onTapped)="showInfoDialog(titleTwoSumup, bodyTwoSumup)"
                  text="Where can I find this?"
                ></link-text>

                <div style="display: flex; align-items: center">
                  <input
                    type="text"
                    class="form-input"
                    [formControl]="sumupSigningKey"
                  />
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="form-field" style="margin-top: 20px">
                <div
                  class="form-field-label"
                  style="
                    color: var(--app-timber);
                    margin-bottom: 0px;
                    margin-top: 20px;
                  "
                >
                  Subdomain<span style="color: red">*</span>
                </div>

                <link-text
                  (onTapped)="showInfoDialog(titleThreeSumup, bodyThreeSumup)"
                  text="Where can I find this?"
                ></link-text>

                <div style="display: flex; align-items: center">
                  <input
                    type="text"
                    class="form-input"
                    [formControl]="sumupSubdomain"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <div class="form-field" style="margin-top: 20px">
                <div class="form-field-label">
                  Username<span style="color: red">*</span>
                </div>
                <input
                  class="form-input"
                  autocomplete="off"
                  [formControl]="sumupUsername"
                  type="text"
                />
              </div>
            </div>

            <div class="col-lg-6">
              <div class="form-field" style="margin-top: 20px">
                <div class="form-field-label">
                  Password<span style="color: red">*</span>
                </div>
                <input
                  class="form-input"
                  autocomplete="off"
                  [formControl]="sumupPassword"
                  type="text"
                />
              </div>
            </div>
          </div>

          <div
            class="form-field"
            style="
              margin-top: 20px;
              display: flex;
              justify-content: space-between;
            "
          >
            <div class="form-field-label">
              Automatically create customers in SumUp<span>
                <info-icon [text]="addCustomersTooltip"></info-icon>
              </span>
            </div>
            <mat-slide-toggle
              [disabled]="false"
              [checked]="addSumupCustomers"
              (change)="addSumupCustomers = !addSumupCustomers"
            >
            </mat-slide-toggle>
          </div>

          <div
            class="form-field"
            style="
              margin-top: 20px;
              display: flex;
              justify-content: space-between;
            "
          >
            <div class="form-field-label">
              Invite customers without a Remy account via email<span>
                <info-icon [text]="inviteUsersTooltip"></info-icon>
              </span>
              <link-text
                (onTapped)="viewExampleMail()"
                text="View sample email"
              ></link-text>
            </div>
            <mat-slide-toggle
              [disabled]="false"
              [checked]="inviteUsersSumup"
              (change)="inviteUsersSumup = !inviteUsersSumup"
            >
            </mat-slide-toggle>
          </div>

          <div *ngIf="sumupConfig?.signingKey">
            <div class="form-field">
              <div class="form-field-label" style="color: var(--app-timber)">
                Configurations
              </div>

              <div style="margin-bottom: 20px">
                <div
                  class="social-container"
                  *ngFor="let item of sumupConfig?.configurations"
                  style="
                    margin-top: 10px;
                    display: flex;
                    justify-content: space-between;
                  "
                >
                  <div style="display: flex; align-items: center">
                    <div
                      class="form-field-label"
                      style="
                        margin-bottom: 0px !important;
                        color: black;
                        font-size: 16px;
                      "
                    >
                      {{ rowLabel(item) }}
                    </div>
                  </div>

                  <div>
                    <mat-icon
                      style="
                        color: var(--app-timber);
                        cursor: pointer;
                        margin-right: 5px;
                      "
                      (click)="editConfiguration(item)"
                      >edit</mat-icon
                    >
                    <mat-icon
                      style="color: red; cursor: pointer"
                      (click)="deleteConfiguration(item._id!)"
                      >cancel</mat-icon
                    >
                  </div>
                </div>
              </div>

              <div
                *ngIf="canAddConfiguration"
                class="add-container"
                (click)="addConfiguration()"
              >
                <div class="popup-subtitle" style="color: var(--app-text-grey)">
                  + Add a Configuration
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <!-- Storekit -->
      <div
        style="margin-top: 20px"
        *ngIf="integration.value == integrations.storekit"
      >
        <form [formGroup]="form">
          <div class="form-field" style="margin-top: 20px">
            <div
              class="form-field-label"
              style="color: var(--app-timber); margin-bottom: 0px"
            >
              Webhook URL
            </div>
            <link-text
              (onTapped)="showInfoDialog(titleOneStorekit, bodyOneStorekit)"
              text="What should I do with this?"
            ></link-text>

            <div style="display: flex; align-items: center; cursor: pointer">
              <input
                type="text"
                class="form-input"
                [formControl]="webhookFormControl"
              />
              <div class="copy" (click)="copyWebhookUrl()">
                <mat-icon class="copy-icon">{{ "content_copy" }}</mat-icon
                ><span>Copy</span>
              </div>
            </div>
          </div>

          <div class="form-field" style="margin-top: 20px">
            <div
              class="form-field-label"
              style="
                color: var(--app-timber);
                margin-bottom: 0px;
                margin-top: 20px;
              "
            >
              Webhook Signing Key<span style="color: red">*</span>
            </div>

            <link-text
              (onTapped)="showInfoDialog(titleTwo, bodyTwoStorekit)"
              text="Where can I find this?"
            ></link-text>

            <div style="display: flex; align-items: center">
              <input
                type="text"
                class="form-input"
                [formControl]="storekitSigningKey"
              />
            </div>
          </div>

          <div
            class="form-field"
            style="
              margin-top: 20px;
              display: flex;
              justify-content: space-between;
            "
          >
            <div class="form-field-label">
              Invite customers without a Remy account via email<span>
                <info-icon [text]="inviteUsersTooltip"></info-icon>
              </span>
              <link-text
                (onTapped)="viewExampleMail()"
                text="View sample email"
              ></link-text>
            </div>
            <mat-slide-toggle
              [disabled]="false"
              [checked]="inviteUsersStorekit"
              (change)="inviteUsersStorekit = !inviteUsersStorekit"
            >
            </mat-slide-toggle>
          </div>

          <div *ngIf="storekitConfig?.signingKey">
            <div class="form-field">
              <div class="form-field-label" style="color: var(--app-timber)">
                Configurations
              </div>

              <div style="margin-bottom: 20px">
                <div
                  class="social-container"
                  *ngFor="let item of storekitConfig?.configurations"
                  style="
                    margin-top: 10px;
                    display: flex;
                    justify-content: space-between;
                  "
                >
                  <div style="display: flex; align-items: center">
                    <div
                      class="form-field-label"
                      style="
                        margin-bottom: 0px !important;
                        color: black;
                        font-size: 16px;
                      "
                    >
                      {{ rowLabel(item) }}
                    </div>
                  </div>

                  <div>
                    <mat-icon
                      style="
                        color: var(--app-timber);
                        cursor: pointer;
                        margin-right: 5px;
                      "
                      (click)="editConfiguration(item)"
                      >edit</mat-icon
                    >
                    <mat-icon
                      style="color: red; cursor: pointer"
                      (click)="deleteConfiguration(item._id!)"
                      >cancel</mat-icon
                    >
                  </div>
                </div>
              </div>

              <div
                *ngIf="canAddConfiguration"
                class="add-container"
                (click)="addConfiguration()"
              >
                <div class="popup-subtitle" style="color: var(--app-text-grey)">
                  + Add a Configuration
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<div mat-dialog-actions>
  <div class="action-container">
    <div class="left-buttons" *ngIf="isNotEmpty(config)">
      <app-primary-button
        [disabled]="loading"
        [title]="'Orders'"
        (tapped)="viewData()"
        color="black"
        border="1px solid var(--app-text-grey)"
        icon="shopping_cart"
      ></app-primary-button>
      <app-primary-button
        [disabled]="loading"
        [title]="'Invites'"
        (tapped)="viewInvites()"
        color="black"
        border="1px solid var(--app-text-grey)"
        icon="email"
      ></app-primary-button>
    </div>

    <div class="right-buttons">
      <app-secondary-button
        *ngIf="isNotEmpty(config)"
        [title]="'Remove Integration'"
        [disabled]="saving"
        (tapped)="remove()"
        style="margin-right: 10px"
      ></app-secondary-button>
      <app-primary-button
        *ngIf="hasIntegration"
        [disabled]="!merchantActive || !hasIntegration || loading || !canSave"
        [tooltip]="!merchantActive ? 'Merchant account inactive' : ''"
        [title]="'Save'"
        (tapped)="save()"
        bgColor="var(--app-timber)"
        color="white"
      ></app-primary-button>
    </div>
  </div>
</div>

<loader [loading]="saving || loading"></loader>
