import { Component, Inject, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'social-dialog',
  templateUrl: './social-dialog.component.html',
  styleUrls: ['./social-dialog.component.css'],
})
export class SocialDialogComponent implements OnInit {
  form!: FormGroup;
  typeFormControl!: FormControl;
  handleFormControl!: FormControl;
  options: any = [];

  constructor(
    public matDialogRef: MatDialogRef<SocialDialogComponent>,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.typeFormControl = new FormControl(
      {
        value: '',
        disabled: this.data.edit?.handle ? true : false,
      },
      [Validators.required]
    );
    this.handleFormControl = new FormControl({ value: '', disabled: false }, [
      Validators.required,
    ]);

    this.form = this.fb.group({
      type: this.typeFormControl,
      handle: this.handleFormControl,
    });

    this.options = [
      { name: 'Facebook' },
      { name: 'Instagram' },
      { name: 'Pinterest' },
      { name: 'TikTok' },
    ];

    if (this.data.edit?.handle) {
      this.typeFormControl.setValue(
        this.options.find((el: any) => el.name === this.data.edit.type)
      );

      this.handleFormControl.setValue(this.data.edit.handle);
    } else {
      this.options = this.options.filter((el: { name: any }) => {
        return !this.data?.exclusions.some(
          (ex: any) => ex.name === el.name || ex === el.name
        );
      });
    }
  }

  primaryTapped() {
    if (this.form?.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.matDialogRef.close({
      type: this.typeFormControl.value,
      handle: this.handleFormControl.value,
    });
  }

  get prefix(): string {
    if (this.typeFormControl.value?.name === 'Facebook') {
      return 'facebook.com/';
    }
    if (this.typeFormControl.value?.name === 'Instagram') {
      return 'instagram.com/';
    }
    if (this.typeFormControl.value?.name === 'Pinterest') {
      return 'pinterest.com/';
    }
    return 'tiktok.com/@';
  }
}
