import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-secondary-button',
  templateUrl: './secondary-button.component.html',
  styleUrls: ['./secondary-button.component.css'],
})
export class SecondaryButtonComponent implements OnInit {
  @Input() type: string = 'button';
  @Input() disabled: boolean = false;
  @Input() title: string = '';
  @Input() tooltip: string = '';
  @Input() bgColor: string = 'transparent';
  @Input() color: string = 'red';
  @Input() border: string = '0px solid white';
  @Output() tapped: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
