import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private api: ApiService) {}

  PATH = 'user';

  getDemo(str: string) {
    return this.api.get(`demo/${str}`);
  }

  getCurrentUser() {
    return this.api.get(`${this.PATH}/current`);
  }

  getUser(user: string) {
    return this.api.get(`${this.PATH}/${user}`);
  }

  getNotificationCount() {
    return this.api.get(`${this.PATH}/notifications/count`);
  }

  getUsers(limit: number, page: number) {
    var query = `?limit=${limit}&page=${page}`;
    return this.api.get(`${this.PATH}/list` + query);
  }

  editUser(user: string) {
    return this.api.post(`${this.PATH}/${user}`, {});
  }

  updateUserProperty(property: string, value: any) {
    return this.api.post(`${this.PATH}/update`, {
      property: property,
      value: value,
    });
  }

  verifyCaptcha(token: string) {
    return this.api.post(`${this.PATH}/captcha`, {
      token: token,
    });
  }
}
