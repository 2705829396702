export abstract class AnalyticsAbstract {
  /// Track an event with a property
  abstract track(event: string, properties?: { [key: string]: any }): void;

  /// Start the timer for an event, then some time later call track on the same event
  abstract timeEvent(event: string): void;

  /// It's common to have certain properties that you want to include with each event you send.
  /// Generally, these are things you know about the user rather than about a specific event.
  /// For example, the user's age, gender, source, or initial referrer.
  abstract setGlobalProperties(properties: { [key: string]: any }): void;

  abstract setSpecificGlobalProperties(properties: {
    [key: string]: any;
  }): void;

  abstract unsetGlobalProperty(property: string): void;

  abstract identifyUser(user: any): void;

  /// Reset generates a new random distinct_id and clears super properties.
  /// Call reset to clear data attributed to a user when that user logs out.
  abstract reset(): void;

  abstract setUserProperty(key: string, value: string): void;

  abstract incrementUserProperty(key: string, value: number): void;
}
