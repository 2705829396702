<button
  [type]="type"
  [matTooltip]="tooltip"
  [style.cursor]="!disabled ? 'pointer' : 'not-allowed'"
  [disabled]="disabled"
  (click)="tapped.emit()"
  mat-flat-button
  class="secondary-button"
  [style.backgroundColor]="bgColor"
  [style.color]="color"
  [style.border]="border"
>
  <span>{{ title }}</span>
</button>
