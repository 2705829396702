<dialog-header
  [title]="data?.title"
  (onClose)="matDialogRef.close()"
></dialog-header>

<div
  mat-dialog-content
  style="
    padding-bottom: 20px;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    background-color: var(--app-container);
  "
>
  <form
    [formGroup]="form"
    (ngSubmit)="primaryTapped()"
    style="margin-top: 20px"
  >
    <div class="form-field" style="margin-top: 20px">
      <div class="form-field-label">{{ data?.label }}</div>
      <input
        class="form-input"
        autocomplete="off"
        [maxlength]="20"
        [formControl]="formControl"
        type="text"
      />
    </div>
  </form>
</div>

<div mat-dialog-actions align="end">
  <app-secondary-button
    style="margin-right: 10px"
    [title]="'Cancel'"
    [disabled]="false"
    (tapped)="matDialogRef.close()"
  ></app-secondary-button>

  <app-primary-button
    [title]="'Save'"
    [disabled]="false"
    (tapped)="primaryTapped()"
    bgColor="var(--app-timber)"
    color="white"
  ></app-primary-button>
</div>

<loader [loading]="loading"></loader>
