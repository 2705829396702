export interface Contact {
  email: string;
  phone: string;
  website: string;
  wifi?: string;
  ssid?: string;
  telCountry?: Country;
}

export interface Country {
  name: string;
  code: string;
  emoji: string;
  dialCode?: string;
  slug: string;
}
