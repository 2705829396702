import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class ListService {
  constructor(private api: ApiService) {}

  PATH = 'v1/list';

  createList() {
    return this.api.post(`${this.PATH}`, {});
  }

  getList(type: string, all?: boolean) {
    return this.api.get(`${this.PATH}/${type}?all=${all}`);
  }
}
