import { Component, Input } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'],
})
export class LoaderComponent {
  @Input() loading = false;
  options: AnimationOptions = { path: '/assets/loader.json', loop: true };

  constructor() {}

  ngOnInit() {}
}
