import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Icon } from 'src/app/shared/icons';

@Component({
  selector: 'icon-dialog',
  templateUrl: './icon-dialog.component.html',
  styleUrls: ['./icon-dialog.component.css'],
})
export class IconDialogComponent implements OnInit {
  @ViewChild('fileInput') fileInput?: ElementRef;

  @Input() readerResult?: string | ArrayBuffer;

  icons: any[] = Icon.icons;
  filledIcons: any[] = Icon.filledIcons;
  tabIndex = 0;

  selectedImage?: [{ name: string; content: ArrayBuffer; mime: string }];

  constructor(
    public matDialogRef: MatDialogRef<IconDialogComponent>,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.tabIndex = this.data.iconImage ? 2 : this.data.filled ? 1 : 0;
  }

  getIcon(index: number) {
    var icon = this.icons[index];

    const [iconName, [unicode, iconNameForDisplay]] = Object.entries(
      icon
    )[0] as [string, [string, string]];

    return iconNameForDisplay;
  }

  getFilledIcon(index: number) {
    var icon = this.filledIcons[index];

    const [iconName, [unicode, iconNameForDisplay]] = Object.entries(
      icon
    )[0] as [string, [string, string]];

    return iconNameForDisplay;
  }

  selectedIcon(index: number) {
    var icon = this.tabIndex == 0 ? this.icons[index] : this.filledIcons[index];

    const [iconName, [unicode, iconNameForDisplay]] = Object.entries(
      icon
    )[0] as [string, [string, string]];

    this.matDialogRef.close({
      web: iconNameForDisplay,
      mobile: unicode,
      filled: this.tabIndex == 1,
    });
  }

  primaryTapped(): void {
    if (this.selectedImage && this.selectedImage.length == 1) {
      this.matDialogRef.close({
        iconImage: this.selectedImage[0],
      });
    }
  }
}
