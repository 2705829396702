import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constants } from 'src/app/app.constants';
import { CardService } from '../../../services/index';
import { CardType, StampCard } from '../../../models/index';

@Component({
  selector: 'configuration-dialog',
  templateUrl: './configuration-dialog.component.html',
  styleUrls: ['./configuration-dialog.component.css'],
})
export class ConfigurationDialogComponent implements OnInit {
  form!: FormGroup;
  // skuFormControl!: FormControl;
  cardFormControl!: FormControl;
  typeFormControl!: FormControl;
  earnMethodFormControl!: FormControl;
  valueFormControl!: FormControl;
  stampsFormControl!: FormControl;
  pointsFormControl!: FormControl;
  pointsCapFormControl!: FormControl;

  loading = false;
  cards: StampCard[] = [];

  multipleOrderTypesAllowed = [
    'Zapier',
    'Eposnow',
    'Storekit',
    'Squarespace',
    'acuity:scheduling',
    'Shopify',
    'Square',
  ];

  orderOnlyTypes = [
    'Zapier',
    'Eposnow',
    'Storekit',
    'Squarespace',
    'acuity:scheduling',
    'Shopify',
  ];

  pointsCardsAllowed = ['Shopify', 'Square'];

  types: any[] = [
    // { name: 'Single Product', value: 'product' },
    { name: 'Order Value', value: 'order' },
  ];

  earningMethods: any[] = [
    {
      name: 'Fixed',
      value: 'fixed',
      info: 'E.g. spend £10 or more to get 10 points or 1 stamp',
      icon: '../../assets/fixed.png',
    },
    {
      name: 'Incremental',
      value: 'incremental',
      info: 'E.g. earn 1 point for every £1 spent',
      icon: '../../assets/incremental.png',
    },
  ];

  editMode = false;

  constructor(
    private cardService: CardService,
    public matDialogRef: MatDialogRef<ConfigurationDialogComponent>,
    public fb: FormBuilder,
    private constants: Constants,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data?.configuration) {
      if (this.isStrictlyOrderOnlyType(this.data.type)) {
        this.types = [{ name: 'Order Value', value: 'order' }];
      }

      this.editMode = true;
    } else {
      // if (
      //   !this.data.orderAvailable &&
      //   !this.multipleOrderTypesAllowed.includes(this.data.type)
      // ) {
      //   this.types = [{ name: 'Single Product', value: 'product' }];
      // }

      if (this.isStrictlyOrderOnlyType(this.data.type)) {
        this.types = [{ name: 'Order Value', value: 'order' }];
      }
    }

    // this.skuFormControl = new FormControl({
    //   value: this.data.configuration?.sku,
    //   disabled: false,
    // });

    this.valueFormControl = new FormControl({
      value: this.data.configuration?.value,
      disabled: false,
    });

    this.stampsFormControl = new FormControl({
      value: this.data.configuration?.numberOfStamps ?? 1,
      disabled: false,
    });

    this.pointsFormControl = new FormControl({
      value: this.data.configuration?.numberOfPoints ?? 0,
      disabled: false,
    });

    this.cardFormControl = new FormControl({ value: '', disabled: false }, [
      Validators.required,
    ]);

    this.typeFormControl = new FormControl(
      {
        value: this.types[0],
        disabled: true,
      },
      [Validators.required]
    );

    this.pointsCapFormControl = new FormControl({
      value: this.data.configuration?.cap,
      disabled: false,
    });

    this.earnMethodFormControl = new FormControl({
      value: this.earningMethods.find(
        (el) => el.value === this.data?.configuration?.method
      ),
      disabled: false,
    });

    if (this.isStrictlyOrderOnlyType(this.data.type)) {
      this.typeFormControl.setValue(this.types[0]);
    }

    this.form = this.fb.group({
      // sku: this.skuFormControl,
      card: this.cardFormControl,
      type: this.typeFormControl,
      method: this.earnMethodFormControl,
      cap: this.pointsCapFormControl,
      value: this.valueFormControl,
      'Number of stamps': this.stampsFormControl,
      'Number of points': this.pointsFormControl,
    });
  }

  ngOnInit(): void {
    this.getCards();
  }

  isStrictlyOrderOnlyType(type: string): boolean {
    return (
      this.orderOnlyTypes.includes(type) &&
      !this.multipleOrderTypesAllowed.includes(type)
    );
  }

  getCards(): void {
    this.loading = true;
    this.cardService
      .getCards(100, 0)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.cards = res.docs;

            if (!this.pointsCardsAllowed.includes(this.data.type)) {
              this.cards = this.cards.filter(
                (el) => el.cardType == CardType.stamp
              );
            }

            this.cards.forEach((card) => {
              if (card.cardType === CardType.stamp) {
                card.info = `${card.numberOfStamps} stamp card`;
              }

              if (card.cardType === CardType.point) {
                card.info = `${card.points?.totalPoints} points card`;
              }

              if (card.cardType === CardType.openEndedPoint) {
                card.info = `Open-ended points card`;
              }
            });

            this.cardFormControl.setValue(
              this.cards.find(
                (el) => el._id?.toString() === this.data?.configuration?.card
              )
            );
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  save(): void {
    if (this.form.invalid) {
      const invalidFields = Object.keys(this.form.controls).filter(
        (field) => this.form.controls[field].invalid
      );

      this.constants.snack(
        'Please complete all fields: ' + invalidFields.join(', ')
      );
      return;
    }

    if (this.cardFormControl?.value?.cardType === 'stamp') {
      if (this.stampsFormControl.value < 1) {
        this.constants.snack('Number of stamps cannot be less than 1');
        return;
      }

      if (
        this.stampsFormControl.value >
        this.cardFormControl.value?.numberOfStamps
      ) {
        this.constants.snack(
          'Number of stamps cannot be more than available stamps for this card'
        );
        return;
      }
    } else {
      if (this.pointsFormControl.value < 1) {
        this.constants.snack('Number of points cannot be less than 1');
        return;
      }

      if (this.cardFormControl?.value?.cardType === 'point') {
        if (
          this.pointsFormControl.value >
          this.cardFormControl.value?.points?.totalPoints
        ) {
          this.constants.snack(
            'Number of points cannot be more than available points for this card'
          );
          return;
        }
      }
    }

    let data = {
      type: this.typeFormControl.value.value,
      card: this.cardFormControl.value._id,
      sku: null,
      value: null,
      _id: null,
      numberOfStamps: null,
      numberOfPoints: null,
      method: null,
      cap: null,
    };

    if (this.cardFormControl?.value?.cardType === 'stamp') {
      data['numberOfStamps'] = this.stampsFormControl.value ?? 1;
    } else {
      data['numberOfPoints'] = this.pointsFormControl.value ?? 1;

      data['method'] = this.earnMethodFormControl.value.value;
      data['cap'] = this.pointsCapFormControl.value;
    }

    if (this.editMode) {
      data['_id'] = this.data.configuration._id;
    }

    if (data.type === 'product') {
      // data['sku'] = this.skuFormControl.value?.trim();
    } else {
      data['value'] = this.valueFormControl.value;
    }

    console.log(data);

    this.matDialogRef.close(data);
  }

  get infoBody(): string {
    return `Use this form to set up how customers earn stamps or points for purchases from your <b>${this.data.type}</b> store.`;
  }

  get skuTooltip(): string {
    if (this.data.type === 'Shopify') {
      return `You can find this in your Shopify Product's overview page`;
    } else if (this.data.type === 'Square') {
      return `You can find this in your Square Item's overview page`;
    } else {
      return `You can find this in your SumUp Product's overview page`;
    }
  }

  typeChanged(): void {
    if (this.typeFormControl.value?.value === 'product') {
      // this.skuFormControl.setValidators([Validators.required]);
      this.valueFormControl.clearValidators();
      this.earnMethodFormControl.clearValidators();
    } else {
      this.valueFormControl.setValidators([Validators.required]);
      // this.skuFormControl.clearValidators();
      this.earnMethodFormControl.setValidators([Validators.required]);
    }

    // this.skuFormControl.updateValueAndValidity();
    this.valueFormControl.updateValueAndValidity();

    this.pointsFormControl.reset();
    this.stampsFormControl.reset();
    this.earnMethodFormControl.setValue(null);
    this.valueFormControl.reset();
    this.cardFormControl.setValue(null);
  }

  earningMethodChanged(): void {
    // if (this.typeFormControl.value?.value === 'product') {
    //   this.skuFormControl.setValidators([Validators.required]);
    //   this.valueFormControl.clearValidators();
    // } else {
    //   this.valueFormControl.setValidators([Validators.required]);
    //   this.skuFormControl.clearValidators();
    // }
    // this.skuFormControl.updateValueAndValidity();
    // this.valueFormControl.updateValueAndValidity();
  }

  get stampNumbers() {
    return Array.from(
      { length: this.cardFormControl.value.numberOfStamps },
      (_, i) => i + 1
    );
  }

  cardSelected(): void {
    this.stampsFormControl.reset();
    this.pointsFormControl.reset();
    this.earnMethodFormControl.setValue(null);
    this.valueFormControl.reset();

    if (this.cardFormControl.value.cardType === CardType.stamp) {
      this.stampsFormControl.setValidators([Validators.required]);
      this.pointsFormControl.clearValidators();
      this.earningMethods[0].info = `E.g. spend £10 or more to get 1 stamp`;
      this.earningMethods[1].info = `E.g. earn 1 stamp for every £10 spent`;
    } else {
      this.pointsFormControl.setValidators([Validators.required]);
      this.stampsFormControl.clearValidators();
      this.earningMethods[0].info = `E.g. spend £10 or more to get 10 points`;
      this.earningMethods[1].info = `E.g. earn 10 points for every £10 spent`;
    }
  }

  get stampOrPoints(): string {
    return this.cardFormControl.value.cardType === CardType.stamp
      ? 'stamp'
      : 'point';
  }

  get minimumSpendInfoText(): string {
    return `The amount the customer must spend in order to qualify for a the specified number of ${this.stampOrPoints}s`;
  }

  get incrementalInfoText(): string {
    return `The number of ${this.stampOrPoints}s the customer should earn for every £x spent`;
  }

  get maximumNumberOfPointsCapInfoText(): string {
    return `The maximum number of points a customer can earn for a single purchase`;
  }

  get showNumberOfStampsField(): boolean {
    return (
      this.cardFormControl.value &&
      this.cardFormControl.value?.cardType === 'stamp'
    );
  }

  get showNumberOfPointsField(): boolean {
    return (
      this.cardFormControl.value &&
      this.cardFormControl.value?.cardType !== 'stamp' &&
      (this.typeFormControl.value?.value !== 'order' ||
        this.earnMethodFormControl.value?.value === 'fixed')
    );
  }

  get incrementalPointsSummary(): string {
    return `Customers will earn ${this.pointsFormControl.value} ${
      this.pointsFormControl.value === 1 ? 'point' : 'points'
    } for every £${this.valueFormControl.value} spent`;
  }

  get showMinimumSpendField(): boolean {
    return (
      this.typeFormControl.value?.value === 'order' &&
      this.cardFormControl.value &&
      (this.earnMethodFormControl.value?.value === 'fixed' ||
        this.cardFormControl.value?.cardType === 'stamp')
    );
  }

  get showIncrementalField(): boolean {
    return (
      this.typeFormControl.value?.value === 'order' &&
      this.cardFormControl.value &&
      this.earnMethodFormControl.value?.value === 'incremental'
    );
  }

  get showIncrementalSummaryField(): boolean {
    return (
      this.showIncrementalField &&
      this.valueFormControl.value &&
      this.pointsFormControl.value
    );
  }

  get showEarningMethodField(): boolean {
    return (
      this.typeFormControl.value?.value === 'order' &&
      this.cardFormControl.value &&
      this.cardFormControl?.value?.cardType !== 'stamp'
    );
  }

  get showSkuField(): boolean {
    return (
      this.typeFormControl.value?.value === 'product' &&
      this.cardFormControl.value
    );
  }
}
