<dialog-header
  title="Upload Image"
  (onClose)="matDialogRef.close()"
></dialog-header>

<div mat-dialog-content style="margin-top: 40px; margin-bottom: 20px">
  <choose-reward
    [label]="'Click here to upload'"
    [icon]="'cloud_upload'"
    (clicked)="triggerFileInput()"
  ></choose-reward>

  <input
    #fileInput
    style="display: none"
    type="file"
    (change)="fileChangeEvent($event)"
    [accept]="fileAccept"
    [multiple]="false"
  />

  <div
    style="
      height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
    "
    *ngIf="imageChangedEvent"
  >
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="data.maintainAspectRatio"
      [aspectRatio]="data.aspectRatio ?? 16 / 9"
      format="png"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded($event)"
      (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"
      [resizeToHeight]="data.resizeToHeight"
    ></image-cropper>
  </div>
</div>

<p *ngIf="fileSize > 0">{{ formatSize(fileSize) }}</p>

<div mat-dialog-actions align="end">
  <app-secondary-button
    [disabled]="false"
    (tapped)="matDialogRef.close()"
    [title]="'Cancel'"
  ></app-secondary-button>

  <app-primary-button
    [disabled]="croppedImage == ''"
    (tapped)="primaryTapped()"
    [title]="'Save'"
    bgColor="var(--app-timber)"
    color="white"
  ></app-primary-button>
</div>
