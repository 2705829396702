import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'stamps-per-scan',
  templateUrl: './stamps-per-scan.component.html',
  styleUrls: ['./stamps-per-scan.component.css'],
})
export class StampsPerScanComponent implements OnInit {
  dateFormControl: FormControl = new FormControl(
    new Date().toISOString().split('T')[0],
    [Validators.required]
  );

  timeFormControl: FormControl = new FormControl('', [Validators.required]);

  minDate: string = new Date().toISOString().split('T')[0];

  times: string[] = [];

  allTimes: string[] = [
    '00:00',
    '01:00',
    '02:00',
    '03:00',
    '04:00',
    '05:00',
    '06:00',
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
  ];

  constructor(
    public matDialogRef: MatDialogRef<StampsPerScanComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: any
  ) {}

  revertToggle = false;

  ngOnInit(): void {
    this.filterAvailableTimes();
    this.initializeDateAndTimeControls();
    this.updateTimeForCurrentDate();

    this.dateFormControl.valueChanges.subscribe(() => {
      this.filterAvailableTimes();
      this.updateTimeForCurrentDate();
    });

    if (this.data?.stampPromo?.active) {
      this.revertToggle = true;
    }
  }

  getLocalTimeString(dateString: string, timeZone: string): string {
    const date = new Date(dateString);

    const formatter = new Intl.DateTimeFormat('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      timeZone: timeZone,
      hour12: false,
    });

    const parts = formatter.formatToParts(date);
    const hour = parts.find((part) => part.type === 'hour')?.value;
    const minute = parts.find((part) => part.type === 'minute')?.value;

    return `${hour}:${minute}`;
  }

  initializeDateAndTimeControls(): void {
    if (this.data?.stampPromo?.active && this.data?.stampPromo?.end) {
      let selectedDate = new Date(this.data.stampPromo.end);
      let formattedDate = selectedDate.toISOString().split('T')[0];
      this.dateFormControl.setValue(formattedDate);

      this.filterAvailableTimes();

      const selectedUKTime = this.getLocalTimeString(
        this.data.stampPromo.end,
        this.data?.stampPromo?.timezone ?? 'Europe/London'
      );

      setTimeout(() => {
        let selectedTime = this.times.find((el) => el === selectedUKTime);

        if (selectedTime) {
          this.timeFormControl.setValue(selectedTime);
        } else {
          this.timeFormControl.setValue(this.times[0]);
        }
      }, 0);
    } else {
      this.dateFormControl.setValue(new Date().toISOString().split('T')[0]);
      this.timeFormControl.setValue(this.allTimes[0]);
    }
  }

  filterAvailableTimes(): void {
    const selectedDate = new Date(this.dateFormControl.value);
    const today = new Date();

    if (selectedDate.toDateString() === today.toDateString()) {
      const currentHour = today.getHours();
      const currentMinutes = today.getMinutes();

      let nextHour = currentHour;
      if (currentMinutes > 0) {
        nextHour = (currentHour + 2) % 24;
      }

      this.times = this.allTimes.filter(
        (time) => parseInt(time.split(':')[0], 10) >= nextHour
      );
    } else {
      this.times = [...this.allTimes];
    }
  }

  updateTimeForCurrentDate(): void {
    if (this.times.length > 0) {
      this.timeFormControl.setValue(this.times[0]);
    }
  }

  buttonOne() {
    this.matDialogRef.close(0);
  }

  buttonTwo() {
    const data = {
      option: 1,
      promo: {
        active: false,
        originalStamps: this.data.originalStamps,
        newStamps: this.data.newStamps,
        end: new Date(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    };

    if (this.revertToggle) {
      data.promo.active = true;
      data.promo.end = new Date(this.getScheduledDateString());
    }

    this.matDialogRef.close(data);
  }

  getScheduledDateString() {
    const localDateTimeString = `${this.dateFormControl.value}T${this.timeFormControl.value}:00`;
    const localDateTime = new Date(localDateTimeString);
    const utcDateTime = new Date(
      localDateTime.getTime() - localDateTime.getTimezoneOffset() * 60000
    );
    return utcDateTime.toISOString().slice(0, -1);
  }
}
