<dialog-header
  title="Select Date Range"
  (onClose)="matDialogRef.close()"
></dialog-header>

<div
  mat-dialog-content
  style="
    padding-bottom: 20px;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    background-color: var(--app-container);
  "
>
  <form [formGroup]="form" style="margin-top: 20px">
    <div class="form-field" style="margin-top: 20px">
      <single-select
        [formCtrl]="quickFormControl"
        [options]="options"
        (selected)="selected($event)"
        [value]="quickFormControl.value"
        [property]="'name'"
      ></single-select>
    </div>

    <div class="form-field" style="margin-top: 20px">
      <div class="form-field-label">From<span style="color: red">*</span></div>
      <input
        class="form-input"
        formControlName="from"
        type="date"
        (change)="changed()"
        [min]="twelveMonthsAgo"
      />

      <div class="form-field-label" style="margin-top: 20px">
        To<span style="color: red">*</span>
      </div>
      <input
        class="form-input"
        formControlName="to"
        type="date"
        (change)="changed()"
        [max]="today"
      />
    </div>
  </form>
</div>

<div mat-dialog-actions align="end">
  <app-secondary-button
    title="Cancel"
    [disabled]="false"
    (tapped)="matDialogRef.close()"
    style="margin-right: 10px"
  ></app-secondary-button>
  <app-primary-button
    title="Export"
    [disabled]="false"
    (tapped)="primaryTapped()"
    bgColor="var(--app-timber)"
    color="white"
  ></app-primary-button>
</div>

<loader [loading]="exporting"></loader>
