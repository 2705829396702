<page-header
  [title]="constants.strings.referrals"
  [subtitle]="subtitle"
></page-header>

<div
  style="
    background-color: var(--app-container);
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
  "
>
  <div style="font-weight: 500; font-size: 15px">
    Invite other merchants to join Remy. For each referral who signs up for a
    trial, you’ll receive a month off your subscription, and they’ll get a free
    month after their trial!
  </div>

  <div style="font-weight: 600; font-size: 15px; margin-top: 20px">
    How it works
  </div>
  <div style="font-weight: 400; font-size: 13px; color: grey">
    It's a simple 3-step process
  </div>

  <mat-stepper
    labelPosition="bottom"
    #stepper
    [disableRipple]="true"
    [@.disabled]="true"
  >
    <ng-template matStepperIcon="number" let-index="index">
      <mat-icon class="menu-icon" style="color: white">check</mat-icon>
    </ng-template>
    <mat-step [completed]="true" [editable]="false" [state]="'done'">
      <ng-template matStepLabel>
        <span class="step-label" style="font-weight: normal !important">
          <span style="font-weight: bold; font-size: 15px">Spread the word</span
          ><br />Tell them about how great Remy would be for their business
        </span>
      </ng-template>
    </mat-step>

    <mat-step [completed]="true" [editable]="false" [state]="'done'">
      <ng-template matStepLabel>
        <span class="step-label">
          <span style="font-weight: bold; font-size: 15px"
            >Encourage Sign-Ups</span
          ><br />
          Ask them to sign up for a free trial on
          <span class="link">
            <a
              href="https://remyrewards.co.uk"
              (click)="ourWebsite()"
              target="_blank"
              >our website</a
            >
          </span>
          and mention your referral
        </span>
      </ng-template>
    </mat-step>

    <mat-step [completed]="true" [editable]="false">
      <ng-template matStepLabel>
        <span class="step-label">
          <span style="font-weight: bold; font-size: 15px"
            >Reap the Rewards</span
          ><br />
          They’ll get a free month after their trial and you'll get a month off
          too!
        </span>
      </ng-template>
    </mat-step>
  </mat-stepper>
</div>

<div
  *ngIf="!referralsLoading"
  style="
    background-color: var(--app-container);
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
  "
>
  <div style="font-weight: 600; font-size: 15px">My Referrals</div>
  <div style="font-weight: 400; font-size: 13px; color: grey">
    Merchants you refer to Remy will show here, you can refer up to 12 per year
  </div>

  <div class="d-flex justify-content-center" style="margin-top: 20px">
    <div class="grid-container">
      <div class="grid-item" *ngFor="let r of maxReferrals; let i = index">
        <i class="icon" *ngIf="!usedReferrals[i]"
          ><img class="q-img" src="../assets/q.png"
        /></i>
        <img
          class="filled-img"
          *ngIf="usedReferrals[i]"
          [src]="usedReferrals[i]?.referee?.logo?.url"
          [matTooltip]="tooltip(usedReferrals[i])"
        />
      </div>
    </div>
  </div>
</div>

<loader [loading]="referralsLoading"></loader>
