<page-header [title]="'Merchant Profile'" [subtitle]="subtitle"></page-header>

<form [formGroup]="merchantForm" *ngIf="!loading">
  <div class="row" style="margin-bottom: 70px">
    <div class="col-lg-6">
      <div class="detail-container" id="left" style="margin-bottom: 20px">
        <div class="form-field-label" style="color: black; font-weight: bold">
          Account Details
        </div>
        <div class="form-field" style="margin-top: 20px">
          <div class="form-field-label">First name</div>
          <input
            class="form-input"
            autocomplete="off"
            [formControl]="firstNameFormControl"
            type="text"
          />
        </div>
        <div class="form-field" style="margin-top: 20px">
          <div class="form-field-label">Last Name</div>
          <input
            class="form-input"
            autocomplete="off"
            [formControl]="lastNameFormControl"
            type="text"
          />
        </div>

        <div class="form-field" style="margin-top: 20px">
          <div class="form-field-label">Email</div>
          <input
            class="form-input"
            autocomplete="off"
            [formControl]="emailFormControl"
            type="email"
          />
        </div>

        <div class="action-container">
          <app-secondary-button
            [title]="'Change Password'"
            [disabled]="loading || saving"
            (tapped)="changePassword()"
          ></app-secondary-button>
          <app-primary-button
            [title]="'Save'"
            [disabled]="loading || saving"
            (tapped)="updateMerchant()"
            bgColor="var(--app-timber)"
            color="white"
          ></app-primary-button>
        </div>
      </div>

      <!-- <div class="detail-container" id="left" style="margin-bottom: 20px">
        <div
          class="form-field-label"
          style="color: black; font-weight: bold; margin-bottom: 0px"
        >
          Email Reports
        </div>
        <div
          class="form-field-label"
          style="
            color: var(--app-text-grey);
            font-weight: normal;
            font-size: 14px;
          "
        >
          You can receive activity reports by email at a frequency of your
          choosing
        </div>
        <div class="form-field" style="margin-top: 20px">
          <mat-checkbox
            style="margin-right: 10px"
            [checked]="dailyReports"
            (change)="reportChecked($event.checked, 'daily')"
            >Daily<span
              style="
                color: var(--app-text-grey);
                margin-left: 5px;
                font-style: italic;
              "
              >(9pm GMT)</span
            ></mat-checkbox
          ><br />
          <mat-checkbox
            style="margin-right: 10px"
            [checked]="weeklyReports"
            (change)="reportChecked($event.checked, 'weekly')"
            >Weekly<span
              style="
                color: var(--app-text-grey);
                margin-left: 5px;
                font-style: italic;
              "
              >(Monday 9am GMT)</span
            ></mat-checkbox
          ><br />
          <mat-checkbox
            [checked]="monthlyReports"
            (change)="reportChecked($event.checked, 'monthly')"
            >Monthly<span
              style="
                color: var(--app-text-grey);
                margin-left: 5px;
                font-style: italic;
              "
              >(1st of month, 9am GMT)</span
            ></mat-checkbox
          >
        </div>
      </div> -->
    </div>

    <div class="col-lg-6">
      <div class="detail-container" id="left">
        <div class="form-field-label" style="color: black; font-weight: bold">
          Business Details
        </div>
        <div class="form-field" style="margin-top: 20px">
          <div class="form-field-label">Business Name</div>
          <input
            class="form-input"
            placeholder="e.g. Coffee Shops LTD"
            autocomplete="off"
            [formControl]="merchantNameFormControl"
            type="text"
          />
        </div>

        <div class="form-field" style="margin-top: 20px">
          <single-select
            label="Business Type"
            [formCtrl]="businessTypeFormControl"
            [options]="businessTypes"
            [value]="businessTypeFormControl.value"
            [showIcon]="false"
          ></single-select>
        </div>

        <div class="form-field-label" style="margin-top: 20px">Logo</div>
        <file-upload
          [enabled]="canManageMerchant"
          [aspectRatio]="1 / 1"
          (fileSelected)="selectedLogo = $event"
          [readerResult]="initialMerchantLogo ?? ''"
        ></file-upload>

        <div
          class="form-field"
          style="margin-top: 20px"
          *ngIf="canManageMerchant"
        >
          <mat-radio-group
            [value]="selectedOption"
            aria-label="Select an option"
            (change)="selectedOption = $event.value"
          >
            <div class="form-field-label" style="text-align: left">
              Location Branding<span
                ><info-icon [text]="tooltip"></info-icon
              ></span>
            </div>
            <div
              *ngFor="let option of options"
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-left: 20px;
              "
            >
              <span>{{ option.label }}</span>
              <mat-radio-button [value]="option.value"></mat-radio-button>
            </div>
          </mat-radio-group>
        </div>

        <div class="action-container" *ngIf="canManageMerchant">
          <app-primary-button
            [title]="'Save'"
            [disabled]="loading || saving"
            (tapped)="updateMerchant()"
            bgColor="var(--app-timber)"
            color="white"
          ></app-primary-button>
        </div>
      </div>
    </div>
  </div>
</form>

<loader [loading]="loading"></loader>
<loader [loading]="saving"></loader>
