<dialog-header
  title="Joining Birthday Club"
  (onClose)="matDialogRef.close()"
></dialog-header>

<div mat-dialog-content>
  <div class="carousel-container">
    <button (click)="previous()" class="nav-arrow left-arrow">&#10094;</button>

    <div
      class="content-block"
      *ngFor="let block of contentBlocks; let i = index"
      [ngClass]="{ active: i === currentIndex }"
    >
      <p class="title">{{ block.title }}</p>
      <img [src]="block.image" />
    </div>

    <button (click)="next()" class="nav-arrow right-arrow">&#10095;</button>
  </div>
</div>
