import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import { Constants } from 'src/app/app.constants';
import { AnalyticsAbstract } from 'src/app/services/analytics/analytics.abstract';
import { PlanSuccessDialogComponent } from 'src/app/shared/components/plan-success-dialog/plan-success-dialog.component';

import {
  CardType,
  Redemption,
  StampEvent,
  UserCard,
  UserReward,
} from '../../models/index';

import {
  AuthService,
  CardService,
  GoogleAnalyticsService,
  InsightsService,
  MerchantService,
  RewardService,
  StorageService,
} from '../../services/index';
import { OnboardComponent } from 'src/app/shared/components/onboard-component/onboard.component';
import { PermissionsService } from 'src/app/services/permissions.service';

interface Insights {
  stampEvents?: string;
  points?: string;
  customers?: string;
  cards?: string;
  rewards?: string;
  redemptions?: string;
  showStamps?: boolean;
  showPoints?: boolean;
  showLocation?: boolean;
}

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent {
  headlineImageSize = 30;
  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'determinate';

  options: { value: string; icon: string; label: string }[] = [
    { value: 'line', icon: 'show_chart', label: 'Line' },
    { value: 'bar', icon: 'bar_chart', label: 'Bar' },
  ];

  exporting = false;
  title = '';
  subtitle: string = '';
  @ViewChild('stampsTable') stampsTable: any;
  @ViewChild('pointsTable') pointsTable: any;
  @ViewChild('cardsTable') cardsTable: any;
  @ViewChild('rewardsTable') rewardsTable: any;
  @ViewChild('redemptionsTable') redemptionsTable: any;

  stampPageIndex = 0;
  stampPageSize = 20;
  stampLength = 0;

  pointsPageIndex = 0;
  pointsPageSize = 20;
  pointsLength = 0;

  redemptionPageIndex = 0;
  redemptionPageSize = 20;
  redemptionLength = 0;

  cardsPageIndex = 0;
  cardsPageSize = 20;
  cardsLength = 0;

  rewardsPageIndex = 0;
  rewardsPageSize = 20;
  rewardsLength = 0;

  recentStamps: StampEvent[] = [];
  recentPoints: StampEvent[] = [];
  redemptions: Redemption[] = [];
  cards: UserCard[] = [];
  rewards: UserReward[] = [];

  insights: Insights = {};

  eventsLoading = false;
  redemptionsLoading = false;
  cardsLoading = false;
  rewardsLoading = false;
  insightsLoading = false;
  initialLoading = false;
  customerStatsLoading = false;
  hourlyLoading = false;

  error = false;

  tabs = ['stamps', 'points', 'redemptions', 'cards', 'rewards'];
  selectedTab = '';
  selectedIndex = 0;

  scrollTimeout: any;
  suppressPaging: boolean = false;

  pointsRange = '';
  stampsRange = '';
  redemptionsRange = '';
  cardsRange = '';
  rewardsRange = '';
  days = '30';
  customerId?: string;
  menuOptions: any[] = [
    {
      title: this.constants.strings.exportAllData,
      icon: 'mark_chat_unread',
    },
    {
      title: this.constants.strings.lapsedCustomerReport,
      icon: 'mark_chat_unread',
    },
  ];

  dateOptions: any[] = [
    { title: 'Last 7 days', value: '7' },
    { title: 'Last 30 days', value: '30' },
    { title: 'Last 3 months', value: '90' },
    { title: 'Last 6 months', value: '180' },
    { title: 'Last 12 months', value: '360' },
  ];

  viewType: string = 'line';
  tab: any;

  showLocation = false;
  canViewCustomers = false;

  constructor(
    public constants: Constants,
    private router: Router,
    private route: ActivatedRoute,
    public authService: AuthService,
    private cardService: CardService,
    private rewardService: RewardService,
    private insightsService: InsightsService,
    public dialog: MatDialog,
    private merchantService: MerchantService,
    private ga: GoogleAnalyticsService,
    private storageService: StorageService,
    private mixpanel: AnalyticsAbstract,
    private breakpointObserver: BreakpointObserver,
    private ps: PermissionsService
  ) {
    this.viewType = this.storageService.get('view') ?? 'line';

    this.canViewCustomers = this.ps.permissions.customers.view();

    this.route.queryParams.subscribe((params) => {
      this.tab = params['tab'];
      const customerId = params['customerId'];
      const p = params['p'];
      const days = params['days'];

      if (customerId) {
        this.customerId = customerId;
        this.removeCustomerIdQueryParam();
      }

      if (p) {
        this.stampPageIndex = parseFloat(p);
      }

      if (days) {
        this.days = days;
      }
    });
  }

  ngOnInit() {
    if (this.customerId?.slice(0, 4) === 'cus_') {
      this.dialog.open(PlanSuccessDialogComponent, {
        data: {
          customerId: this.customerId,
        },
        autoFocus: false,
        width: '400px',
        minHeight: '350px',
        disableClose: true,
        panelClass: 'custom-dialog',
        scrollStrategy: new NoopScrollStrategy(),
      });
    }

    this.initialLoading = true;
    this.getDashboardInsights();
  }

  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result: BreakpointState) => {
        return result.matches;
      })
    );

  tapped(item: any): void {
    this.days = item.value;

    this.mixpanel.track(Constants.analytics_keys.toggleGraphDays, {
      Days: this.days,
    });
  }

  removeCustomerIdQueryParam(): void {
    const urlTree = this.router.createUrlTree([], {
      queryParams: { ...this.route.snapshot.queryParams },
      queryParamsHandling: 'merge',
      preserveFragment: true,
    });

    delete urlTree.queryParams['customerId'];

    this.router.navigateByUrl(urlTree, { replaceUrl: true });
  }

  get selected(): any {
    return this.dateOptions.find((el) => el.value === this.days).title;
  }

  loadData(): void {
    if (this.selectedTab === 'stamps') {
      this.getStampEvents(false);
    }
    if (this.selectedTab === 'points') {
      this.getStampEvents(true);
    }
    if (this.selectedTab === 'redemptions') {
      this.getRedemptions();
    }
    if (this.selectedTab === 'cards') {
      this.getUserCards();
    }
    if (this.selectedTab === 'rewards') {
      this.getUserRewards();
    }
  }

  get pageLoading(): boolean {
    return this.initialLoading;
  }

  get loading(): boolean {
    return (
      this.insightsLoading ||
      this.eventsLoading ||
      this.redemptionsLoading ||
      this.exporting ||
      this.cardsLoading ||
      this.rewardsLoading ||
      this.customerStatsLoading
    );
  }

  getCustomerStats(): void {
    this.customerStatsLoading = true;
    this.insightsService
      .getCustomerStats(+this.days)
      .subscribe({
        next: (res: any) => {
          if (res) {
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.customerStatsLoading = false));
  }

  getDashboardInsights(): void {
    this.insightsLoading = true;
    this.insightsService
      .getDashboardInsights()
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.insights = res;

            if (this.insights.showPoints == false) {
              this.tabs = this.tabs.filter((el) => el !== 'points');
            }
            if (this.insights.showStamps == false) {
              this.tabs = this.tabs.filter((el) => el !== 'stamps');
            }

            this.showLocation = this.insights?.showLocation ?? false;

            if (this.tab) {
              this.selectedTab = this.tab;
              this.selectedIndex = this.tabs.findIndex((el) => el === this.tab);
            } else {
              this.selectedTab = this.tabs[0];
            }

            this.loadData();
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(
        () => ((this.insightsLoading = false), (this.initialLoading = false))
      );
    this.subtitle = this.authService.getNestedUserProperty('merchant', 'name');
  }

  getUserCards(): void {
    this.cardsLoading = true;

    this.merchantService
      .getCustomerCards(this.cardsPageSize, this.cardsPageIndex)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.cardsLength = res.totalDocs;
            this.cards = res.docs;

            this.cardsRange = this.constants.pageCounter(
              this.cardsPageIndex,
              this.cardsPageSize,
              this.cardsLength,
              this.cards?.length
            );
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.cardsLoading = false));
  }

  getUserRewards(): void {
    this.rewardsLoading = true;

    this.merchantService
      .getCustomerRewards(this.rewardsPageSize, this.rewardsPageIndex)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.rewardsLength = res.totalDocs;
            this.rewards = res.docs;

            this.rewardsRange = this.constants.pageCounter(
              this.rewardsPageIndex,
              this.rewardsPageSize,
              this.rewardsLength,
              this.rewards?.length
            );
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.rewardsLoading = false));
  }

  getStampEvents(points: boolean): void {
    this.eventsLoading = true;
    this.cardService
      .getStampEvents(
        points ? this.pointsPageSize : this.stampPageSize,
        points ? this.pointsPageIndex : this.stampPageIndex,
        undefined,
        undefined,
        points
      )
      .subscribe({
        next: (res: any) => {
          if (res) {
            if (points) {
              this.pointsLength = res.totalDocs;
              this.recentPoints = res.docs;

              this.pointsRange = this.constants.pageCounter(
                this.pointsPageIndex,
                this.pointsPageSize,
                this.pointsLength,
                this.recentPoints?.length
              );
            } else {
              this.stampLength = res.totalDocs;
              this.recentStamps = res.docs;

              this.stampsRange = this.constants.pageCounter(
                this.stampPageIndex,
                this.stampPageSize,
                this.stampLength,
                this.recentStamps?.length
              );
            }
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.eventsLoading = false));
  }

  getRedemptions(): void {
    this.redemptionsLoading = true;
    this.rewardService
      .getRedemptions(this.redemptionPageSize, this.redemptionPageIndex)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.redemptionLength = res.totalDocs;
            this.redemptions = res.docs;

            this.redemptionsRange = this.constants.pageCounter(
              this.redemptionPageIndex,
              this.redemptionPageSize,
              this.redemptionLength,
              this.redemptions?.length
            );
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.redemptionsLoading = false));
  }

  addQueryParams(params: any) {
    this.route.queryParams.subscribe((queryParams) => {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: params,
        queryParamsHandling: 'merge',
        skipLocationChange: false,
      });
    });
  }

  tabChanged(index: any): void {
    let tab = this.tabs[index];

    this.addQueryParams({ tab: tab, p: 0, days: this.days });

    this.selectedTab = tab;

    if (index === 0) {
      this.ga.event('db_stamps');
    } else if (index === 1) {
      this.ga.event('db_points');
    } else if (index === 2) {
      this.ga.event('db_redemptions');
    } else if (index === 3) {
      this.ga.event('db_cards');
    } else {
      this.ga.event('db_rewards');
    }

    this.mixpanel.track(Constants.analytics_keys.selectDashboardTab, {
      Tab: tab,
    });

    this.loadData();
  }

  cardPageEvent(event: any) {
    if (!this.suppressPaging) {
      this.cardsPageIndex = event.offset;
      this.addQueryParams({ p: this.cardsPageIndex });
      this.getUserCards();
    }
  }

  rewardPageEvent(event: any) {
    if (!this.suppressPaging) {
      this.rewardsPageIndex = event.offset;
      this.addQueryParams({ p: this.rewardsPageIndex });
      this.getUserRewards();
    }
  }

  stampPageEvent(event: any) {
    if (!this.suppressPaging) {
      this.stampPageIndex = event.offset;
      this.addQueryParams({ p: this.stampPageIndex });
      this.getStampEvents(false);
    }
  }

  pointsPageEvent(event: any) {
    if (!this.suppressPaging) {
      this.pointsPageIndex = event.offset;
      this.addQueryParams({ p: this.pointsPageIndex });
      this.getStampEvents(true);
    }
  }

  redemptionPageEvent(event: any) {
    if (!this.suppressPaging) {
      this.redemptionPageIndex = event.offset;
      this.addQueryParams({ p: this.redemptionPageIndex });
      this.getRedemptions();
    }
  }

  handleScroll() {
    this.suppressPaging = true;

    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }

    this.scrollTimeout = setTimeout(() => {
      this.suppressPaging = false;
    }, 100);
  }

  viewTypeToggled(type: string): void {
    this.mixpanel.track(Constants.analytics_keys.toggleGraphType, {
      'Graph type': type,
    });

    this.storageService.set('view', type);
    this.viewType = type;
  }

  daysSelected(days: any) {
    this.days = days;
    this.addQueryParams({ days: this.days });
  }

  valueObtainedPercent(card: any): number {
    const stampCard =
      card?.card && typeof card.card !== 'string'
        ? card.card?.[0] ?? card.card
        : card.card;

    if (stampCard?.cardType == CardType.point) {
      const totalPoints = stampCard?.points?.totalPoints ?? 0;
      const currentPoints = card?.numberOfPoints ?? 0;
      return totalPoints ? (currentPoints / totalPoints) * 100 : 0;
    } else {
      const totalStamps = stampCard?.numberOfStamps ?? 0;
      const currentStamps = card?.numberOfStamps ?? 0;
      return totalStamps ? (currentStamps / totalStamps) * 100 : 0;
    }
  }

  valueObtained(card: any): string {
    const stampCard =
      card?.card && typeof card.card !== 'string'
        ? card.card?.[0] ?? card.card
        : card.card;

    if (stampCard?.cardType === CardType.point) {
      const totalPoints = stampCard?.points?.totalPoints ?? 0;
      const currentPoints = card?.numberOfPoints ?? 0;

      return `${
        currentPoints > totalPoints ? totalPoints : currentPoints
      }/${totalPoints}`;
    } else {
      const totalStamps = stampCard?.numberOfStamps ?? 0;
      const currentStamps = card?.numberOfStamps ?? 0;

      return `${
        currentStamps > totalStamps ? totalStamps : currentStamps
      }/${totalStamps}`;
    }
  }

  get firstContainerLabel(): string {
    if (this.insights?.showPoints && !this.insights.showStamps) {
      return 'Points';
    } else if (!this.insights.showPoints && this.insights.showStamps) {
      return 'Stamps';
    } else if (this.insights.showPoints && this.insights.showStamps) {
      return this.selectedTab === 'points' ? 'Points' : 'Stamps';
    }

    return 'Stamps';
  }

  get firstContainerValue(): string {
    if (this.insights?.showPoints && !this.insights.showStamps) {
      return this.insights.points
        ? this.formatNumber(+this.insights.points)
        : '0';
    } else if (!this.insights.showPoints && this.insights.showStamps) {
      return this.insights.stampEvents
        ? this.formatNumber(+this.insights.stampEvents)
        : '0';
    } else if (this.insights.showPoints && this.insights.showStamps) {
      return this.selectedTab === 'points'
        ? this.insights.points
          ? this.formatNumber(+this.insights.points)
          : '0'
        : this.insights.stampEvents
        ? this.formatNumber(+this.insights.stampEvents)
        : '0';
    }

    return '0';
  }

  formatNumber(value: number): string {
    if (value >= 1000) {
      return (value / 1000).toFixed(1) + 'k';
    }
    return value.toString();
  }

  get showCardTypeColumn(): boolean {
    const hasDifferentTypes = !this.cards.every((card) => {
      if (Array.isArray(card.card) && Array.isArray(this.cards[0]?.card)) {
        return card.card?.[0]?.cardType === this.cards[0].card?.[0]?.cardType;
      } else {
        return false;
      }
    });

    return hasDifferentTypes;
  }
}
