import { Component } from '@angular/core';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.css'],
})
export class ColorPickerComponent {
  color = '#000000';

  openColorPicker(input: HTMLInputElement): void {
    input.click();
  }

  onColorChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.setColor(input.value);
  }

  setColor(newColor: string): void {
    this.color = newColor;
  }
}
