import { Component, Inject, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constants } from 'src/app/app.constants';

import { RewardService } from '../../../services/index';

@Component({
  selector: 'change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.css'],
})
export class ChangePasswordDialogComponent implements OnInit {
  form!: FormGroup;

  currentPasswordFormControl = new FormControl(
    { value: '', disabled: false },
    Validators.required
  );

  newPasswordFormControl = new FormControl(
    { value: '', disabled: false },
    Validators.required
  );

  confirmPasswordFormControl = new FormControl(
    { value: '', disabled: false },
    Validators.required
  );

  loading = false;
  error = false;

  constructor(
    public matDialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private rewardService: RewardService,
    private constants: Constants
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      current: this.currentPasswordFormControl,
      new: this.newPasswordFormControl,
      confirm: this.confirmPasswordFormControl,
    });
  }

  get passwordValid(): boolean {
    return (
      this.constants.passwordValid(this.newPasswordFormControl.value ?? '') &&
      this.constants.passwordValid(this.confirmPasswordFormControl.value ?? '')
    );
  }

  primaryTapped() {
    if (this.form?.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    let newPassword = this.newPasswordFormControl.value ?? '';
    let confirm = this.confirmPasswordFormControl.value ?? '';

    if (newPassword !== confirm) {
      this.constants.snack('Password do not match');
      return;
    }

    if (!this.passwordValid) {
      this.constants.snack(
        'Password must contain at least 6 characters and 1 digit'
      );
      return;
    }

    this.matDialogRef.close({
      current: this.currentPasswordFormControl.value,
      new: newPassword,
      confirm: confirm,
    });
  }
}
