<loader [loading]="loading"></loader>

<dialog-header
  title="Select Merchant"
  (onClose)="matDialogRef.close()"
  [canClose]="false"
></dialog-header>

<div mat-dialog-content>
  <form [formGroup]="form">
    <div class="form-field" style="margin-top: 20px">
      <single-select
        label="Merchant"
        [formCtrl]="merchantFormControl"
        [options]="merchants"
        property="name"
        [showIcon]="false"
        [showSearch]="true"
      ></single-select>
    </div>
  </form>
</div>

<div mat-dialog-actions align="end" *ngIf="!loading">
  <app-primary-button
    [title]="'Next'"
    [disabled]="form.invalid"
    (tapped)="primaryTapped()"
    bgColor="var(--app-timber)"
    color="white"
  ></app-primary-button>
</div>
