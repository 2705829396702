<page-header [title]="'Integrations'" [subtitle]="subtitle"></page-header>
<div class="row">
  <div class="col-xl-8">
    <div class="detail-container" id="left" style="margin-bottom: 20px">
      <div class="row">
        <div class="col-lg-12">
          <mat-tab-group
            disableRipple="true"
            [animationDuration]="0"
            (selectedTabChange)="tabChanged($event.index)"
            [(selectedIndex)]="selectedIndex"
          >
            <mat-tab label="Shopify">
              <ng-template matTabContent>
                <div *ngIf="!hasShopify">
                  <div
                    class="form-field-label"
                    style="
                      color: var(--app-timber);
                      text-align: center;
                      padding: 40px;
                      margin-bottom: 0px;
                    "
                  >
                    Please contact us to enable the Shopify Integration
                  </div>
                </div>
                <div *ngIf="hasShopify">
                  <form [formGroup]="form">
                    <div class="form-field" style="margin-top: 20px">
                      <div
                        class="form-field-label"
                        style="color: var(--app-timber)"
                      >
                        <span
                          ><img
                            src="../../../assets/shopify-logo.png"
                            width="20"
                            height="20"
                            style="margin-right: 5px" /></span
                        >Shopify Integration Overview
                      </div>
                      <p class="dialog-body" [innerHTML]="overviewText"></p>
                    </div>

                    <div class="form-field" style="margin-top: 20px">
                      <div
                        class="form-field-label"
                        style="color: var(--app-timber); margin-bottom: 0px"
                      >
                        Webhook URL
                      </div>
                      <link-text
                        (onTapped)="showInfoDialog(titleOne, bodyOne)"
                        text="What should I do with this?"
                      ></link-text>

                      <div
                        style="
                          display: flex;
                          align-items: center;
                          cursor: pointer;
                        "
                      >
                        <input
                          type="text"
                          class="form-input"
                          [formControl]="webhookFormControl"
                        />
                        <div class="copy" (click)="copyWebhookUrl()">
                          <mat-icon class="copy-icon">{{
                            "content_copy"
                          }}</mat-icon
                          ><span>Copy</span>
                        </div>
                      </div>
                    </div>

                    <div class="form-field" style="margin-top: 20px">
                      <div
                        class="form-field-label"
                        style="
                          color: var(--app-timber);
                          margin-bottom: 0px;
                          margin-top: 20px;
                        "
                      >
                        Webhook Signing Key<span style="color: red">*</span>
                      </div>

                      <link-text
                        (onTapped)="showInfoDialog(titleTwo, bodyTwo)"
                        text="Where can I find this?"
                      ></link-text>

                      <div style="display: flex; align-items: center">
                        <input
                          type="text"
                          class="form-input"
                          [formControl]="shopifySigningKey"
                        />
                      </div>
                    </div>

                    <div
                      class="form-field"
                      style="
                        margin-top: 20px;
                        display: flex;
                        justify-content: space-between;
                      "
                    >
                      <div class="form-field-label">
                        Invite customers without a Remy account via email<span>
                          <info-icon [text]="inviteUsersTooltip"></info-icon>
                        </span>
                        <link-text
                          (onTapped)="viewExampleMail()"
                          text="View sample email"
                        ></link-text>
                      </div>
                      <mat-slide-toggle
                        [disabled]="false"
                        [checked]="inviteUsersShopify"
                        (change)="inviteUsersShopify = !inviteUsersShopify"
                      >
                      </mat-slide-toggle>
                    </div>

                    <div *ngIf="shopifyConfig?.signingKey">
                      <div class="form-field" style="margin-top: 20px">
                        <div
                          class="form-field-label"
                          style="color: var(--app-timber)"
                        >
                          Configurations
                        </div>

                        <div style="margin-bottom: 20px">
                          <div
                            class="social-container"
                            *ngFor="let item of shopifyConfig?.configurations"
                            style="
                              margin-top: 10px;
                              display: flex;
                              justify-content: space-between;
                            "
                          >
                            <div style="display: flex; align-items: center">
                              <div
                                class="form-field-label"
                                style="
                                  margin-bottom: 0px !important;
                                  color: black;
                                  font-size: 16px;
                                  margin-right: 5px;
                                "
                              >
                                {{ item.type | titlecase }}:
                              </div>
                              <div
                                class="form-field-label"
                                style="
                                  margin-bottom: 0px !important;
                                  color: black;
                                  font-size: 16px;
                                "
                              >
                                {{ rowLabel(item) }}
                              </div>
                            </div>

                            <div>
                              <mat-icon
                                style="
                                  color: var(--app-timber);
                                  cursor: pointer;
                                  margin-right: 5px;
                                "
                                (click)="editConfiguration(item)"
                                >edit</mat-icon
                              >
                              <mat-icon
                                style="color: red; cursor: pointer"
                                (click)="deleteConfiguration(item._id!)"
                                >cancel</mat-icon
                              >
                            </div>
                          </div>
                        </div>

                        <div class="add-container" (click)="addConfiguration()">
                          <div
                            class="popup-subtitle"
                            style="color: var(--app-text-grey)"
                          >
                            + Add a Configuration
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div class="action-container">
                    <app-primary-button
                      *ngIf="shopifyConfig?.signingKey"
                      [title]="'View Data'"
                      (tapped)="viewData()"
                      color="black"
                      border="1px solid var(--app-timber)"
                    ></app-primary-button>
                    <div>
                      <app-secondary-button
                        *ngIf="shopifyConfig?.signingKey"
                        [title]="'Remove Integration'"
                        [disabled]="saving"
                        (tapped)="remove('shopify')"
                        style="margin-right: 10px"
                      ></app-secondary-button>
                      <app-primary-button
                        [title]="'Save'"
                        [disabled]="saving"
                        (tapped)="save()"
                        bgColor="var(--app-timber)"
                        color="white"
                      ></app-primary-button>
                    </div>
                  </div>
                </div>
              </ng-template>
            </mat-tab>

            <mat-tab label="Square">
              <ng-template matTabContent>
                <div *ngIf="!hasSquare">
                  <div
                    class="form-field-label"
                    style="
                      color: var(--app-timber);
                      text-align: center;
                      padding: 40px;
                      margin-bottom: 0px;
                    "
                  >
                    Please contact us to enable the Square Integration
                  </div>
                </div>
                <div *ngIf="hasSquare">
                  <form [formGroup]="form">
                    <div class="form-field" style="margin-top: 20px">
                      <div
                        class="form-field-label"
                        style="color: var(--app-timber)"
                      >
                        <span
                          ><img
                            src="../../../assets/square-logo.png"
                            width="20"
                            height="20"
                            style="margin-right: 5px" /></span
                        >Square Integration Overview
                      </div>
                      <p class="dialog-body" [innerHTML]="overviewText"></p>
                    </div>

                    <div class="form-field" style="margin-top: 20px">
                      <div
                        class="form-field-label"
                        style="color: var(--app-timber); margin-bottom: 0px"
                      >
                        Webhook URL
                      </div>

                      <link-text
                        (onTapped)="
                          showInfoDialog(titleOneSquare, bodyOneSquare)
                        "
                        text="What should I do with this?"
                      ></link-text>

                      <div
                        style="
                          display: flex;
                          align-items: center;
                          cursor: pointer;
                        "
                      >
                        <input
                          type="text"
                          class="form-input"
                          [formControl]="webhookFormControl"
                        />
                        <div class="copy" (click)="copyWebhookUrl()">
                          <mat-icon class="copy-icon">{{
                            "content_copy"
                          }}</mat-icon
                          ><span>Copy</span>
                        </div>
                      </div>
                    </div>

                    <div class="form-field" style="margin-top: 20px">
                      <div
                        class="form-field-label"
                        style="
                          color: var(--app-timber);
                          margin-bottom: 0px;
                          margin-top: 20px;
                        "
                      >
                        Webhook Signature Key<span style="color: red">*</span>
                      </div>

                      <link-text
                        (onTapped)="
                          showInfoDialog(titleTwoSquare, bodyTwoSquare)
                        "
                        text="Where can I find this?"
                      ></link-text>

                      <div style="display: flex; align-items: center">
                        <input
                          type="text"
                          class="form-input"
                          [formControl]="squareSigningKey"
                        />
                      </div>
                    </div>

                    <div class="form-field" style="margin-top: 20px">
                      <div
                        class="form-field-label"
                        style="
                          color: var(--app-timber);
                          margin-bottom: 0px;
                          margin-top: 20px;
                        "
                      >
                        Access Token<span style="color: red">*</span>
                      </div>

                      <link-text
                        (onTapped)="
                          showInfoDialog(accessTokenTitle, accessTokenBody)
                        "
                        text="Where can I find this?"
                      ></link-text>

                      <div style="display: flex; align-items: center">
                        <input
                          type="text"
                          class="form-input"
                          [formControl]="squareAccessToken"
                        />
                      </div>
                    </div>

                    <div
                      class="form-field"
                      style="
                        margin-top: 20px;
                        display: flex;
                        justify-content: space-between;
                      "
                    >
                      <div class="form-field-label">
                        Automatically create customers in Square<span>
                          <info-icon [text]="addCustomersTooltip"></info-icon>
                        </span>
                      </div>
                      <mat-slide-toggle
                        [disabled]="false"
                        [checked]="addSquareCustomers"
                        (change)="addSquareCustomers = !addSquareCustomers"
                      >
                      </mat-slide-toggle>
                    </div>

                    <div
                      class="form-field"
                      style="
                        margin-top: 20px;
                        display: flex;
                        justify-content: space-between;
                      "
                    >
                      <div class="form-field-label">
                        Invite customers without a Remy account via email<span>
                          <info-icon [text]="inviteUsersTooltip"></info-icon>
                        </span>
                        <link-text
                          (onTapped)="viewExampleMail()"
                          text="View sample email"
                        ></link-text>
                      </div>
                      <mat-slide-toggle
                        [disabled]="false"
                        [checked]="inviteUsersSquare"
                        (change)="inviteUsersSquare = !inviteUsersSquare"
                      >
                      </mat-slide-toggle>
                    </div>

                    <div *ngIf="squareConfig?.signingKey">
                      <div class="form-field" style="margin-top: 20px">
                        <div
                          class="form-field-label"
                          style="color: var(--app-timber)"
                        >
                          Configurations
                        </div>

                        <div style="margin-bottom: 20px">
                          <div
                            class="social-container"
                            *ngFor="let item of squareConfig?.configurations"
                            style="
                              margin-top: 10px;
                              display: flex;
                              justify-content: space-between;
                            "
                          >
                            <div style="display: flex; align-items: center">
                              <div
                                class="form-field-label"
                                style="
                                  margin-bottom: 0px !important;
                                  color: black;
                                  font-size: 16px;
                                  margin-right: 5px;
                                "
                              >
                                {{ item.type | titlecase }}:
                              </div>
                              <div
                                class="form-field-label"
                                style="
                                  margin-bottom: 0px !important;
                                  color: black;
                                  font-size: 16px;
                                "
                              >
                                {{ rowLabel(item) }}
                              </div>
                            </div>

                            <div>
                              <mat-icon
                                style="
                                  color: var(--app-timber);
                                  cursor: pointer;
                                  margin-right: 5px;
                                "
                                (click)="editConfiguration(item)"
                                >edit</mat-icon
                              >
                              <mat-icon
                                style="color: red; cursor: pointer"
                                (click)="deleteConfiguration(item._id!)"
                                >cancel</mat-icon
                              >
                            </div>
                          </div>
                        </div>

                        <div class="add-container" (click)="addConfiguration()">
                          <div
                            class="popup-subtitle"
                            style="color: var(--app-text-grey)"
                          >
                            + Add a Configuration
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div class="action-container">
                    <app-primary-button
                      *ngIf="squareConfig?.signingKey"
                      [title]="'View Data'"
                      (tapped)="viewData()"
                      color="black"
                      border="1px solid var(--app-timber)"
                    ></app-primary-button>
                    <div>
                      <app-secondary-button
                        *ngIf="squareConfig?.signingKey"
                        [title]="'Remove Integration'"
                        [disabled]="saving"
                        (tapped)="remove('square')"
                        style="margin-right: 10px"
                      ></app-secondary-button>
                      <app-primary-button
                        [title]="'Save'"
                        [disabled]="saving"
                        (tapped)="save()"
                        bgColor="var(--app-timber)"
                        color="white"
                      ></app-primary-button>
                    </div>
                  </div>
                </div>
              </ng-template>
            </mat-tab>

            <mat-tab label="SumUp">
              <ng-template matTabContent>
                <div *ngIf="!hasSumup">
                  <div
                    class="form-field-label"
                    style="
                      color: var(--app-timber);
                      text-align: center;
                      padding: 40px;
                      margin-bottom: 0px;
                    "
                  >
                    Please contact us to enable the SumUp Integration
                  </div>
                </div>
                <div *ngIf="hasSumup">
                  <form [formGroup]="form">
                    <div class="form-field" style="margin-top: 20px">
                      <div
                        class="form-field-label"
                        style="color: var(--app-timber)"
                      >
                        <span
                          ><img
                            src="../../../assets/sumup.png"
                            width="20"
                            height="20"
                            style="margin-right: 5px" /></span
                        >SumUp Integration Overview
                      </div>
                      <p class="dialog-body" [innerHTML]="overviewText"></p>
                    </div>

                    <div class="form-field" style="margin-top: 20px">
                      <div
                        class="form-field-label"
                        style="color: var(--app-timber); margin-bottom: 0px"
                      >
                        Webhook URL
                      </div>

                      <link-text
                        (onTapped)="showInfoDialog(titleOneSumup, bodyOneSumup)"
                        text="What should I do with this?"
                      ></link-text>

                      <div
                        style="
                          display: flex;
                          align-items: center;
                          cursor: pointer;
                        "
                      >
                        <input
                          type="text"
                          class="form-input"
                          [formControl]="webhookFormControl"
                        />
                        <div class="copy" (click)="copyWebhookUrl()">
                          <mat-icon class="copy-icon">{{
                            "content_copy"
                          }}</mat-icon
                          ><span>Copy</span>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-field" style="margin-top: 20px">
                          <div
                            class="form-field-label"
                            style="
                              color: var(--app-timber);
                              margin-bottom: 0px;
                              margin-top: 20px;
                            "
                          >
                            Verification Token<span style="color: red">*</span>
                          </div>

                          <link-text
                            (onTapped)="
                              showInfoDialog(titleTwoSumup, bodyTwoSumup)
                            "
                            text="Where can I find this?"
                          ></link-text>

                          <div style="display: flex; align-items: center">
                            <input
                              type="text"
                              class="form-input"
                              [formControl]="sumupSigningKey"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-field" style="margin-top: 20px">
                          <div
                            class="form-field-label"
                            style="
                              color: var(--app-timber);
                              margin-bottom: 0px;
                              margin-top: 20px;
                            "
                          >
                            Subdomain<span style="color: red">*</span>
                          </div>

                          <link-text
                            (onTapped)="
                              showInfoDialog(titleThreeSumup, bodyThreeSumup)
                            "
                            text="Where can I find this?"
                          ></link-text>

                          <div style="display: flex; align-items: center">
                            <input
                              type="text"
                              class="form-input"
                              [formControl]="sumupSubdomain"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-field" style="margin-top: 20px">
                          <div class="form-field-label">
                            Username<span style="color: red">*</span>
                          </div>
                          <input
                            class="form-input"
                            autocomplete="off"
                            [formControl]="sumupUsername"
                            type="text"
                          />
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-field" style="margin-top: 20px">
                          <div class="form-field-label">
                            Password<span style="color: red">*</span>
                          </div>
                          <input
                            class="form-input"
                            autocomplete="off"
                            [formControl]="sumupPassword"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      class="form-field"
                      style="
                        margin-top: 20px;
                        display: flex;
                        justify-content: space-between;
                      "
                    >
                      <div class="form-field-label">
                        Automatically create customers in SumUp<span>
                          <info-icon [text]="addCustomersTooltip"></info-icon>
                        </span>
                      </div>
                      <mat-slide-toggle
                        [disabled]="false"
                        [checked]="addSumupCustomers"
                        (change)="addSumupCustomers = !addSumupCustomers"
                      >
                      </mat-slide-toggle>
                    </div>

                    <div
                      class="form-field"
                      style="
                        margin-top: 20px;
                        display: flex;
                        justify-content: space-between;
                      "
                    >
                      <div class="form-field-label">
                        Invite customers without a Remy account via email<span>
                          <info-icon [text]="inviteUsersTooltip"></info-icon>
                        </span>
                        <link-text
                          (onTapped)="viewExampleMail()"
                          text="View sample email"
                        ></link-text>
                      </div>
                      <mat-slide-toggle
                        [disabled]="false"
                        [checked]="inviteUsersSumup"
                        (change)="inviteUsersSumup = !inviteUsersSumup"
                      >
                      </mat-slide-toggle>
                    </div>

                    <div *ngIf="sumupConfig?.signingKey">
                      <div class="form-field" style="margin-top: 20px">
                        <div
                          class="form-field-label"
                          style="color: var(--app-timber)"
                        >
                          Configurations
                        </div>

                        <div style="margin-bottom: 20px">
                          <div
                            class="social-container"
                            *ngFor="let item of sumupConfig?.configurations"
                            style="
                              margin-top: 10px;
                              display: flex;
                              justify-content: space-between;
                            "
                          >
                            <div style="display: flex; align-items: center">
                              <div
                                class="form-field-label"
                                style="
                                  margin-bottom: 0px !important;
                                  color: black;
                                  font-size: 16px;
                                  margin-right: 5px;
                                "
                              >
                                {{ item.type | titlecase }}:
                              </div>
                              <div
                                class="form-field-label"
                                style="
                                  margin-bottom: 0px !important;
                                  color: black;
                                  font-size: 16px;
                                "
                              >
                                {{ rowLabel(item) }}
                              </div>
                            </div>

                            <div>
                              <mat-icon
                                style="
                                  color: var(--app-timber);
                                  cursor: pointer;
                                  margin-right: 5px;
                                "
                                (click)="editConfiguration(item)"
                                >edit</mat-icon
                              >
                              <mat-icon
                                style="color: red; cursor: pointer"
                                (click)="deleteConfiguration(item._id!)"
                                >cancel</mat-icon
                              >
                            </div>
                          </div>
                        </div>

                        <div class="add-container" (click)="addConfiguration()">
                          <div
                            class="popup-subtitle"
                            style="color: var(--app-text-grey)"
                          >
                            + Add a Configuration
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div class="action-container">
                    <app-primary-button
                      *ngIf="sumupConfig?.signingKey"
                      [title]="'View Data'"
                      (tapped)="viewData()"
                      color="black"
                      border="1px solid var(--app-timber)"
                    ></app-primary-button>
                    <div>
                      <app-secondary-button
                        *ngIf="sumupConfig?.signingKey"
                        [title]="'Remove Integration'"
                        [disabled]="saving"
                        (tapped)="remove('sumup')"
                        style="margin-right: 10px"
                      ></app-secondary-button>
                      <app-primary-button
                        [title]="'Save'"
                        [disabled]="saving"
                        (tapped)="save()"
                        bgColor="var(--app-timber)"
                        color="white"
                      ></app-primary-button>
                    </div>
                  </div>
                </div>
              </ng-template>
            </mat-tab>

            <mat-tab label="EposNow">
              <ng-template matTabContent>
                <div *ngIf="!hasEposNow">
                  <div
                    class="form-field-label"
                    style="
                      color: var(--app-timber);
                      text-align: center;
                      padding: 40px;
                      margin-bottom: 0px;
                    "
                  >
                    Please contact us to enable the EposNow Integration
                  </div>
                </div>
                <div *ngIf="hasEposNow">
                  <form [formGroup]="form">
                    <div class="form-field" style="margin-top: 20px">
                      <div
                        class="form-field-label"
                        style="color: var(--app-timber)"
                      >
                        <span
                          ><img
                            src="../../../assets/eposnow.png"
                            width="20"
                            height="20"
                            style="margin-right: 5px" /></span
                        >EposNow Integration Overview
                      </div>
                      <p class="dialog-body" [innerHTML]="overviewText"></p>
                    </div>

                    <div class="form-field" style="margin-top: 20px">
                      <div
                        class="form-field-label"
                        style="color: var(--app-timber); margin-bottom: 0px"
                      >
                        Webhook URL
                      </div>

                      <link-text
                        (onTapped)="
                          showInfoDialog(titleOneEposnow, bodyOneEposNow)
                        "
                        text="What should I do with this?"
                      ></link-text>

                      <div
                        style="
                          display: flex;
                          align-items: center;
                          cursor: pointer;
                        "
                      >
                        <input
                          type="text"
                          class="form-input"
                          [formControl]="webhookEposNowFormControl"
                        />
                        <div class="copy" (click)="copyWebhookUrl()">
                          <mat-icon class="copy-icon">{{
                            "content_copy"
                          }}</mat-icon
                          ><span>Copy</span>
                        </div>
                      </div>
                    </div>

                    <div class="form-field" style="margin-top: 20px">
                      <div
                        class="form-field-label"
                        style="
                          color: var(--app-timber);
                          margin-bottom: 0px;
                          margin-top: 20px;
                        "
                      >
                        Authorization Token<span style="color: red">*</span>
                      </div>

                      <link-text
                        (onTapped)="
                          showInfoDialog(
                            accessTokenTitleEposnow,
                            accessTokenBodyEposnow
                          )
                        "
                        text="Where can I find this?"
                      ></link-text>

                      <div style="display: flex; align-items: center">
                        <input
                          type="text"
                          class="form-input"
                          [formControl]="eposNowAccessToken"
                        />
                      </div>
                    </div>

                    <div
                      class="form-field"
                      style="
                        margin-top: 20px;
                        display: flex;
                        justify-content: space-between;
                      "
                    >
                      <div class="form-field-label">
                        Automatically create customers in EposNow<span>
                          <info-icon [text]="addCustomersTooltip"></info-icon>
                        </span>
                      </div>
                      <mat-slide-toggle
                        [disabled]="false"
                        [checked]="addEposNowCustomers"
                        (change)="addEposNowCustomers = !addEposNowCustomers"
                      >
                      </mat-slide-toggle>
                    </div>

                    <div
                      class="form-field"
                      style="
                        margin-top: 20px;
                        display: flex;
                        justify-content: space-between;
                      "
                    >
                      <div class="form-field-label">
                        Invite customers without a Remy account via email<span>
                          <info-icon [text]="inviteUsersTooltip"></info-icon>
                        </span>
                        <link-text
                          (onTapped)="viewExampleMail()"
                          text="View sample email"
                        ></link-text>
                      </div>
                      <mat-slide-toggle
                        [disabled]="false"
                        [checked]="inviteUsersEposNow"
                        (change)="inviteUsersEposNow = !inviteUsersEposNow"
                      >
                      </mat-slide-toggle>
                    </div>

                    <div *ngIf="eposnowConfig?.accessToken">
                      <div class="form-field" style="margin-top: 20px">
                        <div
                          class="form-field-label"
                          style="color: var(--app-timber)"
                        >
                          Configurations
                        </div>

                        <div style="margin-bottom: 20px">
                          <div
                            class="social-container"
                            *ngFor="let item of eposnowConfig?.configurations"
                            style="
                              margin-top: 10px;
                              display: flex;
                              justify-content: space-between;
                            "
                          >
                            <div style="display: flex; align-items: center">
                              <div
                                class="form-field-label"
                                style="
                                  margin-bottom: 0px !important;
                                  color: black;
                                  font-size: 16px;
                                  margin-right: 5px;
                                "
                              >
                                {{ item.type | titlecase }}:
                              </div>
                              <div
                                class="form-field-label"
                                style="
                                  margin-bottom: 0px !important;
                                  color: black;
                                  font-size: 16px;
                                "
                              >
                                {{ rowLabel(item) }}
                              </div>
                            </div>

                            <div>
                              <mat-icon
                                style="
                                  color: var(--app-timber);
                                  cursor: pointer;
                                  margin-right: 5px;
                                "
                                (click)="editConfiguration(item)"
                                >edit</mat-icon
                              >
                              <mat-icon
                                style="color: red; cursor: pointer"
                                (click)="deleteConfiguration(item._id!)"
                                >cancel</mat-icon
                              >
                            </div>
                          </div>
                        </div>

                        <div class="add-container" (click)="addConfiguration()">
                          <div
                            class="popup-subtitle"
                            style="color: var(--app-text-grey)"
                          >
                            + Add a Configuration
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div class="action-container">
                    <app-primary-button
                      *ngIf="eposnowConfig?.accessToken"
                      [title]="'View Data'"
                      (tapped)="viewData()"
                      color="black"
                      border="1px solid var(--app-timber)"
                    ></app-primary-button>
                    <div>
                      <app-secondary-button
                        *ngIf="eposnowConfig?.accessToken"
                        [title]="'Remove Integration'"
                        [disabled]="saving"
                        (tapped)="remove('eposnow')"
                        style="margin-right: 10px"
                      ></app-secondary-button>
                      <app-primary-button
                        [title]="'Save'"
                        [disabled]="saving"
                        (tapped)="save()"
                        bgColor="var(--app-timber)"
                        color="white"
                      ></app-primary-button>
                    </div>
                  </div>
                </div>
              </ng-template>
            </mat-tab>

            <mat-tab label="Storekit">
              <ng-template matTabContent>
                <div *ngIf="!hasStorekit">
                  <div
                    class="form-field-label"
                    style="
                      color: var(--app-timber);
                      text-align: center;
                      padding: 40px;
                      margin-bottom: 0px;
                    "
                  >
                    Please contact us to enable the Storekit Integration
                  </div>
                </div>
                <div *ngIf="hasStorekit">
                  <form [formGroup]="form">
                    <div class="form-field" style="margin-top: 20px">
                      <div
                        class="form-field-label"
                        style="color: var(--app-timber)"
                      >
                        <span
                          ><img
                            src="../../../assets/storekit.png"
                            width="20"
                            height="20"
                            style="margin-right: 5px" /></span
                        >Storekit Integration Overview
                      </div>
                      <div
                        class="dialog-body"
                        [innerHTML]="overviewTextStorekit"
                      ></div>
                    </div>

                    <div class="form-field" style="margin-top: 20px">
                      <div
                        class="form-field-label"
                        style="color: var(--app-timber); margin-bottom: 0px"
                      >
                        Webhook URL
                      </div>
                      <link-text
                        (onTapped)="
                          showInfoDialog(titleOneStorekit, bodyOneStorekit)
                        "
                        text="What should I do with this?"
                      ></link-text>

                      <div
                        style="
                          display: flex;
                          align-items: center;
                          cursor: pointer;
                        "
                      >
                        <input
                          type="text"
                          class="form-input"
                          [formControl]="webhookFormControl"
                        />
                        <div class="copy" (click)="copyWebhookUrl()">
                          <mat-icon class="copy-icon">{{
                            "content_copy"
                          }}</mat-icon
                          ><span>Copy</span>
                        </div>
                      </div>
                    </div>

                    <div class="form-field" style="margin-top: 20px">
                      <div
                        class="form-field-label"
                        style="
                          color: var(--app-timber);
                          margin-bottom: 0px;
                          margin-top: 20px;
                        "
                      >
                        Webhook Signing Key<span style="color: red">*</span>
                      </div>

                      <link-text
                        (onTapped)="showInfoDialog(titleTwo, bodyTwoStorekit)"
                        text="Where can I find this?"
                      ></link-text>

                      <div style="display: flex; align-items: center">
                        <input
                          type="text"
                          class="form-input"
                          [formControl]="storekitSigningKey"
                        />
                      </div>
                    </div>

                    <div
                      class="form-field"
                      style="
                        margin-top: 20px;
                        display: flex;
                        justify-content: space-between;
                      "
                    >
                      <div class="form-field-label">
                        Invite customers without a Remy account via email<span>
                          <info-icon [text]="inviteUsersTooltip"></info-icon>
                        </span>
                        <link-text
                          (onTapped)="viewExampleMail()"
                          text="View sample email"
                        ></link-text>
                      </div>
                      <mat-slide-toggle
                        [disabled]="false"
                        [checked]="inviteUsersStorekit"
                        (change)="inviteUsersStorekit = !inviteUsersStorekit"
                      >
                      </mat-slide-toggle>
                    </div>

                    <div *ngIf="storekitConfig?.signingKey">
                      <div class="form-field" style="margin-top: 20px">
                        <div
                          class="form-field-label"
                          style="color: var(--app-timber)"
                        >
                          Configurations
                        </div>

                        <div style="margin-bottom: 20px">
                          <div
                            class="social-container"
                            *ngFor="let item of storekitConfig?.configurations"
                            style="
                              margin-top: 10px;
                              display: flex;
                              justify-content: space-between;
                            "
                          >
                            <div style="display: flex; align-items: center">
                              <div
                                class="form-field-label"
                                style="
                                  margin-bottom: 0px !important;
                                  color: black;
                                  font-size: 16px;
                                  margin-right: 5px;
                                "
                              >
                                {{ item.type | titlecase }}:
                              </div>
                              <div
                                class="form-field-label"
                                style="
                                  margin-bottom: 0px !important;
                                  color: black;
                                  font-size: 16px;
                                "
                              >
                                {{ rowLabel(item) }}
                              </div>
                            </div>

                            <div>
                              <mat-icon
                                style="
                                  color: var(--app-timber);
                                  cursor: pointer;
                                  margin-right: 5px;
                                "
                                (click)="editConfiguration(item)"
                                >edit</mat-icon
                              >
                              <mat-icon
                                style="color: red; cursor: pointer"
                                (click)="deleteConfiguration(item._id!)"
                                >cancel</mat-icon
                              >
                            </div>
                          </div>
                        </div>

                        <div class="add-container" (click)="addConfiguration()">
                          <div
                            class="popup-subtitle"
                            style="color: var(--app-text-grey)"
                          >
                            + Add a Configuration
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div class="action-container">
                    <app-primary-button
                      *ngIf="storekitConfig?.signingKey"
                      [title]="'View Data'"
                      (tapped)="viewData()"
                      color="black"
                      border="1px solid var(--app-timber)"
                    ></app-primary-button>
                    <div>
                      <app-secondary-button
                        *ngIf="storekitConfig?.signingKey"
                        [title]="'Remove Integration'"
                        [disabled]="saving"
                        (tapped)="remove('storekit')"
                        style="margin-right: 10px"
                      ></app-secondary-button>
                      <app-primary-button
                        [title]="'Save'"
                        [disabled]="saving"
                        (tapped)="save()"
                        bgColor="var(--app-timber)"
                        color="white"
                      ></app-primary-button>
                    </div>
                  </div>
                </div>
              </ng-template>
            </mat-tab>

            <mat-tab label="Zapier">
              <ng-template matTabContent>
                <div *ngIf="!hasZapier">
                  <div
                    class="form-field-label"
                    style="
                      color: var(--app-timber);
                      text-align: center;
                      padding: 40px;
                      margin-bottom: 0px;
                    "
                  >
                    Please contact us to enable the Zapier Integration
                  </div>
                </div>
                <div *ngIf="hasZapier">
                  <form [formGroup]="form">
                    <div class="form-field" style="margin-top: 20px">
                      <div
                        class="form-field-label"
                        style="color: var(--app-timber)"
                      >
                        <span
                          ><img
                            src="../../../assets/Zapier_logo.jpg"
                            height="20"
                            style="margin-right: 5px" /></span
                        >Zapier Integration Overview
                      </div>
                      <p class="dialog-body" [innerHTML]="overviewText"></p>
                    </div>

                    <div class="form-field" style="margin-top: 20px">
                      <div
                        class="form-field-label"
                        style="color: var(--app-timber); margin-bottom: 0px"
                      >
                        API Key
                      </div>
                      <link-text
                        (onTapped)="
                          showInfoDialog(titleOneZapier, bodyOneZapier)
                        "
                        text="What should I do with this?"
                      ></link-text>

                      <div
                        style="
                          display: flex;
                          align-items: center;
                          cursor: pointer;
                        "
                      >
                        <input
                          type="text"
                          class="form-input"
                          [formControl]="zapierAPIKeyFormControl"
                        />
                        <div class="copy" (click)="copyApiKey()">
                          <mat-icon class="copy-icon">{{
                            "content_copy"
                          }}</mat-icon
                          ><span>Copy</span>
                        </div>
                      </div>
                    </div>

                    <div
                      class="form-field"
                      style="
                        margin-top: 20px;
                        display: flex;
                        justify-content: space-between;
                      "
                    >
                      <div class="form-field-label">
                        Invite customers without a Remy account via email<span>
                          <info-icon [text]="inviteUsersTooltip"></info-icon>
                        </span>
                        <link-text
                          (onTapped)="viewExampleMail()"
                          text="View sample email"
                        ></link-text>
                      </div>
                      <mat-slide-toggle
                        [disabled]="false"
                        [checked]="inviteUsersZapier"
                        (change)="inviteUsersZapier = !inviteUsersZapier"
                      >
                      </mat-slide-toggle>
                    </div>

                    <div *ngIf="zapierConfig?.signingKey">
                      <div class="form-field" style="margin-top: 20px">
                        <div
                          class="form-field-label"
                          style="color: var(--app-timber)"
                        >
                          Configurations
                        </div>

                        <div style="margin-bottom: 20px">
                          <div
                            class="social-container"
                            *ngFor="let item of zapierConfig?.configurations"
                            style="
                              margin-top: 10px;
                              display: flex;
                              justify-content: space-between;
                            "
                          >
                            <div style="display: flex; align-items: center">
                              <div
                                class="form-field-label"
                                style="
                                  margin-bottom: 0px !important;
                                  color: black;
                                  font-size: 16px;
                                  margin-right: 5px;
                                "
                              >
                                {{ item.type | titlecase }}:
                              </div>
                              <div
                                class="form-field-label"
                                style="
                                  margin-bottom: 0px !important;
                                  color: black;
                                  font-size: 16px;
                                "
                              >
                                {{ rowLabel(item) }}
                              </div>
                            </div>

                            <div>
                              <mat-icon
                                style="
                                  color: var(--app-timber);
                                  cursor: pointer;
                                  margin-right: 5px;
                                "
                                (click)="editConfiguration(item)"
                                >edit</mat-icon
                              >
                              <mat-icon
                                style="color: red; cursor: pointer"
                                (click)="deleteConfiguration(item._id!)"
                                >cancel</mat-icon
                              >
                            </div>
                          </div>
                        </div>

                        <div class="add-container" (click)="addConfiguration()">
                          <div
                            class="popup-subtitle"
                            style="color: var(--app-text-grey)"
                          >
                            + Add a Configuration
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div class="action-container">
                    <app-primary-button
                      *ngIf="zapierConfig?.signingKey"
                      [title]="'View Data'"
                      (tapped)="viewData()"
                      color="black"
                      border="1px solid var(--app-timber)"
                    ></app-primary-button>
                    <div>
                      <app-secondary-button
                        *ngIf="zapierConfig?.signingKey"
                        [title]="'Remove Integration'"
                        [disabled]="saving"
                        (tapped)="remove('zapier')"
                        style="margin-right: 10px"
                      ></app-secondary-button>
                      <app-primary-button
                        [title]="'Save'"
                        [disabled]="saving"
                        (tapped)="save()"
                        bgColor="var(--app-timber)"
                        color="white"
                      ></app-primary-button>
                    </div>
                  </div>
                </div>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</div>

<loader [loading]="loading || saving"></loader>
