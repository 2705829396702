import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'linkify',
})
export class LinkifyPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }

    const urlPattern =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    const wwwPattern = /(^|\s)(www\.[\S]+(\b|$))/gim;
    const plainPattern = /(^|\s)([a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}(\b|$))/gim;

    // Function to check if a match is already within an anchor tag
    const isInsideAnchorTag = (value: string, index: number): boolean => {
      const before = value.slice(0, index);
      const after = value.slice(index);
      return (
        before.includes('<a') && before.includes('>') && after.includes('</a>')
      );
    };

    // Process URLs
    return value
      .replace(urlPattern, (match, offset) => {
        if (isInsideAnchorTag(value, offset)) {
          return match;
        }
        return `<a href="${match}" target="_blank">${match}</a>`;
      })
      .replace(wwwPattern, (match, offset) => {
        if (isInsideAnchorTag(value, offset)) {
          return match;
        }
        return `${
          match[0]
        }<a href="http://${match.trim()}" target="_blank">${match.trim()}</a>`;
      })
      .replace(plainPattern, (match, offset) => {
        if (isInsideAnchorTag(value, offset)) {
          return match;
        }
        return `${
          match[0]
        }<a href="http://${match.trim()}" target="_blank">${match.trim()}</a>`;
      });
  }
}
