import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class Icon {
  static filledIcons = [
    { star: ['0xe5f9', 'star'] },
    { favorite: ['0xe25b', 'favorite'] },
    { workspace_premium: ['0xf05ae', 'workspace_premium'] },
    { key: ['0xf052b', 'key'] },
    { thumb_up: ['0xe65b', 'thumb_up'] },
    { face: ['0xe252', 'face'] },
    { rocket_launch: ['0xf055d', 'rocket_launch'] },
    { water_drop: ['0xf05a2', 'water_drop'] },
    { radio_button_checked: ['0xe503', 'radio_button_checked'] },
    { eco: ['0xe217', 'eco'] },
    { pets: ['0xe4a1', 'pets'] },
    { health_and_safety: ['0xe305', 'health_and_safety'] },
    { military_tech: ['0xe3e7', 'military_tech'] },
    { cruelty_free: ['0xf04da', 'cruelty_free'] },
    { cookie: ['0xf04d9', 'cookie'] },
    { bedtime: ['0xe0db', 'bedtime'] },
    { hive: ['0xf0518', 'hive'] },
    { emoji_food_beverage: ['0xe22e', 'emoji_food_beverage'] },
    { emoji_events: ['0xe22c', 'emoji_events'] },
    { new_releases: ['0xe428', 'new_releases'] },
    { stars: ['0xe600', 'stars'] },
    { offline_bolt: ['0xe454', 'offline_bolt'] },
    { spoke: ['0xf056e', 'spoke'] },
    { palette: ['0xe46b', 'palette'] },
    { flash_on: ['0xe293', 'flash_on'] },
    { camera: ['0xe12f', 'camera'] },
    { filter_vintage: ['0xe284', 'filter_vintage'] },
    { sell: ['0xe570', 'sell'] },
    { paid: ['0xe46a', 'paid'] },
    { savings: ['0xe553', 'savings'] },
    { store: ['0xe60a', 'store'] },
    { location_on: ['0xe3ab', 'location_on'] },
    { explore: ['0xe248', 'explore'] },
    { near_me: ['0xe41f', 'near_me'] },
    { park: ['0xe478', 'park'] },
    { wine_bar: ['0xe6f1', 'wine_bar'] },
    { local_pizza: ['0xe3a0', 'local_pizza'] },
    { local_drink: ['0xe391', 'local_drink'] },
    { soup_kitchen: ['0xf056c', 'soup_kitchen'] },
    { dry_cleaning: ['0xe20e', 'dry_cleaning'] },
    { cloud: ['0xe16f', 'cloud'] },
    { book: ['0xe0ef', 'book'] },
    { hexagon: ['0xf0517', 'hexagon'] },
    { pentagon: ['0xf0546', 'pentagon'] },
    { movie: ['0xe40d', 'movie'] },
    { school: ['0xe559', 'school'] },
    { cake: ['0xe120', 'cake'] },
    { spa: ['0xe5d8', 'spa'] },
    { lunch_dining: ['0xe3c2', 'lunch_dining'] },
    { beach_access: ['0xe0d6', 'beach_access'] },
    { local_bar: ['0xe38c', 'local_bar'] },
    { room_service: ['0xe53d', 'room_service'] },
    { sports_bar: ['0xe5e4', 'sports_bar'] },
    { bakery_dining: ['0xe0c9', 'bakery_dining'] },
    { ramen_dining: ['0xe506', 'ramen_dining'] },
    { icecream: ['0xe331', 'icecream'] },
    { dinner_dining: ['0xe1d0', 'dinner_dining'] },
    { museum: ['0xe414', 'museum'] },
    { tapas: ['0xe644', 'tapas'] },
    { coffee: ['0xe178', 'coffee'] },
    { rice_bowl: ['0xe538', 'rice_bowl'] },
    { circle: ['0xe163', 'circle'] },
    { token: ['0xf058e', 'token'] },
    { sunny: ['0xf0575', 'sunny'] },
    { gamepad: ['0xe2d0', 'gamepad'] },
    { priority_high: ['0xe4ec', 'priority_high'] },
    { diamond: ['0xf04ed', 'diamond'] },
    { diversity_1: ['0xf085a', 'diversity_1'] },
    { pregnant_woman: ['0xe4e5', 'pregnant_woman'] },
    { bookmark: ['0xe0f1', 'bookmark'] },
    { calendar_month: ['0xf06bb', 'calendar_month'] },
    { fingerprint: ['0xe287', 'fingerprint'] },
    { celebration: ['0xe149', 'celebration'] },
    { bug_report: ['0xe115', 'bug_report'] },
    { push_pin: ['0xe4f4', 'push_pin'] },
    { category: ['0xe148', 'category'] },
    { extension: ['0xe250', 'extension'] },
    { interests: ['0xf0523', 'interests'] },
    { dangerous: ['0xe1af', 'dangerous'] },
    { pan_tool: ['0xe46c', 'pan_tool'] },
    { flutter_dash: ['0xe2a0', 'flutter_dash'] },
    { wifi_protected_setup: ['0xe6ec', 'wifi_protected_setup'] },
    { mail: ['0xe3c3', 'mail'] },
    { call: ['0xe126', 'call'] },
    { hub: ['0xf051d', 'hub'] },
    { chat_bubble: ['0xe154', 'chat_bubble'] },
    { auto_stories: ['0xe0bf', 'auto_stories'] },
    { square: ['0xf0570', 'square'] },
    { nature: ['0xe41a', 'nature'] },
    { brush: ['0xe113', 'brush'] },
    { landscape: ['0xe365', 'landscape'] },
    { grain: ['0xe2e2', 'grain'] },
    { lens_blur: ['0xe374', 'lens_blur'] },
    { auto_awesome_mosaic: ['0xe0b8', 'auto_awesome_mosaic'] },
    { auto_awesome: ['0xe0b7', 'auto_awesome'] },
    { deblur: ['0xf04e8', 'deblur'] },
  ];

  static icons = [
    { redeem: ['0xf00e6', 'redeem'] },
    { grade: ['0xf0cc', 'grade'] },
    { star_half: ['0xf3de', 'star_half'] },
    { workspace_premium: ['0xf03c0', 'workspace_premium'] },
    { done_all: ['0xefe5', 'done_all'] },
    { thumb_up: ['0xf440', 'thumb_up'] },
    { public: ['0xf2d4', 'public'] },
    { face: ['0xf040', 'face'] },
    { sentiment_satisfied: ['0xf35e', 'sentiment_satisfied'] },
    { rocket_launch: ['0xf0653', 'rocket_launch'] },
    { water_drop: ['0xf0695', 'water_drop'] },
    { eco: ['0xf005', 'eco'] },
    { mood: ['0xf1e5', 'mood'] },
    { health_and_safety: ['0xf0f2', 'health_and_safety'] },
    { sentiment_very_satisfied: ['0xf360', 'sentiment_very_satisfied'] },
    { military_tech: ['0xf1cd', 'military_tech'] },
    { cruelty_free: ['0xf05d4', 'cruelty_free'] },
    { female: ['0xf050', 'female'] },
    { male: ['0xf1ab', 'male'] },
    { transgender: ['0xf45c', 'transgender'] },
    { cookie: ['0xf05d3', 'cookie'] },
    { bedtime: ['0xeecb', 'bedtime'] },
    { hive: ['0xf0611', 'hive'] },
    { emoji_food_beverage: ['0xf01c', 'emoji_food_beverage'] },
    { emoji_events: ['0xf01a', 'emoji_events'] },
    { new_releases: ['0xf20e', 'new_releases'] },
    { stars: ['0xf3e3', 'stars'] },
    { approval: ['0xee7f', 'approval'] },
    { offline_bolt: ['0xf239', 'offline_bolt'] },
    { anchor: ['0xee75', 'anchor'] },
    { gesture: ['0xf0c1', 'gesture'] },
    { spoke: ['0xf0664', 'spoke'] },
    { palette: ['0xf24f', 'palette'] },
    { camera: ['0xef23', 'camera'] },
    { filter_vintage: ['0xf072', 'filter_vintage'] },
    { texture: ['0xf436', 'texture'] },
    { trending_up: ['0xf462', 'trending_up'] },
    { sell: ['0xf353', 'sell'] },
    { paid: ['0xf24e', 'paid'] },
    { savings: ['0xf336', 'savings'] },
    { store: ['0xf3ee', 'store'] },
    { money: ['0xf1e0', 'money'] },
    { location_on: ['0xf193', 'location_on'] },
    { restaurant: ['0xf316', 'restaurant'] },
    { explore: ['0xf037', 'explore'] },
    { near_me: ['0xf206', 'near_me'] },
    { park: ['0xf25c', 'park'] },
    { wine_bar: ['0xf4d2', 'wine_bar'] },
    { local_pizza: ['0xf188', 'local_pizza'] },
    { local_drink: ['0xf179', 'local_drink'] },
    { soup_kitchen: ['0xf0662', 'soup_kitchen'] },
    { dry_cleaning: ['0xeffb', 'dry_cleaning'] },
    { cloud: ['0xef62', 'cloud'] },
    { book: ['0xeedf', 'book'] },
    { percent: ['0xf063f', 'percent'] },
    { content_cut: ['0xef80', 'content_cut'] },
    { hexagon: ['0xf0610', 'hexagon'] },
    { pentagon: ['0xf063e', 'pentagon'] },
    { movie: ['0xf1f5', 'movie'] },
    { flight: ['0xf085', 'flight'] },
    { pedal_bike: ['0xf267', 'pedal_bike'] },
    { school: ['0xf33c', 'school'] },
    { construction: ['0xef78', 'construction'] },
    { air: ['0xee55', 'air'] },
    { cake: ['0xef0f', 'cake'] },
    { self_improvement: ['0xf352', 'self_improvement'] },
    { sports_score: ['0xf3d4', 'sports_score'] },
    { sports_soccer: ['0xf3d5', 'sports_soccer'] },
    { waves: ['0xf4b6', 'waves'] },
    { spa: ['0xf3bb', 'spa'] },
    { lunch_dining: ['0xf89a', 'lunch_dining'] },
    { fitness_center: ['0xf07a', 'fitness_center'] },
    { beach_access: ['0xeec6', 'beach_access'] },
    { local_bar: ['0xf174', 'local_bar'] },
    { pool: ['0xf2bf', 'pool'] },
    { room_service: ['0xf320', 'room_service'] },
    { sports_bar: ['0xf3c6', 'sports_bar'] },
    { bakery_dining: ['0xeeb9', 'bakery_dining'] },
    { ramen_dining: ['0xf2e9', 'ramen_dining'] },
    { nightlife: ['0xf213', 'nightlife'] },
    { icecream: ['0xf11d', 'icecream'] },
    { dinner_dining: ['0xefbf', 'dinner_dining'] },
    { museum: ['0xf1fa', 'museum'] },
    { golf_course: ['0xf0c5', 'golf_course'] },
    { tapas: ['0xf427', 'tapas'] },
    { coffee: ['0xef68', 'coffee'] },
    { grass: ['0xf0d1', 'grass'] },
    { rice_bowl: ['0xf31b', 'rice_bowl'] },
    { apartment: ['0xee7a', 'apartment'] },
    { close: ['0xef5a', 'close'] },
    { circle: ['0xef53', 'circle'] },
    { token: ['0xf0682', 'token'] },
    { wb_sunny: ['0xf4bc', 'wb_sunny'] },
    { gamepad: ['0xf0bd', 'gamepad'] },
    { diamond: ['0xf05e7', 'diamond'] },
    { diversity_1: ['0xf089f', 'diversity_1'] },
  ];
}
