import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Constants } from 'src/app/app.constants';
import { AnalyticsAbstract } from 'src/app/services/analytics/analytics.abstract';
import { DateRangeDialogComponent } from 'src/app/shared/components/date-range-dialog/date-range-dialog.component';
import {
  ExcelService,
  MerchantService,
  AuthService,
} from '../../../services/index';
import { PermissionsService } from 'src/app/services/permissions.service';

interface Report {
  title: string;
  subtitle: string;
  type: string;
  dateRequired: boolean;
  errorMessage?: string;
}

@Component({
  selector: 'reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
})
export class ReportsComponent {
  @Input() smallTitle = false;
  @Input() title = '';
  @Input() subtitle: string = '';
  @ViewChild('myTable') table: any;

  reports: Report[] = [
    {
      title: 'Stamps, Points, Redemptions, Cards & Rewards',
      subtitle: 'A report showing all of these for a given a date range',
      type: 'stamps',
      dateRequired: true,
    },
    {
      title: this.constants.strings.customers,
      subtitle: 'A list of all customers who have taken your loyalty card(s)',
      type: 'customers',
      dateRequired: false,
    },
    {
      title: this.constants.strings.lapsedCustomers,
      subtitle:
        'A list of all customers and the number of days since their last stamp or point',
      type: 'lapsedCustomers',
      dateRequired: false,
    },
    {
      title: 'Unredeemed Rewards',
      subtitle:
        'A list of all rewards which have not yet been redeemed and not expired',
      type: 'unredeemedRewards',
      dateRequired: false,
      errorMessage: 'No unredeemed rewards found',
    },
    {
      title: this.constants.strings.birthdaysThisMonth,
      subtitle:
        'A list of all customers with a birthday this month, including their emails',
      type: 'birthdayThisMonth',
      dateRequired: false,
      errorMessage: 'No birthdays this month',
    },
  ];
  error = false;
  reportLoading = false;

  length = this.reports.length;

  constructor(
    public constants: Constants,
    public authService: AuthService,
    private merchantService: MerchantService,
    public dialog: MatDialog,
    private mixpanel: AnalyticsAbstract,
    private excelService: ExcelService,
    private ps: PermissionsService
  ) {
    const prohibitedReports = [
      this.constants.strings.customers,
      this.constants.strings.lapsedCustomers,
      this.constants.strings.birthdaysThisMonth,
    ];

    if (!ps.permissions.customers.view()) {
      this.reports = this.reports.filter(
        (el) => !prohibitedReports.includes(el.title)
      );
    }
  }

  ngOnInit() {
    this.subtitle = this.authService.getNestedUserProperty('merchant', 'name');
  }

  get emptyString(): string {
    return `No ${this.constants.strings.reports} to display`;
  }

  rowTappedNGX({ row, type }: any) {
    if (type === 'click') {
      if (row.dateRequired) {
        this.selectDates(row);
      } else {
        this.processExport(row);
      }
    }
  }

  selectDates(row: Report): void {
    this.mixpanel.track(Constants.analytics_keys.openDashboardExport, {
      Report: row.type,
    });

    const dialogRef = this.dialog.open(DateRangeDialogComponent, {
      data: {},
      autoFocus: false,
      width: '350px',
      disableClose: true,
      panelClass: 'custom-dialog',
      scrollStrategy: new NoopScrollStrategy(),
    });
    dialogRef.afterClosed().subscribe((dates: any) => {
      if (dates) {
        this.processExport(row, dates.from, dates.to);
      }
    });
  }

  processExport(row: Report, from?: string, to?: string): void {
    const params: { Report: string; from?: string; to?: string } = {
      Report: row.type,
    };

    if (from && to) {
      params.from = from;
      params.to = to;
    }

    this.mixpanel.track(Constants.analytics_keys.dashboardExport, params);

    this.reportLoading = true;
    this.merchantService
      .export(row.type, from, to)
      .subscribe({
        next: (res: any) => {
          if (!res?.data || res?.data?.length === 0) {
            this.constants.snack(row.errorMessage || 'No data to export');
          } else {
            this.excelService.exportExcelFile(res.data.data, res.fileName);
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.reportLoading = false));
  }

  get loading(): boolean {
    return this.reportLoading;
  }

  get email(): string {
    return 'hello@remyrewards.co.uk';
  }
}
