import { User, Merchant } from './index';

export interface Customer {
  _id?: string;
  nanoid?: string;
  user?: string | User;
  merchant?: string | Merchant;
  name?: string;
  stamps?: number;
  rewards?: number;
  pushNotifications?: number;
  redemptions?: number;
  createdAt?: Date;
  email?: string;
  checked?: boolean;
  notes?: string;
}
