<dialog-header
  [title]="data.guides[currentVideo]?.title"
  (onClose)="matDialogRef.close()"
></dialog-header>

<div
  mat-dialog-content
  style="
    padding-bottom: 20px;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    background-color: var(--app-container);
  "
>
  <div style="min-height: 473px; min-width: 840px">
    <iframe
      *ngIf="!loading"
      [src]="safeUrl"
      width="840"
      height="473"
      frameborder="0"
      allowfullscreen
    ></iframe>
  </div>

  <!-- <div
    style="display: flex; margin-top: 10px; align-items: center"
    [ngStyle]="{
      'justify-content': currentVideo === 0 ? 'flex-end' : 'space-between'
    }"
  >
    <p
      *ngIf="currentVideo > 0"
      class="next-title"
      style="
        margin-bottom: 0px;
        font-weight: normal;
        font-size: 14px;
        cursor: pointer;
      "
      (click)="previousClick()"
    >
      Previous:
      {{ data.guides[currentVideo - 1]?.title }}
    </p>
    <p
      *ngIf="currentVideo < data.guides.length - 1"
      class="next-title"
      style="
        margin-bottom: 0px;
        font-weight: normal;
        font-size: 14px;
        cursor: pointer;
      "
      (click)="nextClick()"
    >
      Next:
      {{ data.guides[currentVideo + 1]?.title }}
    </p>
  </div> -->
</div>

<loader [loading]="loading"></loader>
