import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Inject,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import {
  MatDialogRef as MatDialogRef,
  MAT_DIALOG_DATA as MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { Constants } from 'src/app/app.constants';
import { TwoOptionAlertComponent } from '../two-option-alert/two-option-alert.component';
import { GeocodingService } from '../../../services/index';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

@Component({
  selector: 'address-dialog',
  templateUrl: './address-dialog.component.html',
  styleUrls: ['./address-dialog.component.css'],
})
export class AddressDialogComponent implements OnInit {
  changed = false;
  form!: FormGroup;
  countries: { name: string; code: string }[] = [];
  options: { name: string }[] = [];

  address?: Object;
  establishmentAddress?: Object;

  formattedAddress?: string;

  locationTypeFormControl: FormControl = new FormControl();
  countryFormControl: FormControl = new FormControl();
  cityFormControl: FormControl = new FormControl();
  postcodeFormControl: FormControl = new FormControl();
  lineOneFormControl: FormControl = new FormControl();
  whatThreeWordsFormControl: FormControl = new FormControl();
  lineTwoFormControl: FormControl = new FormControl();

  addressFetched = false;
  countrySelected = false;
  locationTypeSelected = false;
  countryCode = 'GB';
  locationType = 0;
  placeId = '';
  lat?: number;
  long?: number;
  website?: string;
  name?: string;
  phoneNumber?: string;

  postcodeSearching = false;
  postcodeValid = false;
  postcode = '';

  @ViewChild('search') search: any;
  public searchElementRef?: ElementRef;
  // public place?: google.maps.places.PlaceResult;

  constructor(
    public dialog: MatDialog,
    private http: HttpClient,
    private constants: Constants,
    public fb: FormBuilder,
    public matDialogRef: MatDialogRef<AddressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public zone: NgZone,
    private geocodingService: GeocodingService
  ) {}

  ngOnInit(): void {
    this.options = [
      { name: 'Commercial Premises (e.g Coffee Shop)' },
      { name: 'Private Premises/Mobile/Online' },
    ];

    this.http
      .get<any>('../../../../assets/json/countries.json')
      .subscribe((data) => {
        this.countries = data;

        const existingCountry = this.countries?.find(
          (el: any) => el.name === this.data.address?.country
        );

        if (existingCountry) {
          this.countryCode = existingCountry?.code;
        }

        this.countryFormControl = new FormControl(
          {
            value: this.data.address ? existingCountry : '',
            disabled: this.data.disable,
          },
          [Validators.required]
        );

        this.cityFormControl = new FormControl(
          this.data.address ? this.data.address.city : '',
          [Validators.required]
        );

        this.postcodeFormControl = new FormControl(
          {
            value: this.data.address ? this.data.address.postcode : '',
            disabled: true,
          },
          [Validators.required]
        );
        this.lineOneFormControl = new FormControl(
          {
            value: this.data.address ? this.data.address.lineOne : '',
            disabled: this.data.disable,
          },
          [Validators.required]
        );
        this.lineTwoFormControl = new FormControl({
          value: this.data.address ? this.data.address.lineTwo : '',
          disabled: this.data.disable,
        });
        this.whatThreeWordsFormControl = new FormControl({
          value: this.data.address ? this.data.address.wtw : '',
          disabled: this.data.disable,
        });
      });

    this.locationTypeFormControl = new FormControl(
      {
        value: '',
        disabled: this.data.disable,
      },
      [Validators.required]
    );

    if (this.data.address) {
      this.countrySelected = true;
      this.addressFetched = true;
      this.locationType = this.data.address?.type ?? 0;
      this.locationTypeSelected = true;
      this.postcodeValid = true;
      this.postcode = this.data.address?.postcode;
      this.placeId = this.data.address?.placeId;
      this.locationTypeFormControl.setValue(this.options[this.locationType]);
    }

    this.form = this.fb.group({
      country: this.countryFormControl,
      city: this.cityFormControl,
      postcode: this.postcodeFormControl,
      lineOne: this.lineOneFormControl,
      lineTwo: this.lineTwoFormControl,
      locationType: this.locationTypeFormControl,
      wtw: this.whatThreeWordsFormControl,
    });
  }

  get primaryEnabled(): boolean {
    if (
      this.countryFormControl.value &&
      this.cityFormControl.value &&
      this.postcodeFormControl.value
    ) {
      return true;
    } else {
      return false;
    }
  }

  getEstablishmentAddress(place: any) {
    this.establishmentAddress = place['formatted_address'];

    const getComponentValue = (type: string): string | undefined =>
      place?.address_components?.find((el: any) => el.types?.includes(type))
        ?.short_name;

    const streetNumber = getComponentValue('street_number') ?? '';
    const lineOne = getComponentValue('route') ?? '';
    const postcode = getComponentValue('postal_code') ?? '';
    const postalTown = getComponentValue('postal_town') ?? '';

    this.placeId = place?.place_id;
    this.lat = place?.geometry?.location?.lat();
    this.long = place?.geometry?.location?.lng();

    if (place?.international_phone_number) {
      this.phoneNumber = place.international_phone_number;
    }
    if (place?.website) {
      this.website = place.website;
    }
    if (place?.name) {
      this.name = place.name;
    }

    if (this.establishmentAddress) {
      this.postcode = postcode;
      this.postcodeFormControl.setValue(postcode ?? '');
      this.cityFormControl.setValue(postalTown ?? '');

      let addressLine = '';
      if (streetNumber) {
        addressLine += streetNumber + ' ';
      }
      addressLine += lineOne ?? '';
      this.lineOneFormControl.setValue(addressLine);
    }

    this.zone.run(() => {
      this.addressFetched = true;
    });
  }

  primaryTapped(): void {
    if (this.form.invalid) {
      return;
    }

    this.save();
  }

  getTown(address_components: any): string {
    let town = '';

    let postal_town = address_components?.find((el: any) =>
      el.types.includes('postal_town')
    );

    let sub_locality = address_components?.find((el: any) =>
      el.types.includes('sublocality')
    );

    let locality = address_components?.find((el: any) =>
      el.types.includes('locality')
    );

    let admin_area_2 = address_components?.find((el: any) =>
      el.types.includes('administrative_area_level_2')
    );

    let admin_area_1 = address_components?.find((el: any) =>
      el.types.includes('administrative_area_level_1')
    );

    if (postal_town) {
      town = postal_town?.long_name;
    } else if (sub_locality) {
      town = sub_locality?.long_name;
    } else if (locality) {
      town = locality?.long_name;
    } else if (admin_area_2 && admin_area_1) {
      town = admin_area_2?.long_name + ', ' + admin_area_1?.long_name;
    } else if (admin_area_2) {
      town = admin_area_2?.long_name;
    } else if (admin_area_1) {
      town = admin_area_1?.long_name;
    }

    return town;
  }

  postCodeChanged(): void {
    if (this.postcodeFormControl.value?.length >= 3) {
      this.postcodeSearching = true;

      this.geocodingService
        .getCoordinates(this.postcodeFormControl.value, this.countryCode)
        .subscribe({
          next: (res: any) => {
            if (res && res.results && res.results.length > 0) {
              const location = res.results[0].geometry.location;
              this.lat = location.lat;
              this.long = location.lng;

              let town = this.getTown(res.results[0]?.address_components);

              if (this.lat && this.long && town !== '') {
                this.postcodeValid = true;
                this.postcode = this.postcodeFormControl.value;
                this.placeId = res.results[0]?.place_id;
                this.cityFormControl.setValue(town);
              } else {
                this.constants.snack('Invalid postcode or no results found');
                this.resetValues();
              }
            } else {
              this.resetValues();
              this.constants.snack('Invalid postcode or no results found');
            }
          },
          error: (res: HttpErrorResponse) => {
            this.resetValues();
            this.constants.snack(res.error.message);
          },
        })
        .add(() => (this.postcodeSearching = false));
    }
  }

  resetValues(): void {
    this.placeId = '';
    this.lat = undefined;
    this.long = undefined;
    this.cityFormControl.setValue(null);
    this.postcodeValid = false;
  }

  selectedCountry(country: any) {
    this.countryCode = country.code;
    this.countrySelected = true;
  }

  selectedLocationType(type: any) {
    if (type?.name === this.options[0].name) {
      this.locationType = 0;
      this.postcodeFormControl.disable();
    } else {
      this.locationType = 1;
      this.postcodeFormControl.enable();
    }

    this.clearFields();
    this.locationTypeSelected = true;
  }

  clearFields(): void {
    this.cityFormControl.setValue('');
    this.lineOneFormControl.setValue('');
    this.lineTwoFormControl.setValue('');
    this.postcodeFormControl.setValue('');
    this.countryFormControl.setValue('');
    this.countrySelected = false;
    this.postcodeValid = false;
    this.countryCode = 'GB';
  }

  showInfoDialog(): void {
    this.dialog.open(TwoOptionAlertComponent, {
      data: {
        title: 'Why are we asking this question?',
        body: this.getBody,
        buttonTwo: 'OK',
      },
      width: '450px',
      autoFocus: false,
      disableClose: true,
      panelClass: 'custom-dialog',
      scrollStrategy: new NoopScrollStrategy(),
    });
  }

  get getBody() {
    return `Your choice will determine how you\'re discovered in the app. <br><br>We understand that some people run their business from home (e.g. Nail Technician) and you might not want your full address to be made public. <br><br>Choosing private means that customers will see your general location on the map (e.g. Town & part of your postcode) only.
`;
  }

  save(): void {
    if (!this.countryFormControl.value) {
      this.constants.snack('Country cannot be blank');
      return;
    }
    if (!this.cityFormControl.value) {
      this.constants.snack(`${this.cityLabel} cannot be blank`);
      return;
    }
    if (!this.postcodeFormControl.value) {
      this.constants.snack(`${this.postcodeLabel} cannot be blank`);
      return;
    }
    if (!this.lineOneFormControl.value && this.locationType === 0) {
      this.constants.snack('Line one cannot be blank');
      return;
    }
    const data = {
      country: this.countryFormControl.value.name,
      city: this.cityFormControl.value?.trim(),
      postcode: this.postcode?.trim(),
      lineOne: '',
      lineTwo: '',
      lat: this.lat ?? this.data.address?.lat,
      long: this.long ?? this.data.address?.long,
      placeId: this.placeId,
      type: this.locationType,
      wtw: this.whatThreeWordsFormControl.value?.trim(),
      phoneNumber: this.phoneNumber,
      website: this.website,
      name: this.name,
    };

    if (this.locationType === 0) {
      data.lineOne = this.lineOneFormControl.value?.trim();
      data.lineTwo = this.lineTwoFormControl.value?.trim();
    }

    const dialogRef = this.dialog.open(TwoOptionAlertComponent, {
      data: {
        title: 'Confirm address',
        body: `This is how your address will be displayed in app, do you wish to continue?<br><br>
        <i>
        ${data.lineOne ? data.lineOne + '<br>' : ''}
        ${data.lineTwo ? data.lineTwo + '<br>' : ''}
        ${data.city ? data.city + '<br>' : ''}
        ${
          data.postcode !== null && data.type == 0 ? data.postcode + '<br>' : ''
        }
        ${data.country ?? ''}<br>
        </i>
        `,
        buttonTwo: 'Save',
        buttonOne: 'Cancel',
      },
      width: '450px',
      autoFocus: false,
      disableClose: true,
      panelClass: 'custom-dialog',
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialogRef.afterClosed().subscribe((option: number) => {
      if (option == 1) {
        this.matDialogRef.close(data);
      }
    });
  }

  disabledTooltip(type: string): string {
    return `Editing the ${type} is disabled, so we can ensure that your premisis is a verified location`;
  }

  get postcodeLabel(): string {
    if (this.countryFormControl.value?.code === 'US') {
      return 'Zipcode';
    } else {
      return 'Postcode';
    }
  }

  get cityLabel(): string {
    if (this.countryFormControl.value?.code === 'US') {
      return 'State';
    } else {
      return 'Town/City';
    }
  }
}
