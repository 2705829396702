import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from 'src/app/app.constants';
import { Message } from 'src/app/models/message';
import { AuthService } from 'src/app/services/auth.service';
import { MerchantService } from 'src/app/services/merchant.service';
import { TwoOptionAlertComponent } from 'src/app/shared/components/two-option-alert/two-option-alert.component';
import { AutomationsDialogComponent } from '../automations_dialog/automations_dialog.component';
import { AnalyticsAbstract } from 'src/app/services/analytics/analytics.abstract';
import { UserNotification } from 'src/app/models/userNotification';
import { Conversation } from 'src/app/models/conversation';
import { ChatService } from 'src/app/services/chat.service';
import { MessagesDialogComponent } from '../messages_dialog/messages_dialog.component';
import { ChatMessage } from 'src/app/models/chatMessage';
import { ConversationSettingsDialogComponent } from '../conversation-settings/conversation-settings-dialog.component';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'engage',
  templateUrl: './engage.component.html',
  styleUrls: ['./engage.component.css'],
})
export class EngageComponent {
  @Input() smallTitle = false;
  @Input() title = '';
  @Input() subtitle: string = '';
  @ViewChild('messageTable') messageTable: any;
  @ViewChild('birthdayTable') birthdayTable: any;
  @ViewChild('conversationTable') conversationTable: any;

  hasBirthdayClubAccess = false;
  menuOptions: any[] = [];
  tableMenuOptions = [{ title: 'Edit Nickname', icon: 'edit' }];
  allChecked: any = { 0: { checked: false } };
  messagePageIndex = 0;
  conversationPageIndex = 0;
  birthdayPageIndex = 0;
  pageSize = 10;
  messageLength = 0;
  conversationLength = 0;
  birthdayLength = 0;
  messages: Message[] = [];
  conversations: Conversation[] = [];
  birthdayNotifications: UserNotification[] = [];
  messagesLoading = true;
  conversationsLoading = false;
  birthdayLoading = false;
  automationsLoading = false;
  sendingPush = false;
  error = false;
  scrollTimeout: any;
  suppressPaging: boolean = false;
  messagesRange = '';
  conversationsRange = '';
  birthdayRange = '';
  saving = false;

  tabs = ['messages', 'birthday', 'conversations'];
  selectedTab = 'messages';
  selectedIndex = 0;

  showConversationsTab = false;
  canViewCustomers = false;
  canEngage = false;

  constructor(
    public dialog: MatDialog,
    public constants: Constants,
    private router: Router,
    public authService: AuthService,
    private merchantService: MerchantService,
    private mixpanel: AnalyticsAbstract,
    private route: ActivatedRoute,
    private chatService: ChatService,
    private ps: PermissionsService
  ) {
    this.showConversationsTab =
      this.authService.getNestedUserProperty('merchant', 'conversations')
        ?.active ?? false;

    this.canViewCustomers = this.ps.permissions.customers.view();
    this.canEngage = this.ps.permissions.engage.view();

    this.menuOptions = [
      {
        title: this.constants.strings.pushNotification,
        icon: 'mark_chat_unread',
      },
    ];
  }

  ngOnInit() {
    this.subtitle = this.authService.getNestedUserProperty('merchant', 'name');
    this.getAutomations();
  }

  getAutomations(): void {
    this.automationsLoading = true;
    this.merchantService
      .getAutomations()
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.hasBirthdayClubAccess = res.hasAccess && this.canEngage;
            if (!this.hasBirthdayClubAccess) {
              if (this.showConversationsTab) {
                this.tabs = ['messages', 'conversations'];
              } else {
                this.tabs = ['messages'];
              }
            }
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => {
        this.route.queryParams.subscribe((params) => {
          const tab = params['tab'];
          if (tab) {
            this.selectedTab = tab;
            this.selectedIndex = this.tabs.findIndex((el) => el === tab) || 0;
          }
        });

        this.automationsLoading = false;
        this.loadData();
      });
  }

  get tooltip(): string {
    if (this.loading) {
      return '';
    }
    if (!this.authService.merchantObj?.active) {
      return this.constants.strings.inactive;
    } else {
      return '';
    }
  }

  get emptyString(): string {
    return `No ${this.constants.strings.messages} to display`;
  }

  getBirthdayNotifications(): void {
    this.messagesLoading = false;
    this.birthdayLoading = true;
    this.merchantService
      .getBirthdayNotifications(this.pageSize, this.birthdayPageIndex)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.birthdayLength = res.totalDocs;
            this.birthdayNotifications = res.docs;

            this.birthdayRange = this.constants.pageCounter(
              this.birthdayPageIndex,
              this.pageSize,
              this.birthdayLength,
              this.birthdayNotifications?.length
            );
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.birthdayLoading = false));
  }

  getConversations(): void {
    if (this.conversationsLoading) {
      return;
    }

    this.conversationsLoading = true;
    this.messagesLoading = false;
    this.chatService
      .getConversations(this.pageSize, this.messagePageIndex)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.conversationLength = res.totalDocs;
            this.conversations = res.docs;

            this.conversationsRange = this.constants.pageCounter(
              this.conversationPageIndex,
              this.pageSize,
              this.conversationLength,
              this.conversations?.length
            );
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.conversationsLoading = false));
  }

  getSentMessages(): void {
    this.messagesLoading = true;
    this.merchantService
      .getSentMessages(this.pageSize, this.messagePageIndex)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.messageLength = res.totalDocs;
            this.messages = res.docs;

            this.messagesRange = this.constants.pageCounter(
              this.messagePageIndex,
              this.pageSize,
              this.messageLength,
              this.messages?.length
            );
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.messagesLoading = false));
  }

  messagePageEvent(event: any) {
    if (!this.suppressPaging) {
      this.messagePageIndex = event.offset;
      this.getSentMessages();
    }
  }

  birthdayPageEvent(event: any) {
    if (!this.suppressPaging) {
      this.birthdayPageIndex = event.offset;
      this.getBirthdayNotifications();
    }
  }

  newMessage(): void {
    this.router.navigate([Constants.routes.location]);
  }

  get loading(): boolean {
    return (
      this.messagesLoading ||
      this.sendingPush ||
      this.saving ||
      this.birthdayLoading ||
      this.automationsLoading ||
      this.conversationsLoading
    );
  }

  onClick(event: any, item: any) {
    if (event === this.constants.strings.pushNotification) {
      this.router.navigate([Constants.routes.engagePush]);
    }
    if (event === this.constants.strings.email) {
    }

    if (event === this.constants.strings.mute) {
      this.muteConversation(true, item);
    }

    if (event === this.constants.strings.unmute) {
      this.muteConversation(false, item);
    }

    if (event === this.constants.strings.block) {
      this.blockConversation(true, item);
    }

    if (event === this.constants.strings.unblock) {
      this.blockConversation(false, item);
    }

    if (
      event === this.constants.strings.duplicate ||
      event === this.constants.strings.edit
    ) {
      this.navigate(item);
    }

    if (event === this.constants.strings.delete) {
      const dialogRef = this.dialog.open(TwoOptionAlertComponent, {
        data: {
          body: 'Would you like to delete this message?',
          buttonOne: 'Cancel',
          buttonTwo: 'Yes',
        },
        scrollStrategy: new NoopScrollStrategy(),
        autoFocus: false,
        width: '350px',
        disableClose: true,
        panelClass: 'custom-dialog',
      });

      dialogRef.afterClosed().subscribe((option: number) => {
        if (option == 1) {
          this.deleteMessage(item);
        }
      });
    }
  }

  muteConversation(mute: boolean, conversation: Conversation) {
    this.mixpanel.track(
      mute
        ? Constants.analytics_keys.muteConversation
        : Constants.analytics_keys.unmuteConversation,
      {
        Conversation: conversation?.nanoid,
      }
    );

    this.saving = true;
    this.chatService
      .muteConversation(mute, conversation._id!)
      .subscribe({
        next: (res: any) => {
          if (res) {
            if (mute) {
              (conversation.muted ?? []).push(conversation.merchant as string);
            } else {
              const index: number = (conversation.muted ?? []).indexOf(
                conversation.merchant as string
              );
              if (index !== -1) {
                (conversation.muted ?? []).splice(index, 1);
              }
            }
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  blockConversation(block: boolean, conversation: Conversation) {
    this.mixpanel.track(
      block
        ? Constants.analytics_keys.blockConversation
        : Constants.analytics_keys.unblockConversation,
      {
        Conversation: conversation?.nanoid,
      }
    );

    this.saving = true;
    this.chatService
      .blockConversation(block, conversation._id!)
      .subscribe({
        next: (res: any) => {
          if (res) {
            conversation.blockedByMerchant = block;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  handleScroll() {
    this.suppressPaging = true;

    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }

    this.scrollTimeout = setTimeout(() => {
      this.suppressPaging = false;
    }, 100);
  }

  openSettings(): void {
    const dialogRef = this.dialog.open(ConversationSettingsDialogComponent, {
      scrollStrategy: new NoopScrollStrategy(),
      autoFocus: false,
      width: '600px',
      disableClose: true,
      panelClass: 'custom-dialog',
    });
  }

  rowTappedNGX({ row, type }: any, birthday: boolean) {
    if (type === 'click') {
      if (row?.draft) {
        this.navigate(row);
      } else if (this.selectedTab == 'conversations') {
        row.lastMessage.seen = true;
        const dialogRef = this.dialog.open(MessagesDialogComponent, {
          data: {
            customer: row.user.name,
            conversation: row,
          },
          scrollStrategy: new NoopScrollStrategy(),
          autoFocus: false,
          width: '600px',
          disableClose: true,
          panelClass: 'custom-dialog',
        });

        dialogRef.afterClosed().subscribe((el) => {
          this.chatService.socketDispose();
        });
      } else {
        const dialogRef = this.dialog.open(TwoOptionAlertComponent, {
          data: {
            title: 'Message',
            body: row?.message,
            buttonOne: birthday ? undefined : 'Duplicate',
            buttonTwo: 'Close',
          },
          scrollStrategy: new NoopScrollStrategy(),
          autoFocus: false,
          width: '350px',
          disableClose: true,
          panelClass: 'custom-dialog',
        });

        dialogRef.afterClosed().subscribe((option: number) => {
          if (option == 0) {
            this.navigate(row);
          }
        });
      }
    }
  }

  navigate(row: Message): void {
    this.router.navigate([Constants.routes.engagePush, row.nanoid]);
  }

  clickRate(row: any): string {
    return row?.delivered == 0 || !row?.delivered
      ? '-'
      : (
          ((row?.delivered ?? 0) /
            (row?.trueRecipients ?? row?.recipients ?? 0)) *
          100
        ).toFixed(2) + '%';
  }

  openAutomations(): void {
    this.mixpanel.track(Constants.analytics_keys.openAutomations);

    this.dialog.open(AutomationsDialogComponent, {
      data: {},
      scrollStrategy: new NoopScrollStrategy(),
      autoFocus: false,
      width: '600px',
      disableClose: true,
      panelClass: 'custom-dialog',
    });
  }

  deleteMessage(message: Message): void {
    this.mixpanel.track(Constants.analytics_keys.deleteMessage, {
      Message: message?.message,
    });

    this.saving = true;
    this.merchantService
      .deleteMessage(message._id!)
      .subscribe({
        next: (res: any) => {
          if (res) {
            window.location.reload();
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  tabChanged(index: any): void {
    let tab = this.tabs[index];

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        tab: tab,
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false,
    });

    this.selectedTab = tab;

    this.loadData();
  }

  loadData(): void {
    if (this.selectedTab === 'messages') {
      this.getSentMessages();
    }
    if (this.selectedTab === 'birthday') {
      this.getBirthdayNotifications();
    }
    if (this.selectedTab === 'conversations') {
      this.getConversations();
    }
  }

  conversationStatus(conversation: Conversation) {
    if (conversation.blockedByMerchant) {
      return 'Blocked';
    } else if (conversation.muted?.includes(conversation.merchant as string)) {
      return 'Muted';
    } else {
      return 'Active';
    }
  }

  status(message: any): string {
    if (message?.schedule?.active && !message?.schedule?.sent) {
      return `Scheduled<br>${this.formatDate(message?.schedule?.date)}`;
    } else if (message?.draft) {
      return 'Draft';
    }

    return 'Sent';
  }

  formatDate(isoString: string): string {
    let dateObject = new Date(isoString);

    let day = dateObject.getDate().toString().padStart(2, '0');
    let month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    let year = dateObject.getFullYear().toString().slice(-2);
    let hours = dateObject.getHours().toString().padStart(2, '0');
    let minutes = dateObject.getMinutes().toString().padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }

  seen(conversation: Conversation) {
    if (
      !(conversation.lastMessage as ChatMessage).seen &&
      (conversation.lastMessage as ChatMessage).authorType ===
        this.constants.userTypes.user
    ) {
      return false;
    }

    return true;
  }

  showInfoIcon(conversation: Conversation) {
    if (this.conversationStatus(conversation) === 'Blocked') {
      return true;
    }
    if (this.conversationStatus(conversation) === 'Muted') {
      return true;
    }
    return false;
  }

  infoIconText(conversation: Conversation): string {
    if (this.conversationStatus(conversation) === 'Blocked') {
      return 'You have blocked this customer from being able to message you';
    }
    if (this.conversationStatus(conversation) === 'Muted') {
      return 'You have muted this customer, you will not be notified when they message you';
    }
    return '';
  }
}
