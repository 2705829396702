<div class="container-fluid" *ngIf="!pageLoading">
  <div class="row">
    <div class="col-md-12" id="left-col">
      <div class="left-content">
        <div class="form-container">
          <div class="img-container">
            <a href="https://remyrewards.co.uk"
              ><img src="../assets/remylogo.png"
            /></a>
          </div>

          <div
            style="
              text-align: center;
              margin-bottom: 30px;
              font-weight: 400;
              font-size: 16px;
            "
          >
            Enter your email address below to reset your password
          </div>

          <form [formGroup]="form">
            <div class="form-field">
              <div class="form-field-label" style="color: var(--app-timber)">
                Email<span style="color: red">*</span>
              </div>
              <input
                class="form-input"
                autocomplete="new-password"
                [formControl]="emailFormControl"
                type="email"
              />
            </div>
            <div class="action-buttons" style="margin-top: 20px">
              <app-secondary-button
                style="margin-right: 10px"
                [title]="'Sign in'"
                [disabled]="false"
                color="darkGrey"
                (tapped)="navigateToLogin()"
              ></app-secondary-button>
              <app-primary-button
                [title]="'Reset Password'"
                [disabled]="loading || form.invalid"
                (tapped)="forgot()"
                bgColor="var(--app-timber)"
                color="white"
              ></app-primary-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<loader [loading]="pageLoading || loading"></loader>
