import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
} from '@angular/core';

import { Editor, Toolbar } from 'ngx-editor';
import DOMPurify from 'dompurify';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.css'],
})
export class TextEditorComponent implements OnInit {
  editor: Editor | undefined;
  @Input() html = '';

  @Output() htmlChange = new EventEmitter<string>();

  toolbar: Toolbar = [['bold', 'underline', 'italic']];

  constructor(public zone: NgZone) {}

  ngOnInit(): void {
    this.editor = new Editor();
  }

  ngOnDestroy(): void {
    this.editor?.destroy();
  }

  decodeHTMLEntities(html: string): string {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  }

  onContentChange(value: string) {
    const decoded = this.decodeHTMLEntities(value);
    const withoutImages = decoded.replace(/<img[^>]*>/gi, '');

    const sanitized = DOMPurify.sanitize(withoutImages, {
      ALLOWED_TAGS: [
        'p',
        'b',
        'i',
        'u',
        'a',
        'ul',
        'ol',
        'li',
        'br',
        'strong',
        'em',
      ],
      ALLOWED_ATTR: ['href'],
      FORBID_TAGS: ['script', 'iframe', 'img'],
      FORBID_ATTR: ['onerror', 'onclick', 'onload'],
    });

    this.htmlChange.emit(sanitized);
  }
}
