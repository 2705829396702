import { StampCard, User, Merchant, Reward, Location } from './index';

export interface UserReward {
  _id?: string;
  nanoid?: string;
  reward?: string | Reward;
  card?: string | StampCard;
  user?: string | User;
  location?: string | Location;
  merchant?: string | Merchant;
  hasRedeemed?: boolean;
  hasExpired?: boolean;
  redeemedDate: Date;
  expiry?: Date;
}
