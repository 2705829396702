<div class="attachments-container" (click)="click()">
  <div *ngIf="!fileUploaded && !fileOK && !readerResult">
    <div
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      "
    >
      <mat-icon class="upload-icon" fontSet="material-icons-outlined"
        >cloud_upload</mat-icon
      >
      <div class="popup-subtitle" style="color: var(--app-text-grey)">
        Click here to upload
      </div>
    </div>
  </div>
  <div *ngIf="(fileUploaded && fileOK) || readerResult">
    <img
      height="100%"
      [src]="readerResult"
      alt="Image"
      class="contained-image"
      [ngStyle]="{
        'border-radius': rounded ? '10px' : '0px'
      }"
    />
  </div>
</div>
