import { Component, Inject, OnInit, LOCALE_ID } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constants } from 'src/app/app.constants';
import { StripeService } from '../../../services/index';
import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'plan-success-dialog',
  templateUrl: './plan-success-dialog.component.html',
  styleUrls: ['./plan-success-dialog.component.css'],
})
export class PlanSuccessDialogComponent implements OnInit {
  loading = false;
  error = false;
  content = '';

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    public constants: Constants,
    private stripeService: StripeService,
    public matDialogRef: MatDialogRef<PlanSuccessDialogComponent>,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.getSubscriptionInfo();
  }

  getSubscriptionInfo(): void {
    this.loading = true;
    this.stripeService
      .getSubscriptionInfo(this.data?.customerId)
      .subscribe({
        next: (res: any) => {
          if (res) {
            const formattedDate = formatDate(
              res?.current_period_end * 1000,
              'dd MMMM yyyy',
              this.locale
            );

            this.content = `
              Your subscription is confirmed - we're so excited that you've decided to officially come on-board 🎉
              
              <br><br>

              Your subscription will auto-renew on <b>${formattedDate}</b>.
              
              <br><br>

              You can cancel anytime and you'll continue to receive the benefits of Remy until the end of the billing period.

            `;
          } else {
            this.matDialogRef.close();
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }
}
