import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/index';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private refreshingToken = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(private authService: AuthService, private router: Router) {}

  isMobile() {
    try {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    } catch (e) {
      return false;
    }
  }

  isRefreshOrLoginCall(url: string) {
    const baseUrl = url.split('?')[0];
    return (
      baseUrl &&
      (baseUrl.endsWith('/refresh-token') ||
        baseUrl.endsWith('/login') ||
        baseUrl.endsWith('/logout') ||
        baseUrl.endsWith('/user'))
    );
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authService.getUserProperty('token');
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    const withCredentials = !!this.isRefreshOrLoginCall(request.url);

    if (token && isApiUrl) {
      const headers: { [key: string]: string } = {
        Authorization: `Bearer ${token}`,
        platform: `web${this.isMobile() ? '-mobile' : ''} 1.0`,
      };

      const merchantId = this.authService.getNestedUserProperty(
        'merchant',
        '_id'
      );
      if (
        merchantId &&
        this.authService.getUserProperty('userType') === 'admin'
      ) {
        headers['merchant'] = merchantId;
      }

      request = request.clone({
        withCredentials,
        setHeaders: headers,
      });
    } else if (withCredentials) {
      request = request.clone({
        withCredentials: true,
      });
    }

    return next.handle(request).pipe(
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          if (error?.error?.redirectTo === 'login') {
            this.authService.logout(true);
          } else if (error?.error?.tryRefresh) {
            return this.handleTokenRefresh(request, next);
          }
        }
        return throwError(() => error);
      })
    );
  }

  private handleTokenRefresh(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.refreshingToken) {
      this.refreshingToken = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((res: any) => {
          this.refreshingToken = false;
          const newToken = res.token;
          this.authService.updateToken(newToken);
          this.refreshTokenSubject.next(newToken);

          return next.handle(this.addToken(request, newToken, true));
        }),
        catchError((error) => {
          this.refreshingToken = false;
          this.authService.logout(true);
          return throwError(() => error);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((token) => {
          return next.handle(this.addToken(request, token));
        })
      );
    }
  }

  private addToken(
    request: HttpRequest<any>,
    token: string,
    isRefreshCall = false
  ): HttpRequest<any> {
    return request.clone({
      withCredentials: true,
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
