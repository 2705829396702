import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

declare const gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor(private authService: AuthService) {}

  event(action: string, params: any = {}) {
    if (gtag && environment.production) {
      params['uid'] = this.uid ?? 'anon';
      params['email'] = this.email ?? 'anon';
      gtag('event', action, params);
    }
  }

  selectContentEvent(content_type: string, item_id: string) {
    if (gtag && environment.production) {
      gtag('event', 'select_content', {
        content_type: content_type,
        item_id: item_id,
        uid: this.uid ?? 'anon',
        email: this.email ?? 'anon',
      });
    }
  }

  get uid(): string {
    return this.authService.getUserProperty('uid');
  }

  get email(): string {
    return this.authService.getUserProperty('email');
  }
}
