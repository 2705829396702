<div style="display: flex">
  <p class="header-title" [style.font-size]="smallTitle ? '18px' : '22px'">
    {{ title }}
  </p>
  <span class="separator" [style.font-size]="smallTitle ? '18px' : '22px'"
    >|</span
  >
  <p class="header-subtitle" [style.font-size]="smallTitle ? '18px' : '22px'">
    {{ subtitle }}
  </p>
</div>
<hr class="divider" />
