import { Reward, Merchant } from './index';

export interface SendAs {
  name: string;
  type: string;
}

export interface Message {
  nanoid?: string;
  _id?: string;
  type?: string;
  message?: string;
  recipients?: number;
  merchant?: string | Merchant;
  reward?: string | Reward;
  draft?: boolean;
  schedule?: {
    active?: boolean;
    sent?: boolean;
    date?: Date;
  };
  recipientList?: string[];
  from?: SendAs;
}
