<div
  class="outer-container"
  [ngStyle]="{
    'background-color': 'rgba(0, 0, 0, 0.8)',
    width: (isHandset$ | async) ? '100%' : '500px'
  }"
>
  <div
    class="inner-container"
    [ngStyle]="
      cardColour
        ? { background: cardColour }
        : {
            background:
              'linear-gradient(' +
              cardGradient?.angle +
              'deg, ' +
              cardGradient?.from +
              ' ' +
              0 +
              '%, ' +
              cardGradient?.from +
              ' ' +
              cardGradient?.value +
              '%, ' +
              cardGradient?.to +
              ' ' +
              100 +
              '%)'
          }
    "
  >
    <div class="front">
      <div class="header-container">
        <img
          *ngIf="showLogo"
          [src]="merchantLogo"
          style="
            width: 60px !important;
            height: 60px;
            margin-right: 5px;
            border-radius: 5px;
          "
        />
        <div>
          <div
            class="colour-label"
            [ngStyle]="{ color: textColour }"
            *ngIf="title"
          >
            {{ title }}
          </div>
          <div
            class="colour-label-desc"
            style="margin-bottom: 10px"
            [ngStyle]="{ color: textColour }"
            *ngIf="description"
          >
            {{ description }}
          </div>
        </div>
      </div>

      <div class="grid-container" *ngIf="cardType && isStampCard">
        <div
          class="grid"
          [ngStyle]="{
            'column-gap': '4%',
            'row-gap': '40px',
            'grid-template-columns': 'repeat(6, 1fr)'
          }"
        >
          <div
            *ngFor="let stamp of stamps; let i = index"
            class="stamp-wrapper"
            (click)="stampClicked(i)"
            [ngStyle]="{
              cursor: isReward(i) ? 'pointer' : 'inherit'
            }"
          >
            <div
              class="inner-circle"
              [ngStyle]="{
                background: showStamps
                  ? stampColour
                  : stampFillEmptyColour ?? 'none',
                'border-radius': borderRadius(true)
              }"
            >
              <mat-icon
                [fontSet]="
                  getIsFilled(i) ? 'material-icons' : 'material-icons-outlined'
                "
                *ngIf="
                  (showStamps && !isReward(i) && !stampImage) ||
                  (isReward(i) && !customRewardImage(i))
                "
                [ngStyle]="{
                  color: iconColor,
                  cursor: 'pointer',
                  opacity: iconOpacity
                }"
              >
                {{ getIcon(i) }}
              </mat-icon>

              <img
                *ngIf="
                  (showStamps && !isReward(i) && stampImage) ||
                  (isReward(i) && customRewardImage(i))
                "
                [ngStyle]="{
                  width: '30px',
                  height: '30px',
                  opacity: iconOpacity
                }"
                [src]="isReward(i) ? customRewardImage(i) : stampImage"
              />
            </div>
            <div
              class="outer-circle"
              [ngStyle]="{
                'border-color': stampOutlineColour,
                'border-radius': borderRadius()
              }"
            ></div>
          </div>
        </div>
      </div>

      <div class="points-container" *ngIf="cardType && !isStampCard">
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div
            class="points-container-center"
            style="width: 100%"
            *ngIf="isPointsCard"
          >
            <div
              class="custom-progress-bar-container"
              style="margin-top: 20px; margin-bottom: 10px"
              [ngStyle]="{
                'background-color': progressBarBgColor
              }"
            >
              <div
                class="custom-progress-bar"
                [ngStyle]="{
                  'background-color': progressBarColor,
                  width: roundedCurrentPointsPercent + '%'
                }"
              ></div>
            </div>

            <div style="display: flex; justify-content: space-between">
              <div
                class="colour-label-desc"
                style="margin-bottom: 10px"
                [ngStyle]="{ color: textColour }"
                *ngIf="isPointsCard"
              >
                <span style="font-weight: bold">{{
                  pointsRemaining +
                    (pointsRemaining === 1 ? " point" : " points")
                }}</span>
                until your reward
              </div>
              <div
                class="colour-label"
                style="margin-bottom: 5px"
                [ngStyle]="{ color: textColour }"
              >
                {{ roundedCurrentPoints }}/{{ totalPoints }}
              </div>
            </div>

            <!-- <mat-progress-bar
              style="background-color: blue !important"
              mode="determinate"
              [value]="roundedCurrentPointsPercent"
            ></mat-progress-bar> -->
          </div>

          <div
            class="points-container-center"
            style="width: 100%"
            *ngIf="isOpenEndedPointsCard"
          >
            <div style="display: flex; flex-direction: row">
              <div style="margin-right: 40px">
                <div
                  class="colour-label"
                  style="margin-bottom: 5px"
                  [ngStyle]="{
                    color: textColour,

                    'font-size': '16px',
                    'font-weight': 'normal'
                  }"
                >
                  Points
                </div>
                <div
                  class="colour-label"
                  style="margin-bottom: 5px"
                  [ngStyle]="{
                    color: textColour,

                    'font-size': '24px',
                    'font-weight': 'bold'
                  }"
                >
                  {{ roundedCurrentPoints
                  }}{{ (maxPoints ?? 0) > 0 ? "/" + maxPoints : "" }}
                </div>
              </div>
              <div>
                <div
                  class="colour-label"
                  style="margin-bottom: 5px"
                  [ngStyle]="{
                    color: textColour,

                    'font-size': '16px',
                    'font-weight': 'normal'
                  }"
                >
                  Available Rewards
                </div>
                <div
                  class="colour-label"
                  style="margin-bottom: 5px"
                  [ngStyle]="{
                    color: textColour,

                    'font-size': '24px',
                    'font-weight': 'bold'
                  }"
                >
                  2
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="qr-code-container">
            <img
              src="../../../../assets/qrcode.svg"
              width="70px"
              height="70px"
            />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
