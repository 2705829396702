<dialog-header
  title="Gradient Settings"
  (onClose)="matDialogRef.close()"
></dialog-header>

<div
  mat-dialog-content
  style="
    padding-bottom: 20px;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    background-color: var(--app-container);
  "
>
  <div
    class="preview"
    [ngStyle]="{
      background:
        'linear-gradient(' +
        rotation +
        'deg, ' +
        fromFormControl.value +
        ' ' +
        minValue +
        '%, ' +
        fromFormControl.value +
        ' ' +
        midValue +
        '%, ' +
        toFormControl.value +
        ' ' +
        maxValue +
        '%)'
    }"
  ></div>

  <div
    style="
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    "
  >
    <div class="colour-container">
      <div
        class="colour-avatar"
        [ngStyle]="{
          'background-color': fromFormControl.value,
          cursor: 'pointer'
        }"
      >
        <ngx-colors
          ngx-colors-trigger
          style="display: inline-block; margin: 5px; opacity: 0"
          (change)="logEvent($event, 'change', fromFormControl)"
          (input)="logEvent($event, 'input', fromFormControl)"
          (slider)="logEvent($event, 'slider', fromFormControl)"
          [formControl]="fromFormControl"
        ></ngx-colors>
      </div>
    </div>
    <div class="colour-container">
      <div
        class="colour-avatar"
        [ngStyle]="{
          'background-color': toFormControl.value,
          cursor: 'pointer'
        }"
      >
        <ngx-colors
          ngx-colors-trigger
          style="display: inline-block; margin: 5px; opacity: 0"
          [formControl]="toFormControl"
          (change)="logEvent($event, 'change', toFormControl)"
          (input)="logEvent($event, 'input', toFormControl)"
          (slider)="logEvent($event, 'slider', toFormControl)"
        ></ngx-colors>
      </div>
    </div>
  </div>

  <div class="custom-slider" style="margin-bottom: 20px">
    <ngx-slider
      [(value)]="midValue"
      [options]="valueOptions"
      (valueChange)="valueChanged($event)"
    ></ngx-slider>
  </div>

  <div class="form-field-label">Gradient Rotation</div>
  <div class="custom-slider" style="padding-top: 20px">
    <ngx-slider
      [(value)]="rotation"
      [options]="rotationOptions"
      (valueChange)="valueChanged($event)"
    ></ngx-slider>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button
    [disabled]="false"
    (click)="matDialogRef.close()"
    mat-flat-button
    class="secondary-button"
    style="margin-right: 10px"
  >
    <span>Cancel</span>
  </button>
  <button
    [disabled]="false"
    (click)="primaryTapped()"
    mat-flat-button
    class="primary-button"
  >
    <span>Save</span>
  </button>
</div>
