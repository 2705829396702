<div class="attachments-container" (click)="click()">
  <div>
    <div
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      "
    >
      <mat-icon class="upload-icon" fontSet="material-icons-outlined">{{
        icon
      }}</mat-icon>
      <div class="popup-subtitle" style="color: var(--app-text-grey)">
        {{ label }}
      </div>
    </div>
  </div>
</div>
