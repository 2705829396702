<div
  class="d-flex justify-content-between align-items-md-center align-items-baseline"
  style="padding: 15px 0px 15px 20px"
>
  <div>
    <p
      class="section-title"
      style="margin-bottom: 0px; font-weight: bold; font-size: 16px"
    >
      {{ title }}
    </p>

    <p
      *ngIf="subtitle"
      class="section-title"
      style="
        margin-bottom: 0px;
        font-weight: normal;
        color: grey;
        font-size: 14px;
      "
    >
      {{ subtitle }}
    </p>
  </div>

  <div style="display: flex">
    <button mat-button (click)="onClose.emit()">
      <mat-icon class="dialog-close-icon">close</mat-icon>
    </button>
  </div>
</div>
