<div class="signup-container">
  <div class="image-section">
    <img [src]="loginImage" alt="Sign Up" class="signup-image" />
    <div class="image-overlay">
      <p class="image-label">{{ loginLabel }}</p>
    </div>
  </div>

  <img src="../assets/remylogo.png" alt="Logo" class="logo" />

  <div class="right-section">
    <div class="form-section">
      <h2
        style="
          font-weight: normal;
          font-family: 'Monomakh';
          text-align: center;
          font-size: 36px;
        "
      >
        Welcome Back
      </h2>
      <p
        style="
          font-weight: normal;
          text-align: center;
          color: darkgrey;
          font-size: 16px;
        "
      >
        Enter your email and password to access your account
      </p>

      <form [formGroup]="loginForm" (ngSubmit)="login()">
        <div class="form-field" style="margin-top: 20px">
          <div class="form-field-label" style="color: var(--app-timber)">
            Email
          </div>
          <input
            class="form-input"
            [formControl]="emailFormControl"
            type="email"
          />
        </div>
        <div class="form-field" style="margin-top: 20px">
          <div class="form-field-label" style="color: var(--app-timber)">
            Password
          </div>
          <div style="display: flex; align-items: center">
            <input
              [type]="hidePassword ? 'password' : 'text'"
              class="form-input"
              formControlName="password"
            />
            <mat-icon
              class="visibility-icon"
              (click)="hidePassword = !hidePassword"
              >{{ hidePassword ? "visibility_off" : "visibility" }}</mat-icon
            >
          </div>
        </div>

        <div style="display: flex; justify-content: flex-end; margin-top: 10px">
          <app-secondary-button
            [title]="'Reset Password'"
            [disabled]="false"
            color="darkGrey"
            [type]="'button'"
            (tapped)="navigateToForgotPassword()"
          ></app-secondary-button>
        </div>

        <div class="action-buttons" style="margin-top: 20px">
          <app-primary-button
            [title]="'Magic Link'"
            [disabled]="loadingOne"
            (tapped)="magicLink()"
            border="1px solid var(--app-timber)"
            color="var(--app-timber)"
            icon=""
          ></app-primary-button>
          <app-primary-button
            [title]="'Sign in'"
            [disabled]="loadingOne || loginForm.invalid"
            (tapped)="login()"
            bgColor="var(--app-timber)"
            color="white"
            [type]="'submit'"
          ></app-primary-button>
        </div>
      </form>
    </div>
  </div>
</div>

<loader [loading]="pageLoading || loadingOne"></loader>
