import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from 'src/app/app.constants';

import { AuthService } from '../../../services/index';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit {
  token = '';
  loading = false;

  isMobile = false;
  form!: FormGroup;
  submitted = false;
  passwordChanged = false;
  userType = this.constants.userTypes.merchant;
  pageLoading = true;

  confirmPasswordFormControl = new FormControl(
    { value: '', disabled: false },
    Validators.required
  );
  passwordFormControl = new FormControl(
    { value: '', disabled: false },
    Validators.required
  );

  @HostListener('window:resize', ['$event'])
  onResize = () => this.testMobile();
  testMobile = () => (this.isMobile = window.innerWidth < 768 ? true : false);

  constructor(
    public constants: Constants,
    private AuthService: AuthService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.queryParams.subscribe((params) => {
      this.token = params['token'];
      if (!this.token) {
        this.router.navigate([Constants.routes.login]);
      }
    });
  }

  reload(): void {
    this.router.navigate(['/login']).then(() => window.location.reload());
  }

  ngOnInit() {
    this.testMobile();

    this.form = this.formBuilder.group({
      password: this.passwordFormControl,
      confirmPassword: this.confirmPasswordFormControl,
    });

    setTimeout(() => {
      this.pageLoading = false;
    }, 500);
  }

  get formControls() {
    return this.form.controls;
  }

  submit() {
    this.submitted = true;

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    if (!this.passwordValid) {
      this.constants.snack(
        'Password must contain at least 6 characters and 1 digit'
      );
      return;
    }

    if (
      this.passwordFormControl.value != this.confirmPasswordFormControl.value
    ) {
      this.constants.snack('Password do not match');
      return;
    }

    this.loading = true;
    this.AuthService.changePassword(this.token, this.passwordFormControl.value!)
      .subscribe({
        next: (res: HttpResponse<any>) => {
          if (res) {
            const body = res.body;

            if (
              body.userType === this.constants.userTypes.merchant ||
              body.userType === this.constants.userTypes.manager
            ) {
              this.home();
            }

            if (body.userType === this.constants.userTypes.staff) {
              this.userType = this.constants.userTypes.staff;
            }

            if (body.userType === this.constants.userTypes.user) {
              this.userType = this.constants.userTypes.user;
            }

            this.passwordChanged = true;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  home() {
    this.router.navigate(['/']);
    setTimeout(() => window.location.reload(), 500);
  }

  openMobileApp(): void {
    if (this.userType === this.constants.userTypes.staff) {
      window.location.replace('https://remyrewards.co.uk/mapp');
    } else {
      window.location.replace('https://remyrewards.co.uk/app');
    }
  }

  get passwordValid(): boolean {
    return (
      this.constants.passwordValid(this.passwordFormControl.value ?? '') &&
      this.constants.passwordValid(this.confirmPasswordFormControl.value ?? '')
    );
  }
}
