import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Constants } from 'src/app/app.constants';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { AuthService, MerchantService } from 'src/app/services';
import { IntegrationDetailDialogComponent } from './integration-detail-dialog/integration-detail-dialog.component';
import { IntegrationConfig } from 'src/app/models';
import { HttpErrorResponse } from '@angular/common/http';

export interface Integration {
  label: string;
  value: string;
  image: string;
  active: boolean;
  tags: string[];
  link: string;
}

@Component({
  selector: 'integrations-home',
  templateUrl: 'integrations-home.component.html',
  styleUrls: ['./integrations-home.component.css'],
})
export class IntegrationsHomeComponent implements OnInit {
  title = '';
  subtitle: string = '';

  activeIntegrations: IntegrationConfig[] = [];

  integrations: Integration[] = [
    {
      label: 'acuity:scheduling',
      value: 'acuity',
      image: '../../../assets/acuity_logo_small.png',
      active: false,
      tags: ['Online'],
      link: 'https://acuityscheduling.com',
    },
    {
      label: 'Epos Now',
      value: 'eposnow',
      image: '../../../assets/eposnow.png',
      active: false,
      tags: ['Online', 'Epos'],
      link: 'https://www.eposnow.com',
    },
    {
      label: 'Shopify',
      value: 'shopify',
      image: '../../../assets/shopify-logo.png',
      active: false,
      tags: ['Online', 'Epos'],
      link: 'https://www.shopify.com',
    },
    {
      label: 'Square',
      value: 'square',
      image: '../../../assets/square-logo.png',
      active: false,
      tags: ['Online', 'Epos'],
      link: 'https://squareup.com',
    },
    {
      label: 'Squarespace',
      value: 'squarespace',
      image: '../../../assets/squarespace_logo_small.png',
      active: false,
      tags: ['Online'],
      link: 'https://www.squarespace.com',
    },
    {
      label: 'Storekit',
      value: 'storekit',
      image: '../../../assets/storekit.png',
      active: false,
      tags: ['Online'],
      link: 'https://storekit.com',
    },
    {
      label: 'SumUp (Goodtill)',
      value: 'sumup',
      image: '../../../assets/sumup.png',
      active: false,
      tags: ['Epos'],
      link: 'https://www.sumup.com/en-gb/goodtill-pos',
    },
    {
      label: 'Zapier',
      value: 'zapier',
      image: '../../../assets/Zapier_logo.jpg',
      active: false,
      tags: ['7000+ Integrations'],
      link: 'https://zapier.com',
    },
  ];

  saving = false;
  loading = false;
  error = false;

  merchantActive = false;

  constructor(
    public constants: Constants,
    public dialog: MatDialog,
    private authService: AuthService,
    private merchantService: MerchantService
  ) {}

  ngOnInit() {
    this.merchantActive = this.authService.merchantObj?.active ?? true;

    this.subtitle = this.authService.getNestedUserProperty('merchant', 'name');

    this.getActiveIntegrations();
  }

  getActiveIntegrations(): void {
    this.loading = true;
    this.merchantService
      .getActiveIntegrations()
      .subscribe({
        next: (res: IntegrationConfig[]) => {
          if (res) {
            this.activeIntegrations = res;

            this.integrations.forEach((el) => {
              const activeIntegration = this.activeIntegrations.find(
                (i) => i.integrationType === el.value
              );
              if (activeIntegration) {
                el.active = true;
              }
            });
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  onIntegrationClick(value: Integration) {
    this.dialog.open(IntegrationDetailDialogComponent, {
      data: {
        integration: value,
      },
      scrollStrategy: new NoopScrollStrategy(),
      autoFocus: false,
      width: '750px',
      disableClose: true,
      panelClass: 'custom-dialog',
    });
  }
}
