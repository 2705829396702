import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class LocationService {
  constructor(private api: ApiService) {}

  PATH = 'location';

  getLocation(location: string) {
    return this.api.get(`${this.PATH}/${location}`);
  }

  getLocations(limit: number, page: number) {
    var query = `?limit=${limit}&page=${page}`;
    return this.api.get(`${this.PATH}/list` + query);
  }

  createLocation(payload: any) {
    return this.api.post(`${this.PATH}`, payload);
  }

  editLocation(payload: any) {
    return this.api.put(`${this.PATH}`, payload);
  }

  toggleLocation(payload: any) {
    return this.api.post(`${this.PATH}/toggle`, payload);
  }
}
