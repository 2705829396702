import {
  Component,
  ElementRef,
  Inject,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { Constants } from 'src/app/app.constants';

@Component({
  selector: 'image-crop-dialog',
  templateUrl: './image-crop-dialog.component.html',
  styleUrls: ['./image-crop-dialog.component.css'],
})
export class ImageCropDialogComponent implements OnInit {
  @ViewChild('fileInput') fileInput!: ElementRef;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  blob!: Blob;
  fileAccept = '.png, .jpg, .jpeg';

  fileName = '';
  fileMime = '';
  fileContent = '';
  fileSize = 0;

  resizeToHeight: number = 500;

  constructor(
    public dialog: MatDialog,
    public fb: FormBuilder,
    public matDialogRef: MatDialogRef<ImageCropDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public zone: NgZone,
    private sanitizer: DomSanitizer,
    private constants: Constants
  ) {}

  ngOnInit(): void {}

  primaryTapped(): void {
    this.save();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;

    const file = event.target.files[0];

    this.fileName = file.name;
    this.fileMime = file.type.replace('image/', '');
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl!);
    // event.blob can be used to upload the cropped image

    if (event.blob) {
      this.blob = event.blob!;
    }
    this.fileSize = this.blob.size;
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  formatSize(bytes: number): string {
    const megabytes = bytes / (1024 * 1024);
    return megabytes.toFixed(2) + ' MB';
  }

  blobToBase64 = () => {
    const reader = new FileReader();
    reader.readAsDataURL(this.blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  save(): void {
    if (this.fileSize > this.data.maxFileSize) {
      this.constants.snack(
        `File cannot be larger than ${this.formatSize(this.data.maxFileSize)}`
      );
      return;
    }

    this.blobToBase64().then((res) => {
      this.matDialogRef.close({
        fileMime: this.fileMime,
        fileName: this.fileName,
        fileContent: res,
        blob: this.blob,
      });
    });
  }

  triggerFileInput(): void {
    this.fileInput.nativeElement.click();
  }
}
