<dialog-header
  [title]="editMode ? 'Update Configuration' : 'Add Configuration'"
  (onClose)="matDialogRef.close()"
></dialog-header>

<div
  mat-dialog-content
  style="
    padding-bottom: 20px;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    background-color: var(--app-container);
    overflow: scroll;
  "
>
  <form [formGroup]="form" style="margin-top: 20px">
    <app-info-box
      title="Info"
      [body]="infoBody"
      [canDismiss]="false"
    ></app-info-box>

    <div class="form-field" style="margin-top: 20px">
      <single-select
        label="Type"
        [formCtrl]="typeFormControl"
        [value]="typeFormControl.value"
        [options]="types"
        (selected)="typeChanged()"
        property="name"
        [showIcon]="false"
      ></single-select>
    </div>

    <div class="form-field" *ngIf="typeFormControl.value">
      <single-select
        label="Loyalty Card"
        [formCtrl]="cardFormControl"
        [options]="cards"
        [value]="cardFormControl.value"
        [inBrackets]="'info'"
        property="title"
        [showIcon]="false"
        (selected)="cardSelected()"
      ></single-select>
    </div>

    <!-- <div class="form-field" *ngIf="showSkuField">
      <div class="form-field-label">
        Product SKU<span
          ><mat-icon
            class="icon"
            [matTooltip]="skuTooltip"
            [matTooltipPosition]="'before'"
            style="cursor: help"
            #locationsTooltip="matTooltip"
            (click)="locationsTooltip.toggle()"
            >info_outlined</mat-icon
          ></span
        >
      </div>
      <input
        class="form-input"
        autocomplete="off"
        [formControl]="skuFormControl"
        maxlength="30"
        type="text"
      />
    </div> -->

    <div class="form-field" *ngIf="showEarningMethodField">
      <single-select
        label="Earning Method"
        [formCtrl]="earnMethodFormControl"
        [value]="earnMethodFormControl.value"
        [options]="earningMethods"
        (selected)="earningMethodChanged()"
        property="name"
        [showIcon]="true"
        [inBrackets]="'info'"
        [webIcon]="true"
      ></single-select>
    </div>

    <div class="form-field" *ngIf="showMinimumSpendField">
      <div class="form-field-label">
        Minimum Order value<span style="color: red">*</span
        ><span><info-icon [text]="minimumSpendInfoText"></info-icon></span>
      </div>

      <input
        class="form-input"
        autocomplete="off"
        placeholder="e.g. 10.00"
        [formControl]="valueFormControl"
        type="number"
        [maxlength]="7"
        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
      />
    </div>

    <div
      class="form-field"
      *ngIf="showIncrementalField"
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <div>
        <div class="form-field-label">
          Spend<span style="color: red">*</span
          ><span><info-icon [text]="minimumSpendInfoText"></info-icon></span>
        </div>

        <input
          class="form-input"
          autocomplete="off"
          placeholder="e.g. 1"
          [formControl]="valueFormControl"
          type="number"
          [maxlength]="7"
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
        />
      </div>
      <span style="position: relative; bottom: -15px; font-size: 22px">=</span>
      <div>
        <div class="form-field-label">
          Points<span style="color: red">*</span
          ><span><info-icon [text]="incrementalInfoText"></info-icon></span>
        </div>

        <input
          class="form-input"
          autocomplete="off"
          placeholder="e.g. 1"
          [formControl]="pointsFormControl"
          type="number"
          [maxlength]="7"
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
        />
      </div>
    </div>

    <div style="padding-top: 10px">
      <app-info-box
        *ngIf="showIncrementalSummaryField"
        [body]="incrementalPointsSummary"
        [canDismiss]="false"
      ></app-info-box>
    </div>

    <div
      class="form-field"
      style="margin-top: 20px"
      *ngIf="showIncrementalField"
    >
      <div class="form-field-label">
        Maximum Cap<span
          ><info-icon [text]="maximumNumberOfPointsCapInfoText"></info-icon
        ></span>
      </div>
      <input
        class="form-input"
        autocomplete="off"
        [maxlength]="5"
        placeholder="e.g. 100 or leave blank for no cap"
        [formControl]="pointsCapFormControl"
        type="number"
        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
      />
    </div>

    <div
      class="form-field"
      style="margin-top: 20px"
      *ngIf="showNumberOfStampsField"
    >
      <single-select
        [infoText]="
          'The number of stamps to give to the customer for meeting the requirements of this configuration'
        "
        [label]="'Number of stamps'"
        [formCtrl]="stampsFormControl"
        [options]="stampNumbers"
        [value]="stampsFormControl.value"
      >
      </single-select>
    </div>

    <div
      class="form-field"
      style="margin-top: 20px"
      *ngIf="showNumberOfPointsField"
    >
      <div class="form-field-label">Number of points</div>
      <input
        class="form-input"
        autocomplete="off"
        [maxlength]="5"
        [formControl]="pointsFormControl"
        type="number"
        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
      />
    </div>
  </form>
</div>

<div mat-dialog-actions align="end">
  <app-secondary-button
    style="margin-right: 10px"
    [title]="'Cancel'"
    [disabled]="false"
    (tapped)="matDialogRef.close()"
  ></app-secondary-button>

  <app-primary-button
    [title]="'Save'"
    [disabled]="false"
    (tapped)="save()"
    bgColor="var(--app-timber)"
    color="white"
  ></app-primary-button>
</div>

<loader [loading]="loading"></loader>
