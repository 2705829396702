import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cons, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import jwt_decode from 'jwt-decode';
import { StorageService } from './storage.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Constants } from '../app.constants';
import { Merchant } from '../models/merchant';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private BASE_URL = environment.apiUrl;

  PATH = 'v1/merchant';

  constructor(
    private api: ApiService,
    private storageService: StorageService,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  private httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private authStatusListener = new Subject<{
    auth: boolean;
  }>();
  private registerListener = new Subject<boolean>();
  private verifyEmailListener = new Subject<boolean>();
  private resetPasswordListener = new Subject<boolean>();
  public isAuthenticated = false;

  getResetPasswordListener() {
    return this.resetPasswordListener.asObservable();
  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  getRegisterListener() {
    return this.registerListener.asObservable();
  }

  getVerifyEmailListener() {
    return this.verifyEmailListener.asObservable();
  }

  getIsAuth() {
    return this.isAuthenticated;
  }

  autoAuthUser() {
    const authData = this.getAuthData();

    if (!authData?.token) {
      return;
    }

    const decoded: any = jwt_decode(authData.token);
    const expiry = decoded['exp'];
    var expiresIn = Math.abs(new Date().getTime() / 1000 - expiry);

    this.isAuthenticated = expiresIn > 0;

    this.authStatusListener.next({
      auth: this.isAuthenticated,
    });
  }

  private getAuthData() {
    if (this.storageService.get('user')) {
      const token = JSON.parse(this.storageService.get('user')).token;
      return {
        token: token,
      };
    } else {
      return;
    }
  }

  resetPassword(email: string) {
    return this.http.post<any>(
      `${this.BASE_URL}v1/password/reset`,
      { email: email },
      { observe: 'response', headers: this.httpHeaders }
    );
  }

  changePassword(token: string, password: string) {
    return this.http.post<any>(
      `${this.BASE_URL}v1/password/change`,
      { token: token, password: password },
      { observe: 'response', headers: this.httpHeaders }
    );
  }

  manualChangePassword(current: string, newPassword: string) {
    return this.http.post<any>(
      `${this.BASE_URL}v1/password/update`,
      { current: current, new: newPassword },
      { observe: 'response', headers: this.httpHeaders }
    );
  }

  register(
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    businessName: string,
    businessType: string,
    country?: string
  ) {
    return this.http
      .post<any>(
        `${this.BASE_URL}v1/register/merchant`,
        {
          email: email,
          password: password,
          firstName: firstName,
          lastName: lastName,
          businessName: businessName,
          businessType: businessType,
          country: country,
        },
        { observe: 'response', headers: this.httpHeaders }
      )
      .subscribe({
        next: (r: HttpResponse<any>) => {
          if (r.body.success) {
            this.registerListener.next(true);
          } else {
            this.registerListener.next(false);
          }
        },
        error: (error) => {
          const parsed = JSON.parse(JSON.stringify(error));
          this.snackBar.open(parsed.error.message, '', {
            duration: 2000,
          });
          this.registerListener.next(false);
        },
      });
  }

  verifyToken(token: string) {
    return this.http
      .post<any>(
        `${this.BASE_URL}v1/verify-email`,
        { token: token },
        { observe: 'response', headers: this.httpHeaders }
      )
      .subscribe({
        next: (r: HttpResponse<any>) => {
          if (r.body.success) {
            this.verifyEmailListener.next(true);
          } else {
            this.verifyEmailListener.next(false);
          }
        },
        error: (error) => {
          const parsed = JSON.parse(JSON.stringify(error));
          this.snackBar.open(parsed.error.message, '', {
            duration: 2000,
          });
          this.verifyEmailListener.next(false);
        },
      });
  }

  refreshToken() {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.http.post(`${this.BASE_URL}v1/refresh-token`, httpOptions);
  }

  redirectToConfirm(email: string) {
    this.router.navigate([Constants.routes.confirm]).then(() => {
      sessionStorage.setItem('email', email);
    });
  }

  login(email: string, password: string, token?: string) {
    this.http
      .post<any>(
        `${this.BASE_URL}v1/login?merchant=true`,
        { email, password, token },
        { observe: 'response', headers: this.httpHeaders }
      )
      .subscribe({
        next: (r: HttpResponse<any>) => {
          const userObj = {
            token: r.body.token,
            name: r.body.name,
            firstName: r.body.firstName,
            uid: r.body.uid,
            nanoid: r.body.nanoid,
            email: r.body.email,
            userType: r.body.userType,
            merchant: r.body.merchant,
            onboarded: r.body?.onboarded,
            isOwner: r.body?.isOwner,
            canViewCustomers: r.body?.canViewCustomers,
          };

          if (r.body.token) {
            this.storageService.set('user', JSON.stringify(userObj));
            this.isAuthenticated = true;
          }
          this.authStatusListener.next({
            auth: this.isAuthenticated,
          });
        },
        error: (error) => {
          const parsed = JSON.parse(JSON.stringify(error));

          if (parsed.error.redirectTo === 'confirm') {
            this.redirectToConfirm(email);
          } else {
            this.snackBar.open(
              parsed.error.message ?? 'Unable to process your request',
              '',
              {
                duration: 2000,
              }
            );
          }
          this.authStatusListener.next({
            auth: false,
          });
        },
      });
  }

  updateToken(newToken: string) {
    const user = JSON.parse(this.storageService.get('user'));
    user.token = newToken;
    this.storageService.set('user', JSON.stringify(user));
  }

  logout(redirect = false) {
    this.isAuthenticated = false;
    this.authStatusListener.next({ auth: false });
    this.storageService.clean();

    if (redirect) {
      this.router
        .navigate([Constants.routes.login])
        .then(() => window.location.reload());
    }
  }

  getUserProperty(property: string): any {
    if (this.storageService.get('user')) {
      return JSON.parse(this.storageService.get('user'))[property];
    }
    return '';
  }

  getMerchantLogo(): string {
    const userJson = this.storageService.get('user');
    if (userJson) {
      const user = JSON.parse(userJson);
      if (
        user &&
        user['merchant'] &&
        user['merchant']['logo'] &&
        user['merchant']['logo']['url']
      ) {
        return user['merchant']['logo']['url'] + '?' + new Date().getTime();
      } else {
        return 'assets/remyIcon.png';
      }
    }
    return '';
  }

  get merchantObj(): Merchant | null {
    const userJson = this.storageService.get('user');
    if (userJson) {
      const user = JSON.parse(userJson);
      if (user && user['merchant']) {
        return user['merchant'];
      }
    }
    return null;
  }

  getNestedUserProperty(property1: string, property2: string): any {
    if (this.storageService.get('user')) {
      const user = JSON.parse(this.storageService.get('user'));
      if (user) {
        if (JSON.parse(this.storageService.get('user'))[property1]) {
          return JSON.parse(this.storageService.get('user'))[property1][
            property2
          ];
        }
      }
    }
    return '';
  }

  updateNestedProperty(
    property1: string,
    property2: string,
    newValue: any
  ): void {
    const userStr = this.storageService.get('user');
    if (userStr) {
      const user = JSON.parse(userStr);

      if (!user[property1]) {
        user[property1] = {};
      }

      user[property1][property2] = newValue;

      this.storageService.set('user', JSON.stringify(user));
    }
  }

  removeNestedProperty(property1: string, property2: string): void {
    const userStr = this.storageService.get('user');
    if (userStr) {
      const user = JSON.parse(userStr);

      if (user[property1] && user[property1][property2] !== undefined) {
        delete user[property1][property2];

        this.storageService.set('user', JSON.stringify(user));
      }
    }
  }

  getMerchant(merchant: string) {
    return this.api.get(`${this.PATH}/${merchant}`);
  }

  updateMerchant(payload: any) {
    return this.api.post(`${this.PATH}/update`, payload);
  }

  createMagicLink(data: any) {
    return this.api.post(`v1/create-magic-link`, data);
  }
}
