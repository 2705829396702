import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  storage: Storage;

  constructor() {
    this.storage = this.isInDevMode()
      ? window.sessionStorage
      : window.localStorage;
  }

  public clean(): void {
    window.sessionStorage.clear();
    window.localStorage.clear();
  }

  public get(key: string): any {
    const value = this.storage.getItem(key);

    return value!;
  }

  public set(key: string, value: any): void {
    this.storage.setItem(key, value);
  }

  public setLocal(key: string, value: string): void {
    this.storage.setItem(key, value);
  }

  public getLocal(key: string): string {
    const value = this.storage.getItem(key);

    return value!;
  }

  isInDevMode(): boolean {
    return !environment.production;
  }
}
