import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Constants } from 'src/app/app.constants';
import { VideoViewerComponent } from '../video-viewer/video-viewer.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Guide } from '../../../models/index';
import { AuthService, ListService } from '../../../services/index';

@Component({
  selector: 'help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css'],
})
export class HelpComponent {
  @Input() smallTitle = false;
  @Input() title = '';
  @Input() subtitle: string = '';
  @ViewChild('myTable') table: any;

  guides: Guide[] = [];
  guidesLoading = false;
  error = false;

  constructor(
    public constants: Constants,
    public authService: AuthService,
    private listService: ListService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.subtitle = this.authService.getNestedUserProperty('merchant', 'name');

    this.getGuides();
  }

  getGuides(): void {
    this.guidesLoading = true;
    this.listService
      .getList('guides')
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.guides = res;
            this.guides?.sort((a, b) => a.order! - b.order!);
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() =>
        setTimeout(() => {
          this.guidesLoading = false;
        }, 500)
      );
  }

  get loading(): boolean {
    return this.guidesLoading;
  }

  openVideo(guide: Guide): void {
    const dialogRef = this.dialog.open(VideoViewerComponent, {
      autoFocus: false,
      // disableClose: true,
      data: {
        guides: this.guides,
        initialIndex: this.guides.findIndex((el) => el.title === guide.title),
      },
      scrollStrategy: new NoopScrollStrategy(),
    });
  }

  get firstName(): string {
    return this.authService.getUserProperty('firstName');
  }
}
