import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from 'src/app/app.constants';
import { MerchantService } from 'src/app/services';
import { AnalyticsAbstract } from 'src/app/services/analytics/analytics.abstract';
import { OauthDialogComponent } from 'src/app/shared/components/oauth-dialog/oauth-dialog.component';

@Component({
  selector: 'oauth',
  templateUrl: 'oauth.component.html',
  styleUrls: ['./oauth.component.css'],
})
export class OauthComponent implements OnInit {
  hasError = false;
  loading = false;

  constructor(
    public constants: Constants,
    public dialog: MatDialog,
    private mixpanel: AnalyticsAbstract,
    private route: ActivatedRoute,
    private router: Router,
    private merchantService: MerchantService
  ) {
    this.route.queryParams.subscribe((params) => {
      const error = params['error']
        ? decodeURIComponent(params['error'])
        : null;

      const state = params['state']
        ? decodeURIComponent(params['state'])
        : null;

      const code = params['code'] ? decodeURIComponent(params['code']) : null;

      if (error || !code) {
        this.hasError = true;
      }

      if (code && !this.hasError) {
        this.verifyOauth(code, state);
      } else {
        this.router.navigate([Constants.routes.integrations]);
        this.constants.snack('Error establishing integration');
      }

      this.mixpanel.track(Constants.analytics_keys.oauthRedirect, {
        error: error,
        state: state,
        code: code,
      });
    });
  }

  ngOnInit() {}

  private verifyOauth(code: string, state: string | null): void {
    this.merchantService
      .verifyOauth({ code, state })
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.showDialog('Verification Complete', res.message, 'Continue');
          }
        },
        error: (res: HttpErrorResponse) => {
          this.hasError = true;
          this.showDialog('Verification Failed', res.message, 'Close');
        },
      })
      .add(() => (this.loading = false));
  }

  private showDialog(title: string, body: string, buttonText: string): void {
    const dialogRef = this.dialog.open(OauthDialogComponent, {
      data: {
        title,
        body,
        buttonTwo: buttonText,
      },
      scrollStrategy: new NoopScrollStrategy(),
      maxWidth: '400px',
      autoFocus: false,
      disableClose: true,
      panelClass: 'custom-dialog',
    });

    dialogRef.afterClosed().subscribe(() => {
      this.router.navigate([Constants.routes.integrations]);
    });
  }
}
