import { Component, Input, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { Constants } from 'src/app/app.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { animate, style, transition, trigger } from '@angular/animations';
import { TwoOptionAlertComponent } from 'src/app/shared/components/two-option-alert/two-option-alert.component';
import { MatDialog } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { AnalyticsAbstract } from 'src/app/services/analytics/analytics.abstract';
import {
  AuthService,
  RewardService,
  LocationService,
  MerchantService,
  CardService,
  UserService,
} from '../../../services/index';
import { Redemption, Location, StampEvent, User } from '../../../models/index';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'team-member-detail',
  templateUrl: './team-member-detail.component.html',
  styleUrls: ['./team-member-detail.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ background: 'red' }),
        animate('1s ease-out', style({})),
      ]),
    ]),
  ],
})
export class TeamMemberDetailComponent {
  @Input() smallTitle = false;
  title = '';
  subtitle: string = '';
  submitted = false;

  tabs = ['overview', 'stamps', 'points', 'redemptions'];
  selectedTab = 'overview';
  selectedIndex = 0;

  @ViewChild('stampsTable') stampsTable: any;
  @ViewChild('pointsTable') pointsTable: any;
  @ViewChild('redemptionsTable') redemptionsTable: any;

  form!: FormGroup;
  editForm!: FormGroup;

  iCanViewCustomers = false;
  canViewCustomers = false;
  canViewDashboard = false;

  firstNameFormControl: FormControl = new FormControl();
  lastNameFormControl: FormControl = new FormControl();
  emailFormControl: FormControl = new FormControl();
  roleFormControl: FormControl = new FormControl();
  locationFormControl: FormControl = new FormControl();

  saving = false;
  toggling = false;

  editMode: boolean = false;
  teamMemberLoading: boolean = false;
  planLimitsLoading: boolean = false;
  nextLoading: boolean = false;
  error: boolean = false;
  teamMember: User = {};
  canCreateTeamMember: boolean = false;
  stampEvents: StampEvent[] = [];
  pointsEvents: StampEvent[] = [];
  redemptions: Redemption[] = [];
  paging = false;

  stampIndex = 0;
  stampSize = 10;
  stampLength = 0;

  pointsIndex = 0;
  pointsSize = 10;
  pointsLength = 0;

  suppressPaging: boolean = false;
  scrollTimeout: any;

  redemptionIndex = 0;
  redemptionSize = 10;
  redemptionLength = 0;

  locationsLoading: boolean = false;
  locations: Location[] = [];

  hideNotice = false;
  stampRange = '';
  pointsRange = '';
  redemptionRange = '';

  weeklyReports = false;
  monthlyReports = false;
  iAmAdmin = false;
  iAmOwner = false;
  iAmManager = false;
  isAdmin = false;
  isOwner = false;
  roles: { label: string; value: string; info: string }[] = [
    {
      label: 'Administrator',
      value: this.constants.userTypes.merchant,
      info: 'Administrators can access the entire Merchant Portal and manage all locations',
    },
    {
      label: 'Location Manager',
      value: this.constants.userTypes.manager,
      info: 'Location Managers can access the Merchant Portal and manage locations which they are assigned to',
    },
    {
      label: 'Staff Member',
      value: this.constants.userTypes.staff,
      info: 'Staff Members can only access the Remy Connect app to give stamps and redeem rewards',
    },
  ];

  constructor(
    private dialog: MatDialog,
    public constants: Constants,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private cardService: CardService,
    private rewardService: RewardService,
    private userService: UserService,
    public authService: AuthService,
    private ps: PermissionsService,
    private merchantService: MerchantService,
    private locationService: LocationService,
    private mixpanel: AnalyticsAbstract
  ) {
    this.iAmOwner = this.ps.isOwner;
    this.iAmAdmin = this.ps.adminOrMerchant;
    this.iAmManager = this.ps.isLocationManager;
    this.iCanViewCustomers = this.ps.permissions.customers.view();

    if (!this.iAmAdmin) {
      this.roles = this.roles.filter(
        (el) => el.value !== this.constants.userTypes.admin
      );
    }

    this.hideNotice =
      this.authService.merchantObj?.dismissedNotices?.includes(
        this.constants.notices.teamCreate
      ) ?? false;

    this.route.params.subscribe(
      (params) => (this.teamMember.nanoid = params['id'])
    );

    this.route.queryParams.subscribe((params) => {
      const tab = params['tab'];
      if (tab) {
        this.selectedTab = tab;
        this.selectedIndex = this.tabs.findIndex((el) => el === tab);
      }
    });

    this.firstNameFormControl = new FormControl(
      { value: '', disabled: false },
      Validators.required
    );
    this.lastNameFormControl = new FormControl(
      { value: '', disabled: false },
      Validators.required
    );

    this.emailFormControl = new FormControl({ value: '', disabled: false }, [
      Validators.required,
      Validators.email,
    ]);

    this.roleFormControl = new FormControl(
      { value: '', disabled: false },
      Validators.required
    );

    this.locationFormControl = new FormControl({ value: '', disabled: false });

    this.form = this.fb.group({
      firstName: this.firstNameFormControl,
      lastName: this.lastNameFormControl,
      email: this.emailFormControl,
      role: this.roleFormControl,
    });

    this.editForm = this.fb.group({
      locations: this.locationFormControl,
    });
  }

  ngOnInit() {
    this.subtitle = this.authService.getNestedUserProperty('merchant', 'name');

    if (this.teamMember.nanoid) {
      this.editMode = true;
    }

    if (!this.editMode) {
      this.getPlanLimits();
    } else {
      this.canCreateTeamMember = true;
    }

    this.getLocations();
  }

  getLocations(): void {
    this.locationsLoading = true;

    const subscription =
      this.authService.getUserProperty('userType') ===
      this.constants.userTypes.manager
        ? this.merchantService.getStaffLocations()
        : this.locationService.getLocations(1000, 0);

    subscription
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.locations = res?.docs ?? res;
            this.locations?.sort((a, b) => a.name!.localeCompare(b.name!));

            if (this.locations?.length === 1) {
              this.locationFormControl.setValue(this.locations);
            }

            if (this.teamMember?.nanoid) {
              this.getTeamMember();
            }
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.locationsLoading = false));
  }

  getPlanLimits(): void {
    this.planLimitsLoading = true;
    this.merchantService
      .getPlanLimits()
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.canCreateTeamMember = res.team > 0;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.planLimitsLoading = false));
  }

  getTeamMember(): void {
    this.teamMemberLoading = true;
    this.userService
      .getUser(this.teamMember.nanoid!)
      .subscribe({
        next: (res: User) => {
          if (res) {
            this.teamMember = res;
            this.isAdmin =
              this.teamMember.userType == this.constants.userTypes.merchant;
            this.isOwner = this.teamMember.isOwner ?? false;
            this.firstNameFormControl.setValue(res.firstName);
            this.lastNameFormControl.setValue(res.lastName);
            this.emailFormControl.setValue(res.email);

            let role = this.roles.find((el) => el.value == res.userType);
            if (role) {
              this.roleFormControl.setValue(role);
            }

            this.title = res.name ?? '';

            this.locationFormControl.setValue(
              this.locations.filter((el) =>
                this.teamMember.locations?.includes(el._id!.toString())
              )
            );

            this.weeklyReports = res.reports?.week ?? true;
            this.monthlyReports = res.reports?.month ?? true;

            this.canViewCustomers = res.canViewCustomers ?? true;
            this.canViewDashboard = res.canViewDashboard ?? true;

            if (
              !this.isSelf &&
              this.teamMember.userType === this.constants.userTypes.merchant
            ) {
            }

            this.loadData();
          } else {
            this.router.navigate([Constants.routes.team]);
          }
        },
        error: (res: HttpErrorResponse) => {
          this.router
            .navigate(['/' + Constants.routes.dashboard], {})
            .then(() => {
              window.location.reload();
            });
        },
      })
      .add(() => (this.teamMemberLoading = false));
  }

  getStampEvents(paging: boolean, points = false): void {
    paging ? (this.paging = true) : (this.nextLoading = true);
    this.cardService
      .getStampEvents(
        points ? this.pointsSize : this.stampSize,
        points ? this.pointsIndex : this.stampIndex,
        this.teamMember._id,
        undefined,
        points
      )
      .subscribe({
        next: (res: any) => {
          if (res) {
            if (points) {
              this.pointsLength = res.totalDocs;
              this.pointsEvents = res.docs;

              this.pointsRange = this.constants.pageCounter(
                this.pointsIndex,
                this.pointsSize,
                this.pointsLength,
                this.pointsEvents?.length
              );
            } else {
              this.stampLength = res.totalDocs;
              this.stampEvents = res.docs;

              this.stampRange = this.constants.pageCounter(
                this.stampIndex,
                this.stampSize,
                this.stampLength,
                this.stampEvents?.length
              );
            }
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (paging ? (this.paging = false) : (this.nextLoading = false)));
  }

  getRedemptions(paging: boolean): void {
    paging ? (this.paging = true) : (this.nextLoading = true);
    this.rewardService
      .getRedemptions(this.stampSize, this.stampIndex, this.teamMember._id)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.redemptionLength = res.totalDocs;
            this.redemptions = res.docs;

            this.redemptionRange = this.constants.pageCounter(
              this.redemptionIndex,
              this.redemptionSize,
              this.redemptionLength,
              this.redemptions?.length
            );
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (paging ? (this.paging = false) : (this.nextLoading = false)));
  }

  stampPageEvent(event: any) {
    if (!this.suppressPaging) {
      this.stampIndex = event.offset;
      this.getStampEvents(true);
    }
  }

  pointsPageEvent(event: any) {
    if (!this.suppressPaging) {
      this.pointsIndex = event.offset;
      this.getStampEvents(true, true);
    }
  }

  redemptionPageEvent(event: any) {
    if (!this.suppressPaging) {
      this.redemptionIndex = event.offset;
      this.getRedemptions(true);
    }
  }

  tabChanged(index: any): void {
    let tab = this.tabs[index];

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        tab: tab,
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false,
    });

    this.selectedTab = tab;

    this.loadData();
  }

  loadData(): void {
    if (this.selectedTab === 'stamps') {
      this.getStampEvents(false);
    }
    if (this.selectedTab === 'points') {
      this.getStampEvents(false, true);
    }
    if (this.selectedTab === 'redemptions') {
      this.getRedemptions(false);
    }
  }

  remove(): void {
    const dialogRef = this.dialog.open(TwoOptionAlertComponent, {
      data: {
        title: 'Confirm',
        body: 'Do you want to deactivate this team member?',
        buttonOne: 'Cancel',
        buttonTwo: 'Yes',
        panelClass: 'custom-dialog',
      },
      scrollStrategy: new NoopScrollStrategy(),
      maxWidth: '400px',
      autoFocus: false,
      disableClose: true,
      panelClass: 'custom-dialog',
    });
    dialogRef.afterClosed().subscribe((option: number) => {
      if (option == 1) {
        this.processRemoval();
      }
    });
  }

  activate(): void {
    const dialogRef = this.dialog.open(TwoOptionAlertComponent, {
      data: {
        title: 'Confirm',
        body: 'Do you want to activate this team member?',
        buttonOne: 'Cancel',
        buttonTwo: 'Yes',
        panelClass: 'custom-dialog',
      },
      scrollStrategy: new NoopScrollStrategy(),
      maxWidth: '400px',
      autoFocus: false,
      disableClose: true,
      panelClass: 'custom-dialog',
    });
    dialogRef.afterClosed().subscribe((option: number) => {
      if (option == 1) {
        this.processActivation();
      }
    });
  }

  get tooltip(): string {
    if (this.loading) {
      return '';
    }
    if (!this.canCreateTeamMember) {
      return this.constants.strings.upgradePlanNotice;
    } else {
      return '';
    }
  }

  get isSelf(): boolean {
    return this.teamMember?._id === this.authService.getUserProperty('uid');
  }

  processRemoval(): void {
    this.saving = true;
    this.merchantService
      .removeTeamMember(this.teamMember?._id!)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.router.navigate([Constants.routes.team]);
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  processActivation(): void {
    this.saving = true;
    this.merchantService
      .activateTeamMember(this.teamMember?._id!)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.router.navigate([Constants.routes.team]);
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  printInvalidControls() {
    const invalidControls = [];
    const controls = this.form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalidControls.push(name);
      }
    }
    console.log('Invalid controls:', invalidControls);
  }

  back() {
    this.router.navigate([Constants.routes.team]);
  }

  save(): void {
    this.submitted = true;

    if (this.form.invalid) {
      this.printInvalidControls();
      this.constants.snack('Please complete all required fields');
      return;
    }

    let locations: string[] = [];

    const value = this.locationFormControl.value;
    if (Array.isArray(value)) {
      locations = value
        .filter((el) => el._id !== undefined && el._id !== null)
        .map((el) => el._id!);
    }

    const payload = {
      firstName: this.firstNameFormControl.value?.trim(),
      lastName: this.lastNameFormControl.value?.trim(),
      email: this.emailFormControl.value?.trim(),
      locations: locations,
      id: this.teamMember._id,
      canViewCustomers: this.canViewCustomers,
      canViewDashboard: this.canViewDashboard,
      userType: this.roleFormControl.value?.value,
    };

    if (this.editMode) {
      this.submit(
        this.merchantService.updateTeamMember(payload, this.teamMember._id)
      );
    } else {
      this.confirmTeamCreation(payload);
    }
  }

  confirmTeamCreation(payload: any) {
    const dialogRef = this.dialog.open(TwoOptionAlertComponent, {
      data: {
        title: 'Confirm',
        body: `Before accessing the platform, ${payload?.firstName} must verify their account using the link sent to <b>${payload?.email}</b>. Please ensure the email is typed correctly before continuing`,
        buttonOne: 'Cancel',
        buttonTwo: 'Continue',
      },
      scrollStrategy: new NoopScrollStrategy(),
      autoFocus: false,
      width: '350px',
      disableClose: true,
      panelClass: 'custom-dialog',
    });
    dialogRef.afterClosed().subscribe((option: number) => {
      if (option == 1) {
        this.submit(this.merchantService.createTeamMember(payload));
      }
    });
  }

  submit(observable: Observable<any>): void {
    this.saving = true;
    observable
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.router.navigate([Constants.routes.team]);
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  get loading(): boolean {
    return (
      this.teamMemberLoading ||
      this.planLimitsLoading ||
      this.locationsLoading ||
      this.saving
    );
  }

  get infoBoxBody() {
    return `Adding team members will invite them to use the <b>Remy Connect</b> mobile app, where they can award stamps and redeem rewards as an alternative to using NFC. <b>It does not give them access to the Merchant Portal.</b>`;
  }

  dismissNotice(): void {
    this.merchantService
      .dismissNotice(this.constants.notices.teamCreate)
      .subscribe({
        next: (res: any) => {},
        error: (res: HttpErrorResponse) => {},
      })
      .add(() => (this.hideNotice = true));
  }

  handleScroll() {
    this.suppressPaging = true;

    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }

    this.scrollTimeout = setTimeout(() => {
      this.suppressPaging = false;
    }, 100);
  }

  reportChecked(state: boolean, type: string) {
    if (type === 'weekly') {
      this.weeklyReports = state;
    }
    if (type === 'monthly') {
      this.monthlyReports = state;
    }

    this.toggleReportFrequency(type, state);
  }

  toggleReportFrequency(frequency: string, state: boolean): void {
    const payload = {
      [frequency]: state,
    };

    this.toggling = true;
    this.merchantService
      .updateTeamMember(payload, this.teamMember._id)
      .subscribe({
        next: (res: User) => {
          if (res) {
            this.mixpanel.track(
              Constants.analytics_keys.toggleReportFrequency,
              {
                Frequency: frequency,
                State: state,
              }
            );
            this.constants.snack('Report frequency updated');
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.toggling = false));
  }

  get isTeamMember(): boolean {
    return (this.isStaff || this.isManager || this.isAdmin) && !this.isOwner;
  }

  get isStaff(): boolean {
    return this.teamMember.userType === this.constants.userTypes.staff;
  }

  get isManager(): boolean {
    return this.teamMember.userType === this.constants.userTypes.manager;
  }

  get isMerchant(): boolean {
    return this.teamMember.userType === this.constants.userTypes.merchant;
  }

  get role(): string {
    if (this.isManager) {
      return 'location manager';
    }
    if (this.isMerchant) {
      return 'administrator';
    }

    return 'staff member';
  }

  get locationTooltip(): string {
    return `Restrict your team to specific locations`;
  }

  get canEditUser(): boolean {
    if (
      (this.iAmAdmin && this.isTeamMember) ||
      (this.iAmOwner && !this.isSelf)
    ) {
      return true;
    }

    if (this.isStaff && this.iAmManager) {
      return true;
    }

    if (this.iAmOwner && !this.isSelf) {
      return true;
    }

    return false;
  }

  roleSelected(): void {
    let role = this.roleFormControl.value?.value;
    if (
      role === this.constants.userTypes.merchant ||
      role === this.constants.userTypes.manager
    ) {
      this.canViewDashboard = true;

      if (role === this.constants.userTypes.merchant) {
        this.canViewCustomers = true;
      }
    } else {
      this.canViewDashboard = false;
      this.canViewCustomers = false;
    }

    if (this.roleFormControl.value.value === this.constants.userTypes.admin) {
      this.locationFormControl.clearValidators();
    } else {
      this.locationFormControl.setValidators([Validators.required]);
    }
  }

  triggerInvite(): void {
    if (this.toggling) {
      return;
    }

    this.toggling = true;
    this.merchantService
      .triggerInvite(this.teamMember._id)
      .subscribe({
        next: (res: User) => {
          if (res) {
            this.constants.snack(
              'Invite sent, please ask them to check their emails'
            );
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.toggling = false));
  }

  isLocationNotString(events: any[]): boolean {
    return events?.length > 0 && typeof events[0]?.location !== 'string';
  }
}
