import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  OnInit,
} from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { Observable } from 'rxjs';
import { Constants } from 'src/app/app.constants';
import { InsightsService } from 'src/app/services';

@Component({
  selector: 'funnel-chart',
  templateUrl: './funnel-chart-component.html',
  styleUrls: ['./funnel-chart-component.css'],
})
export class FunnelChartComponent implements OnChanges {
  @Input() type = 'stamp';
  @Input() chartType: ChartType = 'bar'; // can be 'line' or 'bar'

  labels: string[] = [];
  loading = false;

  public barChartLegend = false;
  public barChartPlugins = [];
  public barChartData: ChartData<'bar'> = {
    labels: [],
    datasets: [],
  };

  public barChartOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        ticks: {
          stepSize: 1,
          callback: function (value: number | string) {
            const num = typeof value === 'number' ? value : parseFloat(value);
            if (num === 0) {
              return '-';
            } else if (!isNaN(num) && num % 1 === 0) {
              return num;
            } else {
              return '';
            }
          },
        },
      },
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    plugins: {
      title: {
        display: true,
        text: 'Conversion Rate',
        font: {
          size: 14,
          weight: '500',
        },
        padding: {
          top: 10,
          bottom: 10,
        },
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          title: () => '',
          label: (context: any) => {
            const value = context.parsed.y;
            const labelIndex = context.parsed.x;
            const label = this.labels[labelIndex];
            return `${label}: ${value.toFixed(0)}%`;
          },
        },
      },
      legend: {
        labels: {
          usePointStyle: true,
          boxWidth: 20,
        },
      },
    },
  };

  constructor(
    private insightService: InsightsService,
    private constants: Constants
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.getBarChartData();
  }

  getBarChartData(): void {
    this.loading = true;
    const observable$ = this.insightService.getConversionFunnel();
    this.processResponse(observable$);
  }

  processResponse(observable$: Observable<any>): void {
    observable$
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.createChartData(res);
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  createChartData(data: any): void {
    const color = '#f1a638';

    this.labels = [
      'Cards Issued',
      'Collected Stamp',
      'Earned Reward',
      'Redeemed Reward',
    ];

    const values = [
      100,
      data.percentages?.stamped ?? 0,
      data.percentages?.rewarded ?? 0,
      data.percentages?.redeemed ?? 0,
    ];

    this.barChartData = {
      labels: this.labels,
      datasets: [
        {
          data: values,
          label: 'Conversion %',
          backgroundColor: this.convertHexToRGBA(color),
          borderColor: color,
          borderWidth: 2,
          borderRadius: 10,
          hoverBackgroundColor: this.convertHexToRGBA(color, 0.8),
        },
      ],
    };

    if (this.barChartOptions?.scales?.['y']) {
      const yScale = this.barChartOptions.scales['y'];

      yScale.max = 100;
      yScale.min = 0;
      yScale.ticks = {
        ...yScale.ticks,
        callback: (val: number | string) => `${val}%`,
        stepSize: 10,
      };
    }
  }

  convertHexToRGBA(hex: string, alpha = 0.5): string {
    if (hex.startsWith('#') === false) {
      hex = '#' + hex;
    }
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  values(valueType: string, events: any[]): number[] {
    const val: number[] = [];

    if (events?.length) {
      for (const label of this.labels) {
        const obj = events.find((el: any) => el.stage === label);
        val.push(obj ? parseFloat(obj[valueType]?.toFixed(0)) : 0);
      }
    }

    return val;
  }
}
