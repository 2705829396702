<dialog-header
  [title]="data.customer"
  (onClose)="matDialogRef.close()"
></dialog-header>

<div
  mat-dialog-content
  style="background-color: #f2f2f2; padding: 0px !important; min-height: 400px"
>
  <div #scrollContainer class="message-list">
    <ng-container
      *ngFor="let message of messages.slice().reverse(); let i = index"
    >
      <div
        class="message-container"
        [ngClass]="{ 'current-user': message.author === myUserId }"
      >
        <div class="message">
          <p>{{ message.text }}</p>
          <div style="display: flex; align-items: center">
            <span class="timestamp">{{
              message.createdAt | date : constants.longDate
            }}</span>
            <mat-icon
              matTooltip="Seen"
              *ngIf="message.seen && message.author === myUserId"
              style="
                color: var(--app-wiskey);
                font-size: 18px;
                text-align: right;
                height: 18px;
              "
              >done_all</mat-icon
            >
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div
  mat-dialog-actions
  align="end"
  *ngIf="!loading && !data.conversation?.blockedByMerchant"
>
  <form [formGroup]="messageForm" class="message-form">
    <input
      class="form-input"
      placeholder="Start typing..."
      autocomplete="off"
      [formControl]="messageFormControl"
      type="text"
    />
    <app-primary-button
      [title]="'Send'"
      (tapped)="sendMessage()"
      bgColor="var(--app-timber)"
      color="white"
      class="send-button"
    ></app-primary-button>
  </form>
</div>

<loader [loading]="saving || loading"></loader>
