<div
  *ngIf="pageValid"
  class="demo-container"
  [ngStyle]="{
    'background-image': 'url(' + page.bg + ')',
    border: page.border,
    'background-color': page.bgColor
  }"
>
  <div class="logo-container">
    <img class="logo" [src]="page.logo" />
  </div>

  <div
    class="copy"
    [style.color]="page.textColor"
    style="
      margin-bottom: 20px;
      text-align: center;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 400;
    "
  >
    Fully customisable digital <br />loyalty cards to truly <br />reflect your
    brand
  </div>

  <div
    class="copy"
    [style.color]="page.textColor"
    style="
      margin-bottom: 10px;
      text-align: center;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 400;
      font-style: italic;
    "
  >
    EXAMPLE LOYALTY CARDS
  </div>

  <!-- Carousel Container -->
  <div class="carousel-container">
    <div
      class="card"
      id="carousel"
      *ngFor="let image of page.images; let i = index"
      [style.transform]="'translateX(-' + currentIndex * 100 + '%)'"
    >
      <img [src]="image" />
    </div>
  </div>

  <!-- Button to swipe through carousel -->
  <div class="animate" *ngIf="page.images.length > 1">
    <app-primary-button
      [title]="'MORE EXAMPLES'"
      (tapped)="nextImage()"
      [bgColor]="page.buttonColor"
      color="white"
      icon="trending_flat"
    ></app-primary-button>
  </div>

  <div class="footer">
    <a href="https://remyrewards.co.uk"
      ><img
        class="remy"
        [src]="
          page.remyLogo == 'light'
            ? './../../assets/logo-dark.png'
            : './../../assets/remylogo.png'
        "
    /></a>
  </div>
</div>

<div *ngIf="!pageValid && !loading">Page Not Found</div>
