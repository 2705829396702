import { StampCard, Customer, CardType } from './index';

export interface StampEvent {
  id: string;
  nanoid: string;
  card: string | StampCard;
  location: string | Location;
  stampNumber: number;
  hasReward: boolean;
  customer: string | Customer;
  source: any;
  createdAt: Date;
  eventType: CardType;
}
