import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { IntegrationConfiguration } from '../models/integrationConfig';

@Injectable({ providedIn: 'root' })
export class MerchantService {
  constructor(private api: ApiService) {}

  unreadNotifications = 0;

  PATH = 'v1/merchant';

  getMerchant(merchant: string) {
    return this.api.get(`${this.PATH}/${merchant}`);
  }

  getMerchants(limit: number, page: number) {
    var query = `?limit=${limit}&page=${page}`;
    return this.api.get(`${this.PATH}/list` + query);
  }

  getCustomers(
    sortKey: string,
    sortDir: string,
    limit: number,
    page: number,
    q: string,
    filters: any,
    cards = []
  ) {
    const query =
      `?limit=${limit}&page=${page}&search=${q}&sortKey=${sortKey}&sortDir=${sortDir}&cards=${cards?.join(
        ','
      )}` +
      Object.keys(filters)
        .map((key) => `&${key}=${filters[key]}`)
        .join('');

    return this.api.get(`${this.PATH}/customers` + query);
  }

  getSentMessages(limit: number, page: number) {
    var query = `?limit=${limit}&page=${page}`;
    return this.api.get(`${this.PATH}/messages/sent` + query);
  }

  getBirthdayNotifications(limit: number, page: number) {
    var query = `?limit=${limit}&page=${page}`;
    return this.api.get(`${this.PATH}/birthday-notifications` + query);
  }

  getCustomer(customer: string) {
    return this.api.get(`${this.PATH}/customer/${customer}`);
  }

  getMessage(message?: string) {
    return this.api.get(`${this.PATH}/message/${message}`);
  }

  getPlanLimits() {
    return this.api.get(`${this.PATH}/limits`);
  }

  getCustomerCards(limit: number, page: number, customer?: string) {
    var query = `?limit=${limit}&page=${page}`;
    return this.api.get(`${this.PATH}/customer/cards/${customer}` + query);
  }

  getTeamMembers(limit: number, page: number) {
    var query = `?limit=${limit}&page=${page}`;
    return this.api.get(`${this.PATH}/team/list` + query);
  }

  getNFCCards() {
    return this.api.get(`${this.PATH}/nfc/list`);
  }

  requestNFCCard(data: any) {
    return this.api.post(`${this.PATH}/nfc/request`, data);
  }

  getNFCStamps(nfcRef: string, limit: number, page: number, points?: boolean) {
    var query = `?limit=${limit}&page=${page}&points=${points}`;
    return this.api.get(`${this.PATH}/nfc/stamps/${nfcRef}` + query);
  }

  getNFCRedemptions(nfcRef: string, limit: number, page: number) {
    var query = `?limit=${limit}&page=${page}`;
    return this.api.get(`${this.PATH}/nfc/redemptions/${nfcRef}` + query);
  }

  toggleNFCCard(card?: string, value?: boolean) {
    return this.api.post(`${this.PATH}/nfc/toggle/${card}`, { value: value });
  }

  updateConversationSettings(
    canBeMessage: boolean,
    emailNotifications: boolean,
    infoText?: string
  ) {
    return this.api.post(`${this.PATH}/conversation-settings`, {
      canBeMessaged: canBeMessage,
      emailNotifications: emailNotifications,
      infoText: infoText,
    });
  }

  editNFCCard(card: string, nickname?: string, location?: string) {
    return this.api.post(`${this.PATH}/nfc/edit/${card}`, {
      nickname: nickname,
      location: location,
    });
  }

  getCustomerRewards(limit: number, page: number, customer?: string) {
    var query = `?limit=${limit}&page=${page}`;
    return this.api.get(`${this.PATH}/customer/rewards/${customer}` + query);
  }

  createMerchant(data: any) {
    return this.api.post(`${this.PATH}`, data);
  }

  toggleCustomerNotifications(data: any) {
    return this.api.put(`${this.PATH}/customer/notifications`, data);
  }

  giveStamp(
    userId: string,
    cardId: string,
    quantity: number,
    location?: string
  ) {
    const data = {
      user: userId,
      type: 'card',
      object: cardId,
      numberOfStamps: quantity,
      location: location,
    };

    return this.api.post(`${this.PATH}/customer/stamp/add`, data);
  }

  redeemReward(customerId: string, rewardId: string, location?: string) {
    return this.api.post(`${this.PATH}/customer/reward/redeem`, {
      customerId: customerId,
      rewardId: rewardId,
      location: location,
    });
  }

  giveReward(
    customerId: string,
    rewardId: string,
    quantity: number,
    notify: boolean
  ) {
    return this.api.post(`${this.PATH}/customer/reward/give`, {
      customerId: customerId,
      rewardId: rewardId,
      quantity: quantity,
      notify: notify,
    });
  }

  expireReward(customerId: string, rewardId: string) {
    return this.api.post(`${this.PATH}/customer/reward/expire`, {
      customerId: customerId,
      rewardId: rewardId,
    });
  }

  createTeamMember(payload: any) {
    return this.api.post(`${this.PATH}/team`, payload);
  }

  removeTeamMember(teamMemberId: string) {
    return this.api.post(`${this.PATH}/team/remove/${teamMemberId}`, {});
  }

  updateTeamMember(payload: any, id?: string) {
    return this.api.post(`${this.PATH}/team/update/${id}`, payload);
  }

  activateTeamMember(id?: string) {
    return this.api.post(`${this.PATH}/team/activate/${id}`, {});
  }

  triggerInvite(id?: string) {
    return this.api.post(`${this.PATH}/team/invite/${id}`, {});
  }

  sendPush(payload: any, draft: boolean) {
    let path = draft ? `${this.PATH}/push/save` : `${this.PATH}/push`;
    return this.api.post(path, payload);
  }

  deleteMessage(messageId: string) {
    return this.api.delete(`${this.PATH}/push/${messageId}`);
  }

  export(type: string, from?: string, to?: string) {
    return this.api.post(`${this.PATH}/export/${type}`, {
      from,
      to,
    });
  }

  dismissNotice(notice: string) {
    return this.api.post(`${this.PATH}/dismiss`, { notice: notice });
  }

  getIntegration(type: string) {
    return this.api.get(`${this.PATH}/integration/${type}`);
  }

  getActiveIntegrations() {
    return this.api.get(`${this.PATH}/integration/active`);
  }

  removeIntegration(type: string) {
    return this.api.post(`${this.PATH}/integration/remove/${type}`, {});
  }

  deleteIntegrationConfiguration(id: string, type: string) {
    return this.api.post(`${this.PATH}/integration/configuration/remove`, {
      id,
      type,
    });
  }

  generateOauthUrl(type: string) {
    return this.api.get(`${this.PATH}/integration/oauth/url/${type}`);
  }

  addShopify(payload: any) {
    return this.api.post(`${this.PATH}/shopify/save`, payload);
  }

  addSquare(payload: any) {
    return this.api.post(`${this.PATH}/square/save`, payload);
  }

  addSumup(payload: any) {
    return this.api.post(`${this.PATH}/sumup/save`, payload);
  }

  addEposNow(payload: any) {
    return this.api.post(`${this.PATH}/eposnow/save`, payload);
  }

  addStorekit(payload: any) {
    return this.api.post(`${this.PATH}/storekit/save`, payload);
  }

  addZapier(payload: any) {
    return this.api.post(`${this.PATH}/zapier/save`, payload);
  }

  addSquarespace(payload: any) {
    return this.api.post(`${this.PATH}/squarespace/save`, payload);
  }

  addAcuity(payload: any) {
    return this.api.post(`${this.PATH}/acuity/save`, payload);
  }

  connectSquarespace(payload: any) {
    return this.api.post(`${this.PATH}/squarespace/connect`, payload);
  }

  verifyOauth(payload: any) {
    return this.api.post(`${this.PATH}/integration/oauth/token`, payload);
  }

  disconnectSquarespace() {
    return this.api.post(`${this.PATH}/squarespace/disconnect`, {});
  }

  addIntegrationConfiguration(body: IntegrationConfiguration, type: string) {
    const payload = {
      ...body,
      integrationType: type,
    };

    return this.api.post(`${this.PATH}/integration/configuration`, payload);
  }

  updateIntegrationConfiguration(body: IntegrationConfiguration, type: string) {
    const payload = {
      ...body,
      integrationType: type,
    };

    return this.api.post(
      `${this.PATH}/integration/configuration/update`,
      payload
    );
  }

  getExternalOrders(limit: number, page: number, type: string) {
    var query = `?limit=${limit}&page=${page}`;
    return this.api.get(`${this.PATH}/integration/orders/${type}` + query);
  }

  getInvites(limit: number, page: number, type: string) {
    var query = `?limit=${limit}&page=${page}&type=${type}`;
    return this.api.get(`${this.PATH}/integration/invites` + query);
  }

  updateCustomer(customerId: string, key: string, value: string) {
    return this.api.post(`${this.PATH}/customer/update/${customerId}`, {
      key: key,
      value: value,
    });
  }

  getAutomations() {
    return this.api.get(`${this.PATH}/automations`);
  }

  getReferrals() {
    return this.api.get(`${this.PATH}/referrals`);
  }

  updateBirthdayClub(
    daysBeforeBirthday: number,
    birthdayEnabled: boolean,
    birthdayMessage: string,
    birthdayReward?: string
  ) {
    return this.api.post(`${this.PATH}/birthday-club`, {
      daysBeforeBirthday: daysBeforeBirthday,
      birthdayEnabled: birthdayEnabled,
      birthdayMessage: birthdayMessage,
      birthdayReward: birthdayReward,
    });
  }

  getStaffLocations() {
    return this.api.get(`${this.PATH}/staff-locations`);
  }

  getChecklistStatus() {
    return this.api.get(`${this.PATH}/onboard-status`);
  }
}
