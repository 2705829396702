import { Component, ElementRef, ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { NavigationEnd, Router } from '@angular/router';
import { Constants } from 'src/app/app.constants';
import { AuthService, StorageService } from '../../services/index';
import { Merchant } from '../../models/index';

enum CheckListItems {
  location,
  reward,
  card,
}

interface ChecklistError {
  title?: string;
  state: boolean;
}

interface ChecklistItem {
  title: string;
  complete: boolean;
  value: CheckListItems;
  route: string;
  id: number;
}

@Component({
  selector: 'checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.css'],
})
export class ChecklistComponent {
  constructor(
    private router: Router,
    private authService: AuthService,
    private storageService: StorageService
  ) {}

  @ViewChild('progressBar', { static: false }) progressBar?: ElementRef;
  progressValue = 0;
  progressBarStyle = {};
  checklistVisible = false;
  checklistComplete = true;

  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'determinate';

  checklistItems: ChecklistItem[] = [
    {
      title: 'Create your first Location',
      complete: false,
      value: CheckListItems.location,
      route: Constants.routes.location,
      id: 1,
    },
    {
      title: 'Create your first Reward',
      complete: false,
      value: CheckListItems.reward,
      route: Constants.routes.reward,
      id: 2,
    },
    {
      title: 'Create your first Loyalty Card',
      complete: false,
      value: CheckListItems.card,
      route: Constants.routes.card,
      id: 3,
    },
  ];

  ngOnInit(): void {
    const merchant = this.authService.getUserProperty('merchant') as Merchant;
    if (merchant && merchant.onboard) {
      const location = this.checklistItems.find(
        (el) => el.value == CheckListItems.location
      );
      if (location) {
        location.complete = merchant.onboard.location ?? false;
      }

      const reward = this.checklistItems.find(
        (el) => el.value == CheckListItems.reward
      );
      if (reward) {
        reward.complete = merchant.onboard.reward ?? false;
      }

      const card = this.checklistItems.find(
        (el) => el.value == CheckListItems.card
      );
      if (card) {
        card.complete = merchant.onboard.card ?? false;
      }
    }

    const checkListVisibleLocal =
      this.storageService.get('checklistVisible') ?? 'true';

    this.checklistItems.forEach((el) => {
      if (el.complete === false) {
        if (checkListVisibleLocal == 'true') {
          this.checklistVisible = true;
        }

        this.checklistComplete = false;
      } else {
        this.progressValue++;
      }
    });

    this.router.events.subscribe((el) => {
      if (el instanceof NavigationEnd) {
        const url = el.url;
        if (url === '/location' || url === '/reward' || url === '/card') {
          this.checklistVisible = false;
        }
      }
      return;
    });

    this.progressBarStyle = { width: this.progressAsPercentage + '%' };
  }

  get progressAsPercentage() {
    return (this.progressValue / this.checklistItems.length) * 100;
  }

  ngAfterViewInit(): void {
    this.progressBar?.nativeElement.style.setProperty(
      '--progress-width',
      `${this.progressAsPercentage}%`
    );

    setTimeout(() => {
      const progressBar = this.progressBar?.nativeElement;
      if (progressBar) {
        progressBar.classList.add('animated');
      }
    }, 0);
  }

  closeChecklist() {
    this.checklistVisible = !this.checklistVisible;
    this.storageService.set('checklistVisible', String(this.checklistVisible));
  }

  updateProgress(value: number) {
    this.progressValue = value;
    this.progressBarStyle = { width: this.progressAsPercentage + '%' };
  }

  checkItem(item: ChecklistItem) {
    if (item.complete) {
      return;
    }

    if (this.router.url === `/${item.route}`) {
      return;
    }
    if (this.canSelectItem(item)) {
      this.router.navigate([item.route]);
    }
  }

  canSelectItem(item: ChecklistItem): ChecklistError {
    if (
      item.value == CheckListItems.reward ||
      item.value == CheckListItems.card
    ) {
      if (
        this.checklistItems.find((el) => el.value == CheckListItems.location)
          ?.complete == false
      ) {
        return { state: false, title: 'Please create a Location first' };
      }
    }

    if (item.value == CheckListItems.card) {
      if (
        this.checklistItems.find((el) => el.value == CheckListItems.reward)
          ?.complete == false
      ) {
        return { state: false, title: 'Please create a Reward first' };
      }
    }
    return { state: true };
  }

  trackById(index: number, item: ChecklistItem): number {
    return item.id;
  }
}
