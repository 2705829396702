import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Constants } from 'src/app/app.constants';
import { AnalyticsAbstract } from 'src/app/services/analytics/analytics.abstract';
import { TwoOptionAlertComponent } from 'src/app/shared/components/two-option-alert/two-option-alert.component';
import { AuthService, MerchantService } from '../../../services/index';
import { User } from '../../../models/index';

@Component({
  selector: 'team-members',
  templateUrl: './team-members.component.html',
  styleUrls: ['./team-members.component.css'],
})
export class TeamMembersComponent {
  @Input() smallTitle = false;
  @Input() title = '';
  @Input() subtitle: string = '';
  @ViewChild('myTable') table: any;

  pageIndex = 0;
  pageSize = 10;
  length = 0;
  teamMembers: User[] = [];
  teamLoading = false;
  planLimitsLoading = false;
  canCreateTeamMember = false;
  error = false;
  suppressPaging: boolean = false;
  scrollTimeout: any;
  teamsRange = '';
  locationCount = 1;

  constructor(
    public constants: Constants,
    public authService: AuthService,
    private merchantService: MerchantService,
    private router: Router,
    public dialog: MatDialog,
    private mixpanel: AnalyticsAbstract
  ) {}

  ngOnInit() {
    this.subtitle = this.authService.getNestedUserProperty('merchant', 'name');

    this.getTeamMembers();
    this.getPlanLimits();
  }

  get tooltip(): string {
    if (this.loading) {
      return '';
    }
    if (!this.authService.merchantObj?.active) {
      return this.constants.strings.inactive;
    } else {
      return '';
    }
  }

  get emptyString(): string {
    return `No ${this.constants.strings.teamMembers} to display`;
  }

  getPlanLimits(): void {
    this.planLimitsLoading = true;
    this.merchantService
      .getPlanLimits()
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.canCreateTeamMember = res.team > 0;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.planLimitsLoading = false));
  }

  getTeamMembers(): void {
    this.teamLoading = true;
    this.merchantService
      .getTeamMembers(this.pageSize, this.pageIndex)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.length = res.totalDocs;
            this.teamMembers = res.docs;
            this.locationCount = res.locations;

            this.teamsRange = this.constants.pageCounter(
              this.pageIndex,
              this.pageSize,
              this.length,
              this.teamMembers?.length
            );
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.teamLoading = false));
  }

  pageEvent(event: any) {
    if (!this.suppressPaging) {
      this.pageIndex = event.offset;
      this.getTeamMembers();
    }
  }

  rowTappedNGX({ row, type }: any) {
    if (type === 'click') {
      this.mixpanel.track(Constants.analytics_keys.viewTeamMember, {
        User: row?.name,
      });
      this.router.navigate([Constants.routes.teamMember + '/' + row.nanoid]);
    }
  }

  remyConnect(): void {
    this.mixpanel.track(Constants.analytics_keys.openRemyConnect);
    window.open('https://remyrewards.co.uk/mapp', '_blank');
  }

  newTeamMember(): void {
    this.mixpanel.track(Constants.analytics_keys.newTeamMember);

    if (!this.canCreateTeamMember) {
      const dialogRef = this.dialog.open(TwoOptionAlertComponent, {
        data: {
          title: 'Extra Team member',
          body: `Please upgrade your plan to add more team members`,
          buttonOne: 'Cancel',
          buttonTwo: 'Upgrade',
        },
        scrollStrategy: new NoopScrollStrategy(),
        autoFocus: false,
        width: '350px',
        disableClose: true,
        panelClass: 'custom-dialog',
      });

      dialogRef.afterClosed().subscribe((option) => {
        if (option === 1) {
          this.mixpanel.track(
            Constants.analytics_keys.viewPlansFromNewTeamMember
          );
          this.router.navigate([Constants.routes.plans]);
        }
      });
    } else {
      this.router.navigate([Constants.routes.teamMember]);
    }
  }

  get loading(): boolean {
    return this.teamLoading || this.planLimitsLoading;
  }

  handleScroll() {
    this.suppressPaging = true;

    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }

    this.scrollTimeout = setTimeout(() => {
      this.suppressPaging = false;
    }, 100);
  }

  role(user: User): string {
    if (user.userType === this.constants.userTypes.manager) {
      return 'Location Manager';
    }
    if (user.userType === this.constants.userTypes.staff) {
      return 'Staff Member';
    }

    return 'Administrator';
  }
}
