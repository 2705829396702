import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constants } from 'src/app/app.constants';
import { MerchantService } from '../../../services/index';
import { Merchant } from '../../../models/index';

@Component({
  selector: 'select-merchant-dialog',
  templateUrl: './select-merchant-dialog.component.html',
  styleUrls: ['./select-merchant-dialog.component.css'],
})
export class SelectMerchantDialogComponent implements OnInit {
  form!: FormGroup;
  merchantFormControl!: FormControl;

  loading = false;
  error = false;
  merchants: Merchant[] = [];

  constructor(
    public matDialogRef: MatDialogRef<SelectMerchantDialogComponent>,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private merchantService: MerchantService,
    private constants: Constants
  ) {}

  ngOnInit(): void {
    this.merchantFormControl = new FormControl({ value: '', disabled: false }, [
      Validators.required,
    ]);

    this.form = this.fb.group({
      merchant: this.merchantFormControl,
    });

    this.getMerchants();
  }

  getMerchants(): void {
    this.loading = true;

    this.merchantService
      .getMerchants(1000, 0)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.merchants = res.docs;
            this.merchants?.sort((a, b) => a.name!.localeCompare(b.name!));
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  primaryTapped() {
    if (this.form?.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.matDialogRef.close(this.merchantFormControl.value);
  }
}
