import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/index';
import { Constants } from '../app.constants';
import { PermissionsService } from '../services/permissions.service';

@Injectable({ providedIn: 'root' })
export class OwnerGuard {
  constructor(private router: Router, private ps: PermissionsService) {}

  canActivate() {
    if (this.ps.ownerOrAdmin) {
      return true;
    }

    this.router.navigate(['/' + Constants.routes.dashboard], {}).then(() => {
      window.location.reload();
    });

    return false;
  }
}
