<div
  class="d-flex justify-content-between align-items-md-center align-items-baseline"
>
  <p
    class="section-title"
    style="margin-bottom: 0px; font-weight: bold; font-size: 16px"
  >
    Select Merchant
  </p>
</div>

<form [formGroup]="form" style="margin-top: 20px">
  <div class="form-field" style="margin-top: 20px">
    <single-select
      label="Merchant"
      [formCtrl]="merchantFormControl"
      [options]="merchants"
      property="name"
      [showIcon]="false"
    ></single-select>
  </div>
</form>

<div class="action-container">
  <button
    [disabled]="false"
    (click)="matDialogRef.close()"
    mat-flat-button
    class="secondary-button"
    style="margin-right: 10px"
  >
    <span>Cancel</span>
  </button>
  <button
    [disabled]="false"
    (click)="primaryTapped()"
    mat-flat-button
    class="primary-button"
  >
    <span>Next</span>
  </button>
</div>

<loader [loading]="loading"></loader>
