import { User, Merchant, UserCard, Customer } from './index';

export interface ExternalOrder {
  _id?: string;
  merchant?: string | Merchant;
  orderNo: string;
  value: number;
  userCard?: string | UserCard;
  type: string;
  sku: string;
  customer?: string | Customer;
  user?: string | User;
  integrationType: 'shopify' | 'square' | 'sumup';
}
