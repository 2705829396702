import { OverlayContainer } from '@angular/cdk/overlay';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'multiple-select',
  templateUrl: './multiple-select.component.html',
  styleUrls: ['./multiple-select.component.css'],
})
export class MultipleSelectComponent {
  @Input() subtitle?: string;
  @Input() infoText?: string;
  @Input() label?: string;
  @Input() formCtrl!: FormControl;
  @Input() inBrackets?: string;
  @Input() options: any[] = [];
  @Input() value = '';
  @Input() disabled: any[] = [];
  @Input() showAll = true;
  @Input() allWord = '';

  @Output() selected: EventEmitter<any> = new EventEmitter();

  @ViewChild('matSelect') matSelect: MatSelect | undefined;
  @ViewChild('allSelected') private allSelected!: MatOption;
  selectAll = 'All';
  constructor(
    private overlayContainer: OverlayContainer,
    private element: ElementRef
  ) {
    this.allSelected?.deselect();
  }

  ngOnInit() {
    this.matSelect?.openedChange.subscribe((opened) => {
      if (!opened) {
        this.overlayContainer
          .getContainerElement()
          .classList.remove('select-overlay');
      }
    });
  }

  beforeOpen() {
    const selectElement =
      this.element.nativeElement.querySelector('mat-select');
    const selectTop = selectElement.getBoundingClientRect().top;
    if (selectTop <= 200)
      this.overlayContainer
        .getContainerElement()
        .classList.add('select-overlay');
    else {
      this.overlayContainer
        .getContainerElement()
        .classList.remove('select-overlay');
    }
  }

  compareCategoryObjects(object1: any, object2: any) {
    if (object1 == `All ${this.allWord}` && object2.name != 'All') {
      this.allSelected.deselect();
      return;
    }

    if (object1?.value && object2?.value) {
      return object1 && object2 && object1.value == object2.value;
    } else if (object1?.name && object2?.name) {
      return object1 && object2 && object1.name == object2.name;
    } else {
      return object1 && object2 && object1._id == object2._id;
    }
  }

  toggleAllSelection() {
    if (this.allSelected?.value === 'All') {
      if (this.allSelected?.selected) {
        this.formCtrl.setValue([{ name: 'All' }, ...this.options]);
      } else {
        this.formCtrl.setValue([]);
      }
    }
    this.selected.emit();
  }

  onSelected() {
    if (this.showAll) {
      if (
        this.formCtrl.value.length === this.options.length &&
        !this.allSelected?.selected
      ) {
        this.formCtrl.setValue([{ name: 'All' }, ...this.formCtrl.value]);
        if (this.allSelected) {
          this.allSelected.select();
        }
      } else {
        if (this.allSelected) {
          this.allSelected.deselect();
        }
      }
    }
    this.selected.emit();
  }

  get isMandatory() {
    if (this.formCtrl) {
      const validator = this.formCtrl.validator
        ? this.formCtrl.validator({} as AbstractControl)
        : null;
      if (validator && validator['required']) {
        return true;
      }
    }
    return false;
  }
}
