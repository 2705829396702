import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class RewardService {
  constructor(private api: ApiService) {}

  PATH = 'v1/reward';

  getReward(reward: string) {
    return this.api.get(`${this.PATH}/${reward}`);
  }

  getRewards(limit: number, page: number) {
    var query = `?limit=${limit}&page=${page}`;
    return this.api.get(`${this.PATH}/list` + query);
  }

  getRedemptions(
    limit: number,
    page: number,
    user?: string,
    customer?: string
  ) {
    var query = `?limit=${limit}&page=${page}&user=${user}&customer=${customer}`;
    return this.api.get(`${this.PATH}/redemptions` + query);
  }

  createReward(payload: any) {
    return this.api.post(`${this.PATH}`, payload);
  }

  editReward(payload: any) {
    return this.api.post(`${this.PATH}/edit`, payload);
  }
}
