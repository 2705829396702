import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constants } from 'src/app/app.constants';
import { AIService } from '../../../services/index';

@Component({
  selector: 'ai-dialog',
  templateUrl: './ai-dialog.component.html',
  styleUrls: ['./ai-dialog.component.css'],
})
export class AIDialogComponent implements OnInit {
  form!: FormGroup;
  textFormControl!: FormControl;
  generating = false;
  error = false;
  includeCustomerName = false;
  ideas = ['', '', ''];

  constructor(
    public matDialogRef: MatDialogRef<AIDialogComponent>,
    public fb: FormBuilder,
    private constants: Constants,
    private aiService: AIService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.textFormControl = new FormControl({ value: '', disabled: false }, [
      Validators.required,
    ]);

    this.form = this.fb.group({
      text: this.textFormControl,
    });
  }

  ngOnInit(): void {}

  getIdeas(): void {
    this.generating = true;
    this.aiService
      .getIdeas(this.textFormControl.value.trim(), this.includeCustomerName)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.ideas = res.ideas;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.generating = false));
  }

  save(idea: string): void {
    this.matDialogRef.close(idea);
  }
}
