import { Options } from '@angular-slider/ngx-slider';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'gradient-dialog',
  templateUrl: './gradient-dialog.component.html',
  styleUrls: ['./gradient-dialog.component.css'],
})
export class GradientDialogComponent implements OnInit {
  form!: FormGroup;
  fromFormControl: FormControl = new FormControl('#344734');
  toFormControl: FormControl = new FormControl('#e7d1b5');

  minValue: number = 0;
  midValue: number = 10;
  maxValue: number = 100;
  valueOptions: Options = {
    floor: 0,
    ceil: 100,
    disabled: false,
    hideLimitLabels: true,
  };

  rotation: number = 45;
  rotationOptions: Options = {
    floor: 0,
    ceil: 360,
    disabled: false,
    hideLimitLabels: true,
  };

  constructor(
    public matDialogRef: MatDialogRef<GradientDialogComponent>,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data.cardGradient) {
      this.fromFormControl.setValue(this.data.cardGradient?.from);
      this.toFormControl.setValue(this.data.cardGradient?.to);
      this.midValue = this.data.cardGradient.value;
      this.rotation = this.data.cardGradient.angle;
    }
    this.form = this.fb.group({
      from: this.fromFormControl,
      to: this.toFormControl,
    });
  }

  public logEvent(event: any, trigger: string, control: FormControl) {}

  primaryTapped(): void {
    this.matDialogRef.close({
      from: this.fromFormControl.value,
      to: this.toFormControl.value,
      angle: this.rotation,
      value: this.midValue,
    });
  }

  valueChanged(value: any) {}

  rotationValueChanged(value: any) {}

  ngOnInit(): void {}
}
