import { Component, OnInit } from '@angular/core';
import { Constants } from 'src/app/app.constants';
import { AnalyticsAbstract } from 'src/app/services/analytics/analytics.abstract';

@Component({
  selector: 'success',
  templateUrl: 'success.component.html',
  styleUrls: ['./success.component.css'],
})
export class SuccessComponent implements OnInit {
  constructor(
    public constants: Constants,
    private mixpanel: AnalyticsAbstract
  ) {}

  ngOnInit() {
    this.mixpanel.track(Constants.analytics_keys.planSuccess, {
      Path: window.location.pathname,
    });
  }
}
