import { Component, Inject, NgZone, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';

@Component({
  selector: 'text-area-dialog',
  templateUrl: './text-area-dialog.component.html',
  styleUrls: ['./text-area-dialog.component.css'],
})
export class TextAreaDialogComponent implements OnInit {
  form!: FormGroup;

  textFormControl!: FormControl;

  constructor(
    public dialog: MatDialog,
    public fb: FormBuilder,
    public matDialogRef: MatDialogRef<TextAreaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public zone: NgZone
  ) {}

  ngOnInit() {
    this.textFormControl = new FormControl(
      { value: this.data.content ?? '', disabled: false },
      Validators.required
    );

    this.form = this.fb.group({
      text: this.textFormControl,
    });
  }

  changed(): void {}

  primaryTapped(): void {
    if (this.form.invalid) {
      return;
    }

    this.save();
  }

  save(): void {
    this.matDialogRef.close(this.textFormControl.value?.trim());
  }
}
