<div class="NgxEditor__Wrapper" *ngIf="editor">
  <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"> </ngx-editor-menu>
  <ngx-editor
    [editor]="editor"
    [disabled]="false"
    [placeholder]="'Type here...'"
    [ngModel]="html"
    (ngModelChange)="onContentChange($event)"
  ></ngx-editor>
</div>
