import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Constants } from './app.constants';
import { CardDetailComponent } from './features/card/card-detail/card-detail.component';
import { CardsComponent } from './features/card/cards/cards.component';
import { CustomerDetailComponent } from './features/customer/customer-detail/customer-detail.component';
import { CustomersComponent } from './features/customer/customers/customers.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { LocationDetailComponent } from './features/location/location-detail/location-detail.component';
import { LocationsComponent } from './features/location/locations/locations.component';
import { RewardDetailComponent } from './features/reward/reward-detail/reward-detail.component';
import { RewardsComponent } from './features/reward/rewards/rewards.component';
import { ProfileComponent } from './features/auth/profile/profile.component';
import { SuccessComponent } from './features/auth/success/success.component';
import { AuthGuard } from './shared/auth.guard';
import { PlansComponent } from './features/auth/plans/plans.component';
import { TeamMembersComponent } from './features/team/team-members/team-members.component';
import { TeamMemberDetailComponent } from './features/team/team-member-detail/team-member-detail.component';
import { ChangePasswordComponent } from './features/auth/change-password/change-password.component';
import { LoginComponent } from './features/auth/login/login.component';
import { NotAuthGuard } from './shared/not-auth.guard';
import { RegisterComponent } from './features/auth/register/register.component';
import { ConfirmComponent } from './features/auth/confirm/confirm.component';
import { VerifyComponent } from './features/auth/verify/verify.component';
import { ResetPasswordComponent } from './features/auth/reset-password/reset-password.component';
import { EngageComponent } from './features/engagement/engage/engage.component';
import { ComposePnComponent } from './features/engagement/compose-pn/compose-pn.component';
import { NFCCardsComponent } from './features/nfc/nfc-cards/nfc-cards.component';
import { NFCCardDetailComponent } from './features/nfc/nfc-card-detail/nfc-card-detail.component';
import { HelpComponent } from './features/help/guides/help.component';
import { DemoComponent } from './features/demo/demo.component';
import { ReportsComponent } from './features/reports/reports/reports.component';
import { ReferralsComponent } from './features/referrals/referrals/referrals.component';
import { OwnerGuard } from './shared/owner.guard';
import { CustomersGuard } from './shared/customers.guard';
import { CreateRewardGuard } from './shared/create-reward.guard';
import { CreateCardGuard } from './shared/create-card.guard copy';
import { TeamGuard } from './shared/team.guard';
import { EngageGuard } from './shared/engage.guard';
import { OauthComponent } from './features/auth/oauth/oauth.component';
import { IntegrationsHomeComponent } from './features/integrations/integrations-home.component';

const routes: Routes = [
  {
    path: 'demo/' + ':name',
    component: DemoComponent,
  },
  {
    path: 'cancel',
    redirectTo: Constants.routes.plans,
  },
  {
    path: 'success',
    redirectTo: Constants.routes.dashboard,
  },
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: Constants.routes.integrations,
    component: IntegrationsHomeComponent,
    canActivate: [AuthGuard, OwnerGuard],
  },
  {
    path: Constants.routes.dashboard,
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: Constants.routes.nfcCards,
    component: NFCCardsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: Constants.routes.nfcEvents + '/:id',
    component: NFCCardDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: Constants.routes.help,
    component: HelpComponent,
    canActivate: [AuthGuard],
  },
  {
    path: Constants.routes.engage,
    component: EngageComponent,
    canActivate: [AuthGuard, EngageGuard],
  },
  {
    path: Constants.routes.engagePush,
    component: ComposePnComponent,
    canActivate: [AuthGuard, EngageGuard],
  },
  {
    path: Constants.routes.engagePush + '/:id',
    component: ComposePnComponent,
    canActivate: [AuthGuard, EngageGuard],
  },
  {
    path: Constants.routes.locations,
    component: LocationsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: Constants.routes.location + '/:id',
    component: LocationDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: Constants.routes.location,
    component: LocationDetailComponent,
    canActivate: [OwnerGuard],
  },
  {
    path: Constants.routes.rewards,
    component: RewardsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: Constants.routes.reward + '/:id',
    component: RewardDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: Constants.routes.reward,
    component: RewardDetailComponent,
    canActivate: [AuthGuard, CreateRewardGuard],
  },
  {
    path: Constants.routes.team,
    component: TeamMembersComponent,
    canActivate: [AuthGuard, TeamGuard],
  },
  {
    path: Constants.routes.teamMember + '/:id',
    component: TeamMemberDetailComponent,
    canActivate: [AuthGuard, TeamGuard],
  },
  {
    path: Constants.routes.teamMember,
    component: TeamMemberDetailComponent,
    canActivate: [AuthGuard, TeamGuard],
  },
  {
    path: Constants.routes.cards,
    component: CardsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: Constants.routes.card + '/:id',
    component: CardDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: Constants.routes.card,
    component: CardDetailComponent,
    canActivate: [AuthGuard, CreateCardGuard],
  },
  {
    path: Constants.routes.profile,
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: Constants.routes.plans,
    component: PlansComponent,
    canActivate: [OwnerGuard],
  },
  {
    path: Constants.routes.customers,
    component: CustomersComponent,
    canActivate: [AuthGuard, CustomersGuard],
  },
  {
    path: Constants.routes.customer + '/:id',
    component: CustomerDetailComponent,
    canActivate: [AuthGuard, CustomersGuard],
  },
  {
    path: Constants.routes.customer,
    component: CustomerDetailComponent,
    canActivate: [AuthGuard, CustomersGuard],
  },

  {
    path: Constants.routes.signup,
    component: RegisterComponent,
    canActivate: [NotAuthGuard],
  },
  {
    path: Constants.routes.success,
    component: SuccessComponent,
  },
  {
    path: Constants.routes.login,
    component: LoginComponent,
    canActivate: [NotAuthGuard],
  },
  {
    path: Constants.routes.changePassword,
    component: ChangePasswordComponent,
    canActivate: [NotAuthGuard],
  },
  {
    path: Constants.routes.resetPassword,
    component: ResetPasswordComponent,
    canActivate: [NotAuthGuard],
  },
  {
    path: Constants.routes.confirm,
    component: ConfirmComponent,
    canActivate: [NotAuthGuard],
  },
  {
    path: Constants.routes.verify,
    component: VerifyComponent,
    canActivate: [NotAuthGuard],
  },
  {
    path: Constants.routes.reports,
    component: ReportsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: Constants.routes.oauth,
    component: OauthComponent,
    canActivate: [AuthGuard],
  },
  {
    path: Constants.routes.referrals,
    component: ReferralsComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
