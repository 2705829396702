import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from 'src/app/app.constants';
import { AnalyticsAbstract } from 'src/app/services/analytics/analytics.abstract';
import { TextAreaDialogComponent } from 'src/app/shared/components/text-area-dialog/text-area-dialog.component';
import { TwoOptionAlertComponent } from 'src/app/shared/components/two-option-alert/two-option-alert.component';
import { GiveRewardDialogComponent } from '../give-reward-dialog/give-reward-dialog.component';
import {
  RewardService,
  MerchantService,
  CardService,
  AuthService,
  LocationService,
} from '../../../services/index';
import {
  StampCard,
  UserCard,
  UserReward,
  User,
  Redemption,
  StampEvent,
  Location,
  Customer,
  CardType,
} from '../../../models/index';
import { GiveStampsDialogComponent } from '../give-stamps-dialog/give-stamps-dialog.component';
import { SingleSelectDialogComponent } from 'src/app/shared/components/single-select-dialog/single-select-dialog.component';

@Component({
  selector: 'customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.css'],
})
export class CustomerDetailComponent {
  chartType = 'line';
  title = '';
  subtitle: string = '';
  @ViewChild('stampsTable') stampsTable: any;
  @ViewChild('pointsTable') pointsTable: any;
  @ViewChild('cardsTable') cardsTable: any;
  @ViewChild('rewardsTable') rewardsTable: any;
  @ViewChild('redemptionsTable') redemptionsTable: any;

  customer: Customer = {};

  cardIndex = 0;
  cardSize = 30;
  cardLength = 0;

  stampIndex = 0;
  stampSize = 30;
  stampLength = 0;

  pointsIndex = 0;
  pointsSize = 30;
  pointsLength = 0;

  redemptionIndex = 0;
  redemptionSize = 30;
  redemptionLength = 0;

  rewardIndex = 0;
  rewardSize = 30;
  rewardLength = 0;

  userCards: UserCard[] = [];
  userRewards: UserReward[] = [];
  stampEvents: StampEvent[] = [];
  pointsEvents: StampEvent[] = [];
  redemptions: Redemption[] = [];

  customerLoading = false;
  locationsLoading = false;
  nextLoading: boolean = false;

  saving = false;
  paging = false;
  error = false;

  cardMenuOptions = [
    { title: 'Give Stamps', pointsTitle: 'Give Points', icon: 'approval' },
    // { title: 'Deactivate', icon: 'cancel' },
  ];

  rewardMenuOptions = [
    { title: 'Redeem', icon: 'redeem' },
    { title: 'Expire', icon: 'event_busy' },
  ];

  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'determinate';

  tabs = ['overview', 'stamps', 'points', 'redemptions', 'cards', 'rewards'];
  selectedTab = 'overview';
  selectedIndex = 0;

  planLimitsLoading = false;
  canManageCustomer = false;

  scrollTimeout: any;
  suppressPaging: boolean = false;

  cardsRange = '';
  rewardsRange = '';
  stampsRange = '';
  pointsRange = '';
  redemptionsRange = '';

  days = '30';

  constructor(
    private cardService: CardService,
    private rewardService: RewardService,
    public constants: Constants,
    private route: ActivatedRoute,
    private authService: AuthService,
    private merchantService: MerchantService,
    private router: Router,
    public dialog: MatDialog,
    private mixpanel: AnalyticsAbstract,
    private locationService: LocationService
  ) {
    this.route.queryParams.subscribe((params) => {
      const tab = params['tab'];
      if (tab) {
        this.selectedTab = tab;
        this.selectedIndex = this.tabs.findIndex((el) => el === tab);
      }
    });

    this.route.params.subscribe(
      (params) => (this.customer.nanoid = params['id'])
    );

    if (this.customer.nanoid) {
      this.getCustomer();
    }

    this.getPlanLimits();
  }

  ngOnInit() {
    this.subtitle = this.authService.getNestedUserProperty('merchant', 'name');
  }

  get tooltip(): string {
    if (this.loading) {
      return '';
    }
    if (!this.authService.merchantObj?.active) {
      return this.constants.strings.inactive;
    } else if (!this.canManageCustomer) {
      return this.constants.strings.upgradePlanNotice;
    } else {
      return '';
    }
  }

  getPlanLimits(): void {
    this.planLimitsLoading = true;
    this.merchantService
      .getPlanLimits()
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.canManageCustomer = res.manage ?? false;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.planLimitsLoading = false));
  }

  isUser(user?: string | User): user is User {
    return (
      typeof user !== 'string' &&
      user !== null &&
      typeof user?.name === 'string'
    );
  }

  tabChanged(index: any): void {
    let tab = this.tabs[index];

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        tab: tab,
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false,
    });

    this.selectedTab = tab;

    this.loadData();
  }

  stampPageEvent(event: any) {
    if (!this.suppressPaging) {
      this.stampIndex = event.offset;
      this.getStampEvents(true);
    }
  }

  pointsPageEvent(event: any) {
    if (!this.suppressPaging) {
      this.pointsIndex = event.offset;
      this.getStampEvents(true, true);
    }
  }

  rewardPageEvent(event: any) {
    if (!this.suppressPaging) {
      this.redemptionIndex = event.offset;
      this.getCustomerRewards(true);
    }
  }

  cardPageEvent(event: any) {
    if (!this.suppressPaging) {
      this.cardIndex = event.offset;
      this.getCustomerCards(true);
    }
  }

  redemptionPageEvent(event: any) {
    if (!this.suppressPaging) {
      this.redemptionIndex = event.offset;
      this.getRedemptions(true);
    }
  }

  rowTappedNGX({ row, type }: any) {
    if (type === 'click') {
      this.router.navigate([Constants.routes.card + '/' + row.nanoid]);
    }
  }

  loadData(): void {
    if (this.selectedTab === 'stamps') {
      this.getStampEvents(false);
    }
    if (this.selectedTab === 'points') {
      this.getStampEvents(false, true);
    }
    if (this.selectedTab === 'cards') {
      this.getCustomerCards(false);
    }
    if (this.selectedTab === 'rewards') {
      this.getCustomerRewards(false);
    }
    if (this.selectedTab === 'redemptions') {
      this.getRedemptions(false);
    }
  }

  getCustomer(): void {
    this.customerLoading = true;
    this.merchantService
      .getCustomer(this.customer.nanoid!)
      .subscribe({
        next: (res: Customer) => {
          if (res) {
            this.customer = res;

            this.title = this.getCustomerName(res);

            this.loadData();
          }
        },
        error: (res: HttpErrorResponse) => {
          this.router
            .navigate(['/' + Constants.routes.dashboard], {})
            .then(() => {
              window.location.reload();
            });
        },
      })
      .add(() => (this.customerLoading = false));
  }

  getCustomerName(res: Customer): string {
    if (typeof res.user === 'string') {
      return res.user ?? '';
    } else {
      return res.user!.name ?? '';
    }
  }

  getStampEvents(paging: boolean, points = false): void {
    paging ? (this.paging = true) : (this.nextLoading = true);
    this.cardService
      .getStampEvents(
        points ? this.pointsSize : this.stampSize,
        points ? this.pointsIndex : this.stampIndex,
        undefined,
        this.customer._id,
        points
      )
      .subscribe({
        next: (res: any) => {
          if (res) {
            if (points) {
              this.pointsLength = res.totalDocs;
              this.pointsEvents = res.docs;

              this.pointsRange = this.constants.pageCounter(
                this.pointsIndex,
                this.pointsSize,
                this.pointsLength,
                this.pointsEvents?.length
              );
            } else {
              this.stampLength = res.totalDocs;
              this.stampEvents = res.docs;

              this.stampsRange = this.constants.pageCounter(
                this.stampIndex,
                this.stampSize,
                this.stampLength,
                this.stampEvents?.length
              );
            }
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (paging ? (this.paging = false) : (this.nextLoading = false)));
  }

  getRedemptions(paging: boolean): void {
    paging ? (this.paging = true) : (this.nextLoading = true);
    this.rewardService
      .getRedemptions(
        this.redemptionSize,
        this.redemptionIndex,
        undefined,
        this.customer._id
      )
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.redemptionLength = res.totalDocs;
            this.redemptions = res.docs;

            this.redemptionsRange = this.constants.pageCounter(
              this.redemptionIndex,
              this.redemptionSize,
              this.redemptionLength,
              this.redemptions?.length
            );
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (paging ? (this.paging = false) : (this.nextLoading = false)));
  }

  getCustomerCards(paging: boolean): void {
    paging ? (this.paging = true) : (this.nextLoading = true);
    this.merchantService
      .getCustomerCards(this.cardSize, this.cardIndex, this.customer._id!)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.cardLength = res.totalDocs;
            this.userCards = res.docs;

            this.cardsRange = this.constants.pageCounter(
              this.cardIndex,
              this.cardSize,
              this.cardLength,
              this.userCards?.length
            );
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (paging ? (this.paging = false) : (this.nextLoading = false)));
  }

  getCustomerRewards(paging: boolean): void {
    paging ? (this.paging = true) : (this.nextLoading = true);
    this.merchantService
      .getCustomerRewards(this.rewardSize, this.rewardIndex, this.customer._id!)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.rewardLength = res.totalDocs;
            this.userRewards = res.docs;

            this.rewardsRange = this.constants.pageCounter(
              this.rewardIndex,
              this.rewardSize,
              this.rewardLength,
              this.userRewards?.length
            );
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (paging ? (this.paging = false) : (this.nextLoading = false)));
  }

  onClick(event: any, item: any) {
    if (!this.canManageCustomer) {
      return;
    }

    if (event === 'Give Stamps') {
      this.openGiveStampDialog(item);
    } else if (event === 'Redeem') {
      if (this.userRewards.find((el) => el._id === item)?.hasRedeemed) {
        this.constants.snack('Reward has already been redeemed');
        return;
      }

      if (this.userRewards.find((el) => el._id === item)?.hasExpired) {
        this.constants.snack('Reward has already expired');
        return;
      }

      const dialogRef = this.dialog.open(TwoOptionAlertComponent, {
        data: {
          title: 'Confirm',
          body: 'Do you want to mark this reward as redeemed?',
          buttonOne: 'Cancel',
          buttonTwo: 'Yes',
        },
        scrollStrategy: new NoopScrollStrategy(),
        autoFocus: false,
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((option: number) => {
        if (option == 1) {
          this.getLocations(item);
        }
      });
    } else if (event === 'Expire') {
      if (this.userRewards.find((el) => el._id === item)?.hasExpired) {
        this.constants.snack('Reward has already expired');
        return;
      }
      if (this.userRewards.find((el) => el._id === item)?.hasRedeemed) {
        this.constants.snack('Reward has already been redeemed');
        return;
      }
      const dialogRef = this.dialog.open(TwoOptionAlertComponent, {
        data: {
          title: 'Confirm',
          body: 'Do you want to mark this reward as expired?',
          buttonOne: 'Cancel',
          buttonTwo: 'Yes',
        },
        scrollStrategy: new NoopScrollStrategy(),
        autoFocus: false,
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((option: number) => {
        if (option == 1) {
          this.expireReward(item);
        }
      });
    }
  }

  openGiveStampDialog(userCard: any): void {
    this.mixpanel.track(Constants.analytics_keys.openStampPicker, {
      Customer: this.getCustomerName(this.customer),
    });

    const isPoints =
      (userCard?.card[0] ?? userCard?.card)?.cardType == CardType.point;

    const dialogRef = this.dialog.open(GiveStampsDialogComponent, {
      data: {
        title: isPoints ? 'Give Customer Points' : 'Give Customer Stamps',
        label: isPoints ? 'Points' : 'Stamps',
        property: 'number',
        userCard: userCard,
        number: (userCard?.card[0] ?? userCard?.card)?.numberOfStamps || 1,
        stamps: !isPoints,
      },
      scrollStrategy: new NoopScrollStrategy(),
      width: '600px',
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((val: any) => {
      if (isPoints) {
        let location: string;
        if (val?.location) {
          location = val.location;
        }

        const pointsNumber = val?.value;
        const rewards = this.rewardsToGiveFromPoints(pointsNumber, userCard);
        const totalPointsAvailable =
          (userCard?.card[0] ?? userCard?.card)?.points?.totalPoints -
          userCard?.numberOfPoints;

        if (pointsNumber > 0) {
          if (
            pointsNumber > totalPointsAvailable &&
            totalPointsAvailable !== 0
          ) {
            this.constants.snack(
              `You cannot give more than ${totalPointsAvailable} points`
            );
            return;
          }
          const dialogRef = this.dialog.open(TwoOptionAlertComponent, {
            data: {
              title: 'Confirm',
              body: `Do you want to give this customer ${pointsNumber ?? 1} ${
                (pointsNumber || 1) > 1 ? 'points' : 'point'
              }? ${
                rewards > 0
                  ? ' <br><br>They will also receive ' +
                    rewards +
                    (rewards === 1 ? ' reward' : ' rewards')
                  : ''
              }`,
              buttonOne: 'Cancel',
              buttonTwo: 'Yes',
            },
            scrollStrategy: new NoopScrollStrategy(),
            autoFocus: false,
            disableClose: true,
          });
          dialogRef.afterClosed().subscribe((option: number) => {
            if (option == 1) {
              this.giveStamp(userCard._id, pointsNumber, true, location);
            }
          });
        }
      } else {
        const stampNumber = val?.number;
        const rewards = this.rewardsToGive(stampNumber, userCard);

        let location: string;
        if (val?.location) {
          location = val.location;
        }

        if (stampNumber > 0) {
          const dialogRef = this.dialog.open(TwoOptionAlertComponent, {
            data: {
              title: 'Confirm',
              body: `Do you want to give this customer ${stampNumber ?? 1} ${
                (stampNumber || 1) > 1 ? 'stamps' : 'stamp'
              }? ${
                rewards > 0
                  ? ' <br><br>They will also receive ' +
                    rewards +
                    (rewards === 1 ? ' reward' : ' rewards')
                  : ''
              }`,
              buttonOne: 'Cancel',
              buttonTwo: 'Yes',
            },
            scrollStrategy: new NoopScrollStrategy(),
            autoFocus: false,
            disableClose: true,
          });
          dialogRef.afterClosed().subscribe((option: number) => {
            if (option == 1) {
              this.giveStamp(userCard._id, stampNumber, false, location);
            }
          });
        }
      }
    });
  }

  rewardsToGive(stamps: number, userCard: any): number {
    const totalStamps = (userCard.numberOfStamps ?? 0) + stamps;

    let rewardsCount = 0;

    const rewards = (userCard.card[0] as StampCard)?.rewards ?? [];

    if (rewards && rewards.length > 0) {
      rewards.forEach((reward) => {
        if (
          userCard.numberOfStamps < reward.stamp &&
          totalStamps >= reward.stamp
        ) {
          rewardsCount += 1;
        }
      });
    }

    return rewardsCount;
  }

  rewardsToGiveFromPoints(points: number, userCard: any): number {
    const totalPoints = (userCard?.card?.points?.totalPoints ?? 0) + points;

    let rewardsCount = 0;

    // Get the list of rewards, checking for the 'points' field
    const rewards = (userCard?.card[0] as StampCard)?.rewards ?? [];

    if (rewards && rewards.length > 0) {
      rewards.forEach((reward) => {
        if (
          userCard?.points?.totalPoints < (reward.points ?? 0) &&
          totalPoints >= (reward.points ?? 0)
        ) {
          rewardsCount += 1;
        }
      });
    }

    return rewardsCount;
  }

  giveReward(): void {
    const customerName = this.getCustomerName(this.customer);

    this.mixpanel.track(Constants.analytics_keys.openGiveReward, {
      Customer: customerName,
    });

    this.dialog.open(GiveRewardDialogComponent, {
      data: {
        customer: this.customer,
      },
      scrollStrategy: new NoopScrollStrategy(),
      width: '450px',
      autoFocus: false,
      panelClass: 'custom-dialog-panel',
    });
  }

  giveStamp(
    cardId: string,
    quantity: number,
    isPoints: boolean,
    location?: string
  ): void {
    let analyticsPayload = {
      Customer: this.getCustomerName(this.customer),
      Card: cardId,
      Points: 0,
      Stamps: 0,
    };

    if (isPoints) {
      analyticsPayload.Points = quantity;
    } else {
      analyticsPayload.Stamps = quantity;
    }

    this.mixpanel.track(
      isPoints
        ? Constants.analytics_keys.givePoints
        : Constants.analytics_keys.giveStamps,
      analyticsPayload
    );

    this.saving = true;
    this.merchantService
      .giveStamp(
        typeof this.customer.user === 'string'
          ? this.customer.user!
          : this.customer.user?._id!,
        cardId,
        quantity,
        location
      )
      .subscribe({
        next: (res: Customer) => {
          if (res) {
            this.constants.snack(
              isPoints
                ? 'Point(s) awarded to customer'
                : 'Stamp(s) awarded to customer'
            );

            setTimeout(() => {
              window.location.reload();
            }, 500);
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  getLocations(redeemId: string): void {
    this.locationsLoading = true;

    this.locationService
      .getLocations(1000, 0)
      .subscribe({
        next: (res: any) => {
          if (res) {
            const locations = res.docs.filter(
              (el: Location) => el.active === true && el.approved === true
            );

            this.selectLocationForRewardRedemption(redeemId, locations);
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => {
        this.locationsLoading = false;
      });
  }

  selectLocationForRewardRedemption(
    redeemId: string,
    locations: string[] = []
  ): void {
    if (locations.length > 1) {
      const dialogRef = this.dialog.open(SingleSelectDialogComponent, {
        data: {
          title: 'Select a location',
          label: 'Location',
          property: 'name',
          options: locations,
          infoText:
            'Select a location to attribute this redemption to for reporting purposes',
        },
        scrollStrategy: new NoopScrollStrategy(),
        width: '600px',
        autoFocus: false,
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe((data) => {
        if (data) {
          this.redeemReward(redeemId, data._id);
        }
      });
    } else {
      this.redeemReward(redeemId);
    }
  }

  redeemReward(redeemId: string, location?: string): void {
    this.saving = true;
    this.merchantService
      .redeemReward(this.customer._id!, redeemId, location)
      .subscribe({
        next: (res: Customer) => {
          if (res) {
            this.constants.snack('Reward redeemed');

            setTimeout(() => {
              window.location.reload();
            }, 500);
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  expireReward(redeemId: string): void {
    this.saving = true;
    this.merchantService
      .expireReward(this.customer._id!, redeemId)
      .subscribe({
        next: (res: Customer) => {
          if (res) {
            this.constants.snack('Reward expired');

            setTimeout(() => {
              window.location.reload();
            }, 500);
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  get loading(): boolean {
    return this.customerLoading || this.planLimitsLoading;
  }

  handleScroll() {
    this.suppressPaging = true;

    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }

    this.scrollTimeout = setTimeout(() => {
      this.suppressPaging = false;
    }, 100);
  }

  getPreferences(type: string): string {
    let list: string[] = [];

    if (typeof this.customer.user !== 'string') {
      if (this.customer.user?.preferences[type]) {
        for (var pref of this.customer.user?.preferences[type]) {
          list.push(pref);
        }
      }
    }

    return list.join(', ');
  }

  get userId(): string {
    if (typeof this.customer.user === 'string') {
      return this.customer.user ?? '';
    } else {
      return this.customer.user!._id ?? '';
    }
  }

  editNotes(): void {
    const dialogRef = this.dialog.open(TextAreaDialogComponent, {
      data: {
        title: 'Customer Notes',
        label: 'Notes',
        content: this.customer?.notes,
      },
      scrollStrategy: new NoopScrollStrategy(),
      width: '600px',
      autoFocus: false,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((content?: string) => {
      if (content) {
        this.updateCustomer(content);
      }
    });
  }

  updateCustomer(notes: string): void {
    this.saving = true;
    this.merchantService
      .updateCustomer(this.customer._id!, 'notes', notes)
      .subscribe({
        next: (res: Customer) => {
          if (res) {
            this.constants.snack('Customer Updated');
            this.customer.notes = notes;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  valueObtainedPercent(card: any): number {
    const stampCard =
      card?.card && typeof card.card !== 'string'
        ? card.card?.[0] ?? card.card
        : card.card;

    if (stampCard?.cardType == CardType.point) {
      const totalPoints = stampCard?.points?.totalPoints ?? 0;
      const currentPoints = card?.numberOfPoints ?? 0;
      return totalPoints ? (currentPoints / totalPoints) * 100 : 0;
    } else {
      const totalStamps = stampCard?.numberOfStamps ?? 0;
      const currentStamps = card?.numberOfStamps ?? 0;
      return totalStamps ? (currentStamps / totalStamps) * 100 : 0;
    }
  }

  valueObtained(card: any): string {
    const stampCard =
      card?.card && typeof card.card !== 'string'
        ? card.card?.[0] ?? card.card
        : card.card;

    if (stampCard?.cardType === CardType.point) {
      const totalPoints = stampCard?.points?.totalPoints ?? 0;
      const currentPoints = card?.numberOfPoints ?? 0;

      return `${
        currentPoints > totalPoints ? totalPoints : currentPoints
      }/${totalPoints}`;
    } else {
      const totalStamps = stampCard?.numberOfStamps ?? 0;
      const currentStamps = card?.numberOfStamps ?? 0;

      return `${
        currentStamps > totalStamps ? totalStamps : currentStamps
      }/${totalStamps}`;
    }
  }

  isLocationNotString(events: any[]): boolean {
    return events?.length > 0 && typeof events[0]?.location !== 'string';
  }
}
