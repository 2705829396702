import { Injectable } from '@angular/core';
import { AnalyticsAbstract } from './analytics.abstract';
import mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsImpl extends AnalyticsAbstract {
  constructor() {
    super();
    this.initializeMixpanel();
  }

  private initializeMixpanel() {
    if (!environment.production) {
      return;
    }

    mixpanel.init(environment.mixpanel, {
      track_pageview: true,
      persistence: 'localStorage',
    });
  }

  track(event: string, properties?: { [key: string]: any }): void {
    if (!environment.production) {
      return;
    }

    mixpanel.track(event, properties);
  }

  timeEvent(event: string): void {
    if (!environment.production) {
      return;
    }

    mixpanel.time_event(event);
  }

  setGlobalProperties(properties: { [key: string]: any }): void {
    if (!environment.production) {
      return;
    }

    mixpanel.register(properties);
  }

  setSpecificGlobalProperties(properties: { [key: string]: any }): void {
    if (!environment.production) {
      return;
    }

    mixpanel.register_once(properties);
  }

  unsetGlobalProperty(property: string): void {
    if (!environment.production) {
      return;
    }

    mixpanel.unregister(property);
  }

  identifyUser(user: any): void {
    if (!environment.production) {
      return;
    }

    if (user.nanoid) {
      mixpanel.identify(user.nanoid);
    }

    if (user.email) {
      this.setUserProperty('Email', user.email);
    }

    if (user.name) {
      this.setUserProperty('Name', user.name);
      this.setGlobalProperties({ Name: user.name, App: 'portal' });
    }

    this.setUserProperty('Type', (user.userType || 'Merchant').toLowerCase());
  }

  reset(): void {
    if (!environment.production) {
      return;
    }

    mixpanel.reset();
  }

  setUserProperty(key: string, value: string): void {
    if (!environment.production) {
      return;
    }

    mixpanel.people.set(key, value);
  }

  incrementUserProperty(key: string, value: number): void {
    if (!environment.production) {
      return;
    }

    mixpanel.people.increment(key, value);
  }
}
