import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'choose-reward',
  templateUrl: './choose-reward.component.html',
  styleUrls: ['./choose-reward.component.css'],
})
export class ChooseRewardComponent {
  @Input() icon?: string;
  @Input() label?: string;
  @Input() rounded = false;
  @Output() clicked: EventEmitter<any> = new EventEmitter();

  constructor(
    public dialog: MatDialog,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  click(): void {
    this.clicked.emit();
  }
}
