import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constants } from 'src/app/app.constants';

@Component({
  selector: 'birthday-club-info',
  templateUrl: './birthday-club-info.component.html',
  styleUrls: ['./birthday-club-info.component.css'],
})
export class BirthdayClubInfoComponent implements OnInit {
  currentIndex = 0;
  contentBlocks = [
    {
      title:
        "When customers take your loyalty card, if they have not already set their date of birth in their profile, they'll be invited to do so at this point if your Birthday Club is active",
      image: '../../../../assets/bday-1.jpeg',
    },
    {
      title:
        'Once a customer has added their date of birth, they will automatically become a part of your Birthday Club and will receive push notifications according to the configuration you set up',
      image: '../../../../assets/bday-2.jpeg',
    },
    {
      title:
        'A Join Birthday Club button will be displayed for those customers who have taken your loyalty card but not yet entered their date of birth, as a constant reminder to do so',
      image: '../../../../assets/bday-3.jpeg',
    },
  ];

  constructor(
    public matDialogRef: MatDialogRef<BirthdayClubInfoComponent>,
    public fb: FormBuilder,
    private constants: Constants,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  previous() {
    this.currentIndex =
      this.currentIndex > 0
        ? this.currentIndex - 1
        : this.contentBlocks.length - 1;
  }

  next() {
    this.currentIndex =
      this.currentIndex < this.contentBlocks.length - 1
        ? this.currentIndex + 1
        : 0;
  }
}
