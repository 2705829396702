import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Constants } from 'src/app/app.constants';
import { AuthService } from '../../../services/index';

@Component({
  selector: 'video-viewer',
  templateUrl: './video-viewer.component.html',
  styleUrls: ['./video-viewer.component.css'],
})
export class VideoViewerComponent {
  currentVideo = 0;
  loading = true;
  safeUrl?: SafeResourceUrl;

  constructor(
    public constants: Constants,
    public authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer,
    public matDialogRef: MatDialogRef<VideoViewerComponent>
  ) {
    this.currentVideo = this.data.initialIndex;
    this.safeUrl = this.getSafeUrl();
  }

  ngOnInit() {
    setTimeout(() => {
      this.loading = false;
    }, 500);
  }

  getSafeUrl(): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      this.data.guides[this.currentVideo].videoUrl
    );
  }

  updateVideo(): void {
    this.loading = true;
    setTimeout(() => {
      this.safeUrl = this.getSafeUrl();
      this.loading = false;
    }, 500);
  }

  previousClick(): void {
    if (this.currentVideo > 0) {
      this.currentVideo--;
      this.updateVideo();
    }
  }

  nextClick(): void {
    if (this.currentVideo < this.data.guides.length - 1) {
      this.currentVideo++;
      this.updateVideo();
    }
  }
}
