<div
  class="detail-container"
  id="right"
  style="max-width: 550px; max-height: 670px"
>
  <div
    *ngIf="isStampCard"
    style="display: flex; justify-content: space-between; margin-bottom: 10px"
  >
    <div class="custom-slider">
      <mat-slide-toggle
        [disabled]="false"
        [checked]="showStamps"
        (change)="showStamps = !showStamps"
        ><span class="section-subtitle"> Preview with stamps</span>
      </mat-slide-toggle>
    </div>
  </div>

  <card-preview
    [textColour]="textColour"
    [cardColour]="cardColour"
    [stampColour]="stampColour"
    [stampFillEmptyColour]="stampFillEmpty"
    [stampOutlineColour]="stampOutlineColour"
    [stamps]="stamps"
    [stampIcon]="stampIcon"
    [rewardIcon]="rewardIcon"
    [rewardIcons]="rewardIcons"
    [rewards]="rewards"
    [title]="title"
    [description]="description"
    [iconOpacity]="iconOpacity"
    [iconColor]="iconColor"
    [showLogo]="showLogo"
    [showStamps]="showStamps"
    [shape]="shape"
    [cardGradient]="cardGradient"
    [stampImage]="stampImage"
    [rewardImage]="rewardImage"
    [merchantLogo]="merchantLogo"
    [cardType]="cardType"
    [currentPoints]="currentPoints"
    [totalPoints]="totalPoints"
    [progressBarColor]="progressBarColor"
    [progressBarBgColor]="progressBarBgColor"
  ></card-preview>
</div>
