<div class="container-fluid" *ngIf="!pageLoading">
  <div class="row">
    <div class="col-md-12" id="left-col">
      <div class="left-content">
        <div class="form-container">
          <div class="img-container">
            <a href="https://remyrewards.co.uk"
              ><img src="../assets/remylogo.png"
            /></a>
          </div>

          <div class="form-field" style="margin-top: 20px">
            <div
              class="form-field-label"
              style="color: var(--app-timber)"
              *ngIf="verified"
            >
              Thank you for verifying your email address, you can now log in to
              start setting up your merchant account
            </div>
            <div
              class="form-field-label"
              style="color: var(--app-timber)"
              *ngIf="!verified"
            >
              We're unable to verify your email address, please try again or
              resend a verification link.
            </div>
          </div>
          <div class="action-buttons" style="margin-top: 20px">
            <app-secondary-button
              *ngIf="!verified"
              style="margin-right: 10px"
              [title]="'Resend link'"
              [disabled]="false"
              color="darkGrey"
              (tapped)="(null)"
            ></app-secondary-button>
            <app-primary-button
              [title]="'Sign in'"
              [disabled]="false"
              (tapped)="login()"
              bgColor="var(--app-timber)"
              color="white"
            ></app-primary-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<loader [loading]="loading || pageLoading"></loader>
