export interface Plan {
  name: string;
  features: Feature[];
  mostPopular: boolean;
  id: string;
  prices: Price[];
  active: boolean;
  extraLocation: ExtraLocation;
}

export interface Price {
  active: boolean;
  id: string;
  recurring: Recurring;
  value: number;
  currency: string;
  lookup_key: string;
  nickname: string;
  product: string;
  unit_amount: number;
  unit_amount_decimal: string;
  currency_options?: any;
}

export interface Feature {
  name: string;
}

export interface ExtraLocation {
  monthly: any;
  annually: any;
}

interface Recurring {
  interval: string;
  interval_count: number;
}
