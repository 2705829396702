import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { Subscription } from 'rxjs';
import { Constants } from 'src/app/app.constants';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AnimationOptions } from 'ngx-lottie';
import { AuthService, ListService, UserService } from '../../../services/index';

declare const window: any;

window.onHcaptchaVerify = (response: string) => {};

@Component({
  selector: 'register',
  templateUrl: 'register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {
  options: AnimationOptions = { path: '/assets/rewards.json', loop: false };
  countries: string[] = [];
  isMobile = false;
  submitted = false;
  pageLoading = true;
  resetPasswordSub!: Subscription;
  registerSub!: Subscription;
  hidePassword: boolean = true;
  form!: FormGroup;

  firstNameFormControl = new FormControl(
    { value: '', disabled: false },
    Validators.required
  );

  lastNameFormControl = new FormControl(
    { value: '', disabled: false },
    Validators.required
  );

  businessNameFormControl = new FormControl(
    { value: '', disabled: false },
    Validators.required
  );

  businessTypeFormControl = new FormControl(
    { value: { name: '', value: '' }, disabled: false },
    Validators.required
  );

  countryFormControl = new FormControl(
    { value: { name: '', value: '' }, disabled: false },
    Validators.required
  );

  emailFormControl = new FormControl(
    { value: '', disabled: false },
    Validators.required
  );

  passwordFormControl = new FormControl({ value: '', disabled: false }, [
    Validators.required,
  ]);

  confirmPasswordFormControl = new FormControl({ value: '', disabled: false }, [
    Validators.required,
  ]);

  loading = false;
  fetching = false;

  businessTypes: any[] = [];

  verifyingCaptcha = false;
  captchaSuccess = false;

  @HostListener('window:resize', ['$event'])
  onResize = () => this.testMobile();
  testMobile = () => (this.isMobile = window.innerWidth < 768 ? true : false);

  constructor(
    public constants: Constants,
    private formBuilder: FormBuilder,
    private router: Router,
    private listService: ListService,
    private authService: AuthService,
    private userService: UserService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.getBusinessTypes();
    this.testMobile();

    this.http
      .get<any>('../../../../assets/json/countries.json')
      .subscribe((data) => {
        this.countries = data;
      });

    this.form = this.formBuilder.group({
      email: this.emailFormControl,
      password: this.passwordFormControl,
      confirmPassword: this.confirmPasswordFormControl,
      firstName: this.firstNameFormControl,
      lastName: this.lastNameFormControl,
      business: this.businessNameFormControl,
      type: this.businessTypeFormControl,
      country: this.countryFormControl,
    });
  }

  getBusinessTypes(): void {
    this.fetching = true;
    this.listService
      .getList('tags', true)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.businessTypes = res;
            this.businessTypes?.sort((a, b) => a.name.localeCompare(b.name));
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.fetching = false));
  }

  onVerify(token: any): void {
    this.verifyingCaptcha = true;
    this.userService
      .verifyCaptcha(token)
      .subscribe({
        next: (res: any) => {
          if (res?.success) {
            this.captchaSuccess = true;
          } else {
            this.captchaSuccess = false;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.captchaSuccess = false;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.verifyingCaptcha = false));
  }

  onExpired(token: any): void {
    this.captchaSuccess = false;
  }

  onError(token: any): void {
    this.captchaSuccess = false;
  }

  register() {
    this.submitted = true;

    if (this.form.invalid) {
      this.form.markAllAsTouched();

      Object.keys(this.form.controls).forEach((key) => {
        const control = this.form.get(key);
        if (control?.invalid) {
          console.log(`Invalid form control: ${key}`);
        }
      });
      return;
    }

    if (!this.passwordValid) {
      this.constants.snack(
        'Password must contain at least 6 characters and 1 digit'
      );
      return;
    }

    if (
      this.passwordFormControl.value != this.confirmPasswordFormControl.value
    ) {
      this.constants.snack('Password do not match');
      return;
    }

    if (!this.captchaSuccess) {
      this.constants.snack('Invalid captcha, please try again');
      return;
    }

    this.loading = true;

    this.registerSub = this.authService
      .getRegisterListener()
      .subscribe((result) => {
        this.registerSub.unsubscribe();
        this.loading = false;
        if (result) {
          this.navigate(this.form.value.email);
        }
      });

    this.authService.register(
      this.form.value.email,
      this.form.value.password,
      this.firstNameFormControl.value!,
      this.lastNameFormControl.value!,
      this.businessNameFormControl.value!,
      this.businessTypeFormControl.value?.name ?? '',
      this.countryFormControl.value?.name
    );
  }

  navigate(email: string) {
    this.router.navigate([Constants.routes.confirm], {
      queryParams: { e: email },
    });
  }

  login() {
    this.router.navigate([Constants.routes.login]);
  }

  get passwordValid(): boolean {
    return (
      this.constants.passwordValid(this.passwordFormControl.value ?? '') &&
      this.constants.passwordValid(this.confirmPasswordFormControl.value ?? '')
    );
  }

  onHcaptchaVerify(response: string) {}
}
