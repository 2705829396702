<page-header
  [title]="constants.strings.help"
  [subtitle]="subtitle"
></page-header>

<div style="margin-top: 20px" *ngIf="!guidesLoading">
  <div
    style="
      background-color: var(--app-container);
      border-radius: 15px;
      padding: 20px;
    "
  >
    <p
      class="section-title"
      style="margin-bottom: 5px; font-weight: bold; font-size: 16px"
    >
      Video Guides
    </p>
    <p
      class="section-title"
      style="
        margin-bottom: 20px;
        font-weight: normal;
        color: grey;
        font-size: 14px;
      "
    >
      Still have questions?
      <span
        ><a
          target="_blank"
          href="https://remy-rewards.notion.site/remy-rewards/Remy-FAQ-4e083f6284f6499691b92bdb8885a329"
          >Head over to our FAQ site</a
        ></span
      >
    </p>
    <div class="thumbnail-container">
      <div
        class="thumbnail"
        *ngFor="let guide of guides; let i = index"
        (click)="openVideo(guide)"
      >
        <div class="img-container">
          <img [src]="guide.imageUrl" [alt]="guide.title" />
          <mat-icon class="play-button">play_circle_outline</mat-icon>
        </div>

        <div class="thumbnail-title">
          {{ guide.title }}
        </div>
        <div class="thumbnail-description">
          {{ guide?.description }}
        </div>
      </div>
    </div>
  </div>
</div>

<loader [loading]="loading"></loader>
