<page-header title="Dashboard" [subtitle]="subtitle"></page-header>

<div class="row" *ngIf="!pageLoading">
  <div class="col-6 col-lg-3">
    <div class="digit-container">
      <div class="image-container">
        <img src="../../../assets/stamp.svg" width="40" style="color: blue" />
      </div>
      <div class="text-container">
        <p class="digit-subtitle">
          {{ firstContainerValue }}
        </p>
        <p class="digit-title">
          {{ firstContainerLabel }}
        </p>
      </div>
    </div>
  </div>
  <div class="col-6 col-lg-3">
    <div class="digit-container">
      <div class="image-container">
        <img src="../../../assets/review.svg" width="40" />
      </div>
      <div class="text-container">
        <p class="digit-subtitle">{{ insights.customers ?? "-" }}</p>
        <p class="digit-title">Customers</p>
      </div>
    </div>
  </div>
  <div class="col-6 col-lg-3">
    <div class="digit-container">
      <div class="image-container">
        <img src="../../../assets/rewards.svg" width="40" />
      </div>
      <div class="text-container">
        <p class="digit-subtitle">{{ insights.rewards ?? "-" }}</p>
        <p class="digit-title">Rewards</p>
      </div>
    </div>
  </div>
  <div class="col-6 col-lg-3">
    <div class="digit-container">
      <div class="image-container">
        <img src="../../../assets/redemption.svg" width="40" />
      </div>
      <div class="text-container">
        <p class="digit-subtitle">
          {{ insights.redemptions ?? "-" }}
          <sup
            [matTooltip]="'Reward to Redemption Rate'"
            style="
              font-style: italic;
              font-size: 12px;
              color: var(--app-text-grey);
              cursor: help;
            "
            >({{ redemptionRate }}%)</sup
          >
        </p>
        <p class="digit-title">Redemptions</p>
      </div>
    </div>
  </div>
</div>

<div style="margin-top: 10px" *ngIf="!pageLoading">
  <div
    style="
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
      background-color: var(--app-container);
      border-radius: 10px 10px 0px 0px;
      padding: 10px;
    "
  >
    <div
      style="
        margin-left: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      "
    >
      <app-primary-button
        [title]="selected"
        [disabled]="false"
        [mat-menu-trigger-for]="userMenu"
        [bgColor]="'white'"
        color="var(--app-timber)"
        border="1px solid lightGrey"
        [icon]="'keyboard_arrow_down'"
        [reverse]="true"
      ></app-primary-button>

      <mat-menu #userMenu="matMenu" class="custom-menu">
        <div *ngFor="let item of dateOptions; let i = index">
          <button
            class="menu-list-item"
            style="border-radius: 5px"
            (click)="tapped(item)"
            mat-menu-item
          >
            <span
              class="menu-list-item-label"
              [style.fontWeight]="selected == item.title ? '600' : '400'"
              >{{ item.title }}</span
            >
          </button>
        </div>
      </mat-menu>
    </div>
  </div>

  <mat-tab-group
    mat-align-tabs="start"
    mat-stretch-tabs="false"
    disableRipple="true"
    [animationDuration]="0"
    [(selectedIndex)]="selectedIndex"
    (selectedTabChange)="tabChanged($event.index)"
  >
    >
    <mat-tab label="Stamps" *ngIf="insights?.showStamps == true">
      <div class="chart-container">
        <div class="row">
          <div class="col-lg-6">
            <chart
              [type]="'stamp'"
              [chartType]="viewType"
              *ngIf="viewType !== 'hide' && selectedTab === 'stamps'"
              [days]="days"
              (optionTapped)="daysSelected($event)"
            ></chart>
          </div>
          <div class="col-lg-6">
            <hourly-chart
              [type]="'stamp'"
              [chartType]="viewType"
              *ngIf="viewType !== 'hide' && selectedTab === 'stamps'"
              [days]="days"
              (optionTapped)="daysSelected($event)"
            ></hourly-chart>
          </div>
        </div>
      </div>

      <!-- <div class="chart-container">
        <div class="row">
          <div class="col-lg-12">
            <funnel-chart
              [type]="'funnel'"
              *ngIf="viewType !== 'hide' && selectedTab === 'stamps'"
            ></funnel-chart>
          </div>
        </div>
      </div> -->

      <ngx-datatable
        style="margin-bottom: 70px"
        #stampsTable
        class="material"
        [headerHeight]="45"
        [rows]="recentStamps"
        [scrollbarV]="false"
        [scrollbarH]="true"
        [footerHeight]="50"
        [columnMode]="'force'"
        [messages]="{ emptyMessage: 'No stamps to display' }"
        [rowHeight]="48"
        [externalPaging]="true"
        [count]="stampLength"
        [offset]="stampPageIndex"
        (page)="stampPageEvent($event)"
        (scroll)="handleScroll()"
        [limit]="stampPageSize"
      >
        <ngx-datatable-column name="Date" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.createdAt | date : constants.longestDate }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Card" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a
              [routerLink]="['/card', row?.card?.nanoid]"
              (click)="$event.stopPropagation()"
            >
              {{ row?.card?.title }}
            </a>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Customer" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <ng-container *ngIf="canViewCustomers; else noLink">
              <a
                [routerLink]="['/customer', row?.customer?.nanoid]"
                (click)="$event.stopPropagation()"
              >
                {{ row?.user?.name }}
              </a>
            </ng-container>
            <ng-template #noLink>
              {{ row?.user?.name }}
            </ng-template>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Stamp #" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.stampNumber }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Source" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <img
              *ngIf="constants.isShopify(row)"
              src="../../../assets/shopify-logo.png"
              width="20"
              height="20"
              style="margin-right: 5px"
            />
            <img
              *ngIf="constants.isSquare(row)"
              src="../../../assets/square-logo.png"
              width="20"
              height="20"
              style="margin-right: 5px"
            />
            <img
              *ngIf="constants.isSumUp(row)"
              src="../../../assets/sumup.png"
              width="20"
              height="20"
              style="margin-right: 5px"
            />
            <img
              *ngIf="constants.isStorekit(row)"
              src="../../../assets/storekit.png"
              width="20"
              height="20"
              style="margin-right: 5px"
            />
            <img
              *ngIf="constants.isZapier(row)"
              src="../../../assets/zapier-logo-small.png"
              width="20"
              height="20"
              style="margin-right: 5px"
            />
            <img
              *ngIf="constants.isSquarespace(row)"
              src="../../../assets/squarespace_logo_small.png"
              width="20"
              height="20"
              style="margin-right: 5px"
            />
            <img
              *ngIf="constants.isAcuity(row)"
              src="../../../assets/acuity_logo_small.png"
              width="20"
              height="20"
              style="margin-right: 5px"
            />
            <span
              *ngIf="
                row?.source?.user ||
                  (row?.source?.nfc && !row?.source?.nfc?.master);
                else altSource
              "
            >
              <a
                *ngIf="row?.source?.user"
                [routerLink]="['/team-member', row.source?.user?.nanoid]"
                (click)="$event.stopPropagation()"
              >
                {{ constants.sourceString(row) }}
              </a>
              <a
                *ngIf="row?.source?.nfc"
                [routerLink]="['/nfc-events', row.source?.nfc?.ref]"
                (click)="$event.stopPropagation()"
              >
                {{ constants.sourceString(row) }}
              </a>
            </span>

            <ng-template #altSource>
              {{ constants.sourceString(row) }}
            </ng-template>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="Location"
          [resizeable]="false"
          *ngIf="showLocation"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a
              [routerLink]="['/location', row.location?.nanoid]"
              (click)="$event.stopPropagation()"
            >
              {{ row?.location?.name }}
            </a>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-offset="offset"
            let-isVisible="isVisible"
          >
            <div class="page-count" *ngIf="stampLength > 0">
              <span>{{ stampsRange }}</span>
            </div>
            <datatable-pager
              [pagerLeftArrowIcon]="'datatable-icon-left'"
              [pagerRightArrowIcon]="'datatable-icon-right'"
              [pagerPreviousIcon]="'datatable-icon-prev'"
              [pagerNextIcon]="'datatable-icon-skip'"
              [page]="stampPageIndex + 1"
              [size]="pageSize"
              [count]="stampLength"
              [hidden]="!(rowCount / pageSize > 1)"
              (change)="stampsTable.onFooterPage($event)"
            >
            </datatable-pager>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </mat-tab>

    <mat-tab label="Points" *ngIf="insights?.showPoints == true">
      <div class="chart-container">
        <div class="row">
          <div class="col-lg-6">
            <chart
              [type]="'point'"
              [chartType]="viewType"
              *ngIf="viewType !== 'hide' && selectedTab === 'points'"
              [days]="days"
              (optionTapped)="daysSelected($event)"
            ></chart>
          </div>
          <div class="col-lg-6">
            <hourly-chart
              [type]="'point'"
              [chartType]="viewType"
              *ngIf="viewType !== 'hide' && selectedTab === 'points'"
              [days]="days"
              (optionTapped)="daysSelected($event)"
            ></hourly-chart>
          </div>
        </div>
      </div>

      <ngx-datatable
        style="margin-bottom: 70px"
        #pointsTable
        class="material"
        [headerHeight]="45"
        [rows]="recentPoints"
        [scrollbarV]="false"
        [scrollbarH]="true"
        [footerHeight]="50"
        [columnMode]="'force'"
        [messages]="{ emptyMessage: 'No points to display' }"
        [rowHeight]="48"
        [externalPaging]="true"
        [count]="pointsLength"
        [offset]="pointsPageIndex"
        (page)="pointsPageEvent($event)"
        (scroll)="handleScroll()"
        [limit]="pointsPageSize"
      >
        <ngx-datatable-column name="Date" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.createdAt | date : constants.longestDate }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Card" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a
              [routerLink]="['/card', row?.card?.nanoid]"
              (click)="$event.stopPropagation()"
            >
              {{ row?.card?.title }}
            </a>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Customer" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <ng-container *ngIf="canViewCustomers; else noLink">
              <a
                [routerLink]="['/customer', row?.customer?.nanoid]"
                (click)="$event.stopPropagation()"
              >
                {{ row?.user?.name }}
              </a>
            </ng-container>
            <ng-template #noLink>
              {{ row?.user?.name }}
            </ng-template>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Points" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.points | number : "1.0-0" }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Source" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <img
              *ngIf="constants.isShopify(row)"
              src="../../../assets/shopify-logo.png"
              width="20"
              height="20"
              style="margin-right: 5px"
            />
            <img
              *ngIf="constants.isSquare(row)"
              src="../../../assets/square-logo.png"
              width="20"
              height="20"
              style="margin-right: 5px"
            />
            <img
              *ngIf="constants.isSumUp(row)"
              src="../../../assets/sumup.png"
              width="20"
              height="20"
              style="margin-right: 5px"
            />
            <img
              *ngIf="constants.isStorekit(row)"
              src="../../../assets/storekit.png"
              width="20"
              height="20"
              style="margin-right: 5px"
            />
            <img
              *ngIf="constants.isSquarespace(row)"
              src="../../../assets/squarespace_logo_small.png"
              width="20"
              height="20"
              style="margin-right: 5px"
            />
            <img
              *ngIf="constants.isAcuity(row)"
              src="../../../assets/acuity_logo_small.png"
              width="20"
              height="20"
              style="margin-right: 5px"
            />
            <img
              *ngIf="constants.isZapier(row)"
              src="../../../assets/zapier-logo-small.png"
              width="20"
              height="20"
              style="margin-right: 5px"
            />
            <span
              *ngIf="
                row?.source?.user ||
                  (row?.source?.nfc && !row?.source?.nfc?.master);
                else altSource
              "
            >
              <a
                *ngIf="row?.source?.user"
                [routerLink]="['/team-member', row.source?.user?.nanoid]"
                (click)="$event.stopPropagation()"
              >
                {{ constants.sourceString(row) }}
              </a>
              <a
                *ngIf="row?.source?.nfc"
                [routerLink]="['/nfc-events', row.source?.nfc?.ref]"
                (click)="$event.stopPropagation()"
              >
                {{ constants.sourceString(row) }}
              </a>
            </span>

            <ng-template #altSource>
              {{ constants.sourceString(row) }}
            </ng-template>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="Location"
          [resizeable]="false"
          *ngIf="showLocation"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a
              [routerLink]="['/location', row.location?.nanoid]"
              (click)="$event.stopPropagation()"
            >
              {{ row?.location?.name }}
            </a>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-offset="offset"
            let-isVisible="isVisible"
          >
            <div class="page-count" *ngIf="pointsLength > 0">
              <span>{{ pointsRange }}</span>
            </div>
            <datatable-pager
              [pagerLeftArrowIcon]="'datatable-icon-left'"
              [pagerRightArrowIcon]="'datatable-icon-right'"
              [pagerPreviousIcon]="'datatable-icon-prev'"
              [pagerNextIcon]="'datatable-icon-skip'"
              [page]="pointsPageIndex + 1"
              [size]="pageSize"
              [count]="pointsLength"
              [hidden]="!(rowCount / pageSize > 1)"
              (change)="pointsTable.onFooterPage($event)"
            >
            </datatable-pager>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </mat-tab>

    <mat-tab label="Redemptions">
      <div class="chart-container">
        <div class="row">
          <div class="col-lg-6">
            <chart
              [type]="'redemption'"
              [chartType]="viewType"
              *ngIf="viewType !== 'hide' && selectedTab === 'redemptions'"
              [days]="days"
              (optionTapped)="daysSelected($event)"
            ></chart>
          </div>
          <div class="col-lg-6">
            <hourly-chart
              [type]="'redemption'"
              [chartType]="viewType"
              *ngIf="viewType !== 'hide' && selectedTab === 'redemptions'"
              [days]="days"
              (optionTapped)="daysSelected($event)"
            ></hourly-chart>
          </div>
        </div>
      </div>

      <ngx-datatable
        style="margin-bottom: 70px"
        #redemptionsTable
        class="material"
        [headerHeight]="45"
        [rows]="redemptions"
        [scrollbarV]="false"
        [scrollbarH]="true"
        [footerHeight]="50"
        [columnMode]="'force'"
        [messages]="{ emptyMessage: 'No redemptions to display' }"
        [rowHeight]="48"
        [externalPaging]="true"
        [count]="redemptionLength"
        [offset]="redemptionPageIndex"
        (page)="redemptionPageEvent($event)"
        (scroll)="handleScroll()"
        [limit]="redemptionPageSize"
      >
        <ngx-datatable-column name="Redemption Date" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.createdAt | date : constants.longestDate }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Reward" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a
              [routerLink]="['/reward', row?.reward?.nanoid]"
              (click)="$event.stopPropagation()"
            >
              {{ row?.reward?.title }}
            </a>
          </ng-template>
        </ngx-datatable-column>

        <!-- <ngx-datatable-column name="Card/PN" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.card?.title ?? "Push Notification" }}
          </ng-template>
        </ngx-datatable-column> -->

        <ngx-datatable-column name="Customer" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <ng-container *ngIf="canViewCustomers; else noLink">
              <a
                [routerLink]="['/customer', row?.customer?.nanoid]"
                (click)="$event.stopPropagation()"
              >
                {{ row?.user?.name }}
              </a>
            </ng-container>
            <ng-template #noLink>
              {{ row?.user?.name }}
            </ng-template>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Source" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span
              *ngIf="
                row?.source?.user ||
                  (row?.source?.nfc && !row?.source?.nfc?.master);
                else altSource
              "
            >
              <a
                *ngIf="row?.source?.user"
                [routerLink]="['/team-member', row.source?.user?.nanoid]"
                (click)="$event.stopPropagation()"
              >
                {{ constants.sourceString(row) }}
              </a>
              <a
                *ngIf="row?.source?.nfc"
                [routerLink]="['/nfc-events', row.source?.nfc?.ref]"
                (click)="$event.stopPropagation()"
              >
                {{ constants.sourceString(row) }}
              </a>
            </span>

            <ng-template #altSource>
              {{ constants.sourceString(row) }}
            </ng-template>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="Location"
          [resizeable]="false"
          *ngIf="showLocation"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a
              [routerLink]="['/location', row.location?.nanoid]"
              (click)="$event.stopPropagation()"
            >
              {{ row?.location?.name }}
            </a>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-offset="offset"
            let-isVisible="isVisible"
          >
            <div class="page-count" *ngIf="redemptionLength > 0">
              <span>{{ redemptionsRange }}</span>
            </div>
            <datatable-pager
              [pagerLeftArrowIcon]="'datatable-icon-left'"
              [pagerRightArrowIcon]="'datatable-icon-right'"
              [pagerPreviousIcon]="'datatable-icon-prev'"
              [pagerNextIcon]="'datatable-icon-skip'"
              [page]="redemptionPageIndex + 1"
              [size]="pageSize"
              [count]="redemptionLength"
              [hidden]="!(rowCount / pageSize > 1)"
              (change)="redemptionsTable.onFooterPage($event)"
            >
            </datatable-pager>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </mat-tab>

    <mat-tab label="Cards">
      <div class="chart-container">
        <div class="row">
          <div class="col-lg-6">
            <chart
              [type]="'card'"
              [chartType]="viewType"
              *ngIf="viewType !== 'hide' && selectedTab === 'cards'"
              [days]="days"
              (optionTapped)="daysSelected($event)"
            ></chart>
          </div>
          <div class="col-lg-6">
            <hourly-chart
              [type]="'card'"
              [chartType]="viewType"
              *ngIf="viewType !== 'hide' && selectedTab === 'cards'"
              [days]="days"
              (optionTapped)="daysSelected($event)"
            ></hourly-chart>
          </div>
        </div>
      </div>

      <ngx-datatable
        style="margin-bottom: 70px"
        #cardsTable
        class="material"
        [headerHeight]="45"
        [rows]="cards"
        [scrollbarV]="false"
        [messages]="{ emptyMessage: 'No loyalty cards to display' }"
        [scrollbarH]="true"
        [footerHeight]="50"
        [columnMode]="'force'"
        [rowHeight]="48"
        [externalPaging]="true"
        [count]="cardsLength"
        [offset]="cardsPageIndex"
        (page)="cardPageEvent($event)"
        (scroll)="handleScroll()"
        [limit]="cardsPageSize"
      >
        <ngx-datatable-column name="Date Taken" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.createdAt | date : constants.longDate }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Card" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div *ngIf="!(row?.card[0] ?? row?.card)?.title">
              {{ (row?.card[0] ?? row?.card)?.title ?? "" }}
            </div>

            <a
              *ngIf="(row?.card[0] ?? row?.card)?.title"
              [routerLink]="['/card', (row?.card[0] ?? row?.card)?.nanoid]"
              (click)="$event.stopPropagation()"
            >
              {{ (row?.card[0] ?? row?.card)?.title }}
            </a>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Customer" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <ng-container *ngIf="canViewCustomers; else noLink">
              <a
                [routerLink]="['/customer', row?.customer?.nanoid]"
                (click)="$event.stopPropagation()"
              >
                {{ row?.user?.name }}
              </a>
            </ng-container>
            <ng-template #noLink>
              {{ row?.user?.name }}
            </ng-template>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="Type"
          [resizeable]="true"
          *ngIf="showCardTypeColumn"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ cardType(row) }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Progress" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div style="display: flex">
              <mat-progress-spinner
                style="margin-right: 5px"
                class="progress"
                [color]="color"
                [mode]="mode"
                [value]="valueObtainedPercent(row)"
                [diameter]="20"
                *ngIf="
                  (row?.card[0] ?? row?.card)?.points?.maxPoints > 0 ||
                  (row?.card[0] ?? row?.card)?.cardType === 'stamp'
                "
              >
              </mat-progress-spinner>
              <span>
                {{
                  valueObtained(row).includes("/")
                    ? valueObtained(row)
                    : (valueObtained(row) | number : "1.0-0")
                }}
              </span>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-offset="offset"
            let-isVisible="isVisible"
          >
            <div class="page-count" *ngIf="cardsLength > 0">
              <span>{{ cardsRange }}</span>
            </div>
            <datatable-pager
              [pagerLeftArrowIcon]="'datatable-icon-left'"
              [pagerRightArrowIcon]="'datatable-icon-right'"
              [pagerPreviousIcon]="'datatable-icon-prev'"
              [pagerNextIcon]="'datatable-icon-skip'"
              [page]="cardsPageIndex + 1"
              [size]="pageSize"
              [count]="cardsLength"
              [hidden]="!(rowCount / pageSize > 1)"
              (change)="cardsTable.onFooterPage($event)"
            >
            </datatable-pager>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </mat-tab>

    <mat-tab label="Rewards">
      <div class="chart-container">
        <div class="row">
          <div class="col-lg-6">
            <chart
              [type]="'reward'"
              [chartType]="viewType"
              *ngIf="viewType !== 'hide' && selectedTab === 'rewards'"
              [days]="days"
              (optionTapped)="daysSelected($event)"
            ></chart>
          </div>
          <div class="col-lg-6">
            <hourly-chart
              [type]="'reward'"
              [chartType]="viewType"
              *ngIf="viewType !== 'hide' && selectedTab === 'rewards'"
              [days]="days"
              (optionTapped)="daysSelected($event)"
            ></hourly-chart>
          </div>
        </div>
      </div>

      <ngx-datatable
        style="margin-bottom: 70px"
        #rewardsTable
        class="material"
        [headerHeight]="45"
        [rows]="rewards"
        [scrollbarV]="false"
        [messages]="{ emptyMessage: 'No rewards to display' }"
        [scrollbarH]="true"
        [footerHeight]="50"
        [columnMode]="'force'"
        [rowHeight]="48"
        [externalPaging]="true"
        [count]="rewardsLength"
        [offset]="rewardsPageIndex"
        (page)="rewardPageEvent($event)"
        (scroll)="handleScroll()"
        [limit]="rewardsPageSize"
      >
        <ngx-datatable-column name="Date Received" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.createdAt | date : constants.longDate }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Reward" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div *ngIf="!(row?.reward[0] ?? row?.reward)?.title">
              {{ (row?.reward[0] ?? row?.reward)?.title ?? "" }}
            </div>

            <a
              *ngIf="(row?.reward[0] ?? row?.reward)?.title"
              [routerLink]="[
                '/reward',
                (row?.reward[0] ?? row?.reward)?.nanoid
              ]"
              (click)="$event.stopPropagation()"
            >
              {{ (row?.reward[0] ?? row?.reward)?.title }}
            </a>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Customer" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <ng-container *ngIf="canViewCustomers; else noLink">
              <a
                [routerLink]="['/customer', row?.customer?.nanoid]"
                (click)="$event.stopPropagation()"
              >
                {{ row?.user?.name }}
              </a>
            </ng-container>
            <ng-template #noLink>
              {{ row?.user?.name }}
            </ng-template>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Source" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ constants.userRewardSource(row) }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Expires" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{
              row?.expiry ? (row?.expiry | date : constants.longDate) : "Never"
            }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Status" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div id="status-chip" [ngClass]="constants.statusChipClass(row)">
              {{ constants.statusChipLabel(row) }}
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-offset="offset"
            let-isVisible="isVisible"
          >
            <div class="page-count" *ngIf="rewardsLength > 0">
              <span>{{ rewardsRange }}</span>
            </div>
            <datatable-pager
              [pagerLeftArrowIcon]="'datatable-icon-left'"
              [pagerRightArrowIcon]="'datatable-icon-right'"
              [pagerPreviousIcon]="'datatable-icon-prev'"
              [pagerNextIcon]="'datatable-icon-skip'"
              [page]="rewardsPageIndex + 1"
              [size]="pageSize"
              [count]="rewardsLength"
              [hidden]="!(rowCount / pageSize > 1)"
              (change)="rewardsTable.onFooterPage($event)"
            >
            </datatable-pager>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </mat-tab>
  </mat-tab-group>
</div>

<loader [loading]="loading"></loader>
