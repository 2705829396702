import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Constants } from 'src/app/app.constants';
import { Plan, Price } from 'src/app/models/plan';
import { AuthService, StripeService } from '../../../../services/index';
import { Merchant } from '../../../../models/index';

@Component({
  selector: 'plan-card',
  templateUrl: 'plan-card.component.html',
  styleUrls: ['./plan-card.component.css'],
})
export class PlanCardComponent implements OnInit {
  merchantForm!: FormGroup;

  merchantNameFormControl = new FormControl(
    { value: '', disabled: false },
    Validators.required
  );

  title = '';
  subtitle: string = '';
  merchant: Merchant = {};

  selectedLogo?: [{ name: string; content: ArrayBuffer }];

  @Input() enabled = true;
  @Input() currency = 'gbp';
  @Input() plan!: Plan;
  @Input() loading: boolean = false;
  @Input() basis: string = 'year';
  @Output() buttonTapped: EventEmitter<any> = new EventEmitter();

  constructor(public constants: Constants, private authService: AuthService) {}

  ngOnInit() {}

  isActivePlan(): boolean {
    if (!this.enabled) {
      return false;
    }
    return this.price.lookup_key === this.authService.getUserProperty('plan');
  }

  get hasPlan(): boolean {
    return this.authService.getUserProperty('plan') !== 'none';
  }

  get basisString(): string {
    if (this.basis === 'year') {
      return 'billed annually';
    } else {
      return 'billed monthly';
    }
  }

  get emoji(): string {
    if (this.plan.name === 'Starter') {
      return '🌱';
    } else {
      return '🚀';
    }
  }

  get price(): Price {
    let price: Price;

    price = this.plan.prices.find(
      (el) => el.recurring?.interval == this.basis
    ) as Price;
    return price!;
  }

  get displayPrice(): number {
    const unitAmount =
      this.price?.currency_options?.[this.currency]?.unit_amount || 0;
    const divisor = this.basis === 'year' ? 12 : 1;
    return Math.round(unitAmount / divisor);
  }

  get annualPrice(): Price {
    let price: Price;

    price = this.plan.prices.find(
      (el) => el.recurring?.interval == 'month'
    ) as Price;
    return price!;
  }

  get annualDisplayPrice(): number {
    if (this.annualPrice?.currency_options) {
      if (this.annualPrice?.currency_options?.hasOwnProperty(this.currency)) {
        return (
          (this.annualPrice?.currency_options[this.currency]?.unit_amount ??
            0) / 1
        );
      }
    }
    return (this.annualPrice?.unit_amount ?? 0) / 1;
  }

  get upgradeString(): string {
    return 'Change';
  }

  get locationPrice(): number {
    if (this.basis == 'year') {
      return this.plan.extraLocation.annually[this.currency];
    } else {
      return this.plan.extraLocation.monthly[this.currency];
    }
  }

  get currencySymbol(): string {
    if (this.currency === 'gbp') {
      return '£';
    } else if (this.currency === 'usd') {
      return '$';
    } else {
      return '€';
    }
  }
}
