<div
  class="uf-tour-selection uf-notification uf-right uf-visible"
  *ngIf="checklistVisible"
>
  <div class="uf-notification-inner">
    <div
      style="
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
      "
    >
      <p
        class="section-title"
        style="margin-bottom: 10px; font-weight: bold; font-size: 16px"
      >
        Get Started
      </p>
    </div>

    <div class="uf-content">
      <span class="uf-progress-value"
        >{{ progressValue }}/{{ checklistItems.length }}</span
      >
      <div class="uf-progress">
        <div
          #progressBar
          class="uf-progress-bar"
          [ngStyle]="progressBarStyle"
        ></div>
      </div>
    </div>

    <div class="uf-actions">
      <div
        *ngFor="let item of checklistItems; trackBy: trackById; let i = index"
      >
        <ng-container *ngIf="canSelectItem(item) as checklistError">
          <button
            class="uf-button uf-btn-block"
            (click)="checklistError.state ? checkItem(item) : null"
            [ngStyle]="{
              cursor: checklistError.state ? 'pointer' : 'not-allowed'
            }"
            [matTooltip]="
              checklistError.state ? '' : checklistError.title ?? ''
            "
          >
            <mat-icon class="complete-icon" *ngIf="item.complete"
              >check_circle</mat-icon
            >

            <svg
              *ngIf="!item.complete"
              class="svg-icon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="12"
                cy="12"
                r="9"
                stroke="darkGrey"
                stroke-width="1.5"
                stroke-linecap="round"
              ></circle>
              1
            </svg>

            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: start;
              "
            >
              <span
                style="text-align: start; font-size: 12px"
                [ngStyle]="{
                  color:
                    item.complete || checklistError.state ? 'black' : 'darkGrey'
                }"
                >{{ "Step " + (i + 1) }}</span
              >
              <span
                [ngStyle]="{
                  color:
                    item.complete || checklistError.state
                      ? 'black'
                      : 'darkGrey',
                  fontWeight: '500'
                }"
                >{{ item.title }}</span
              >
            </div>
          </button>
        </ng-container>
      </div>

      <div class="uf-bottom-action">
        <div
          style="color: darkGrey; cursor: pointer"
          (click)="closeChecklist()"
        >
          Dismiss checklist
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="dismissed-checklist-button"
  *ngIf="!checklistVisible && !checklistComplete"
  (click)="closeChecklist()"
>
  <span class="uf-progress-value-dismissed"
    >{{ progressValue }}/{{ checklistItems.length }}</span
  >
</div>
