import { Merchant, Reward } from './index';

export interface UserNotification {
  nanoid?: string;
  _id?: string;
  title?: string;
  category?: string;
  seen?: boolean;
  delivered?: boolean;
  data?: UserNotificationData;
  createdAt?: string;
}

interface UserNotificationData {
  merchant?: string | Merchant;
  merchantName?: string;
  reward?: string | Reward;
  rewardName?: string;
}
