<mat-button-toggle-group
  name="fontStyle"
  aria-label="Font Style"
  disableRipple="true"
>
  <mat-button-toggle
    *ngFor="let type of options"
    [checked]="selectedType.toLowerCase() === type.value"
    [value]="type.value"
    (change)="selectType.emit(type.value)"
    ><mat-icon *ngIf="type.icon" style="margin-right: 5px">{{
      type.icon
    }}</mat-icon
    ><span [innerHTML]="type.label"></span
  ></mat-button-toggle>
</mat-button-toggle-group>
