import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'nfc-nickname-dialog',
  templateUrl: './nfc-nickname.component.html',
  styleUrls: ['./nfc-nickname.component.css'],
})
export class NFCNicknameDialogComponent implements OnInit {
  form!: FormGroup;
  nicknameFormControl!: FormControl;
  loading = false;
  error = false;

  constructor(
    public matDialogRef: MatDialogRef<NFCNicknameDialogComponent>,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.nicknameFormControl = new FormControl({
      value: this.data.nickname ?? '',
      disabled: false,
    });

    this.form = this.fb.group({
      nickname: this.nicknameFormControl,
    });
  }

  primaryTapped() {
    if (this.form?.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const newNickname = this.nicknameFormControl.value;

    if (this.data.nickname !== newNickname) {
      this.matDialogRef.close({
        nickname: newNickname,
        changed: true,
      });
    } else {
      this.matDialogRef.close({ changed: false });
    }
  }
}
