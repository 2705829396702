import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'nfc-options-container',
  templateUrl: './nfc-options-container.component.html',
  styleUrls: ['./nfc-options-container.component.css'],
})
export class NFCOptionsContainer implements OnInit {
  @Output() optionTapped = new EventEmitter<number>();
  @Input() selected = 0;

  constructor(
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  select(option: number) {
    this.optionTapped.emit(option);
    this.selected = option;
  }
}
