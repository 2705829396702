import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Constants } from 'src/app/app.constants';
import { AnalyticsAbstract } from 'src/app/services/analytics/analytics.abstract';
import { MerchantService, AuthService } from '../../../services/index';

@Component({
  selector: 'referrals',
  templateUrl: './referrals.component.html',
  styleUrls: ['./referrals.component.css'],
})
export class ReferralsComponent {
  @Input() smallTitle = false;
  @Input() title = '';
  @Input() subtitle: string = '';

  error = false;

  maxReferrals: number[] = [];
  usedReferrals: any[] = [];
  referralsLoading = false;

  constructor(
    public constants: Constants,
    public authService: AuthService,
    private merchantService: MerchantService,
    public dialog: MatDialog,
    private mixpanel: AnalyticsAbstract
  ) {
    this.maxReferrals = Array.from({ length: 12 }, (_, index) => index + 1);
  }

  ngOnInit() {
    this.subtitle = this.authService.getNestedUserProperty('merchant', 'name');
    this.getReferrals();
  }

  getReferrals(): void {
    this.referralsLoading = true;
    this.merchantService
      .getReferrals()
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.usedReferrals = res;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.referralsLoading = false));
  }

  ourWebsite(): void {
    this.mixpanel.track(Constants.analytics_keys.ourWebsiteFromReferral);
  }

  tooltip(item: any) {
    return `You referred ${item?.referee?.name} on ${new Date(
      item?.createdAt
    )?.toLocaleDateString('en-GB')}`;
  }
}
