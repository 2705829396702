import { Location, Merchant } from './index';

export interface NFCCard {
  nanoid?: string;
  _id?: string;
  ref?: string;
  active?: boolean;
  nickname?: string;
  location?: string | Location;
  merchant?: string | Merchant;
  count?: number;
  createdAt?: Date;
  updatedAt?: Date;
}
