<dialog-header
  title="Conversation Settings"
  (onClose)="matDialogRef.close()"
></dialog-header>

<div
  mat-dialog-content
  style="
    padding-bottom: 20px;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    background-color: var(--app-container);
  "
>
  <div
    class="form-field"
    style="margin-top: 20px; display: flex; justify-content: space-between"
  >
    <div class="form-field-label">
      Customers can message me<span>
        <info-icon
          [text]="
            'Control whether customers can message you from the Remy Rewards customer app'
          "
        ></info-icon>
      </span>
    </div>
    <mat-slide-toggle
      [disabled]="false"
      [checked]="canBeMessaged"
      (change)="canBeMessaged = !canBeMessaged"
    >
    </mat-slide-toggle>
  </div>

  <div
    class="form-field"
    style="margin-top: 20px; display: flex; justify-content: space-between"
  >
    <div class="form-field-label">
      Email Notifications<span>
        <info-icon
          [text]="
            'Control whether you receive email notifications when customers message you'
          "
        ></info-icon>
      </span>
    </div>

    <mat-slide-toggle
      [disabled]="false"
      [checked]="emailNotifications"
      (change)="emailNotifications = !emailNotifications"
    >
    </mat-slide-toggle>
  </div>

  <form [formGroup]="form">
    <div class="form-field" style="margin-top: 20px">
      <div class="form-field-label">
        Info Text<span>
          <info-icon
            [text]="'This will be displayed in the customer\'s chat window'"
          ></info-icon>
        </span>
      </div>
      <textarea
        class="form-input"
        autocomplete="off"
        [formControl]="infoTextFormControl"
        placeholder="e.g. office hours or what the chat feature can or cannot be used for"
        type="text"
        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
        [maxlength]="200"
        [rows]="3"
      ></textarea>
    </div>
  </form>
</div>

<div mat-dialog-actions align="end">
  <app-primary-button
    [title]="'Save'"
    (tapped)="saveSettings()"
    bgColor="var(--app-timber)"
    color="white"
    class="send-button"
  ></app-primary-button>
</div>

<loader [loading]="saving || loading"></loader>
