import { Pipe, PipeTransform } from '@angular/core';
import { Conversation } from '../../../models/index';

@Pipe({
  name: 'conversationMenuOptions',
  pure: true,
})
export class ConversationMenuOptionsPipe implements PipeTransform {
  transform(conversation: Conversation, constants: any): any[] {
    if (conversation.blockedByMerchant) {
      return [
        {
          title: constants.strings.unblock,
          icon: 'block',
        },
      ];
    } else if (conversation.muted?.includes(conversation.merchant as string)) {
      return [
        {
          title: constants.strings.block,
          icon: 'block',
        },
        {
          title: constants.strings.unmute,
          icon: 'notifications',
        },
      ];
    } else {
      return [
        {
          title: constants.strings.block,
          icon: 'block',
        },
        {
          title: constants.strings.mute,
          icon: 'notifications_off',
        },
      ];
    }
  }
}
