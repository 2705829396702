import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from 'src/app/app.constants';
import { AuthService, RewardService } from '../../../services/index';
import { Reward } from '../../../models/index';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.css'],
})
export class RewardsComponent {
  @Input() smallTitle = false;
  @Input() title = '';
  @Input() subtitle: string = '';
  @ViewChild('myTable') table: any;

  pageIndex = 0;
  pageSize = 10;
  length = 0;

  rewards: Reward[] = [];
  rewardsLoading = false;
  suppressPaging: boolean = false;
  scrollTimeout: any;
  error = false;
  rewardRange = '';
  locationCount = 1;
  permissionToCreateReward = false;

  constructor(
    public constants: Constants,
    private router: Router,
    private authService: AuthService,
    private rewardService: RewardService,
    private ps: PermissionsService
  ) {
    this.permissionToCreateReward = this.ps.permissions.reward.create();
  }

  ngOnInit() {
    this.subtitle = this.authService.getNestedUserProperty('merchant', 'name');
    this.getRewards();
  }

  get emptyString(): string {
    return `No ${this.constants.strings.rewards} to display`;
  }

  pageEvent(event: any) {
    if (!this.suppressPaging) {
      this.pageIndex = event.offset;
      this.getRewards();
    }
  }

  rowTappedNGX({ row, type }: any) {
    if (type === 'click') {
      this.router.navigate([Constants.routes.reward + '/' + row.nanoid]);
    }
  }

  newReward(): void {
    this.router.navigate([Constants.routes.reward]);
  }

  getRewards(): void {
    this.rewardsLoading = true;
    this.rewardService
      .getRewards(this.pageSize, this.pageIndex)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.length = res.totalDocs;
            this.rewards = res.docs;
            this.locationCount = res.locations;

            this.rewardRange = this.constants.pageCounter(
              this.pageIndex,
              this.pageSize,
              this.length,
              this.rewards?.length
            );
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.rewardsLoading = false));
  }

  get loading(): boolean {
    return this.rewardsLoading;
  }

  handleScroll() {
    this.suppressPaging = true;

    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }

    this.scrollTimeout = setTimeout(() => {
      this.suppressPaging = false;
    }, 100);
  }
}
