<dialog-header title="Address" (onClose)="matDialogRef.close()"></dialog-header>

<div
  mat-dialog-content
  style="
    padding-bottom: 20px;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    background-color: var(--app-container);
  "
>
  <form [formGroup]="form" style="margin-top: 20px">
    <div class="form-field" style="margin-top: 20px">
      <single-select
        label="What best describes your location?"
        subtitle="Why are we asking you this question?"
        (subtitleTapped)="showInfoDialog()"
        [formCtrl]="locationTypeFormControl"
        [options]="options"
        property="name"
        [value]="locationTypeFormControl.value"
        (selected)="selectedLocationType($event)"
      ></single-select>
    </div>

    <div class="form-field" *ngIf="locationTypeSelected">
      <single-select
        label="Country"
        *ngIf="countries?.length"
        [formCtrl]="countryFormControl"
        [options]="countries"
        property="name"
        [value]="countryFormControl.value"
        (selected)="selectedCountry($event)"
        [showSearch]="true"
      ></single-select>
    </div>

    <div *ngIf="locationTypeSelected && locationType === 1 && countrySelected">
      <div class="form-field">
        <div class="form-field-label">
          {{ postcodeLabel }}<span style="color: red">*</span
          ><span
            ><info-icon
              text="We need your postcode so we can display your general location on the map, we will only display your Town and Country in the app, not your postcode or precise location"
            ></info-icon
          ></span>
        </div>
        <div class="input-with-button">
          <input
            class="form-input"
            autocomplete="off"
            [formControl]="postcodeFormControl"
            type="text"
          />
          <button class="suffix-button" mat-button (click)="postCodeChanged()">
            Search
          </button>
        </div>
      </div>
      <div class="form-field" style="margin-top: 20px" *ngIf="postcodeValid">
        <div class="form-field-label">
          {{ cityLabel }}<span style="color: red">*</span>
        </div>
        <input
          class="form-input"
          autocomplete="off"
          [formControl]="cityFormControl"
          type="text"
        />
      </div>
    </div>

    <div
      class="form-field"
      *ngIf="countries?.length && countrySelected && locationType === 0"
    >
      <AutocompleteComponent
        (setAddress)="getEstablishmentAddress($event)"
        adressType="establishment"
        [country]="countryCode"
        [locationName]="data.locationName ?? ''"
      ></AutocompleteComponent>
    </div>

    <div *ngIf="addressFetched && locationType === 0">
      <div class="row">
        <div class="col-lg-6">
          <div class="form-field" style="margin-top: 20px">
            <div class="form-field-label">
              First Line<span style="color: red">*</span>
            </div>
            <input
              class="form-input"
              autocomplete="off"
              [formControl]="lineOneFormControl"
              type="text"
            />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-field" style="margin-top: 20px">
            <div class="form-field-label">Second Line</div>
            <input
              class="form-input"
              autocomplete="off"
              [formControl]="lineTwoFormControl"
              type="text"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <div class="form-field" style="margin-top: 20px">
            <div class="form-field-label">
              {{ postcodeLabel }}<span style="color: red">*</span>
            </div>
            <input
              class="form-input"
              autocomplete="off"
              [formControl]="postcodeFormControl"
              type="text"
              style="cursor: not-allowed"
              [matTooltip]="disabledTooltip(postcodeLabel)"
            />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-field" style="margin-top: 20px">
            <div class="form-field-label">
              {{ cityLabel }}<span style="color: red">*</span>
            </div>
            <input
              class="form-input"
              autocomplete="off"
              [formControl]="cityFormControl"
              type="text"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      class="form-field"
      style="margin-top: 20px"
      *ngIf="
        locationTypeSelected &&
        countrySelected &&
        (postcodeValid || addressFetched)
      "
    >
      <div style="width: 100%">
        <div class="form-field-label" style="margin-bottom: 0px">
          What3Words
        </div>

        <link-text
          text="Find my what3words"
          url="https://what3words.com"
        ></link-text>

        <div
          style="
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
          "
        >
          <div
            class="form-field-label"
            style="width: 30px; padding-right: 10px; margin: 0px !important"
          >
            ///
          </div>
          <input
            class="form-input"
            autocomplete="off"
            [formControl]="whatThreeWordsFormControl"
            type="text"
          />
        </div>
      </div>
    </div>
  </form>
</div>

<div mat-dialog-actions align="end">
  <app-secondary-button
    title="Cancel"
    [disabled]="false"
    (tapped)="matDialogRef.close()"
    style="margin-right: 10px"
  ></app-secondary-button>
  <app-primary-button
    title="Save"
    [disabled]="false"
    (tapped)="primaryTapped()"
    bgColor="var(--app-timber)"
    color="white"
  ></app-primary-button>
</div>

<loader [loading]="postcodeSearching"></loader>
