<div class="container-fluid">
  <div class="row">
    <div class="col-md-12" id="left-col">
      <div class="left-content">
        <div class="form-container">
          <div class="img-container">
            <a href="https://remyrewards.co.uk"
              ><img src="../assets/remylogo.png"
            /></a>
          </div>

          <div class="form-field" style="margin-top: 20px">
            <div class="form-field-label" style="color: var(--app-timber)">
              {{
                "Please confirm your email by clicking on the confirmation link sent to "
              }}<b>{{ email }}</b>
            </div>
          </div>
          <div class="action-buttons" style="margin-top: 20px">
            <app-primary-button
              [title]="'Back to Sign in'"
              [disabled]="false"
              (tapped)="login()"
              bgColor="var(--app-timber)"
              color="white"
            ></app-primary-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
