import { Component, Input } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Constants } from 'src/app/app.constants';
import { SocialDialogComponent } from '../social-dialog/social-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AddressDialogComponent } from 'src/app/shared/components/address-dialog/address-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints,
} from '@angular/cdk/layout';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

import {
  Country,
  Merchant,
  OpeningTimes,
  Address,
  Location,
} from '../../../models/index';
import {
  StorageService,
  AuthService,
  LocationService,
  MerchantService,
} from '../../../services/index';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'location-detail',
  templateUrl: './location-detail.component.html',
  styleUrls: ['./location-detail.component.css'],
})
export class LocationDetailComponent {
  gmt = true;
  @Input() smallTitle = false;
  @Input() title = '';
  @Input() subtitle: string = '';

  tags: { name: string }[] = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  options = [
    {
      value: this.constants.locationLogo.merchant,
      label: 'Use Merchant logo for this location',
    },
    {
      value: this.constants.locationLogo.location,
      label: 'Use Custom logo for this location',
    },
  ];

  selectedLogoOption: string = this.constants.locationLogo.merchant;
  form!: FormGroup;
  nameFormControl: FormControl = new FormControl();
  descriptionFormControl: FormControl = new FormControl();
  addressFormControl: FormControl = new FormControl();
  emailFormControl: FormControl = new FormControl();
  phoneFormControl: FormControl = new FormControl();
  websiteFormControl: FormControl = new FormControl();

  ssidFormControl: FormControl = new FormControl();
  wifiFormControl: FormControl = new FormControl();

  searchFormControl: FormControl = new FormControl();

  showCustomBrandingOption = false;
  socials: any[] = [];
  location: Location = {};
  address?: Address;

  selectedCover?: [{ name: string; content: ArrayBuffer; mime: string }];
  selectedLogo?: [{ name: string; content: ArrayBuffer; mime: string }];

  initialCoverUrl?: string | null;
  initialLogoUrl?: string | null;
  searchActive = false;
  saving = false;
  openingTimes = [
    {
      fromControl: new FormControl(
        { value: '08:00', disabled: false },
        Validators.required
      ),
      toControl: new FormControl(
        { value: '18:00', disabled: false },
        Validators.required
      ),
      day: 'Monday',
      label: 'Mon',
      active: true,
    },
    {
      fromControl: new FormControl(
        { value: '08:00', disabled: false },
        Validators.required
      ),
      toControl: new FormControl(
        { value: '18:00', disabled: false },
        Validators.required
      ),
      day: 'Tuesday',
      label: 'Tue',
      active: true,
    },
    {
      fromControl: new FormControl(
        { value: '08:00', disabled: false },
        Validators.required
      ),
      toControl: new FormControl(
        { value: '18:00', disabled: false },
        Validators.required
      ),
      day: 'Wednesday',
      label: 'Wed',
      active: true,
    },
    {
      fromControl: new FormControl(
        { value: '08:00', disabled: false },
        Validators.required
      ),
      toControl: new FormControl(
        { value: '18:00', disabled: false },
        Validators.required
      ),
      day: 'Thursday',
      label: 'Thu',
      active: true,
    },
    {
      fromControl: new FormControl(
        { value: '08:00', disabled: false },
        Validators.required
      ),
      toControl: new FormControl(
        { value: '18:00', disabled: false },
        Validators.required
      ),
      day: 'Friday',
      label: 'Fri',
      active: true,
    },
    {
      fromControl: new FormControl(
        { value: '08:00', disabled: false },
        Validators.required
      ),
      toControl: new FormControl(
        { value: '18:00', disabled: false },
        Validators.required
      ),
      day: 'Saturday',
      label: 'Sat',
      active: true,
    },
    {
      fromControl: new FormControl(
        { value: '08:00', disabled: false },
        Validators.required
      ),
      toControl: new FormControl(
        { value: '18:00', disabled: false },
        Validators.required
      ),
      day: 'Sunday',
      label: 'Sun',
      active: true,
    },
  ];

  altOpeningTimes = [
    {
      fromControl: new FormControl(
        { value: '08:00', disabled: false },
        Validators.required
      ),
      toControl: new FormControl(
        { value: '18:00', disabled: false },
        Validators.required
      ),
      day: 'Monday',
      label: 'Mon',
      active: true,
    },
    {
      fromControl: new FormControl(
        { value: '08:00', disabled: false },
        Validators.required
      ),
      toControl: new FormControl(
        { value: '18:00', disabled: false },
        Validators.required
      ),
      day: 'Tuesday',
      label: 'Tue',
      active: true,
    },
    {
      fromControl: new FormControl(
        { value: '08:00', disabled: false },
        Validators.required
      ),
      toControl: new FormControl(
        { value: '18:00', disabled: false },
        Validators.required
      ),
      day: 'Wednesday',
      label: 'Wed',
      active: true,
    },
    {
      fromControl: new FormControl(
        { value: '08:00', disabled: false },
        Validators.required
      ),
      toControl: new FormControl(
        { value: '18:00', disabled: false },
        Validators.required
      ),
      day: 'Thursday',
      label: 'Thu',
      active: true,
    },
    {
      fromControl: new FormControl(
        { value: '08:00', disabled: false },
        Validators.required
      ),
      toControl: new FormControl(
        { value: '18:00', disabled: false },
        Validators.required
      ),
      day: 'Friday',
      label: 'Fri',
      active: true,
    },
    {
      fromControl: new FormControl(
        { value: '08:00', disabled: false },
        Validators.required
      ),
      toControl: new FormControl(
        { value: '18:00', disabled: false },
        Validators.required
      ),
      day: 'Saturday',
      label: 'Sat',
      active: true,
    },
    {
      fromControl: new FormControl(
        { value: '08:00', disabled: false },
        Validators.required
      ),
      toControl: new FormControl(
        { value: '18:00', disabled: false },
        Validators.required
      ),
      day: 'Sunday',
      label: 'Sun',
      active: true,
    },
  ];

  alternativeTimesEdited = false;
  editMode: boolean = false;
  planLimitsLoading = false;
  locationLoading: boolean = false;
  error: boolean = false;
  canCreateLocation = false;
  canEditLocation = false;
  submitted = false;
  countryCodes: Country[] = [];
  filteredCountryCodes: Country[] = [];
  selectedCountry?: Country;

  constructor(
    public constants: Constants,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private locationService: LocationService,
    private authService: AuthService,
    private merchantService: MerchantService,
    private breakpointObserver: BreakpointObserver,
    private storageService: StorageService,
    private http: HttpClient,
    private ps: PermissionsService
  ) {
    this.canEditLocation = this.ps.permissions.location.edit();

    this.http
      .get<any>('../../../../assets/json/country-codes.json')
      .subscribe((data: any[]) => {
        this.countryCodes = data.map((el) => {
          return {
            ...el,
            dialCode: el.dialCodes?.[0],
          };
        });

        this.filteredCountryCodes = this.countryCodes;
      });

    this.route.params.subscribe(
      (params) => (this.location.nanoid = params['id'])
    );

    if (this.location.nanoid) {
      this.editMode = true;
      this.getLocation();
    } else {
      if (typeof this.authService.getUserProperty('merchant') != 'string') {
        const merchant = this.authService.getUserProperty(
          'merchant'
        ) as Merchant;
        if (merchant) {
          this.setCustomBrandingOption(merchant);
        }
      }
    }

    if (!this.editMode) {
      this.getPlanLimits();
    } else {
      this.canCreateLocation = true;
    }

    this.nameFormControl = new FormControl(
      { value: '', disabled: false },
      Validators.required
    );
    this.descriptionFormControl = new FormControl(
      { value: '', disabled: false },
      Validators.required
    );
    this.websiteFormControl = new FormControl({ value: '', disabled: false });
    this.addressFormControl = new FormControl(
      { value: '', disabled: false },
      Validators.required
    );
    this.emailFormControl = new FormControl({ value: '', disabled: false }, [
      Validators.email,
    ]);
    this.phoneFormControl = new FormControl({ value: '', disabled: false });
    this.wifiFormControl = new FormControl({ value: '', disabled: false });
    this.ssidFormControl = new FormControl({ value: '', disabled: false });

    this.form = this.fb.group({
      name: this.nameFormControl,
      description: this.descriptionFormControl,
      address: this.addressFormControl,
      email: this.emailFormControl,
      phone: this.phoneFormControl,
      website: this.websiteFormControl,
      wifi: this.wifiFormControl,
      ssid: this.ssidFormControl,
    });
  }

  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result: BreakpointState) => {
        return result.matches;
      })
    );

  ngOnInit() {
    this.subtitle = this.authService.getNestedUserProperty('merchant', 'name');
  }

  getPlanLimits(): void {
    this.planLimitsLoading = true;
    this.merchantService
      .getPlanLimits()
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.canCreateLocation = res.locations > 0;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.planLimitsLoading = false));
  }

  getLocation(): void {
    this.locationLoading = true;
    this.locationService
      .getLocation(this.location.nanoid!)
      .subscribe({
        next: (res: Location) => {
          if (res) {
            this.location = res;
            this.nameFormControl.setValue(res.name);
            this.descriptionFormControl.setValue(res.description);
            this.emailFormControl.setValue(res.contact?.email);
            this.phoneFormControl.setValue(res.contact?.phone);
            this.websiteFormControl.setValue(res.contact?.website);
            this.address = res.address;
            this.addressFormControl.setValue(
              this.constants.constructAddress(res.address!)
            );
            this.tags = (res.tags ?? []).map((el: any) => ({ name: el }));
            this.socials = res.socials!;
            this.wifiFormControl.setValue(res.contact?.wifi);
            this.ssidFormControl.setValue(res.contact?.ssid);
            this.setOpeningTimes(this.openingTimes, this.location.openingTimes);
            this.setOpeningTimes(
              this.altOpeningTimes,
              this.location.altOpeningTimes ?? this.location.openingTimes
            );

            if (this.location.altOpeningTimes) {
              this.alternativeTimesEdited = true;
            }

            this.initialCoverUrl = this.location.cover?.url
              ? this.location.cover?.url + '?' + new Date().getTime()
              : null;

            this.initialLogoUrl = this.location.logo?.url
              ? this.location.logo?.url + '?' + new Date().getTime()
              : null;

            if (this.initialLogoUrl) {
              this.selectedLogoOption = this.constants.locationLogo.location;
            }

            if (typeof this.location?.merchant != 'string') {
              const merchant = this.location.merchant as Merchant;
              if (merchant) {
                this.setCustomBrandingOption(merchant);
              }
            }

            if (!this.location.contact?.telCountry) {
              this.selectedCountry = this.countryCodes[0];
            } else {
              this.selectedCountry = this.countryCodes.find(
                (el: Country) =>
                  el.code === this.location.contact?.telCountry?.code
              );
            }
          }
        },
        error: (res: HttpErrorResponse) => {
          this.router
            .navigate(['/' + Constants.routes.dashboard], {})
            .then(() => {
              window.location.reload();
            });
        },
      })
      .add(() => (this.locationLoading = false));
  }

  setCustomBrandingOption(merchant: Merchant) {
    if (merchant.customBranding) {
      this.showCustomBrandingOption = true;
    } else {
      this.showCustomBrandingOption = false;
    }
  }

  setOpeningTimes(mainOpeningTimes: any[], locationTimes?: OpeningTimes): void {
    const daysMapping: Record<string, keyof OpeningTimes> = {
      Monday: 'monday',
      Tuesday: 'tuesday',
      Wednesday: 'wednesday',
      Thursday: 'thursday',
      Friday: 'friday',
      Saturday: 'saturday',
      Sunday: 'sunday',
    };

    for (const time of mainOpeningTimes) {
      const dayKey = daysMapping[time.day];
      const item = locationTimes?.[dayKey];

      time.active = item?.active ?? false;

      if (item?.start) {
        time.fromControl.setValue(item.start);
      }

      if (item?.end) {
        time.toControl.setValue(item.end);
      }
    }
  }

  toggleDay(day: any, active: boolean) {
    day.active = active;
    this.timeChanged();
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.tags.push({ name: this.constants.capitalizeWords(value).trim() });
    }

    if (input) {
      input.value = '';
    }
  }

  remove(tag: { name: string }): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  openSocialDialog(handle?: string, type?: string): void {
    const dialogRef = this.dialog.open(SocialDialogComponent, {
      data: {
        exclusions: this.socials.map((el) => el.type),
        edit: {
          handle,
          type,
        },
      },
      width: '450px',
      autoFocus: false,
      disableClose: true,
      scrollStrategy: new NoopScrollStrategy(),
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        const index = this.socials.findIndex(
          (el) => (el.type?.name ?? el.type) === (data.type.name ?? data.type)
        );

        if (index !== -1) {
          this.socials[index].handle = data.handle;
        } else {
          this.socials.push(data);
        }
      }
    });
  }

  removeSocial(item: any): void {
    const index = this.socials.findIndex((el) => el === item);
    if (index !== -1) {
      this.socials.splice(index, 1);
    }
  }

  buildOpeningTimes(openingTimes: any[]): any[] {
    const times: any[] = [];

    for (const time of openingTimes) {
      if (time.active && (!time.fromControl?.value || !time.toControl?.value)) {
        return [];
      }

      const openingTime: any = {};
      openingTime[time.day] = {
        start: time.fromControl?.value || '',
        end: time.toControl?.value || '',
        active: time.active || false,
      };
      times.push(openingTime);
    }

    return times;
  }

  timeChanged(): void {
    if (!this.gmt) {
      //Alternative time changed
      this.alternativeTimesEdited = true;
    }
  }

  save(): void {
    this.submitted = true;

    if (this.form.invalid) {
      this.constants.snack('Please complete all required fields');
      return;
    }

    let times = this.buildOpeningTimes(this.openingTimes);
    let altOpeningTimes: any[] = this.buildOpeningTimes(
      this.alternativeTimesEdited ? this.altOpeningTimes : this.openingTimes
    );

    if (times.length === 0) {
      return;
    }

    if (altOpeningTimes.length === 0) {
      altOpeningTimes = times;
    }

    const payload = {
      name: this.nameFormControl.value?.trim(),
      description: this.descriptionFormControl.value?.trim(),
      socials: this.socials.map((el) => ({
        handle: el.handle,
        type: el.type.name ?? el.type,
        link: this.buildLink(el.handle, el.type.name ?? el.type),
      })),
      openingTimes: times,
      altOpeningTimes: altOpeningTimes,
      contact: {
        email: this.emailFormControl.value?.trim(),
        phone: this.phoneFormControl.value?.toString().trim(),
        website: this.websiteFormControl.value?.trim()?.toLowerCase(),
        wifi: this.wifiFormControl.value?.trim(),
        ssid: this.ssidFormControl.value?.trim(),
        telCountry: this.selectedCountry,
      },
      address: this.address,
      tags: this.tags.map((el) => el.name),
      cover: this.selectedCover?.[0],
      logo: {},
      id: this.location._id,
      customLogo:
        this.selectedLogoOption === this.constants.locationLogo.location,
    };

    if (
      this.selectedLogoOption === this.constants.locationLogo.location &&
      (this.selectedLogo?.length || this.location?.logo)
    ) {
      payload.logo = this.selectedLogo?.[0] ?? this.location.logo ?? {};
    }

    this.saving = true;

    if (this.editMode) {
      this.submit(this.locationService.editLocation(payload), false);
    } else {
      this.submit(this.locationService.createLocation(payload), true);
    }
  }

  get tooltip(): string {
    if (this.loading) {
      return '';
    }
    if (!this.canCreateLocation) {
      return this.constants.strings.upgradePlanNotice;
    } else {
      return '';
    }
  }

  submit(observable: Observable<any>, isNew: boolean): void {
    observable
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.router.navigate([Constants.routes.locations]).then(() => {
              if (isNew) {
                const existing = this.authService.getNestedUserProperty(
                  'merchant',
                  'onboard'
                );
                if (existing) {
                  this.authService.updateNestedProperty('merchant', 'onboard', {
                    location: true,
                    reward: existing.reward ?? false,
                    card: existing.card ?? false,
                  });
                  this.storageService.set('checklistVisible', String(true));
                }
                window.location.reload();
              }
            });
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  buildLink(handle: string, type: string) {
    handle = handle.replace('@', '')?.toLowerCase();

    if (type === 'Facebook') {
      return `https://facebook.com/${handle}`;
    } else if (type === 'Instagram') {
      return `https://instagram.com/${handle}`;
    } else if (type === 'TikTok') {
      return `https://tiktok.com/@${handle}`;
    } else {
      return `https://pinterest.com/${handle}`;
    }
  }

  openAddressDialog() {
    const dialogRef = this.dialog.open(AddressDialogComponent, {
      data: {
        title: 'Address',
        property: 'name',
        address: this.location?.address,
        editMode: false,
        locationName: this.nameFormControl.value,
      },
      minWidth: '600px',
      maxHeight: '85vh',
      autoFocus: false,
      disableClose: true,
      panelClass: 'custom-dialog',
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.address = result;
        if (this.location) {
          this.location.address = result;
        }

        this.addressFormControl.setValue(
          this.constants.constructAddress(result)
        );

        const foundCountryCode = this.countryCodes.find(
          (el) => el.name === this.location.address?.country
        );
        if (foundCountryCode && !this.editMode) {
          this.selectedCountry = foundCountryCode;
        }

        if (!this.editMode) {
          if (result?.name && !this.nameFormControl.value) {
            this.nameFormControl.setValue(result.name);
          }
          if (result?.website && !this.websiteFormControl.value) {
            this.websiteFormControl.setValue(result.website);
          }
          if (result?.phoneNumber && !this.phoneFormControl.value) {
            const phoneWithoutCode = result.phoneNumber.replace(
              /^\+\d+\s?/,
              ''
            );
            this.phoneFormControl.setValue(phoneWithoutCode);
          }
        }
      }
    });
  }

  get loading(): boolean {
    return this.planLimitsLoading || this.locationLoading || this.saving;
  }

  back() {
    this.router.navigate([Constants.routes.locations]);
  }

  openQRCode() {
    window.open(this.location?.qrCode?.url, '_blank');
  }

  onSelectCountryCode(country: Country): void {
    this.selectedCountry = country;

    setTimeout(() => {
      this.searchActive = false;
      this.searchFormControl.setValue('');
    }, 200);
  }

  onTypeSearch(): void {
    let searchTerm = this.searchFormControl.value;
    this.searchActive = searchTerm !== '';

    if (this.searchActive) {
      this.filteredCountryCodes = this.countryCodes.filter((el) =>
        el.name.includes(searchTerm)
      );
    }
  }

  get timezoneLabel(): string {
    return this.gmt ? 'GMT' : 'BST';
  }
}
