import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class InsightsService {
  constructor(private api: ApiService) {}

  PATH = 'insights';

  getDashboardInsights() {
    return this.api.get(`v1/${this.PATH}/dashboard`);
  }

  getConversionFunnel() {
    return this.api.get(`v1/${this.PATH}/customer-funnel`);
  }

  getCustomerStats(days: number) {
    let query = `?days=${days}`;
    return this.api.get(`v1/${this.PATH}/customers` + query);
  }

  getHourlyDistribution(days: number, type: string, customer?: string) {
    let query = `?days=${days}&type=${type}`;

    if (customer) {
      query += `&customer=${customer}`;
    }

    return this.api.get(`v1/${this.PATH}/hourly` + query);
  }

  getWeeklyCards(days: number) {
    let query = `?days=${days}`;

    return this.api.get(`v1/${this.PATH}/weekly-cards` + query);
  }

  getWeeklyStamps(days: number, customer?: string, points?: boolean) {
    let query = `?days=${days}`;

    if (customer) {
      query += `&customer=${customer}`;
    }

    if (points) {
      query += `&points=${points}`;
    }

    return this.api.get(`v2/${this.PATH}/weekly-stamps` + query);
  }

  getWeeklyRewards(days: number, customer?: string) {
    let query = `?days=${days}`;

    if (customer) {
      query += `&customer=${customer}`;
    }

    return this.api.get(`v1/${this.PATH}/weekly-rewards` + query);
  }

  getWeeklyRedemptions(days: number, customer?: string) {
    let query = `?days=${days}`;

    if (customer) {
      query += `&customer=${customer}`;
    }

    return this.api.get(`v2/${this.PATH}/weekly-redemptions` + query);
  }

  getRedemptionRate() {
    return this.api.get(`v1/${this.PATH}/redemption-rate`);
  }
}
