<div class="container">
  <div class="row">
    <!-- Left Column -->
    <div class="col-lg-6" id="left" (click)="select(0)">
      <div
        id="inner-left"
        [ngStyle]="{
          'border-color':
            selected === 0 ? 'var(--app-timber)' : 'var(--app-light-grey)',
          'border-width': selected === 0 ? '2px' : '1px'
        }"
      >
        <img src="../../../../assets/template-white.png" />
      </div>
    </div>
    <!-- Right Column -->
    <div class="col-lg-6" id="right" (click)="select(1)">
      <div
        id="inner-right"
        [ngStyle]="{
          'border-color':
            selected === 1 ? 'var(--app-timber)' : 'var(--app-light-grey)',
          'border-width': selected === 1 ? '2px' : '1px'
        }"
      >
        <img src="../../../../assets/template-black.png" />
      </div>
    </div>
  </div>
</div>
