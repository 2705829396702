import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button-toggle-group',
  templateUrl: './button-toggle-group.component.html',
  styleUrls: ['./button-toggle-group.component.css'],
})
export class ButtonToggleGroupComponent implements OnInit {
  @Input() typeOptions = [];
  @Input() selectedType = '';
  @Input() options: { value: string; icon?: string; label: string }[] = [];
  @Output() selectType: EventEmitter<string> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
