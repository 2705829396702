<page-header
  [title]="constants.strings.help"
  [subtitle]="subtitle"
></page-header>

<div style="margin-top: 20px" *ngIf="!guidesLoading">
  <div
    style="
      background-color: var(--app-container);
      border-radius: 10px;
      padding: 20px;
    "
  >
    <p
      class="section-title"
      style="
        font-weight: normal;
        color: grey;
        font-size: 14px;
        margin-bottom: 0px;
      "
    >
      Still have questions?
      <span
        ><a
          target="_blank"
          href="https://remy-rewards.notion.site/remy-rewards/Remy-FAQ-4e083f6284f6499691b92bdb8885a329"
          >FAQs</a
        ></span
      >
    </p>

    <p
      class="section-title"
      style="
        margin-bottom: 0px;
        font-weight: normal;
        color: grey;
        font-size: 14px;
      "
    >
      Email:
      <span
        ><a target="_blank" href="mailto:hello@remyrewards.co.uk"
          >hello@remyrewards.co.uk</a
        ></span
      >
    </p>

    <p
      class="section-title"
      style="
        margin-bottom: 20px;
        font-weight: normal;
        color: grey;
        font-size: 14px;
      "
    >
      Whatsapp support:
      <span
        ><a
          target="_blank"
          href="https://api.whatsapp.com/send?phone=447874475624"
          >+447874475624</a
        ></span
      >
    </p>
    <div class="thumbnail-container">
      <div
        class="thumbnail"
        *ngFor="let guide of guides; let i = index"
        (click)="open(guide)"
      >
        <div class="img-container">
          <img [src]="guide.imageUrl" [alt]="guide.title" />
        </div>

        <div class="thumbnail-title">
          {{ guide.title }}
        </div>
        <div class="thumbnail-description">
          {{ guide?.description }}
        </div>
      </div>
    </div>
  </div>
</div>

<loader [loading]="loading"></loader>
