import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class GeocodingService {
  constructor(private http: HttpClient) {}

  getCoordinates(postcode: string, countryCode: string): Observable<any> {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${postcode}&components=country:${countryCode}&key=${environment.googleMapsApiKey}`;
    return this.http.get(url);
  }

  getPlaceDetails(placeId: string) {
    const url = `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&fields=name,rating,formatted_phone_number,formatted_address,geometry,website&key=${environment.googleMapsApiKey}`;
    return this.http.get(url);
  }
}
