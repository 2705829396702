<div style="margin-bottom: 10px; display: flex"></div>

<canvas
  class="bar-canvas"
  baseChart
  [data]="barChartData"
  [options]="barChartOptions"
  [plugins]="barChartPlugins"
  [legend]="barChartLegend"
  [type]="chartType === 'bar' ? 'bar' : 'line'"
>
</canvas>

<p style="margin-bottom: 0px; font-size: 0px">{{ " " }}</p>
