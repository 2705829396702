import { User, Merchant, StampCard, Reward, Customer } from './index';

export interface Redemption {
  id: string;
  nanoid: string;
  card: string | StampCard;
  user: string | User;
  customer: string | Customer;
  merchant: string | Merchant;
  reward: string | Reward;
}
