import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from 'src/app/app.constants';
import { AnimationOptions } from 'ngx-lottie';
import { AuthService } from '../../../services/index';
import { Subscription } from 'rxjs';

@Component({
  selector: 'verify',
  templateUrl: 'verify.component.html',
  styleUrls: ['./verify.component.css'],
})
export class VerifyComponent implements OnInit {
  options: AnimationOptions = { path: '/assets/rewards.json', loop: false };

  isMobile = false;
  verified = false;
  loading = false;
  verifyEmailSub!: Subscription;
  pageLoading = true;
  @HostListener('window:resize', ['$event'])
  onResize = () => this.testMobile();
  testMobile = () => (this.isMobile = window.innerWidth < 768 ? true : false);

  constructor(
    public constants: Constants,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    this.route.queryParams.subscribe((params) => {
      const token = params['token'];
      if (token) {
        this.verifyToken(token);
      } else {
        this.verified = false;
      }
    });
  }

  ngOnInit() {
    this.testMobile();

    setTimeout(() => {
      this.pageLoading = false;
    }, 500);
  }

  verifyToken(token: string): void {
    this.loading = true;

    this.verifyEmailSub = this.authService
      .getVerifyEmailListener()
      .subscribe((result) => {
        this.verifyEmailSub.unsubscribe();
        this.loading = false;
        if (result) {
          this.verified = true;
        } else {
          this.verified = false;
        }
      });
    this.authService.verifyToken(token);
  }

  login() {
    this.router.navigate([Constants.routes.login]);
  }
}
