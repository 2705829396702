<dialog-header
  [title]="data.title"
  (onClose)="matDialogRef.close()"
></dialog-header>

<div
  mat-dialog-content
  style="
    padding-bottom: 20px;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    background-color: var(--app-container);
  "
>
  <form [formGroup]="form" style="margin-top: 20px">
    <div class="form-field">
      <single-select
        [label]="data.label"
        [formCtrl]="formControl"
        [property]="data.property"
        [options]="options"
        [showIcon]="false"
        [infoText]="data?.infoText"
      ></single-select>
    </div>
  </form>
</div>

<div mat-dialog-actions align="end">
  <app-secondary-button
    title="Cancel"
    [disabled]="false"
    (tapped)="matDialogRef.close()"
    style="margin-right: 10px"
  ></app-secondary-button>
  <app-primary-button
    title="Save"
    [disabled]="false"
    (tapped)="primaryTapped()"
    bgColor="var(--app-timber)"
    color="white"
  ></app-primary-button>
</div>
