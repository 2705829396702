import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Constants } from 'src/app/app.constants';
import { AuthService } from '../../../services/index';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {
  form!: FormGroup;
  pageLoading = true;
  isMobile = false;
  loading = false;

  emailFormControl = new FormControl({ value: '', disabled: false }, [
    Validators.required,
    Validators.email,
  ]);

  @HostListener('window:resize', ['$event'])
  onResize = () => this.testMobile();
  testMobile = () => (this.isMobile = window.innerWidth < 768 ? true : false);

  constructor(
    public constants: Constants,
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: this.emailFormControl,
    });

    setTimeout(() => {
      this.pageLoading = false;
    }, 500);
  }

  forgot(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.loading = true;

    this.authService
      .resetPassword(this.emailFormControl.value!)
      .subscribe({
        next: (res: any) => {
          this.constants.snack(
            'Please check your email for reset instructions'
          );
          this.navigateToLogin();
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message, true);
        },
      })
      .add(() => (this.loading = false));
  }

  navigateToLogin(): void {
    this.router.navigate([Constants.routes.login]);
  }
}
