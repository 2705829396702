<form [formGroup]="form" (ngSubmit)="searchAction()">
  <div
    class="search-container"
    [ngClass]="{
      'search-container-selected': typing == true
    }"
    [ngStyle]="{
      'background-color': backgroundColor
    }"
  >
    <input
      autocomplete="off"
      matInput
      placeholder="Search"
      name="search-input"
      [(ngModel)]="searchTerm"
      [ngModelOptions]="{ standalone: true }"
      (input)="inputChanged($event)"
      [ngStyle]="{
        maxWidth: isMobile ? '100px' : '200px'
      }"
    />
    <button
      *ngIf="!isDirectSearch"
      matSuffix
      mat-button
      (click)="searchButtonTapped()"
      type="button"
    >
      <mat-icon>{{ isSearching ? "close" : "search" }}</mat-icon>
    </button>
    <button
      *ngIf="isDirectSearch"
      matSuffix
      mat-button
      (click)="searchButtonTapped()"
      type="button"
    >
      <mat-icon>{{
        isSearching && isDirectSearch ? "close" : "search"
      }}</mat-icon>
    </button>
  </div>
</form>
