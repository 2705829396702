<page-header [title]="'Choose a Plan'" [subtitle]="subtitle"></page-header>

<div class="row" *ngIf="!loading">
  <div class="col-lg-12">
    <div class="detail-container">
      <div class="row">
        <div
          class="col-md-6"
          [ngStyle]="{
            'text-align': (isHandset$ | async) ? 'center' : 'left',
            'margin-bottom': (isHandset$ | async) ? '10px' : '0px'
          }"
        >
          <app-button-toggle-group
            [selectedType]="currency"
            (selectType)="toggleCurrency($event)"
            [options]="options"
          ></app-button-toggle-group>
        </div>
        <div
          class="col-md-6"
          [ngStyle]="{
            'text-align': (isHandset$ | async) ? 'center' : 'right'
          }"
        >
          <app-button-toggle-group
            [selectedType]="basis"
            (selectType)="toggleBasis($event)"
            [options]="typeOptions"
          ></app-button-toggle-group>
        </div>
      </div>

      <!-- <div
        class="form-field"
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <app-button-toggle-group
          [selectedType]="currency"
          (selectType)="toggleCurrency($event)"
          [options]="options"
        ></app-button-toggle-group>

        <app-button-toggle-group
          [selectedType]="basis"
          (selectType)="toggleBasis($event)"
          [options]="typeOptions"
        ></app-button-toggle-group>
      </div> -->

      <div class="row justify-content-center" style="margin-top: 40px">
        <div class="col-xl-4" *ngFor="let plan of plans">
          <plan-card
            [enabled]="!hasFree"
            [plan]="plan"
            [currency]="currency"
            [basis]="basis"
            (buttonTapped)="checkout($event)"
            [loading]="saving || loading"
          ></plan-card>
        </div>
      </div>

      <div class="col-lg-12">
        <div
          class="form-field-label"
          style="
            color: black;
            font-size: 18px;
            margin-bottom: 0px;
            margin-top: 20px;
          "
        >
          My Subscription
        </div>
        <div
          class="form-field-label"
          style="color: black; font-size: 14px; margin-top: 10px"
        >
          {{ "Current Plan: " }}
          <span style="color: var(--app-timber); font-weight: 600">{{
            currentPlan
          }}</span>
        </div>
        <div
          *ngIf="renewalDate && !cancelDate"
          class="form-field-label"
          style="color: black; font-size: 14px; margin-top: 10px"
        >
          {{ "Next Renewal Date: " }}
          <span style="color: var(--app-timber); font-weight: 600">{{
            renewalDate! * 1000 | date : "dd MMMM yy"
          }}</span>
        </div>
        <div
          *ngIf="cancelDate"
          class="form-field-label"
          style="color: black; font-size: 14px; margin-top: 10px"
        >
          {{ "Cancellation Date: " }}
          <span style="color: var(--app-timber); font-weight: 600">{{
            cancelDate! * 1000 | date : "dd MMMM yy"
          }}</span
          ><span style="color: black; font-size: 14px; margin-top: 10px"
            >, you can continue enjoying Remy up to this date, uprade to any of
            our plans to continue thereafter.</span
          >
        </div>
        <div
          *ngIf="hasTrial"
          class="form-field-label"
          style="color: black; font-size: 14px; margin-top: 10px"
        >
          {{ "Your trial ends in " }}
          <span style="color: var(--app-timber); font-weight: 600">{{
            trialEnds + " day" + (trialEnds > 1 ? "s" : "")
          }}</span>
        </div>
      </div>

      <div style="margin-top: 20px" *ngIf="!hasFree">
        <a [href]="portalUrl" target="_blank">
          <button
            mat-flat-button
            class="primary-button"
            (click)="openBillingPortal()"
          >
            My Billing Portal
          </button>
        </a>
      </div>
    </div>
  </div>
</div>

<loader [loading]="loading"></loader>
