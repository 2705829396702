import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Constants } from 'src/app/app.constants';
import { AnalyticsAbstract } from 'src/app/services/analytics/analytics.abstract';
import { TwoOptionAlertComponent } from 'src/app/shared/components/two-option-alert/two-option-alert.component';
import {
  AuthService,
  CardService,
  MerchantService,
} from '../../../services/index';
import { StampCard } from '../../../models/index';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.css'],
})
export class CardsComponent {
  @Input() smallTitle = false;
  @Input() title = '';
  @Input() subtitle: string = '';
  @ViewChild('myTable') table: any;

  pageIndex = 0;
  pageSize = 10;
  length = 0;
  cards: StampCard[] = [];
  cardsLoading = false;
  error = false;
  scrollTimeout: any;
  suppressPaging: boolean = false;
  saving = false;
  cardsRange = '';
  planLimitsLoading = false;
  canCreateCard = false;
  permissionToCreateCard = false;
  locationCount = 1;
  canManageCards = false;

  constructor(
    public dialog: MatDialog,
    public constants: Constants,
    private authService: AuthService,
    private cardService: CardService,
    private router: Router,
    private merchantService: MerchantService,
    private mixpanel: AnalyticsAbstract,
    private ps: PermissionsService
  ) {
    this.canManageCards = this.ps.adminOrMerchant;
    this.permissionToCreateCard = this.ps.permissions.card.create();
  }

  ngOnInit() {
    this.subtitle = this.authService.getNestedUserProperty('merchant', 'name');
    this.getCards();
    this.getPlanLimits();
  }

  get emptyString(): string {
    return `No ${this.constants.strings.stampCards} to display`;
  }

  getPlanLimits(): void {
    this.planLimitsLoading = true;
    this.merchantService
      .getPlanLimits()
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.canCreateCard = res.cards > 0;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.planLimitsLoading = false));
  }

  getCards(): void {
    this.cardsLoading = true;
    this.cardService
      .getCards(this.pageSize, this.pageIndex)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.length = res.totalDocs;
            this.cards = res.docs;
            this.locationCount = res.locations;

            this.cardsRange = this.constants.pageCounter(
              this.pageIndex,
              this.pageSize,
              this.length,
              this.cards?.length
            );

            const titleCounts = this.cards.reduce((acc, card) => {
              if (card.title) {
                acc[card.title] = (acc[card.title] || 0) + 1;
              }
              return acc;
            }, {} as Record<string, number>);

            this.cards.forEach((el) => {
              if (el.title && titleCounts[el.title] > 1) {
                el.title = `${el.title} (#${el.ref})`;
              }
            });
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.cardsLoading = false));
  }

  pageEvent(event: any) {
    if (!this.suppressPaging) {
      this.pageIndex = event.offset;
      this.getCards();
    }
  }

  rowTappedNGX({ row, type }: any) {
    if (type === 'click') {
      this.router.navigate([Constants.routes.card + '/' + row.nanoid]);
    }
  }

  newCard(): void {
    if (!this.canCreateCard) {
      const dialogRef = this.dialog.open(TwoOptionAlertComponent, {
        data: {
          title: 'Extra Card',
          body: `Please upgrade your plan to add more loyalty cards`,
          buttonOne: 'Cancel',
          buttonTwo: 'Upgrade Now',
        },
        scrollStrategy: new NoopScrollStrategy(),
        autoFocus: false,
        width: '350px',
        disableClose: true,
        panelClass: 'custom-dialog',
      });

      dialogRef.afterClosed().subscribe((option) => {
        if (option === 1) {
          this.router.navigate([Constants.routes.plans]);
        }
      });
    } else {
      this.router.navigate([Constants.routes.card]);
    }
  }

  get loading(): boolean {
    return this.cardsLoading || this.planLimitsLoading;
  }

  handleScroll() {
    this.suppressPaging = true;

    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }

    this.scrollTimeout = setTimeout(() => {
      this.suppressPaging = false;
    }, 100);
  }

  get activateTitle() {
    return { title: this.constants.strings.activate, icon: 'check_circle' };
  }

  get deactivateTitle() {
    return { title: this.constants.strings.deactivate, icon: 'cancel' };
  }

  handleMenuOptionSelection(option: string, card: StampCard): void {
    const action =
      option === this.constants.strings.deactivate ? 'Deactivate' : 'Activate';
    const dialogData = this.getDialogData(action);

    const dialogRef = this.dialog.open(TwoOptionAlertComponent, {
      data: dialogData,
      autoFocus: false,
      maxWidth: '500px',
      disableClose: true,
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialogRef.afterClosed().subscribe((selectedOption: number) => {
      if (selectedOption == 1) {
        this.toggleCard(action === 'Activate', card);
      }
    });
  }

  getDialogData(action: string) {
    const isDeactivating = action === 'Deactivate';
    return {
      title: 'Confirm',
      body: isDeactivating
        ? "Deactivating this loyalty card will prevent new customers from acquiring it, while current customers can still earn stamps/points until completion, after which their card will be removed and they won't receive further stamps."
        : 'Activating this loyalty card will allow new customers to obtain it, and existing customers will be able to continue collecting stamps/points.',
      buttonOne: 'Cancel',
      buttonTwo: action,
    };
  }

  toggleCard(activate: boolean, card: StampCard) {
    this.mixpanel.track(Constants.analytics_keys.toggleCard, {
      card: card.title,
      state: activate,
    });

    this.saving = true;
    this.cardService
      .toggleCard({ cardId: card._id, status: activate })
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.cards.find((el) => el._id == card._id)!.active = activate;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }
}
