<p>Pick a color:</p>

<div class="picker" [style.backgroundColor]="color">
  <input
    #colorInput
    type="color"
    [value]="color"
    (change)="onColorChange($event)"
  />
</div>

<div>
  <button (click)="openColorPicker(colorInput)">Open picker</button>
</div>
