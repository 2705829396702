<page-header
  [title]="constants.strings.locations"
  [subtitle]="subtitle"
></page-header>

<div
  style="display: flex; justify-content: flex-end; margin-bottom: 10px"
  *ngIf="isAdmin"
>
  <app-primary-button
    [title]="constants.strings.newLocation"
    [disabled]="loading"
    (tapped)="newLocation()"
    [icon]="'add'"
  ></app-primary-button>
</div>

<div *ngIf="!locationsLoading">
  <ngx-datatable
    #myTable
    class="material"
    [messages]="{
      emptyMessage:
        '<a href=https://app.remyrewards.co.uk/location>+ Add your first Location</a>'
    }"
    [headerHeight]="45"
    [rows]="locations"
    [scrollbarV]="false"
    [scrollbarH]="true"
    [footerHeight]="50"
    [columnMode]="'force'"
    [rowHeight]="48"
    [externalPaging]="true"
    [count]="length"
    [offset]="pageIndex"
    (page)="pageEvent($event)"
    (scroll)="handleScroll()"
    [limit]="pageSize"
    (activate)="rowTappedNGX($event)"
  >
    <ngx-datatable-column name="Created" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.createdAt | date : constants.longDate }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Name" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.name }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Address" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ constants.constructShortAddress(row?.address) }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Type" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{
          row?.address?.type === 0 ? "Commercial Premises" : "Private Premises"
        }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Status" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div id="status-chip" [ngClass]="constants.statusChipClass(row)">
          {{ locationStatus(row) }}
          <span *ngIf="!row?.approved"
            ><info-icon
              text="This location is in review, it will only be visible on the customer app when approved"
            ></info-icon
          ></span>
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Actions" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <mat-icon
          *ngIf="menuOptionsMap[row._id]?.length"
          class="status-icon"
          style="color: var(--app-timber)"
          [mat-menu-trigger-for]="menu"
          (click)="$event.stopPropagation()"
          [matMenuTriggerData]="{ location: row }"
          >more_horiz</mat-icon
        >
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-footer>
      <ng-template
        ngx-datatable-footer-template
        let-rowCount="rowCount"
        let-pageSize="pageSize"
        let-offset="offset"
        let-isVisible="isVisible"
      >
        <div class="page-count" *ngIf="length > 0">
          <span>{{ locationRange }}</span>
        </div>
        <datatable-pager
          [pagerLeftArrowIcon]="'datatable-icon-left'"
          [pagerRightArrowIcon]="'datatable-icon-right'"
          [pagerPreviousIcon]="'datatable-icon-prev'"
          [pagerNextIcon]="'datatable-icon-skip'"
          [page]="pageIndex + 1"
          [size]="pageSize"
          [count]="length"
          [hidden]="!(rowCount / pageSize > 1)"
          (change)="table.onFooterPage($event)"
        >
        </datatable-pager>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>
</div>

<loader [loading]="loading"></loader>

<mat-menu #menu="matMenu" class="custom-menu">
  <ng-template matMenuContent let-location="location">
    <button
      *ngFor="let item of menuOptionsMap[location._id]"
      [disabled]="false"
      class="menu-list-item"
      style="border-radius: 5px"
      (click)="handleMenuOptionSelection(item.title, location)"
      mat-menu-item
    >
      <mat-icon
        fontSet="material-icons-outlined"
        style="
          font-size: 16px;
          vertical-align: middle;
          display: flex;
          align-items: center;
          margin-right: 5px;
        "
        >{{ item.icon }}</mat-icon
      >
      <span class="menu-list-item-label"> {{ item.title }}</span>
    </button>
  </ng-template>
</mat-menu>
