<dialog-header
  title="AI Message Generator"
  (onClose)="matDialogRef.close()"
></dialog-header>

<div
  mat-dialog-content
  style="
    padding-bottom: 20px;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    background-color: var(--app-container);
  "
>
  <form [formGroup]="form" style="margin-top: 20px">
    <div class="form-field">
      <div class="form-field-label">What do you want to write about?</div>
      <input
        class="form-input"
        placeholder="e.g. Encourage my customers to visit today for 20% off all cakes"
        autocomplete="off"
        [formControl]="textFormControl"
        maxlength="100"
        type="text"
      />
    </div>

    <div style="margin-top: 10px">
      <mat-slide-toggle
        [disabled]="false"
        [checked]="includeCustomerName"
        (change)="includeCustomerName = !includeCustomerName"
      >
        Include Customer's First Name
      </mat-slide-toggle>
    </div>

    <div class="form-field" style="margin-top: 20px">
      <div class="form-field-label">Generated Messages</div>
      <div
        *ngFor="let idea of ideas"
        [class.shimmer-effect]="idea.length === 0"
        class="idea"
        (click)="idea.length ? save(idea) : null"
      >
        {{ idea }}
      </div>
    </div>
  </form>
</div>

<div mat-dialog-actions align="end">
  <app-secondary-button
    style="margin-right: 10px"
    [title]="'Cancel'"
    [disabled]="false"
    (tapped)="matDialogRef.close()"
  ></app-secondary-button>

  <app-primary-button
    [title]="'✨ Generate'"
    [disabled]="!textFormControl.value || generating"
    (tapped)="getIdeas()"
    bgColor="var(--app-timber)"
    color="white"
  ></app-primary-button>
</div>

<loader [loading]="generating"></loader>
