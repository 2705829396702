import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { Constants } from 'src/app/app.constants';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ChangePasswordDialogComponent } from '../change-password-dialog/change-password-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { AuthService, ListService } from '../../../services/index';
import { Merchant, User } from '../../../models/index';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'profile',
  templateUrl: 'profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  merchantForm!: FormGroup;
  selectedOption: string = this.constants.locationLogo.merchant;

  firstNameFormControl = new FormControl(
    { value: '', disabled: false },
    Validators.required
  );

  lastNameFormControl = new FormControl(
    { value: '', disabled: false },
    Validators.required
  );

  emailFormControl = new FormControl({ value: '', disabled: true }, [
    Validators.required,
    Validators.email,
  ]);

  merchantNameFormControl = new FormControl(
    { value: '', disabled: true },
    Validators.required
  );

  businessTypeFormControl = new FormControl(
    { value: { name: '' }, disabled: true },
    Validators.required
  );

  title = '';
  subtitle: string = '';
  merchant: Merchant = {};
  loadingMerchant = false;
  saving = false;
  error = false;
  fetching = false;
  canManageMerchant = false;

  selectedLogo?: [{ name: string; content: ArrayBuffer; mime: string }];
  businessTypes: string[] = [];

  initialMerchantLogo?: string | null;

  weeklyReports = true;
  monthlyReports = false;

  options = [
    {
      value: this.constants.locationLogo.merchant,
      label: 'Use same branding for my locations',
    },
    {
      value: this.constants.locationLogo.location,
      label: 'Use custom branding for my locations',
    },
  ];

  constructor(
    public constants: Constants,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private authService: AuthService,
    private listService: ListService,
    private ps: PermissionsService
  ) {
    this.canManageMerchant = this.ps.permissions.merchant.manage();
  }

  ngOnInit() {
    this.merchantForm = this.formBuilder.group({
      merchantName: this.merchantNameFormControl,
      businessType: this.businessTypeFormControl,
      firstName: this.firstNameFormControl,
      lastName: this.lastNameFormControl,
      email: this.emailFormControl,
    });

    this.getMerchant();

    this.subtitle = this.authService.getNestedUserProperty('merchant', 'name');
  }

  get timestamp(): number {
    return new Date().getTime();
  }

  getBusinessTypes(merchant: any): void {
    this.fetching = true;
    this.listService
      .getList('tags', true)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.businessTypes = res.map((el: any) => el.name);
            this.businessTypes?.sort((a, b) => a.localeCompare(b));
            this.businessTypeFormControl.setValue(merchant.type);
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.fetching = false));
  }

  updateMerchant(): void {
    const merchantObj = {
      name: this.merchantNameFormControl.value,
      logo: this.selectedLogo ? this.selectedLogo[0] : null,
      id: this.merchant._id,
      firstName: this.firstNameFormControl.value,
      lastName: this.lastNameFormControl.value,
      type: this.businessTypeFormControl.value,
      customBranding:
        this.selectedOption == this.constants.locationLogo?.location,
    };

    this.saving = true;
    this.authService
      .updateMerchant(merchantObj)
      .subscribe({
        next: (res: Merchant) => {
          if (res) {
            this.merchant = res;
            this.merchantNameFormControl.setValue(res.name!);

            this.initialMerchantLogo = this.setMerchantLogo(this.merchant);

            window.location.reload();
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  setMerchantLogo(merchant: Merchant): string {
    if (merchant?.logo && merchant?.logo?.url) {
      return merchant?.logo?.url + '?' + new Date().getTime();
    } else {
      return '';
    }
  }

  getMerchant(): void {
    this.loadingMerchant = true;
    this.authService
      .getMerchant(this.authService.getNestedUserProperty('merchant', 'nanoid'))
      .subscribe({
        next: (res: { merchant: Merchant; user: User }) => {
          if (res) {
            this.firstNameFormControl.setValue(res.user.firstName ?? '');
            this.lastNameFormControl.setValue(res.user.lastName ?? '');
            this.emailFormControl.setValue(res.user.email ?? '');

            this.merchant = res.merchant;
            this.merchantNameFormControl.setValue(this.merchant.name!);
            this.initialMerchantLogo = this.setMerchantLogo(this.merchant);
            this.getBusinessTypes(this.merchant);

            if (this.canManageMerchant) {
              this.merchantNameFormControl.enable();
              this.businessTypeFormControl.enable();
            }

            this.weeklyReports = res.user?.reports?.week ?? true;
            this.monthlyReports = res.user?.reports?.month ?? true;

            this.selectedOption = this.merchant?.customBranding
              ? this.constants.locationLogo.location
              : this.constants.locationLogo.merchant;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loadingMerchant = false));
  }

  get loading(): boolean {
    return this.fetching || this.loadingMerchant;
  }

  changePassword(): void {
    const dialogRef = this.dialog.open(ChangePasswordDialogComponent, {
      autoFocus: false,
      disableClose: true,
      width: '500px',
      scrollStrategy: new NoopScrollStrategy(),
    });
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data) {
        this.saving = true;

        this.authService
          .manualChangePassword(data.current, data.new)
          .subscribe({
            next: (res: HttpResponse<any>) => {
              if (res) {
                const body = res.body;

                if (body.userType === this.constants.userTypes.merchant) {
                  this.constants.snack('Password changed');
                }
              }
            },
            error: (res: HttpErrorResponse) => {
              this.constants.snack(res.error.message);
            },
          })
          .add(() => (this.saving = false));
      }
    });
  }

  toggleReportFrequency(frequency: string, state: boolean): void {
    this.saving = true;
    this.authService
      .updateMerchant({
        id: this.merchant._id,
        [frequency]: state,
      })
      .subscribe({
        next: (res: Merchant) => {
          if (res) {
            this.constants.snack('Report frequency updated');
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  get tooltip(): string {
    return `If your locations have different names and/or branding to your main merchant account then you can elect to set up separate branding by location`;
  }
}
