import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from 'src/app/app.constants';
import { RequestNFCDialogComponent } from '../request-nfc-dialog/request-nfc-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NFCNicknameDialogComponent } from '../nfc-nickname-dialog/nfc-nickname.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { AnalyticsAbstract } from 'src/app/services/analytics/analytics.abstract';
import { SingleSelectDialogComponent } from 'src/app/shared/components/single-select-dialog/single-select-dialog.component';
import { Location, NFCCard } from '../../../models/index';
import {
  LocationService,
  MerchantService,
  AuthService,
} from '../../../services/index';

@Component({
  selector: 'nfc-cards',
  templateUrl: './nfc-cards.component.html',
  styleUrls: ['./nfc-cards.component.css'],
})
export class NFCCardsComponent {
  @Input() smallTitle = false;
  @Input() title = '';
  @Input() subtitle: string = '';
  @ViewChild('myTable') table: any;

  nfcCards: NFCCard[] = [];
  cardsLoading = false;
  toggleLoading = false;
  error = false;
  scrollTimeout: any;
  suppressPaging: boolean = false;
  locationsLoading = false;
  tableMenuOptions = [
    { title: this.constants.strings.editNickname, icon: 'edit' },
    { title: this.constants.strings.linkedLocation, icon: 'location_on' },
  ];

  locationCount = 1;

  constructor(
    private dialog: MatDialog,
    public constants: Constants,
    private authService: AuthService,
    private merchantService: MerchantService,
    private router: Router,
    private locationService: LocationService,
    private mixpanel: AnalyticsAbstract
  ) {}

  ngOnInit() {
    this.subtitle = this.authService.getNestedUserProperty('merchant', 'name');

    this.getNFCCards();
  }

  get emptyString(): string {
    return `No ${this.constants.strings.nfcCards} to display`;
  }

  get tooltip(): string {
    if (this.loading) {
      return '';
    }
    if (!this.authService.merchantObj?.active) {
      return this.constants.strings.inactive;
    } else {
      return '';
    }
  }

  toggleCard(card: NFCCard): void {
    this.mixpanel.track(Constants.analytics_keys.toggleNFC, {
      NFC: card.ref,
      state: !card.active,
    });

    this.toggleLoading = true;
    this.merchantService
      .toggleNFCCard(card._id, !card.active)
      .subscribe({
        next: (res: any) => {
          if (res) {
            card.active = !card.active;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.toggleLoading = false));
  }

  rowTappedNGX({ row, type }: any) {
    if (type === 'click') {
      this.router.navigate([Constants.routes.nfcEvents + '/' + row.ref]);
    }
  }

  getNFCCards(): void {
    this.cardsLoading = true;
    this.merchantService
      .getNFCCards()
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.nfcCards = res.cards;
            this.locationCount = res.locations;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.cardsLoading = false));
  }

  get loading(): boolean {
    return this.cardsLoading || this.toggleLoading || this.locationsLoading;
  }

  openRequestDialog(): void {
    this.dialog.open(RequestNFCDialogComponent, {
      width: '450px',
      maxHeight: '85vh',
      autoFocus: false,
      scrollStrategy: new NoopScrollStrategy(),
    });
  }

  onClick(event: any, row: any) {
    if (event === this.constants.strings.editNickname) {
      const dialogRef = this.dialog.open(NFCNicknameDialogComponent, {
        data: {
          nickname: row.nickname ?? '',
        },
        width: '450px',
        autoFocus: false,
        disableClose: true,
        scrollStrategy: new NoopScrollStrategy(),
      });
      dialogRef.afterClosed().subscribe((data) => {
        if (data?.changed) {
          this.editNFCCard(row, data?.nickname);
        }
      });
    }

    if (event == this.constants.strings.linkedLocation) {
      this.getLocations(row);
    }
  }

  getLocations(card: NFCCard): void {
    this.locationsLoading = true;

    const subscription =
      this.authService.getUserProperty('userType') ===
      this.constants.userTypes.manager
        ? this.merchantService.getStaffLocations()
        : this.locationService.getLocations(1000, 0);

    subscription
      .subscribe({
        next: (res: any) => {
          if (res) {
            let locations: Location[] = res?.docs ?? res;
            locations?.sort((a, b) => a.name!.localeCompare(b.name!));

            this.openLocationDialog(card, locations);
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.locationsLoading = false));
  }

  openLocationDialog(card: NFCCard, locations: Location[]): void {
    const dialogRef = this.dialog.open(SingleSelectDialogComponent, {
      data: {
        title: 'Select a location',
        label: 'Location',
        property: 'name',
        options: locations,
        initialValue: locations.find(
          (el) => el._id?.toString() === (card.location as Location)?._id
        ),
      },
      scrollStrategy: new NoopScrollStrategy(),
      width: '600px',
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.editNFCCard(card, card.nickname, data);
      }
    });
  }

  editNFCCard(card: NFCCard, nickname?: string, location?: Location): void {
    this.toggleLoading = true;
    this.merchantService
      .editNFCCard(card._id!, nickname, location?._id)
      .subscribe({
        next: (res: any) => {
          if (res) {
            if (nickname === '') {
              delete card.nickname;
            } else {
              card.nickname = nickname;
            }

            if (location) {
              card.location = location;
            }
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.toggleLoading = false));
  }

  handleScroll() {
    this.suppressPaging = true;

    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }

    this.scrollTimeout = setTimeout(() => {
      this.suppressPaging = false;
    }, 100);
  }
}
