<div class="signup-container">
  <div class="image-section">
    <img [src]="loginImage" alt="Sign Up" class="signup-image" />
    <div class="image-overlay">
      <p class="image-label">{{ loginLabel }}</p>
    </div>
  </div>

  <img src="../assets/remylogo.png" alt="Logo" class="logo" />

  <div class="right-section">
    <div class="form-section">
      <h2
        style="
          font-weight: normal;
          font-family: 'Monomakh';
          text-align: center;
          font-size: 36px;
        "
      >
        Reset Password
      </h2>
      <p
        style="
          font-weight: normal;
          text-align: center;
          color: darkgrey;
          font-size: 16px;
        "
      >
        Enter your email address below to reset your password
      </p>

      <form [formGroup]="form" (ngSubmit)="forgot()">
        <div class="form-field" style="margin-top: 20px">
          <div class="form-field-label" style="color: var(--app-timber)">
            Email
          </div>
          <input
            class="form-input"
            [formControl]="emailFormControl"
            type="email"
          />
        </div>

        <div class="action-buttons" style="margin-top: 20px">
          <app-secondary-button
            style="margin-right: 10px"
            [title]="'Sign in'"
            [disabled]="false"
            color="darkGrey"
            (tapped)="navigateToLogin()"
            [type]="'button'"
          ></app-secondary-button>
          <app-primary-button
            [title]="'Reset Password'"
            [disabled]="loading || form.invalid"
            (tapped)="forgot()"
            bgColor="var(--app-timber)"
            color="white"
            [type]="'submit'"
          ></app-primary-button>
        </div>
      </form>
    </div>
  </div>
</div>

<loader [loading]="pageLoading || loading"></loader>
