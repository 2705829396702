import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Constants } from 'src/app/app.constants';
import { AnalyticsAbstract } from 'src/app/services/analytics/analytics.abstract';
import { TwoOptionAlertComponent } from 'src/app/shared/components/two-option-alert/two-option-alert.component';
import { Location } from '../../../models/index';
import {
  AuthService,
  ListService,
  LocationService,
  MerchantService,
} from '../../../services/index';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.css'],
})
export class LocationsComponent {
  menuOptionsMap: { [locationId: string]: { icon: string; title: string }[] } =
    {};

  @Input() smallTitle = false;
  @Input() title = '';
  @Input() subtitle: string = '';
  @ViewChild('myTable') table: any;

  pageIndex = 0;
  pageSize = 10;
  length = 0;
  locations: Location[] = [];
  locationsLoading = false;
  error = false;
  scrollTimeout: any;
  suppressPaging: boolean = false;
  locationRange = '';
  planLimitsLoading = false;
  canCreateLocation = false;
  isAdmin: boolean = false;
  saving = false;

  constructor(
    public constants: Constants,
    private router: Router,
    private locationService: LocationService,
    private authService: AuthService,
    private ps: PermissionsService,
    private listService: ListService,
    private merchantService: MerchantService,
    public dialog: MatDialog,
    private mixpanel: AnalyticsAbstract
  ) {
    this.isAdmin = this.ps.adminOrMerchant;
  }

  ngOnInit() {
    this.subtitle = this.authService.getNestedUserProperty('merchant', 'name');

    this.getLocations();
    this.getPlanLimits();
    // this.createList();
  }

  getPlanLimits(): void {
    this.planLimitsLoading = true;
    this.merchantService
      .getPlanLimits()
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.canCreateLocation = res.locations > 0;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.planLimitsLoading = false));
  }

  get emptyString(): string {
    return `No ${this.constants.strings.locations} to display`;
  }

  createList(): void {
    this.listService.createList().subscribe({
      next: (res: any) => {
        if (res) {
        }
      },
      error: (res: HttpErrorResponse) => {
        this.error = true;
        this.constants.snack(res.error.message);
      },
    });
  }

  getLocations(): void {
    this.locationsLoading = true;

    this.locationService
      .getLocations(this.pageSize, this.pageIndex)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.length = res.totalDocs;
            this.locations = res.docs;

            this.locations.forEach((location) => {
              this.menuOptionsMap[location._id!] =
                this.calculateMenuOptions(location);
            });

            this.locationRange = this.constants.pageCounter(
              this.pageIndex,
              this.pageSize,
              this.length,
              this.locations?.length
            );
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.locationsLoading = false));
  }

  pageEvent(event: any) {
    if (!this.suppressPaging) {
      this.pageIndex = event.offset;
      this.getLocations();
    }
  }

  rowTappedNGX({ row, type }: any) {
    if (type === 'click') {
      this.router.navigate([Constants.routes.location + '/' + row.nanoid]);
    }
  }

  newLocation(): void {
    if (!this.canCreateLocation) {
      this.dialog.open(TwoOptionAlertComponent, {
        data: {
          title: 'Extra Location',
          body: `Please contact support at <a target="_blank" href="mailto:hello@remyrewards.co.uk">hello@remyrewards.co.uk</a> to add an extra location to your plan`,
          buttonTwo: 'OK',
        },
        scrollStrategy: new NoopScrollStrategy(),
        autoFocus: false,
        width: '350px',
        disableClose: true,
        panelClass: 'custom-dialog',
      });
    } else {
      this.router.navigate([Constants.routes.location]);
    }
  }

  get loading(): boolean {
    return this.locationsLoading || this.planLimitsLoading;
  }

  handleScroll() {
    this.suppressPaging = true;

    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }

    this.scrollTimeout = setTimeout(() => {
      this.suppressPaging = false;
    }, 100);
  }

  get activateTitle() {
    return { title: this.constants.strings.activate, icon: 'check_circle' };
  }

  get deactivateTitle() {
    return { title: this.constants.strings.deactivate, icon: 'cancel' };
  }

  handleMenuOptionSelection(option: string, location: Location): void {
    if (option === this.constants.strings.downloadQRCode) {
      this.openQRCode(location.qrCode?.url!);
    } else {
      const action =
        option === this.constants.strings.deactivate
          ? 'Deactivate'
          : 'Activate';
      const dialogData = this.getDialogData(action);

      const dialogRef = this.dialog.open(TwoOptionAlertComponent, {
        data: dialogData,
        autoFocus: false,
        maxWidth: '500px',
        disableClose: true,
        scrollStrategy: new NoopScrollStrategy(),
      });

      dialogRef.afterClosed().subscribe((selectedOption: number) => {
        if (selectedOption == 1) {
          this.toggle(action === 'Activate', location);
        }
      });
    }
  }

  getDialogData(action: string) {
    const isDeactivating = action === 'Deactivate';
    return {
      title: 'Confirm',
      body: isDeactivating
        ? 'Deactivating this location will make it non-discoverable in the Remy Rewards app, customers who have already taken your stamp will still be able to view it'
        : 'Activating this location will make it discoverable in the Remy Rewards app',
      buttonOne: 'Cancel',
      buttonTwo: action,
    };
  }

  toggle(activate: boolean, location: Location) {
    this.mixpanel.track(Constants.analytics_keys.toggleLocation, {
      location: location.name,
      state: activate,
    });

    this.saving = true;
    this.locationService
      .toggleLocation({ locationId: location._id, status: activate })
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.locations.find((el) => el._id == location._id)!.active =
              activate;

            this.menuOptionsMap[location._id!] =
              this.calculateMenuOptions(location);
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  locationStatus(location: Location) {
    if (location.approved == false) {
      return 'In Review';
    } else {
      if (location.active == true) {
        return 'Active';
      } else {
        return 'Inactive';
      }
    }
  }

  openQRCode(link: string) {
    window.open(link, '_blank');
  }

  calculateMenuOptions(location: Location): { icon: string; title: string }[] {
    let options: { icon: string; title: string }[] = [];

    if (location.qrCode?.url) {
      options.push({ title: 'Download QR Code', icon: 'qr_code' });
    }

    if (location.approved && this.isAdmin) {
      if (location.active) {
        options.push({
          title: this.deactivateTitle.title,
          icon: this.deactivateTitle.icon,
        });
      } else {
        options.push({
          title: this.activateTitle.title,
          icon: this.activateTitle.icon,
        });
      }
    }

    return options;
  }
}
