<span
  [style.cursor]="!disabled ? 'pointer' : 'not-allowed'"
  [matTooltip]="tooltip"
  [matTooltipPosition]="'above'"
>
  <button
    [type]="type"
    [disabled]="disabled"
    (click)="tapped.emit()"
    mat-flat-button
    class="primary-button"
    [style.backgroundColor]="bgColor"
    [style.color]="color"
    [style.border]="border"
  >
    <div
      style="display: flex; justify-content: space-between; align-items: center"
      [style.flexDirection]="reverse ? 'row-reverse' : 'row'"
    >
      <span *ngIf="icon"
        ><mat-icon
          fontSet="material-icons-outlined"
          [style.color]="color"
          style="
            font-size: 16px;
            vertical-align: middle;
            display: flex;
            align-items: center;
            justify-content: center;
          "
          >{{ icon }}</mat-icon
        ></span
      >

      <span *ngIf="image"
        ><img
          style="
            font-size: 16px;
            vertical-align: middle;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
          "
          height="20"
          [src]="image"
        />
      </span>

      <span>{{ title }}</span>
    </div>
    <span *ngIf="number > 0" class="number"></span>
  </button>
</span>
