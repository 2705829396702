import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Socket } from 'ngx-socket-io';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class ChatService {
  constructor(
    private api: ApiService,
    private socket: Socket,
    private authService: AuthService
  ) {}

  PATH = 'v1/chat';

  socketDispose() {
    this.socket.disconnect();
  }

  registerConversation(conversationId: string): void {
    if (!this.socket.ioSocket.connected) {
      this.socket.connect();
    }

    const userId = this.authService.getUserProperty('uid');
    const data = {
      conversationId,
      userId,
    };

    this.socket.emit('register_conversation', JSON.stringify(data));
  }

  onMessage(): Observable<any> {
    return this.socket.fromEvent('message');
  }

  getConversations(limit: number, page: number) {
    var query = `?limit=${limit}&page=${page}`;
    return this.api.get(`${this.PATH}/conversations` + query);
  }

  getMessages(limit: number, page: number, conversationId: string) {
    var query = `?limit=${limit}&page=${page}&conversationId=${conversationId}`;
    return this.api.get(`${this.PATH}/messages` + query);
  }

  sendMessage(message: string, conversationId: string) {
    return this.api.post(`${this.PATH}/message`, {
      message: message,
      conversationId: conversationId,
    });
  }

  muteConversation(mute: boolean, conversationId: string) {
    return this.api.post(`${this.PATH}/conversation/mute`, {
      mute: mute,
      conversationId: conversationId,
    });
  }

  blockConversation(block: boolean, conversationId: string) {
    return this.api.post(`${this.PATH}/conversation/block`, {
      block: block,
      conversationId: conversationId,
    });
  }
}
