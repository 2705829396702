import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constants } from 'src/app/app.constants';
import { UserService, AuthService } from '../../../services/index';

@Component({
  selector: 'onboard',
  templateUrl: './onboard.component.html',
  styleUrls: ['./onboard.component.css'],
})
export class OnboardComponent implements OnInit {
  step = 1;
  mailText = '';
  saving = false;

  constructor(
    public matDialogRef: MatDialogRef<OnboardComponent>,
    public fb: FormBuilder,
    private constants: Constants,
    private authService: AuthService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  backTapped() {
    this.step--;
  }

  primaryTapped() {
    if (this.step === 2) {
      this.saving = true;
      this.userService
        .updateUserProperty('onboarded', true)
        .subscribe({
          next: (res: any) => {
            if (res) {
              this.matDialogRef.close();
            }
          },
          error: (res: HttpErrorResponse) => {
            this.constants.snack(res.error.message);
          },
        })
        .add(() => (this.saving = false));
      this.matDialogRef.close();
    } else {
      this.step++;
    }
  }

  get name(): string {
    return this.authService.getUserProperty('firstName');
  }
}
