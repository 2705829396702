import { Component, OnInit } from '@angular/core';
import { AuthService, StripeService } from '../../../services/index';
import { Merchant } from '../../../models/index';
import { Constants } from 'src/app/app.constants';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TwoOptionAlertComponent } from 'src/app/shared/components/two-option-alert/two-option-alert.component';
import { MatDialog } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { AnalyticsAbstract } from 'src/app/services/analytics/analytics.abstract';
import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints,
} from '@angular/cdk/layout';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'plans',
  templateUrl: 'plans.component.html',
  styleUrls: ['./plans.component.css'],
})
export class PlansComponent implements OnInit {
  title = '';
  subtitle: string = '';
  merchant: Merchant = {};
  plansLoading = false;
  subscriptionLoading = false;
  saving = false;
  error = false;

  options: { value: string; icon?: string; label: string }[] = [
    { value: 'gbp', label: 'GBP' },
    { value: 'usd', label: 'USD' },
    { value: 'eur', label: 'EUR' },
  ];

  typeOptions: { value: string; icon?: string; label: string }[] = [
    { value: 'year', label: 'Annual <b>(20% off)</b>' },
    { value: 'month', label: 'Monthly' },
  ];

  selectedLogo?: [{ name: string; content: ArrayBuffer }];

  plans: any[] = [];
  basis = 'year';
  currency = 'gbp';

  hasTrial = false;
  hasFree = false;

  renewalDate?: number;
  cancelDate?: number;

  constructor(
    public dialog: MatDialog,
    public constants: Constants,
    private authService: AuthService,
    private stripeService: StripeService,
    private mixpanel: AnalyticsAbstract,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit() {
    this.getProducts();
    this.getSubscriptionInfo();
    this.subtitle = this.authService.getNestedUserProperty('merchant', 'name');

    this.hasTrial = this.authService.getUserProperty('hasTrial');
    this.hasFree = this.authService.getUserProperty('hasFree');
  }

  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result: BreakpointState) => {
        return result.matches;
      })
    );

  get currentPlan(): string {
    if (this.hasFree) {
      return 'SPECIAL FREE';
    }

    if (this.hasTrial) {
      return 'TRIAL';
    } else {
      return this.constants.convertToTitleCase(
        this.authService.getUserProperty('plan')
      );
    }
  }

  get portalUrl(): string {
    return `${
      environment.stripeCustomerPortal
    }?prefilled_email=${this.authService.getUserProperty('email')}`;
  }

  get trialEnds(): number {
    return this.constants.calculateDiff(
      this.authService.getUserProperty('trialEnds')
    );
  }

  checkout(event: any): void {
    this.mixpanel.track(Constants.analytics_keys.selectPlan, {
      Plan: event?.nickname,
      Currency: this.currency,
      Basis: this.basis,
    });

    const dialogRef = this.dialog.open(TwoOptionAlertComponent, {
      data: {
        title: 'Checkout',
        body: `The plan you have selected is <b>${event?.nickname}</b>, do you wish to continue?`,
        buttonOne: 'Cancel',
        buttonTwo: 'Yes',
      },
      scrollStrategy: new NoopScrollStrategy(),
      autoFocus: false,
      width: '350px',
      disableClose: true,
      panelClass: 'custom-dialog',
    });
    dialogRef.afterClosed().subscribe((option: number) => {
      if (option == 1) {
        this.mixpanel.track(Constants.analytics_keys.planSelected, {
          Plan: event?.nickname,
          Currency: this.currency,
          Basis: this.basis,
        });

        this.saving = true;
        this.stripeService.createCheckoutSession(event.lookup_key).subscribe({
          next: (res: any) => {
            if (res && res.url) {
              window.open(res.url, '_self');
            } else {
              this.handleSubscriptionUpdateSuccess(event?.nickname);
            }
          },
          error: (error: HttpErrorResponse) => {
            this.handleSubscriptionUpdateError(error);
          },
        });
      }
    });
  }

  private handleSubscriptionUpdateSuccess(plan: string): void {
    this.mixpanel.track(Constants.analytics_keys.planUpdated, {
      Plan: plan,
      Currency: this.currency,
      Basis: this.basis,
    });

    const dialogRef = this.dialog.open(TwoOptionAlertComponent, {
      data: {
        title: 'Plan Updated',
        body: `Your subscription has been updated to ${plan}`,
        buttonTwo: 'OK',
      },
      scrollStrategy: new NoopScrollStrategy(),
      autoFocus: false,
      width: '350px',
      disableClose: true,
      panelClass: 'custom-dialog',
    });
    dialogRef.afterClosed().subscribe((option: number) => {
      window.location.reload();
    });
  }

  private handleSubscriptionUpdateError(error: HttpErrorResponse): void {
    this.saving = false;
    this.constants.snack(error.error.message);
  }

  getSubscriptionInfo(): void {
    this.subscriptionLoading = true;

    this.stripeService
      .getSubscriptionInfo()
      .subscribe({
        next: (res: any) => {
          if (res) {
            if (res.current_period_end) {
              this.renewalDate = res.current_period_end;
            }
            if (res.cancel_at) {
              this.cancelDate = res.cancel_at;
            }

            if (res.nickname?.toLowerCase().includes('month')) {
              this.basis = 'month';
            } else {
              this.basis = 'year';
            }

            const currencyOption = this.options.find(
              (el) => el.value == res.currency
            );
            if (currencyOption) {
              this.currency = currencyOption.value;
            }
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.subscriptionLoading = false));
  }

  getProducts(): void {
    this.plansLoading = true;

    this.stripeService
      .getProducts()
      .subscribe({
        next: (res: any) => {
          if (res) {
            const { products, prices } = res;

            products.forEach((product: any) => {
              const pricesToAdd = prices.filter(
                (price: any) => price.product === product.id
              );
              product.prices = pricesToAdd;

              if (product.plan) {
                product.mostPopular = product.plan.mostPopular;
              }

              if (!product.metadata.location) {
                const locationProducts = products.filter(
                  (el: any) => el?.metadata?.location === 'true'
                );

                let locationPrices: any[] = [];

                locationProducts.forEach((prod: any) => {
                  locationPrices = locationPrices.concat(
                    prices.filter((price: any) => price.product === prod.id)
                  );
                });

                const monthly = locationPrices.find(
                  (el: any) =>
                    el.recurring?.interval === 'month' &&
                    el.metadata?.plan === product.metadata?.plan
                );

                const annually = locationPrices.find(
                  (el: any) =>
                    el.recurring?.interval === 'year' &&
                    el.metadata?.plan === product.metadata?.plan
                );

                if (
                  monthly &&
                  annually &&
                  monthly.currency_options &&
                  annually.currency_options
                ) {
                  product.extraLocation = {
                    monthly: {
                      gbp:
                        monthly.currency_options?.gbp?.unit_amount / 100 || 0,
                      usd:
                        monthly.currency_options?.usd?.unit_amount / 100 || 0,
                      eur:
                        monthly.currency_options?.eur?.unit_amount / 100 || 0,
                    },
                    annually: {
                      gbp:
                        annually.currency_options?.gbp?.unit_amount /
                          100 /
                          12 || 0,
                      usd:
                        annually.currency_options?.usd?.unit_amount /
                          100 /
                          12 || 0,
                      eur:
                        annually.currency_options?.eur?.unit_amount /
                          100 /
                          12 || 0,
                    },
                  };
                }
              }
            });

            // Filter products for plans
            this.plans = products.filter(
              (el: any) => !el?.metadata?.location && el.active === true
            );
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.plansLoading = false));
  }

  get loading(): boolean {
    return this.plansLoading || this.saving || this.subscriptionLoading;
  }

  openBillingPortal(): void {
    this.mixpanel.track(Constants.analytics_keys.openBillingPortal);
  }

  toggleCurrency(event: any): void {
    this.mixpanel.track(Constants.analytics_keys.togglePlanCurrency, {
      Currency: event,
    });

    this.currency = event;
  }

  toggleBasis(event: any): void {
    this.mixpanel.track(Constants.analytics_keys.togglePlanBasis, {
      Basis: event,
    });

    this.basis = event;
  }
}
