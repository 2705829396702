<page-header [title]="'Integrations'" [subtitle]="subtitle"></page-header>
<loader [loading]="loading || saving"></loader>

<div class="integrations-container">
  <div
    class="integration-item"
    *ngFor="let integration of integrations"
    (click)="onIntegrationClick(integration)"
  >
    <img
      [src]="integration.image"
      [alt]="integration.label"
      class="integration-image"
    />
    <span class="integration-title">{{ integration.label }}</span>

    <div class="integration-status" *ngIf="integration.active">
      <mat-icon
        aria-hidden="false"
        style="
          color: green;
          display: flex;
          align-items: center;
          font-size: 16px;
        "
        >check_circle_outlined</mat-icon
      >
      <span>Enabled</span>
    </div>

    <div class="integration-category">
      <span class="tag" *ngFor="let tag of integration.tags">{{ tag }}</span>
    </div>
  </div>
</div>

<loader [loading]="loading"></loader>
