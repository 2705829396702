import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Address } from './models/address';
import { StampEvent } from './models/stamp_event';
import { UserReward } from './models/user_reward';
import { TwoOptionAlertComponent } from './shared/components/two-option-alert/two-option-alert.component';
import { MatDialog } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Observable } from 'rxjs';
import { CardType } from './models';

@Injectable({
  providedIn: 'root',
})
export class Constants {
  constructor(private snackBar: MatSnackBar, public dialog: MatDialog) {}

  longDate = 'dd/MM/yy HH:mm';
  longestDate = 'dd/MM/yy HH:mm:ss';
  shortDate = 'dd/MM/yy';
  timeDate = 'HH:mm:ss';

  userTypes = {
    admin: 'admin',
    merchant: 'merchant',
    manager: 'manager',
    staff: 'staff',
    user: 'user',
  };

  notices = {
    teamCreate: 'teamCreate',
  };

  assets = {
    card: 'card',
    tag: 'tag',
    both: 'both',
  };

  locationLogo = {
    merchant: 'merchant',
    location: 'location',
  };

  strings = {
    lapsedCustomers: 'Lapsed Customers',
    birthdaysThisMonth: 'Birthdays this month',
    unblock: 'Unblock',
    block: 'Block',
    mute: 'Mute',
    unmute: 'Unmute',
    delete: 'Delete',
    activate: 'Activate',
    deactivate: 'Deactivate',
    upgradePlanNotice: 'You need to upgrade your plan',
    addLocation: 'Please set up your Location first',
    inactive: 'Merchant profile inactive',
    nfcNotice: 'You have already requested an NFC card',
    locations: 'Locations',
    location: 'Location',
    myTeam: 'My Team',
    teamMembers: 'Team Members',
    teamMember: 'Team Member',
    stampCards: 'Stamp Cards',
    loyaltyCards: 'Loyalty Cards',
    orders: 'Orders',
    stampCard: 'Stamp Card',
    loyaltyCard: 'Loyalty Card',
    customers: 'Customers',
    team: 'Team',
    engage: 'Engage',
    reports: 'Reports',
    quickActions: 'Quick Actions',
    nfcCards: 'NFC Cards',
    nfcEvents: 'NFC Events',
    help: 'Help',
    referrals: 'Referrals',
    customer: 'Customer',
    dashboard: 'Dashboard',
    rewards: 'Rewards',
    reward: 'Reward',
    newReward: 'New Reward',
    newCard: 'New Card',
    giveReward: 'Give Reward',
    newTeamMember: 'New Team Member',
    remyConnect: 'Remy Connect',
    downloadQRCode: 'Download QR Code',
    cancel: 'Cancel',
    triggerInvite: 'Trigger Invite',
    newNFCCard: 'Request NFC Card',
    newLocation: 'New Location',
    newMessage: 'New Message',
    settings: 'Settings',
    messages: 'Messages',
    plansAndBilling: 'Plans & Billing',
    profile: 'Profile',
    integrations: 'Integrations',
    pushNotification: 'Push Notification',
    editNickname: 'Edit Nickname',
    linkedLocation: 'Linked Location',
    email: 'Email',
    duplicate: 'Duplicate',
    edit: 'Edit',
    logout: 'Logout',
    filters: 'Filter',
    clear: 'Clear',
    export: 'Export',
    newNote: 'New Note',
    exportCustomers: 'Export Customers',
    automations: 'Automations',
    exportAllData: 'Export Data',
    lapsedCustomerReport: 'Lapsed Customers',
    authFooter:
      'We’re passionate about supporting small, local businesses to thrive. And as customers of those businesses, we understand the power of a more personal relationship and being rewarded for our loyalty - Hannah, Co-Founder',
  };

  static analytics_keys = {
    magicLink: 'Magic Link',
    viewDemo: 'View Demo',
    toggleGraphType: 'Toggle Graph Type',
    openDashboardExport: 'Open Dashboard Export',
    dashboardExport: 'Dashboard Export',
    toggleGraphDays: 'Toggle Graph Days',
    lapsedCustomerReport: 'Lapsed Customer Report',
    selectDashboardTab: 'Select Dashboard Tab',
    selectSidebarMenu: 'Select Sidebar Menu',
    openRemyConnect: 'Open Remy Connect',
    viewTeamMember: 'View Team Member',
    viewPlansFromNewTeamMember: 'View Plans from New Team Member',
    newTeamMember: 'New Team Member',
    ourWebsiteFromReferral: 'Our website from referral',
    selectCardDetailTab: 'Select Card Detail Tab',
    openStampPicker: 'Open Stamp Picker',
    giveStamps: 'Give Stamps',
    givePoints: 'Give Points',
    giveReward: 'Give Reward',
    openGiveReward: 'Open Give Reward',
    openAIGenerator: 'Open AI Generator',
    generateAIMessage: 'Generate AI Message',
    openEmojiPicker: 'Open Emoji Picker',
    openRecipientsFilter: 'Open Recipients Filter',
    sendPushNotification: 'Send Push Notification',
    savePushNotification: 'Save Push Notification',
    openBillingPortal: 'Open Billing Portal',
    selectPlan: 'Select Plan',
    planSelected: 'Plan Selected',
    planUpdated: 'Plan Updated',
    planSuccess: 'Plan Success',
    togglePlanCurrency: 'Toggle Plan Currency',
    togglePlanBasis: 'Toggle Plan Basis',
    openAutomations: 'Open Automations',
    updateBirthdayClub: 'Update Birthday Club',
    deleteMessage: 'Delete Message',
    resetPassword: 'Reset Password',
    muteConversation: 'Mute Conversation',
    unmuteConversation: 'Unmute Conversation',
    blockConversation: 'Block Conversation',
    unblockConversation: 'Unblock Conversation',
    signUp: 'Sign Up',
    login: 'Login',
    generateLocationTags: 'Generate Location Tags',
    locationTagsGenerated: 'Location Tags Generated',
    loginSuccess: 'Login Success',
    toggleCard: 'Toggle Card',
    toggleLocation: 'Toggle Location',
    toggleNFC: 'Toggle NFC',
    toggleReportFrequency: 'Toggle Report Frequency',
    oauthRedirect: 'Oauth Redirect',
  };

  static routes = {
    dashboard: 'dashboard',
    integrations: 'integrations',
    nfcCards: 'nfc',
    nfcEvents: 'nfc-events',
    engage: 'engage',
    help: 'help',
    engagePush: 'engage-push',
    locations: 'locations',
    reports: 'reports',
    referrals: 'referrals',
    location: 'location',
    rewards: 'rewards',
    reward: 'reward',
    team: 'team',
    teamMember: 'team-member',
    cards: 'cards',
    card: 'card',
    profile: 'profile',
    plans: 'plans',
    customers: 'customers',
    customer: 'customer',
    changePassword: 'change-password',
    resetPassword: 'reset-password',
    login: 'login',
    confirm: 'confirm',
    verify: 'verify',
    signup: 'signup',
    success: 'success',
    download: 'download',
    oauth: 'oauth',
  };

  constructShortAddress(address: Address, includeCountry = true): string {
    if (address) {
      const addressParts: string[] = [];

      if (address.city) {
        addressParts.push(address.city);
      }

      if (address.country && includeCountry) {
        addressParts.push(address.country);
      }

      return addressParts.join(', ');
    } else {
      return '';
    }
  }

  constructAddress(address: Address, includeCountry = true): string {
    if (address) {
      const addressParts: string[] = [];

      if (address.lineOne) {
        addressParts.push(address.lineOne);
      }

      if (address.lineTwo) {
        addressParts.push(address.lineTwo);
      }

      if (address.city) {
        addressParts.push(address.city);
      }

      if (address.postcode) {
        addressParts.push(address.postcode);
      }

      if (address.country && includeCountry) {
        addressParts.push(address.country);
      }

      return addressParts.join(', ');
    } else {
      return '';
    }
  }

  capitalizeWords(str: string): string {
    const words = str.split(' ');

    const capitalizedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });

    return capitalizedWords.join(' ');
  }

  callbackSnack(content: string, dialog?: boolean): Observable<boolean> {
    return this.snnack(content, dialog);
  }

  snack(content: string, dialog?: boolean): void {
    this.snnack(content, dialog).subscribe();
  }

  snnack(content: string, dialog: boolean = false): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      if (dialog) {
        const dialogRef = this.dialog.open(TwoOptionAlertComponent, {
          data: {
            title: 'Info',
            body: content,
            buttonTwo: 'OK',
          },
          width: '450px',
          autoFocus: false,
          disableClose: true,
          panelClass: 'custom-dialog',
          scrollStrategy: new NoopScrollStrategy(),
        });

        dialogRef.afterClosed().subscribe(() => {
          observer.next(true);
          observer.complete();
        });
      } else {
        this.snackBar.open(content, '', {
          duration: 5000,
        });
        observer.next(true);
        observer.complete();
      }
    });
  }

  userRewardSource(row: any) {
    if (row?.givenByMerchant) {
      return 'Merchant';
    }

    let card = row?.card[0] ?? row?.card;

    if (card?.title) {
      return card?.title;
    }

    if (row?.gifted) {
      return 'Gifted';
    }

    if (row?.birthdayClub) {
      return '🎉 Birthday Club';
    }

    return 'Push Notification';
  }

  calculateDiff(trialEndDate: Date) {
    let currentDate = new Date();
    trialEndDate = new Date(trialEndDate);

    return Math.floor(
      (Date.UTC(
        trialEndDate.getFullYear(),
        trialEndDate.getMonth(),
        trialEndDate.getDate()
      ) -
        Date.UTC(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate()
        )) /
        (1000 * 60 * 60 * 24)
    );
  }

  convertToTitleCase(inputString: string) {
    let words = inputString.split('-');
    let titleCaseString = words
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' - ');

    return titleCaseString;
  }

  passwordValid(password?: string): boolean {
    if (!password || password.length < 6) {
      return false;
    }

    const digitRegex = /\d/;
    const letterRegex = /[a-zA-Z]/;

    if (!digitRegex.test(password)) {
      return false;
    }

    if (!letterRegex.test(password)) {
      return false;
    }

    return true;
  }

  pageCounter(
    index: number,
    size: number,
    length: number,
    arrayLength: number
  ): string {
    const startIndex = index * size + 1;
    const endIndex = Math.min(startIndex + size - 1, length);

    return length < size
      ? `${arrayLength} / ${length}`
      : `${startIndex}-${endIndex} / ${length}`;
  }

  sourceString(row: StampEvent): string {
    if (row.source?.join) {
      return `Join Incentive`;
    }

    if (row.source?.sumup?.ref) {
      return `#${row?.source?.sumup?.ref}`;
    }

    if (row.source?.shopify?.ref) {
      return `#${row?.source?.shopify?.ref}`;
    }

    if (row.source?.square?.ref) {
      return `#${row?.source?.square?.ref}`;
    }

    if (row.source?.zapier?.ref) {
      return `#${row?.source?.zapier?.ref}`;
    }

    if (row.source?.storekit?.ref) {
      return `#${row?.source?.storekit?.ref}`;
    }

    if (row.source?.squarespace?.ref) {
      return `#${row?.source?.squarespace?.ref}`;
    }

    if (row.source?.acuity?.ref) {
      return `#${row?.source?.acuity?.ref}`;
    }

    if (row.source?.user?.name) {
      return row?.source?.user?.name;
    }

    if (row.source?.nfc?.nickname) {
      return row?.source?.nfc?.nickname;
    }

    return 'NFC (' + row?.source?.nfc?.ref + ')';
  }

  isShopify(row: StampEvent): boolean {
    if (row.source?.shopify?.ref) {
      return true;
    }
    return false;
  }

  isSquare(row: StampEvent): boolean {
    if (row.source?.square?.ref) {
      return true;
    }
    return false;
  }

  isZapier(row: StampEvent): boolean {
    if (row.source?.zapier?.ref) {
      return true;
    }
    return false;
  }

  isStorekit(row: StampEvent): boolean {
    if (row.source?.storekit?.ref) {
      return true;
    }
    return false;
  }

  isSquarespace(row: StampEvent): boolean {
    if (row.source?.squarespace?.ref) {
      return true;
    }
    return false;
  }

  isAcuity(row: StampEvent): boolean {
    if (row.source?.acuity?.ref) {
      return true;
    }
    return false;
  }

  isSumUp(row: StampEvent): boolean {
    if (row.source?.sumup?.ref) {
      return true;
    }
    return false;
  }

  rewardHasExpired(reward: UserReward): boolean {
    if (reward?.hasExpired) {
      return true;
    }

    if (reward?.expiry && reward?.expiry < new Date()) {
      return true;
    }
    return false;
  }

  statusChipClass(row: any): string {
    if (row?.hasRedeemed) {
      return 'status-chip-redeemed';
    }

    if (
      this.rewardHasExpired(row) ||
      (!row?.active && row?.approved) ||
      row?.active === false
    ) {
      return 'status-chip-expired';
    }

    return row?.active ? 'status-chip-redeemed' : 'status-chip-unredeemed';
  }

  statusChipLabel(row: any) {
    return row?.hasRedeemed
      ? 'Redeemed'
      : this.rewardHasExpired(row)
      ? 'Expired'
      : 'Unredeemed';
  }
}
