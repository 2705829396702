export interface OpeningTimes {
  monday: OpeningTime;
  tuesday: OpeningTime;
  wednesday: OpeningTime;
  thursday: OpeningTime;
  friday: OpeningTime;
  saturday: OpeningTime;
  sunday: OpeningTime;
}

export interface OpeningTime {
  start: string;
  end: string;
  active: boolean;
}
