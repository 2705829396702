export interface Address {
  type: number;
  placeId: string;
  lineOne: string;
  lineTwo: string;
  postcode: string;
  city: string;
  country: string;
  lat?: number;
  long?: number;
  wtw?: string;
}
