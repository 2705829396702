import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.css'],
})
export class DialogHeaderComponent {
  @Input() title: string = '';
  @Input() subtitle?: string;
  @Output() onClose: EventEmitter<any> = new EventEmitter();

  constructor() {}
}
