<div style="margin-bottom: 10px; display: flex"></div>

<canvas
  baseChart
  [type]="'bar'"
  [data]="barChartData"
  [options]="barChartOptions"
  [plugins]="barChartPlugins"
  [legend]="barChartLegend"
  class="bar-canvas"
></canvas>

<p style="margin-bottom: 0px; font-size: 0px">{{ " " }}</p>
