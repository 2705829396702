import {
  SocialAccount,
  Merchant,
  OpeningTimes,
  File,
  Contact,
  Address,
} from './index';

export interface Location {
  _id?: string;
  nanoid?: string;
  merchant?: string | Merchant;
  name?: string;
  description?: string;
  address?: Address;
  active?: boolean;
  approved?: boolean;
  socials?: SocialAccount[];
  tags?: string[];
  contact?: Contact;
  openingTimes?: OpeningTimes; //Default/GMT
  altOpeningTimes?: OpeningTimes; //BST
  cover?: File;
  logo?: File;
  qrCode?: File;
}
