<dialog-header
  [title]="data?.title ?? 'Confirm'"
  (onClose)="matDialogRef.close()"
></dialog-header>

<div mat-dialog-content class="mat-typography">
  <p class="dialog-body" [innerHTML]="data.body"></p>
</div>

<div mat-dialog-actions align="end">
  <app-secondary-button
    *ngIf="data?.buttonOne"
    [title]="data.buttonOne"
    [disabled]="false"
    (tapped)="buttonOne()"
    style="margin-right: 10px"
  ></app-secondary-button>
  <app-primary-button
    [title]="data.buttonTwo"
    [disabled]="false"
    (tapped)="buttonTwo()"
    bgColor="var(--app-timber)"
    color="white"
  ></app-primary-button>
</div>
