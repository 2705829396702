import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { Constants } from 'src/app/app.constants';
import { HttpErrorResponse } from '@angular/common/http';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { TwoOptionAlertComponent } from 'src/app/shared/components/two-option-alert/two-option-alert.component';
import { ConfigurationDialogComponent } from 'src/app/shared/components/configuration-dialog/configuration-dialog.component';
import {
  IntegrationConfig,
  IntegrationConfiguration,
} from 'src/app/models/integrationConfig';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { ShopifyDataDialogComponent } from 'src/app/shared/components/shopify-data-dialog/shopify-data-dialog.component';
import { InviteDialogComponent } from 'src/app/shared/components/invite-dialog/invite-dialog.component';
import { AuthService, MerchantService } from 'src/app/services';
import { Integration } from '../integrations-home.component';

@Component({
  selector: 'integration-detail-dialog',
  templateUrl: 'integration-detail-dialog.component.html',
  styleUrls: ['./integration-detail-dialog.component.css'],
})
export class IntegrationDetailDialogComponent implements OnInit {
  integrations = {
    squarespace: 'squarespace',
    acuity: 'acuity',
    shopify: 'shopify',
    square: 'square',
    sumup: 'sumup',
    eposnow: 'eposnow',
    storekit: 'storekit',
    zapier: 'zapier',
  };

  error = false;
  saving = false;
  loading = false;

  tabs = ['overview'];
  selectedIndex = 0;
  selectedTab = 'overview';

  merchantActive = false;

  integration!: Integration;

  form!: FormGroup;

  zapierAPIKeyFormControl!: FormControl;

  webhookFormControl!: FormControl;
  webhookEposNowFormControl!: FormControl;

  shopifySigningKey!: FormControl;
  storekitSigningKey!: FormControl;

  acuityAPIKey!: FormControl;
  acuityUserID!: FormControl;

  sumupUsername!: FormControl;
  sumupPassword!: FormControl;
  sumupSigningKey!: FormControl;
  sumupSubdomain!: FormControl;

  eposNowAccessToken!: FormControl;

  addSquareCustomers = false;
  addSumupCustomers = false;
  addEposNowCustomers = false;

  inviteUsersShopify = false;
  inviteUsersSquare = false;
  inviteUsersSumup = false;
  inviteUsersZapier = false;
  inviteUsersEposNow = false;
  inviteUsersStorekit = false;
  inviteUsersSquarespace = false;
  inviteUsersAcuity = false;

  zapierConfig?: IntegrationConfig;
  shopifyConfig?: IntegrationConfig;
  storekitConfig?: IntegrationConfig;
  squareConfig?: IntegrationConfig;
  sumupConfig?: IntegrationConfig;
  eposnowConfig?: IntegrationConfig;
  squarespaceConfig?: IntegrationConfig;
  acuityConfig?: IntegrationConfig;

  hasShopify = false;
  hasStorekit = false;
  hasSumup = false;
  hasEposNow = false;
  hasSquare = false;
  hasSquarespace = false;
  hasAcuity = false;
  hasZapier?: {
    enabled: boolean;
    apiKey: string;
  };

  hasIntegration = false;
  canSave = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<IntegrationDetailDialogComponent>,
    public configDialogRef: MatDialogRef<ConfigurationDialogComponent>,
    public fb: FormBuilder,
    public dialog: MatDialog,
    private merchantService: MerchantService,
    private authService: AuthService,
    public constants: Constants,
    private formBuilder: FormBuilder
  ) {
    this.integration = this.data.integration as Integration;

    this.hasShopify =
      this.authService.getNestedUserProperty('merchant', 'shopify') ?? false;

    this.hasStorekit =
      this.authService.getNestedUserProperty('merchant', 'storekit') ?? false;

    this.hasSumup =
      this.authService.getNestedUserProperty('merchant', 'sumup') ?? false;

    this.hasSquare =
      this.authService.getNestedUserProperty('merchant', 'square') ?? false;

    this.hasEposNow =
      this.authService.getNestedUserProperty('merchant', 'eposnow') ?? false;

    this.hasZapier = this.authService.getNestedUserProperty(
      'merchant',
      'zapier'
    ) ?? { enabled: false };

    this.hasSquarespace =
      this.authService.getNestedUserProperty('merchant', 'squarespace') ??
      false;

    this.hasAcuity =
      this.authService.getNestedUserProperty('merchant', 'acuity') ?? false;

    if (this.integration.value === this.integrations.acuity) {
      this.hasIntegration = this.hasAcuity;
    } else if (this.integration.value === this.integrations.shopify) {
      this.hasIntegration = this.hasShopify;
    } else if (this.integration.value === this.integrations.storekit) {
      this.hasIntegration = this.hasStorekit;
    } else if (this.integration.value === this.integrations.sumup) {
      this.hasIntegration = this.hasSumup;
    } else if (this.integration.value === this.integrations.square) {
      this.hasIntegration = this.hasSquare;
    } else if (this.integration.value === this.integrations.eposnow) {
      this.hasIntegration = this.hasEposNow;
    } else if (this.integration.value === this.integrations.zapier) {
      this.hasIntegration = this.hasZapier?.enabled ?? false;
    } else if (this.integration.value === this.integrations.squarespace) {
      this.hasIntegration = this.hasSquarespace;
    } else {
      this.hasIntegration = false;
    }

    this.zapierAPIKeyFormControl = new FormControl({
      value: '',
      disabled: true,
    });

    const nanoid = this.authService.getNestedUserProperty('merchant', 'nanoid');
    const webhookUrl = `https://api.remyrewards.co.uk/v1/webhooks/orders/create/${nanoid}`;
    const webhookUrlEposNow = `/v1/webhooks/orders/create/${nanoid}`;

    this.webhookFormControl = new FormControl({
      value: webhookUrl,
      disabled: true,
    });

    this.webhookEposNowFormControl = new FormControl({
      value: webhookUrlEposNow,
      disabled: true,
    });

    this.shopifySigningKey = new FormControl({ value: '', disabled: false });

    this.storekitSigningKey = new FormControl({ value: '', disabled: false });

    this.sumupSigningKey = new FormControl({ value: '', disabled: false });
    this.sumupUsername = new FormControl({ value: '', disabled: false });
    this.sumupPassword = new FormControl({ value: '', disabled: false });
    this.sumupSubdomain = new FormControl({ value: '', disabled: false });

    this.eposNowAccessToken = new FormControl({ value: '', disabled: false });

    this.acuityAPIKey = new FormControl({ value: '', disabled: false });
    this.acuityUserID = new FormControl({ value: '', disabled: false });

    this.form = this.formBuilder.group({
      zapierApiKey: this.zapierAPIKeyFormControl,
      webhookUrl: this.webhookFormControl,
      webhookEposNowUrl: this.webhookEposNowFormControl,
      shopifySigningKey: this.shopifySigningKey,
      storekitSigningKey: this.storekitSigningKey,
      sumupSigningKey: this.sumupSigningKey,
      sumupSubdomain: this.sumupSubdomain,
      sumupUsername: this.sumupUsername,
      sumupPassword: this.sumupPassword,
      eposNowAuthToken: this.eposNowAccessToken,
      acuityApiKey: this.acuityAPIKey,
      acuityUserID: this.acuityUserID,
    });
  }

  ngOnInit(): void {
    this.merchantActive = this.authService.merchantObj?.active ?? true;

    this.loadData();
  }

  copyApiKey(): void {
    const copyHandler = (e: ClipboardEvent) => {
      e.clipboardData!.setData(
        'text/plain',
        this.zapierAPIKeyFormControl.value
      );
      e.preventDefault();
      document.removeEventListener('copy', copyHandler);
    };

    document.addEventListener('copy', copyHandler);

    navigator.clipboard
      .writeText(this.zapierAPIKeyFormControl.value)
      .then(() => {
        this.constants.snack('Zapier API key copied to clipboard');
      })
      .catch((err) => {
        console.error('Failed to copy text to clipboard:', err);
      });
  }

  copyWebhookUrl(): void {
    const copyHandler = (e: ClipboardEvent) => {
      e.clipboardData!.setData('text/plain', this.webhookFormControl.value);
      e.preventDefault();
      document.removeEventListener('copy', copyHandler);
    };

    document.addEventListener('copy', copyHandler);

    navigator.clipboard
      .writeText(this.webhookFormControl.value)
      .then(() => {
        this.constants.snack('Webhook URL copied to clipboard');
      })
      .catch((err) => {
        console.error('Failed to copy text to clipboard:', err);
      });
  }

  loadData(): void {
    const clearValidatorsFor = (controls: AbstractControl[]) => {
      controls.forEach((control) => control.clearValidators());
    };

    const setValidatorsFor = (
      controls: { control: AbstractControl; validators: ValidatorFn[] }[]
    ) => {
      controls.forEach(({ control, validators }) =>
        control.setValidators(validators)
      );
    };

    const updateValidityFor = (controls: AbstractControl[]) => {
      controls.forEach((control) => control.updateValueAndValidity());
    };

    const allControls = [
      this.shopifySigningKey,
      this.storekitSigningKey,
      this.sumupSigningKey,
      this.sumupSubdomain,
      this.sumupUsername,
      this.sumupPassword,
      this.eposNowAccessToken,
      this.zapierAPIKeyFormControl,
      this.acuityAPIKey,
      this.acuityUserID,
    ];

    const integrationsConfig = {
      [this.integrations.shopify]: {
        validators: [
          {
            control: this.shopifySigningKey,
            validators: [Validators.required],
          },
        ],
        action: () => this.getShopifyIntegration(),
      },
      [this.integrations.sumup]: {
        validators: [
          { control: this.sumupSigningKey, validators: [Validators.required] },
          { control: this.sumupSubdomain, validators: [Validators.required] },
          { control: this.sumupUsername, validators: [Validators.required] },
          { control: this.sumupPassword, validators: [Validators.required] },
        ],
        action: () => this.getSumupIntegration(),
      },
      [this.integrations.eposnow]: {
        validators: [
          {
            control: this.eposNowAccessToken,
            validators: [Validators.required],
          },
        ],
        action: () => this.getEposNowIntegration(),
      },
      [this.integrations.storekit]: {
        validators: [
          {
            control: this.storekitSigningKey,
            validators: [Validators.required],
          },
        ],
        action: () => this.getStorekitIntegration(),
      },
      [this.integrations.squarespace]: {
        validators: [],
        action: () => this.getSquarespaceIntegration(),
      },
      [this.integrations.square]: {
        validators: [],
        action: () => this.getSquareIntegration(),
      },
      [this.integrations.acuity]: {
        validators: [
          {
            control: this.acuityAPIKey,
            validators: [Validators.required],
          },
          {
            control: this.acuityUserID,
            validators: [Validators.required],
          },
        ],
        action: () => this.getAcuityIntegration(),
      },
      default: {
        validators: [
          {
            control: this.zapierAPIKeyFormControl,
            validators: [Validators.required],
          },
        ],
        action: () => this.getZapierIntegration(),
      },
    };

    const selectedConfig =
      integrationsConfig[
        this.integration.value as keyof typeof integrationsConfig
      ] || integrationsConfig.default;

    selectedConfig.action();
    setValidatorsFor(selectedConfig.validators);
    updateValidityFor(allControls);
  }

  showInfoDialog(title: string, body: string): void {
    this.dialog.open(TwoOptionAlertComponent, {
      data: {
        title: title,
        body: body,
        buttonTwo: 'Close',
      },
      width: '450px',
      autoFocus: false,
      disableClose: true,
      panelClass: 'custom-dialog',
      scrollStrategy: new NoopScrollStrategy(),
    });
  }

  viewExampleMail(): void {
    window.open(
      'https://remy-system.s3.eu-west-2.amazonaws.com/Invite+email+example.png',
      '_blank'
    );
  }

  get overviewText(): string {
    return `Use our <b>${this.integration.label}</b> integration to automatically give
        stamps to your customers for purchases in your online
        store or in-store EPos. <b>Customers must be pre-registered</b> on Remy,
        have added your loyalty card(s) to their Remy wallet, and
        place orders using the email associated with their Remy
        account in order for the linking to work. <br><a href="${this.integration.link}">${this.integration.link}</a>
      `;
  }

  // get overviewTextStorekit(): string {
  //   return `Use our <b>${this.integration.label}</b> integration to automatically give
  //       stamps to your customers for purchases in your online
  //       store or in-store EPos. <b>Customers must be pre-registered</b> on Remy,
  //       have added your loyalty card(s) to their Remy wallet, and
  //       place orders using the email associated with their Remy
  //       account in order for the linking to work. <a
  //                         href="https://remy-system.s3.eu-west-2.amazonaws.com/Remy+x+Storekit.pdf" target="_blank"
  //                         >Overview Guide</a
  //                       >
  //     `;
  // }

  get titleOne(): string {
    return 'Shopify Integration Setup';
  }

  get titleOneZapier(): string {
    return 'Zapier Integration Setup';
  }

  get titleOneSquare(): string {
    return 'Square Integration Setup';
  }

  get titleOneSumup(): string {
    return 'SumUp Integration Setup';
  }

  get titleOneEposnow(): string {
    return 'EposNow Integration Setup';
  }

  get titleOneStorekit(): string {
    return 'Storekit Integration Setup';
  }

  get titleOneAcuity(): string {
    return 'acuity:scheduling Integration Setup';
  }

  get titleTwo(): string {
    return 'Webhook Signing Key';
  }

  get titleTwoAcuity(): string {
    return 'API Key';
  }

  get titleThreeAcuity(): string {
    return 'User ID';
  }

  get titleTwoSquare(): string {
    return 'Webhook Signature Key';
  }

  get titleTwoSumup(): string {
    return 'Webhook Verification Token';
  }

  get titleThreeSumup(): string {
    return 'Webbook Subdoman';
  }

  get bodyOne(): string {
    return `You'll need to create a new Webhook in your Shopify store using this URL, following these instructions:<br><br>

    1. Navigate to your <a href="https://admin.shopify.com/store" target="_blank">Shopify store</a><br>
    2. Open <b>Settings</b><br>
    3. Click on <b>Notifications</b> in the left sidebar<br>
    4. Open <b>Webhooks</b><br>
    5. Create a new Webhook<br>
    6. Select the <b>Order Payment</b> Event, and the <b>JSON</b> format<br>
    7. Paste your unique <b>Webhook URL</b> into the URL field<br>
    8. Select the <b>latest</b> Webhook API version<br>
    9. Your Webhook is now set up, and Remy will be notified when orders have been paid for
  `;
  }

  get bodyOneStorekit(): string {
    return `You'll need to create a new Webhook in your Storekit store using this URL, following these instructions:<br><br>

    1. Navigate to your <a href="https://dashboard.storekit.com/settings/webhooks" target="_blank">Storekit store</a><br>
    2. If it's not enabled, then you'll need to ask the Storekit team to enable it<br>
    3. Click <b>+ Add Endpoint</b><br>
    4. Enter this  Webhook URL into the <b>Endpoint URL</b> field in Storekit<br>
    5. From the <b>Subscribe to events</b> section, select <b>order.created</b><br>
    6. Click <b>Create</b>
  `;
  }

  get bodyOneAcuity(): string {
    return `You'll need to set up Webhooks in your acuity:scheduling dashboard using this URL, following these instructions:<br><br>

    1. Navigate to your acuity:scheduling admin dashboard<br>
    2. Click on <b>Integrations</b> in the sidebar menu<br>
    3. Scroll down to <b>Webhooks</b> and click <b>Set Up</b><br>
    4. In the popup which appears from the right, enter the Webhook URL into the <b>NEW APPOINTMENT SCHEDULED</b> and <b>ORDER COMPLETED</b> fields then press <b>Save Settings</b><br>
  `;
  }

  get bodyOneZapier(): string {
    return `Follow the below instructions to get started with the Zapier x Remy integration:<br><br>

    1. Navigate to <a href="https://zapier.com/apps" target="_blank">Zapier</a><br>
    2. Add the <b>Remy Rewards</b> integration<br>
    3. Securely connect your Zap to Remy using your API key <br>
    4. Select the <b>Create Order</b> event<br>
    5. Ensure that the Customer Email, Order Number, Order Value and Order Source are populated from your chosen source (e.g. storekit, deliveroo or just eat order details)<br>
    6. Your Zapier integration is now set up, and Remy will be notified when orders have been placed from your chosen source<br>
    7. It's important to note that the customer must already have your Remy loyalty card using the same email address provided in the Zap<br>
    8. Refer to this <a href="https://remy-rewards.notion.site/Remy-x-Zapier-Integration-Guide-4daaafed390b4a6aa1595b92ab24ff02" target="_blank">Zapier x Remy integration guide</a> for more information
  `;
  }

  get bodyOneSquare(): string {
    return `You'll need to create a new App and Webhook in your <b>Square</b> store using this URL, following these instructions:<br><br>

    1. Navigate to your <a href="https://developer.squareup.com/apps" target="_blanl">Square dashboard</a><br>
    2. Create a new App with the name <b>Remy</b> and skip the next two steps<br>
    3. Open the newly created App, and open <b>Webhooks/Subscriptions</b> from the left sidebar<br>
    4. Ensure that the <b>Production</b> tab is selected (not Sandbox)<br>
    5. Select <b>Add subscription</b><br>
    6. Give your webhook the name <b>remy-webook</b><br>
    7. Paste your <b>Webhook URL</b> into the <b>URL</b> field<br>
    8. Select the <b>latest</b> API version<br>
    9. Select the <b>payment.created</b> Event only<br>
    10. Save your webhook<br>
    11. Click on the new webhook you just created, and copy the <b>Signature key</b>, you'll need this for the next step
  `;
  }

  get bodyOneSumup(): string {
    return `You'll need to create a new Webhook in your <b>SumUp</b> POS Back Office using this URL, following these instructions:<br><br>

    1. Navigate to your <a href="https://pos.thegoodtill.com/app/integrations/webhook" target="_blank">SumUp Goodtill store</a><br>
    2. Paste your <b>Webhook URL</b> into the <b>Webhook URL</b> field<br>
    3. Tick the <b>sale.completed</b> event<br>
    4. Tick the <b>Enable Wehook Integration</b> option at the bottom<br>
    5. Click <b>Save Settings</b><br>
    6. Copy the <b>Verification Token</b> located above the table, you'll need this for the next step<br><br>
    7. To allow Remy to fetch information about eligible orders you will need to setup a new Admin user, navigate <a href="https://pos.thegoodtill.com/app/users/all" target="_blank">HERE</a><br>
    8. Click <b>+ Add new User</b> with the following details:<br>
    9. Name: <b>Remy</b><br>
    10. Email: <b>hello@remyrewards.co.uk</b><br>
    11. Set a username and password of your choosing, make a note of these as you'll need them for the later steps<br>
    13. Role: <b>Admin</b><br>
    14. Make sure the <b>Active</b> box is ticked, then click <b>Submit</b>
  `;
  }

  get bodyOneEposNow(): string {
    return `You'll need to create a new Webhook in your <b>EposNow</b> Back Office using this URL, following these instructions:<br><br>

    1. Navigate to your <a href="https://www.eposnowhq.com/Pages/BackOffice/Integrations/WebHooks/WebHookList.aspx" target="_blank">Epos Now back-office</a><br>
    2. Click on <b>ADD WEBHOOK TRIGGGER</b><br>
    3. Select the API device you created earlier, the one you called <b>Remy</b><br>
    4. Enter the Webhook Route Path into the <b>Route Path</b> field<br>
    5. Select the <b>Complete Transaction</b> event<br>
    6. Click <b>ADD</b> to save the webhook
  `;
  }

  get bodyTwo(): string {
    return `Locate your Shopify Webhook Signing Key in the Webhook section, as outlined in the previous step. It's identified by a lengthy character string next to the label <b>'Your webhooks will be signed with'</b> and paste it here`;
  }

  get bodyTwoStorekit(): string {
    return `Locate your Storekit Webhook Signing Key in the Order Created webhook overview from the previous step. It's identified by a lengthy masked string under the label <b>'Signing Secret'</b>, you'll need to click on the eye icon to reveal it - it begins with 'whsec', then paste it here `;
  }

  get bodyTwoAcuity(): string {
    return `Locate your acuity:scheduling API Key from the API section of your Integrations page in your acuity:scheduling dashboard, reveal your API Key by clicking on <b>View Credentials</b>`;
  }

  get bodyThreeAcuity(): string {
    return `Locate your acuity:scheduling User ID from the API section of your Integrations page in your acuity:scheduling dashboard, reveal your User ID by clicking on <b>View Credentials</b>`;
  }

  get bodyTwoSquare(): string {
    return `Click on your newly created Square Webhook, and copy the <b>Signature key</b> and paste it here`;
  }

  get bodyTwoSumup(): string {
    return `From your Webhook Settings page which you accessed from the previous step, copy the <b>Verification Token</b> and paste it here`;
  }

  get bodyThreeSumup(): string {
    return `From your main Settings page, click on <b>Global Settings</b>, copy the <b>Subdomain</b> value`;
  }

  get accessTokenTitle(): string {
    return `Square Access Token`;
  }

  get accessTokenTitleEposnow(): string {
    return `Epos Now Authorization Token`;
  }

  get accessTokenBody(): string {
    return `Open the newly created app in your <a href="https://developer.squareup.com/apps" target="_blank">Square dashboard</a>, then follow these instructions:<br><br>

    1. Click on the <b>Credentials</b> tab in the left sidebar<br>
    2. Ensure that the <b>Production</b> segment is selected<br>
    3. Copy the <b>Production Access Token</b><br><br>
    <i><b>Important</b>: When copying the Access Token, make sure to copy the un-masked version of this, and not just the bullet points you see (••••)</i>
  `;
  }

  get accessTokenBodyEposnow(): string {
    return `
    Open the API app in your EposNow back office interface
    <a href="https://www.eposnowhq.com/Pages/BackOffice/Integrations/REST/RESTManagement.aspx" target="_blank">HERE</a>, then follow these instructions:<br><br>

    1. Click on the <b>REST API</b> tab in the left sidebar<br>
    2. Create a new API Device, you can call this <b>Remy</b> and set the location to your main store<br>
    3. Paste the following URL into the <b>API URL</b> field: <b>https://api.remyrewards.co.uk</b><br>
    4. Click on the <b>Access Credentials</b> button<br>
    5. Copy the <b>Authorization Token</b> and paste it into this field
  `;
  }

  remove(): void {
    const dialog = this.dialog.open(TwoOptionAlertComponent, {
      data: {
        title: `Remove ${this.integration.label} Integration`,
        body: `Removing your ${
          this.integration.label
        } integration will mean that purchases ${
          this.integration.value === 'zapier'
            ? 'captured through your Zapier integration'
            : 'made in your ' + this.integration.label + ' store'
        } will no longer by linked to your Remy customer loyalty cards<br><br>Do you want to continue with the de-integration?`,
        buttonOne: 'Cancel',
        buttonTwo: 'Continue',
      },
      width: '500px',
      autoFocus: false,
      disableClose: true,
      scrollStrategy: new NoopScrollStrategy(),
      panelClass: 'custom-dialog',
    });

    dialog.afterClosed().subscribe((option: boolean) => {
      if (option) {
        this.removeIntegration();
      }
    });
  }

  getShopifyIntegration(): void {
    this.loading = true;

    this.merchantService
      .getIntegration(this.integrations.shopify)
      .subscribe({
        next: (res?: IntegrationConfig) => {
          if (res) {
            this.shopifyConfig = res;

            this.shopifyConfig.configurations?.sort((a, b) =>
              (a?.type ?? 'order').localeCompare(b?.type ?? 'order')
            );

            this.shopifySigningKey.setValue(this.shopifyConfig?.signingKey);

            this.inviteUsersShopify =
              this.shopifyConfig.inviteUser?.active ?? false;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  getSquareIntegration(): void {
    this.loading = true;

    this.merchantService
      .getIntegration(this.integrations.square)
      .subscribe({
        next: (res?: IntegrationConfig) => {
          if (res) {
            this.squareConfig = res;
            this.squareConfig.configurations?.sort((a, b) =>
              (a?.type ?? 'order').localeCompare(b?.type ?? 'order')
            );

            this.addSquareCustomers = this.squareConfig.addCustomers ?? false;

            this.inviteUsersSquare =
              this.squareConfig.inviteUser?.active ?? false;

            if (!this.squareConfig?.oauth?.updatedOn) {
              this.canSave = false;
            }
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  getSumupIntegration(): void {
    this.loading = true;

    this.merchantService
      .getIntegration(this.integrations.sumup)
      .subscribe({
        next: (res?: IntegrationConfig) => {
          if (res) {
            this.sumupConfig = res;
            this.sumupConfig.configurations?.sort((a, b) =>
              (a?.type ?? 'order').localeCompare(b?.type ?? 'order')
            );

            this.sumupSigningKey.setValue(this.sumupConfig?.signingKey);
            this.sumupSubdomain.setValue(this.sumupConfig.subdomain);
            this.sumupUsername.setValue(this.sumupConfig?.username);
            this.sumupPassword.setValue(this.sumupConfig?.password);

            this.addSumupCustomers = this.sumupConfig.addCustomers ?? false;

            this.inviteUsersSumup =
              this.sumupConfig.inviteUser?.active ?? false;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  getEposNowIntegration(): void {
    this.loading = true;

    this.merchantService
      .getIntegration(this.integrations.eposnow)
      .subscribe({
        next: (res?: IntegrationConfig) => {
          if (res) {
            this.eposnowConfig = res;
            this.eposnowConfig.configurations?.sort((a, b) =>
              (a?.type ?? 'order').localeCompare(b?.type ?? 'order')
            );

            this.eposNowAccessToken.setValue(this.eposnowConfig?.accessToken);

            this.addEposNowCustomers = this.eposnowConfig.addCustomers ?? false;

            this.inviteUsersEposNow =
              this.eposnowConfig.inviteUser?.active ?? false;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  getStorekitIntegration(): void {
    this.loading = true;

    this.merchantService
      .getIntegration(this.integrations.storekit)
      .subscribe({
        next: (res?: IntegrationConfig) => {
          if (res) {
            this.storekitConfig = res;
            this.storekitConfig.configurations?.sort((a, b) =>
              (a?.type ?? 'order').localeCompare(b?.type ?? 'order')
            );

            this.storekitSigningKey.setValue(this.storekitConfig?.signingKey);

            this.inviteUsersStorekit =
              this.storekitConfig.inviteUser?.active ?? false;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  getAcuityIntegration(): void {
    this.loading = true;

    this.merchantService
      .getIntegration(this.integrations.acuity)
      .subscribe({
        next: (res?: IntegrationConfig) => {
          if (res) {
            this.acuityConfig = res;
            this.acuityConfig.configurations?.sort((a, b) =>
              (a?.type ?? 'order').localeCompare(b?.type ?? 'order')
            );

            this.acuityAPIKey.setValue(this.acuityConfig?.apiKey);
            this.acuityUserID.setValue(this.acuityConfig?.userId);

            this.inviteUsersAcuity =
              this.acuityConfig.inviteUser?.active ?? false;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  getZapierIntegration(): void {
    this.loading = true;

    this.merchantService
      .getIntegration(this.integrations.zapier)
      .subscribe({
        next: (res?: IntegrationConfig) => {
          if (res) {
            this.zapierConfig = res;
            this.zapierConfig.configurations?.sort((a, b) =>
              (a?.type ?? 'order').localeCompare(b?.type ?? 'order')
            );

            this.zapierAPIKeyFormControl.setValue(this.hasZapier?.apiKey);
            // this.zapierAPIKeyFormControl.setValue(this.zapierConfig?.apiKey);

            this.inviteUsersZapier =
              this.zapierConfig.inviteUser?.active ?? false;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  getSquarespaceIntegration(): void {
    this.loading = true;

    this.merchantService
      .getIntegration('squarespace')
      .subscribe({
        next: (res?: IntegrationConfig) => {
          if (res) {
            this.squarespaceConfig = res;
            this.squarespaceConfig.configurations?.sort((a, b) =>
              (a?.type ?? 'order').localeCompare(b?.type ?? 'order')
            );

            this.inviteUsersSquarespace =
              this.squarespaceConfig.inviteUser?.active ?? false;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  save(): void {
    if (this.form.invalid) {
      this.constants.snack('Please complete all required fields');

      // Loop through form controls to find invalid ones
      Object.keys(this.form.controls).forEach((key) => {
        const controlErrors = this.form.get(key)?.errors;
        if (controlErrors) {
          console.log(`Field: ${key}, Errors:`, controlErrors);
        }
      });

      return;
    }

    this.saving = true;

    if (this.integration.value === 'shopify') {
      this.saveShopify();
    } else if (this.integration.value === 'square') {
      this.saveSquare();
    } else if (this.integration.value === 'sumup') {
      this.saveSumup();
    } else if (this.integration.value === 'eposnow') {
      this.saveEposNow();
    } else if (this.integration.value === 'storekit') {
      this.saveStorekit();
    } else if (this.integration.value === 'squarespace') {
      this.saveSquarespace();
    } else if (this.integration.value === 'acuity') {
      this.saveAcuity();
    } else {
      this.saveZapier();
    }
  }

  saveShopify(): void {
    const key = this.shopifySigningKey.value?.trim();

    this.merchantService
      .addShopify({
        key: key,
        inviteUsers: this.inviteUsersShopify,
      })
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.constants.snack('Shopify Integration Updated');

            if (this.shopifyConfig) {
              this.shopifyConfig.signingKey = key;
            }

            if (!this.shopifyConfig?.configurations?.length) {
              this.addConfigDialog();
            }
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  saveSumup(): void {
    const key = this.sumupSigningKey.value?.trim();
    const subdomain = this.sumupSubdomain.value?.trim();
    const username = this.sumupUsername.value?.trim();
    const password = this.sumupPassword.value?.trim();

    this.merchantService
      .addSumup({
        key: key,
        subdomain: subdomain,
        username: username,
        password: password,
        addCustomers: this.addSumupCustomers,
        inviteUsers: this.inviteUsersSumup,
      })
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.constants.snack('SumUp Integration Updated');

            if (this.sumupConfig) {
              this.sumupConfig.signingKey = key;
              this.sumupConfig.subdomain = subdomain;
            }

            if (!this.sumupConfig?.configurations?.length) {
              this.addConfigDialog();
            }

            this.matDialogRef.close();
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  saveEposNow(): void {
    const token = this.eposNowAccessToken?.value?.trim();

    this.merchantService
      .addEposNow({
        token: token,
        addCustomers: this.addEposNowCustomers,
        inviteUsers: this.inviteUsersEposNow,
      })
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.constants.snack('EposNow Integration Updated');

            if (this.eposnowConfig) {
              this.eposnowConfig.accessToken = token;
            }

            if (!this.eposnowConfig?.configurations?.length) {
              this.addConfigDialog();
            }

            this.matDialogRef.close();
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  saveStorekit(): void {
    const key = this.storekitSigningKey?.value?.trim();

    this.merchantService
      .addStorekit({
        key: key,
        inviteUsers: this.inviteUsersStorekit,
      })
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.constants.snack('Storekit Integration Updated');

            if (this.storekitConfig) {
              this.storekitConfig.signingKey = key;
            }

            if (!this.storekitConfig?.configurations?.length) {
              this.addConfigDialog();
            }

            this.matDialogRef.close();
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  saveSquare(): void {
    this.merchantService
      .addSquare({
        addCustomers: this.addSquareCustomers,
        inviteUsers: this.inviteUsersSquare,
      })
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.constants.snack('Square Integration Updated');

            if (!this.squareConfig?.configurations?.length) {
              this.addConfigDialog();
            }
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  saveAcuity(): void {
    const key = this.acuityAPIKey?.value?.trim();
    const userId = this.acuityUserID?.value?.trim();

    this.merchantService
      .addAcuity({
        key: key,
        userId: userId,
        inviteUsers: this.inviteUsersAcuity,
      })
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.constants.snack('Acuity Integration Updated');

            if (this.acuityConfig) {
              this.acuityConfig.apiKey = key;
            }

            if (!this.acuityConfig?.configurations?.length) {
              this.addConfigDialog();
            }
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  saveZapier(): void {
    this.merchantService
      .addZapier({
        inviteUsers: this.inviteUsersZapier,
      })
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.constants.snack('Zapier Integration Updated');
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  saveSquarespace(): void {
    this.merchantService
      .addSquarespace({
        inviteUsers: this.inviteUsersSquarespace,
      })
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.constants.snack('Squarespace Integration Updated');
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  addConfigDialog(): void {
    this.dialog.open(TwoOptionAlertComponent, {
      data: {
        title: 'Configurations',
        body: 'You need to add at least one configuration before your integration is complete, you can do this by clicking on "<b>+ Add a Configuration"</b>',

        buttonTwo: 'OK',
      },
      scrollStrategy: new NoopScrollStrategy(),
      autoFocus: false,
      width: '350px',
      disableClose: true,
      panelClass: 'custom-dialog',
    });
  }

  removeIntegration(): void {
    const type = this.integration.value;

    this.saving = true;

    if (type === this.integrations.squarespace) {
      this.merchantService
        .disconnectSquarespace()
        .subscribe({
          next: (res: any) => {
            if (res) {
              this.constants.snack(
                `${this.integration.label} integration removed`
              );

              this.constants
                .callbackSnack(
                  'Squarespace integration removed successfully',
                  true
                )
                .subscribe(() => {
                  this.matDialogRef.close();
                  location.reload();
                });
            }
          },
          error: (res: HttpErrorResponse) => {
            this.constants.snack(res.error.message);
          },
        })
        .add(() => (this.saving = false));
    } else {
      this.merchantService
        .removeIntegration(type)
        .subscribe({
          next: (res: any) => {
            if (res) {
              this.constants
                .callbackSnack(
                  `${this.integration.label} integration removed successfully`,
                  true
                )
                .subscribe(() => {
                  this.matDialogRef.close();
                  location.reload();
                });
            }
          },
          error: (res: HttpErrorResponse) => {
            this.constants.snack(res.error.message);
          },
        })
        .add(() => (this.saving = false));
    }
  }

  get canAddConfiguration(): boolean {
    // if (this.config?.configurations?.length) {
    //   return false;
    // }

    return true;
  }

  addConfiguration(): void {
    var canAddOrderValue = false;

    if (!this.config?.configurations?.find((el) => el.type === 'order')) {
      canAddOrderValue = true;
    }

    this.configDialogRef = this.dialog.open(ConfigurationDialogComponent, {
      width: '500px',
      autoFocus: false,
      disableClose: true,
      panelClass: 'custom-dialog',
      data: {
        orderAvailable: canAddOrderValue,
        type: this.integration.label,
      },
      scrollStrategy: new NoopScrollStrategy(),
    });

    this.configDialogRef.afterClosed().subscribe((data: any) => {
      if (data) {
        this.addNewConfiguration(data);
      }
    });
  }

  addNewConfiguration(data: IntegrationConfiguration): void {
    this.loading = true;

    this.merchantService
      .addIntegrationConfiguration(data, this.integration.value)
      .subscribe({
        next: (res?: IntegrationConfig) => {
          if (res) {
            this.configDialogRef.close();
            this.updateConfigurations(res);
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  updateConfigurations(res: IntegrationConfig): void {
    let config: IntegrationConfig | undefined;

    switch (this.integration.value) {
      case this.integrations.acuity:
        config = this.acuityConfig;
        break;
      case this.integrations.eposnow:
        config = this.eposnowConfig;
        break;
      case this.integrations.shopify:
        config = this.shopifyConfig;
        break;
      case this.integrations.square:
        config = this.squareConfig;
        break;
      case this.integrations.storekit:
        config = this.storekitConfig;
        break;
      case this.integrations.sumup:
        config = this.sumupConfig;
        break;
      case this.integrations.squarespace:
        config = this.squarespaceConfig;
        break;
      default:
        config = this.zapierConfig;
    }

    if (res.configurations) {
      if (config) {
        config.configurations = res.configurations;
      }
    }
  }

  get config(): IntegrationConfig | undefined {
    let config: IntegrationConfig | undefined;

    switch (this.integration.value) {
      case this.integrations.acuity:
        config = this.acuityConfig;
        break;
      case this.integrations.eposnow:
        config = this.eposnowConfig;
        break;
      case this.integrations.shopify:
        config = this.shopifyConfig;
        break;
      case this.integrations.square:
        config = this.squareConfig;
        break;
      case this.integrations.storekit:
        config = this.storekitConfig;
        break;
      case this.integrations.sumup:
        config = this.sumupConfig;
        break;
      case this.integrations.squarespace:
        config = this.squarespaceConfig;
        break;
      default:
        config = this.zapierConfig;
    }

    return config;
  }

  editConfiguration(data: IntegrationConfiguration): void {
    this.configDialogRef = this.dialog.open(ConfigurationDialogComponent, {
      width: '500px',
      autoFocus: false,
      disableClose: true,
      panelClass: 'custom-dialog',
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        configuration: data,
        type: this.integration.label,
      },
    });

    this.configDialogRef.afterClosed().subscribe((data: any) => {
      if (data) {
        this.updateConfiguration(data);
      }
    });
  }

  updateConfiguration(data: IntegrationConfiguration): void {
    this.loading = true;

    this.merchantService
      .updateIntegrationConfiguration(data, this.integration.value)
      .subscribe({
        next: (res: IntegrationConfig) => {
          if (res) {
            this.configDialogRef.close();
            this.updateConfigurations(res);
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  deleteConfiguration(id: string): void {
    const dialog = this.dialog.open(TwoOptionAlertComponent, {
      data: {
        title: 'Remove Configuration',
        body: 'Do you want to remove this configuration?',
        buttonOne: 'Cancel',
        buttonTwo: 'Yes',
      },
      width: '400px',
      autoFocus: false,
      disableClose: true,
      panelClass: 'custom-dialog',
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialog.afterClosed().subscribe((option: boolean) => {
      if (option) {
        this.loading = true;

        this.merchantService
          .deleteIntegrationConfiguration(id, this.integration.value)
          .subscribe({
            next: (res?: IntegrationConfig) => {
              if (res) {
                this.updateConfigurations(res);
              }
            },
            error: (res: HttpErrorResponse) => {
              this.constants.snack(res.error.message);
            },
          })
          .add(() => (this.loading = false));
      }
    });
  }

  viewData(): void {
    this.dialog.open(ShopifyDataDialogComponent, {
      data: {
        title:
          this.integration.value === 'acuity'
            ? 'acuity:scheduling orders/appointments (Qualifying)'
            : this.integration.value === 'shopify'
            ? 'Shopify Orders (Qualifying)'
            : this.integration.value == 'square'
            ? 'Square Orders (Qualifying)'
            : this.integration.value === 'sumup'
            ? 'SumUp Orders (Qualifying)'
            : this.integration.value === 'eposnow'
            ? 'EposNow Orders (Qualifying)'
            : this.integration.value === 'storekit'
            ? 'Storekit Orders (Qualifying)'
            : this.integration.value === 'squarespace'
            ? 'Squarespace Orders (Qualifying)'
            : 'Zapier Orders (Qualifying)',
        type: this.integration.value,
      },
      autoFocus: false,
      width: '1200px',
      minHeight: '600px',
      panelClass: 'custom-dialog',
      scrollStrategy: new NoopScrollStrategy(),
    });
  }

  viewInvites(): void {
    this.dialog.open(InviteDialogComponent, {
      data: {
        title: 'Invites sent to customers',
        type: this.integration.value,
      },
      autoFocus: false,
      width: '1200px',
      minHeight: '600px',
      panelClass: 'custom-dialog',
      scrollStrategy: new NoopScrollStrategy(),
    });
  }

  get infoIconTooltip(): string {
    return 'Customers may qualify for multiple stamps through various value and product configurations. Use this setting to limit customers to a single stamp per order, regardless of the number of qualifying products';
  }

  get addCustomersTooltip(): string {
    return 'Enable this for customers to be automatically added to your Epos customer list when they take one of your Remy loyalty cards for the first time, their email and name will be taken';
  }

  get inviteUsersTooltip(): string {
    return 'Enable this feature to immediately email customers without a Remy account or loyalty card, inviting them to join - they will only be emailed once';
  }

  rowLabel(item: IntegrationConfiguration) {
    let stampString = item.numberOfStamps === 1 ? 'stamp' : 'stamps';
    let pointString = item.numberOfPoints === 1 ? 'point' : 'points';
    let str = item.numberOfStamps ? stampString : pointString;
    let qty = item.numberOfStamps ?? item.numberOfPoints;

    if (item.type === 'order') {
      if (item?.method === 'fixed' || item.numberOfStamps) {
        return `Spend ${item?.value} or more to get ${qty} ${str}`;
      } else {
        return `Earn ${qty} ${str} for every ${item?.value} spent ${
          item?.cap ? '(up to ' + item?.cap + ' points)' : ''
        }`;
      }
    } else {
      return `${'SKU ' + item?.sku} (${qty} ${str})`;
    }
  }

  connectOauth(): void {
    this.loading = true;

    this.merchantService.generateOauthUrl(this.integration.value).subscribe({
      next: (res: string) => {
        if (res) {
          location.href = res;
        }
      },
      error: (res: HttpErrorResponse) => {
        this.loading = false;
        this.constants.snack(res.error.message);
      },
    });
    // .add(() => (this.loading = false));
  }

  tabChanged(index: any): void {
    let tab = this.tabs[index];

    this.selectedTab = tab;
  }

  isNotEmpty = (obj: any): boolean => {
    if (!obj) {
      return false;
    }

    return Object.keys(obj).length > 0;
  };
}
