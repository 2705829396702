import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { Constants } from 'src/app/app.constants';
import { HttpErrorResponse } from '@angular/common/http';
import { ChatMessage, Merchant, User } from '../../../models/index';
import { MerchantService, UserService } from '../../../services/index';

@Component({
  selector: 'conversation-settings',
  templateUrl: './conversation-settings-dialog.component.html',
  styleUrls: ['./conversation-settings-dialog.component.css'],
})
export class ConversationSettingsDialogComponent implements OnInit {
  error = false;
  saving = false;
  loading = false;

  merchant?: Merchant;

  canBeMessaged = false;
  emailNotifications = true;

  form!: FormGroup;
  infoTextFormControl: FormControl = new FormControl();

  constructor(
    public constants: Constants,
    public matDialogRef: MatDialogRef<ConversationSettingsDialogComponent>,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private userService: UserService,
    private merchantService: MerchantService
  ) {
    this.infoTextFormControl = new FormControl({ value: '', disabled: false });

    this.form = this.fb.group({
      infoText: this.infoTextFormControl,
    });
  }

  ngOnInit(): void {
    this.getMerchant();
  }

  saveSettings(): void {
    this.saving = true;
    this.merchantService
      .updateConversationSettings(
        this.canBeMessaged,
        this.emailNotifications,
        this.infoTextFormControl.value ?? ''
      )
      .subscribe({
        next: (res: ChatMessage) => {
          if (res) {
            this.constants.snack('Conversation settings updated');
            this.matDialogRef.close(true);
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const now = new Date();
    if (
      date.getFullYear() === now.getFullYear() &&
      date.getMonth() === now.getMonth() &&
      date.getDate() === now.getDate()
    ) {
      return 'Today';
    } else if (
      date.getFullYear() === now.getFullYear() &&
      date.getMonth() === now.getMonth() &&
      date.getDate() === now.getDate() - 1
    ) {
      return 'Yesterday';
    } else {
      return date.toDateString();
    }
  }

  getMerchant(): void {
    this.loading = true;

    this.userService
      .getCurrentUser()
      .subscribe({
        next: (res: User) => {
          if (res) {
            if (typeof res.merchant !== 'string') {
              this.merchant = res.merchant;
              this.canBeMessaged =
                this.merchant?.conversations?.canBeMessaged ?? true;

              this.emailNotifications =
                this.merchant?.conversations?.emailNotifications ?? true;

              this.infoTextFormControl.setValue(
                this.merchant?.conversations?.info
              );
            }
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  save(): void {}
}
