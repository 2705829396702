import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constants } from 'src/app/app.constants';
import { StampCard } from 'src/app/models';
import { CardService } from 'src/app/services';

interface FilterOptions {
  label: string;
  key: string;
  followOn?: {
    key: string;
    label: string;
    formControl: FormControl;
  };
}

@Component({
  selector: 'recipients-filter-dialog',
  templateUrl: './recipients-filter-dialog.component.html',
  styleUrls: ['./recipients-filter-dialog.component.css'],
})
export class RecipientsFilterDialog implements OnInit {
  filterOptions: FilterOptions[] = [];

  filters: any = {};
  hasFilters = false;
  cardsLoading = false;
  cards: StampCard[] = [];

  form!: FormGroup;
  cardsFormControl: FormControl = new FormControl();

  constructor(
    private cardService: CardService,
    public matDialogRef: MatDialogRef<RecipientsFilterDialog>,
    public fb: FormBuilder,
    private constants: Constants,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.filters = this.cloneFilters(data?.filters);

    this.hasFilters = this.getHasFilters();

    this.form = this.fb.group({
      cards: this.cardsFormControl,
    });
  }

  ngOnInit(): void {
    this.filterOptions = [
      {
        label: 'Upcoming birthday this month',
        key: 'birthdayThisMonth',
      },
      { label: 'Has never received a reward', key: 'receivedReward' },
      { label: 'Has never redeemed a reward', key: 'redeemedReward' },
      { label: 'Has never received a stamp', key: 'receivedStamp' },
      {
        label: 'Has never received a push notification',
        key: 'receivedNotification',
      },
      {
        label: 'Has un-redeemed rewards',
        key: 'unredeemedRewards',
      },
      {
        label: 'Has not received a stamp in x+ days',
        key: 'lapsedStamps',
        followOn: {
          label: 'Number of days',
          key: 'lapsedStampsDays',
          formControl: new FormControl(),
        },
      },
    ];

    this.getCards();
  }

  getCards(): void {
    this.cardsLoading = true;
    this.cardService
      .getCards(100, 0)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.cards = res.docs;

            const titleCounts = this.cards.reduce((acc, card) => {
              if (card.title) {
                acc[card.title] = (acc[card.title] || 0) + 1;
              }
              return acc;
            }, {} as Record<string, number>);

            this.cards.forEach((el) => {
              if (el.title && titleCounts[el.title] > 1) {
                el.title = `${el.title} (#${el.ref})`;
              }
            });

            if (Array.isArray(this.data?.cards) && this.data.cards.length > 0) {
              this.cardsFormControl.setValue(
                this.cards.filter((el) => this.data.cards.includes(el._id))
              );
            } else {
              this.cardsFormControl.setValue(this.cards);
            }
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.cardsLoading = false));
  }

  toggle(key: string, value: any): void {
    this.filters[key] = value;

    this.hasFilters = this.getHasFilters();
  }

  primaryTapped() {
    let canProceed = true;

    this.filterOptions.forEach((filterOption) => {
      if (filterOption.followOn) {
        const mainFilter = this.filters[filterOption.key];
        const followOnFilter = this.filters[filterOption.followOn.key];

        if (mainFilter && !followOnFilter) {
          canProceed = false;
        }
      }
    });

    if (!this.cardsFormControl.value?.length) {
      this.constants.snack('Please select at least one card');
      return;
    }

    if (!canProceed) {
      this.constants.snack('Please complete all fields');
    } else {
      this.matDialogRef.close({
        filters: this.filters,
        cards: this.cardsFormControl.value.map((el: StampCard) => el._id),
      });
    }
  }

  secondaryTapped() {
    Object.keys(this.filters).forEach((el: any) => {
      this.filters[el] = false;
    });

    this.cardsFormControl.setValue(this.cards);
  }

  private cloneFilters(originalFilters: any): any {
    return originalFilters ? JSON.parse(JSON.stringify(originalFilters)) : {};
  }

  getHasFilters(): boolean {
    var filtersApplied = false;

    Object.keys(this.filters).forEach((el: any) => {
      if (this.filters[el] === true) {
        filtersApplied = true;
      }
    });

    return filtersApplied;
  }
}
