<dialog-header
  title="Filter Recipients"
  (onClose)="matDialogRef.close()"
></dialog-header>

<div
  mat-dialog-content
  style="
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    background-color: var(--app-container);
  "
>
  <div *ngFor="let filter of filterOptions">
    <div style="margin-top: 20px; margin-bottom: 20px">
      <div style="display: flex; justify-content: space-between">
        <div class="form-field-label" style="color: black; font-weight: normal">
          {{ filter?.label }}
        </div>
        <mat-slide-toggle
          [disabled]="false"
          [checked]="filters[filter.key]"
          (change)="toggle(filter.key, $event.checked)"
        >
        </mat-slide-toggle>
      </div>
      <div
        style="
          display: flex;
          justify-content: flex-end;
          align-items: center;
          text-align: right;
        "
        *ngIf="filter.followOn && filters[filter.key]"
      >
        <div
          class="form-field-label"
          style="
            color: black;
            font-weight: normal;
            flex: 3;
            margin-bottom: 0px;
            margin-right: 10px;
          "
        >
          {{ filter?.followOn?.label }}
        </div>
        <input
          style="flex: 1"
          class="form-input"
          autocomplete="off"
          placeholder="e.g. 14"
          (input)="
            toggle(filter.followOn.key, filter.followOn.formControl.value)
          "
          [formControl]="filter.followOn.formControl"
          type="number"
          [maxlength]="4"
          min="0"
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
        />
      </div>
    </div>
  </div>

  <form [formGroup]="form" *ngIf="(cards ?? []).length > 1">
    <hr />
    <div class="form-field" style="margin-top: 20px">
      <multiple-select
        label="Send to customers who have the following cards:"
        [formCtrl]="cardsFormControl"
        [options]="cards"
        [showAll]="false"
        value="title"
      >
      </multiple-select>
    </div>
  </form>
</div>

<div mat-dialog-actions align="end">
  <app-secondary-button
    *ngIf="hasFilters"
    title="Reset"
    [disabled]="false"
    (tapped)="secondaryTapped()"
    style="margin-right: 10px"
  ></app-secondary-button>
  <app-primary-button
    title="Apply"
    [disabled]="false"
    (tapped)="primaryTapped()"
    bgColor="var(--app-timber)"
    color="white"
  ></app-primary-button>
</div>

<loader [loading]="cardsLoading"></loader>
