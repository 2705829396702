import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constants } from 'src/app/app.constants';

@Component({
  selector: 'emoji-dialog',
  templateUrl: './emoji-dialog.component.html',
  styleUrls: ['./emoji-dialog.component.css'],
})
export class EmojiDialogComponent implements OnInit {
  constructor(
    public matDialogRef: MatDialogRef<EmojiDialogComponent>,
    public fb: FormBuilder,
    private constants: Constants,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  emojiClicked(emoji: any) {
    this.matDialogRef.close({ emoji: emoji.emoji.native });
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.constants.snack(`${val} copied to clipboard`);
  }
}
