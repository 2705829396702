import { StampCard, Merchant } from './index';

export interface IntegrationConfiguration {
  _id?: string;
  type?: string;
  value?: number;
  sku?: string;
  card?: string | StampCard;
  numberOfStamps?: number;
}

export interface IntegrationConfig {
  merchant?: string | Merchant;
  signingKey?: string;
  subdomain?: string;
  username?: string;
  password?: string;
  addCustomers?: boolean;
  accessToken?: string;
  configurations?: IntegrationConfiguration[];
  type?: string;
  inviteUser?: {
    active?: boolean;
    withStamps?: boolean;
  };
}
