<div class="container-fluid" *ngIf="!fetching">
  <div class="row">
    <div class="col-md-12" id="left-col">
      <div class="left-content">
        <div class="form-container">
          <div class="img-container">
            <a href="https://remyrewards.co.uk"
              ><img src="../assets/remylogo.png"
            /></a>
          </div>

          <div style="margin-bottom: 10px; font-weight: bold; font-size: 15px">
            Sign up now to start your
            <span style="color: var(--app-timber); cursor: inherit"
              >30-day</span
            >
            free trial
          </div>

          <div style="margin-bottom: 30px; font-size: 15px">
            Already have an account?
            <span style="color: blue" (click)="login()">Sign in</span>
          </div>

          <form [formGroup]="form" autocomplete="off">
            <div class="row">
              <div
                class="col-lg-6"
                [ngStyle]="{
                  'padding-left': '0px',
                  'padding-right': isMobile ? '0px' : '10px'
                }"
              >
                <div class="form-field" style="margin-top: 20px">
                  <div
                    class="form-field-label"
                    style="color: var(--app-timber)"
                  >
                    First name<span style="color: red">*</span>
                  </div>
                  <input
                    class="form-input"
                    autocomplete="new-password"
                    [formControl]="firstNameFormControl"
                    type="text"
                  />
                </div>
              </div>
              <div
                class="col-lg-6"
                [ngStyle]="{
                  'padding-left': '0px',
                  'padding-right': isMobile ? '0px' : '10px'
                }"
              >
                <div class="form-field" style="margin-top: 20px">
                  <div
                    class="form-field-label"
                    style="color: var(--app-timber)"
                  >
                    Last name<span style="color: red">*</span>
                  </div>
                  <input
                    class="form-input"
                    autocomplete="new-password"
                    [formControl]="lastNameFormControl"
                    type="text"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div
                class="col-lg-6"
                [ngStyle]="{
                  'padding-left': '0px',
                  'padding-right': isMobile ? '0px' : '10px'
                }"
              >
                <div class="form-field" style="margin-top: 20px">
                  <single-select
                    *ngIf="countries?.length"
                    label="Country"
                    [formCtrl]="countryFormControl"
                    [options]="countries"
                    property="name"
                    [value]="countryFormControl.value"
                  ></single-select>
                </div>
              </div>
              <div
                class="col-lg-6"
                [ngStyle]="{
                  'padding-left': '0px',
                  'padding-right': isMobile ? '0px' : '10px'
                }"
              >
                <div class="form-field" style="margin-top: 20px">
                  <single-select
                    label="What best describes your business?"
                    [formCtrl]="businessTypeFormControl"
                    [options]="businessTypes"
                    property="name"
                    [webIcon]="true"
                    [showIcon]="true"
                  ></single-select>
                </div>
              </div>
            </div>

            <div class="row">
              <div
                class="col-lg-6"
                [ngStyle]="{
                  'padding-left': '0px',
                  'padding-right': isMobile ? '0px' : '10px'
                }"
              >
                <div class="form-field" style="margin-top: 20px">
                  <div
                    class="form-field-label"
                    style="color: var(--app-timber)"
                  >
                    Business name<span style="color: red">*</span>
                  </div>
                  <input
                    class="form-input"
                    autocomplete="new-password"
                    [formControl]="businessNameFormControl"
                    type="text"
                    placeholder="E.g. The Cosy Cone"
                  />
                </div>
              </div>
              <div
                class="col-lg-6"
                [ngStyle]="{
                  'padding-left': '0px',
                  'padding-right': isMobile ? '0px' : '10px'
                }"
              >
                <div class="form-field" style="margin-top: 20px">
                  <div
                    class="form-field-label"
                    style="color: var(--app-timber)"
                  >
                    Email<span style="color: red">*</span>
                  </div>
                  <input
                    class="form-input"
                    autocomplete="new-password"
                    [formControl]="emailFormControl"
                    type="email"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div
                class="col-lg-6"
                [ngStyle]="{
                  'padding-left': '0px',
                  'padding-right': isMobile ? '0px' : '10px'
                }"
              >
                <div class="form-field" style="margin-top: 20px">
                  <div
                    class="form-field-label"
                    style="color: var(--app-timber)"
                  >
                    Password<span style="color: red">*</span>
                  </div>
                  <div style="display: flex; align-items: center">
                    <input
                      class="form-input"
                      [ngStyle]="{
                        border:
                          passwordValid || !submitted
                            ? '1px solid var(--app-light-grey)'
                            : '2px solid red'
                      }"
                      autocomplete="new-password"
                      [formControl]="passwordFormControl"
                      [type]="hidePassword ? 'password' : 'text'"
                    />
                    <mat-icon
                      class="visibility-icon"
                      (click)="hidePassword = !hidePassword"
                      >{{
                        hidePassword ? "visibility_off" : "visibility"
                      }}</mat-icon
                    >
                  </div>
                </div>
              </div>
              <div
                class="col-lg-6"
                [ngStyle]="{
                  'padding-left': '0px',
                  'padding-right': isMobile ? '0px' : '10px'
                }"
              >
                <div class="form-field" style="margin-top: 20px">
                  <div
                    class="form-field-label"
                    style="color: var(--app-timber)"
                  >
                    Confirm password<span style="color: red">*</span>
                  </div>
                  <input
                    class="form-input"
                    [ngStyle]="{
                      border:
                        passwordValid || !submitted
                          ? '1px solid var(--app-light-grey)'
                          : '2px solid red'
                    }"
                    autocomplete="new-password"
                    [formControl]="confirmPasswordFormControl"
                    [type]="hidePassword ? 'password' : 'text'"
                  />
                </div>
              </div>
            </div>

            <div style="margin-top: 20px">
              <ng-hcaptcha
                (verify)="onVerify($event)"
                (expired)="onExpired($event)"
                (error)="onError($event)"
              >
              </ng-hcaptcha>
            </div>
          </form>

          <div class="action-buttons" style="margin-top: 20px">
            <app-primary-button
              [title]="'Sign up'"
              [disabled]="loading || !captchaSuccess"
              (tapped)="register()"
              bgColor="var(--app-timber)"
              color="white"
            ></app-primary-button>
          </div>

          <div
            class="form-field"
            style="margin-top: 20px; text-align: center; font-size: 11px"
          >
            By signing up, you agree to the
            <a href="https://remyrewards.co.uk/terms/"
              ><span style="font-size: 11px">Terms of Service</span></a
            >
            and
            <a href="https://remyrewards.co.uk/privacy-policy/"
              ><span style="font-size: 11px">Privacy Policy</span></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<loader [loading]="verifyingCaptcha || fetching || loading"></loader>
