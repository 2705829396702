<div class="container-fluid" *ngIf="!pageLoading">
  <div class="row">
    <div class="col-md-12" id="left-col">
      <div class="left-content">
        <div class="form-container">
          <div class="img-container">
            <a href="https://remyrewards.co.uk"
              ><img src="../assets/remylogo.png"
            /></a>
          </div>
          <form [formGroup]="form" *ngIf="!passwordChanged">
            <div class="form-field">
              <div class="form-field-label" style="color: var(--app-timber)">
                Password<span style="color: red">*</span>
              </div>
              <input
                class="form-input"
                autocomplete="new-password"
                [ngStyle]="{
                  border:
                    passwordValid || !submitted
                      ? '1px solid var(--app-light-grey)'
                      : '2px solid red'
                }"
                [formControl]="passwordFormControl"
                type="password"
              />
            </div>
            <div class="form-field" style="margin-top: 20px">
              <div class="form-field-label" style="color: var(--app-timber)">
                Confirm Password<span style="color: red">*</span>
              </div>
              <input
                class="form-input"
                autocomplete="new-password"
                [ngStyle]="{
                  border:
                    passwordValid || !submitted
                      ? '1px solid var(--app-light-grey)'
                      : '2px solid red'
                }"
                [formControl]="confirmPasswordFormControl"
                type="password"
              />
            </div>

            <div class="action-buttons" style="margin-top: 20px">
              <app-primary-button
                [title]="'Change password'"
                [disabled]="loading || form.invalid"
                (tapped)="submit()"
                bgColor="var(--app-timber)"
                color="white"
              ></app-primary-button>
            </div>
          </form>

          <div
            *ngIf="passwordChanged"
            style="
              display: flex;
              justify-content: center;
              flex-direction: column;
              align-items: center;
            "
          >
            <div style="margin-bottom: 20px; text-align: center">
              Your password has been changed, you can now return to the mobile
              app to login
            </div>
            <app-primary-button
              [title]="
                userType === this.constants.userTypes.staff
                  ? 'Open the Remy Connect app'
                  : 'Open the Remy Rewards app'
              "
              (tapped)="openMobileApp()"
            ></app-primary-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<loader [loading]="loading || pageLoading"></loader>
