import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class AIService {
  constructor(private api: ApiService) {}

  PATH = 'v1/ai';

  getIdeas(context: string, includeCustomerName = false) {
    return this.api.post(`${this.PATH}/ideas`, {
      context: context,
      includeCustomerName: includeCustomerName,
    });
  }

  getTags(description: string) {
    return this.api.post(`${this.PATH}/location-tags`, {
      description,
    });
  }
}
