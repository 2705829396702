<dialog-header
  title="Change Password"
  (onClose)="matDialogRef.close()"
></dialog-header>

<div
  mat-dialog-content
  *ngIf="!loading"
  style="
    padding-bottom: 20px;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    background-color: var(--app-container);
  "
>
  <form [formGroup]="form" style="margin-top: 20px">
    <div class="form-field" style="margin-top: 20px">
      <div class="form-field-label">
        Current Password<span style="color: red">*</span>
      </div>
      <input
        class="form-input"
        autocomplete="new-password"
        [formControl]="currentPasswordFormControl"
        [type]="'password'"
      />
    </div>

    <div class="form-field" style="margin-top: 20px">
      <div class="form-field-label">
        New Password<span style="color: red">*</span>
      </div>
      <input
        class="form-input"
        autocomplete="new-password"
        [formControl]="newPasswordFormControl"
        [type]="'password'"
      />
    </div>

    <div class="form-field" style="margin-top: 20px">
      <div class="form-field-label">
        Confirm New Password<span style="color: red">*</span>
      </div>
      <input
        class="form-input"
        autocomplete="new-password"
        [formControl]="confirmPasswordFormControl"
        [type]="'password'"
      />
    </div>
  </form>
</div>

<div mat-dialog-actions align="end">
  <app-secondary-button
    style="margin-right: 10px"
    [title]="'Cancel'"
    [disabled]="false"
    (tapped)="matDialogRef.close()"
  ></app-secondary-button>

  <app-primary-button
    [title]="'Save'"
    [disabled]="false"
    (tapped)="primaryTapped()"
    bgColor="var(--app-timber)"
    color="white"
  ></app-primary-button>
</div>

<loader [loading]="loading"></loader>
