import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constants } from 'src/app/app.constants';
import { AnalyticsAbstract } from 'src/app/services/analytics/analytics.abstract';
import { UserService, ApiService } from '../../services/index';

@Component({
  selector: 'demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.css'],
})
export class DemoComponent implements OnInit {
  constructor(
    public matDialogRef: MatDialogRef<DemoComponent>,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private mixPanel: AnalyticsAbstract,
    private userService: UserService,
    private constants: Constants
  ) {}

  loading = false;
  currentPage = 'knead';
  pageValid = false;

  // pages: any = {
  //   knead: {
  //     images: [
  //       '../../../assets/demo/knead-3.gif',
  //       '../../../assets/demo/knead-1.gif',
  //       '../../../assets/demo/knead-2.gif',
  //     ],
  //     logo: '../../../assets/demo/logo.png',
  //     bg: '"../../../assets/demo/bg.png"',
  //     bgColor: '#b4d3e0',
  //     textColor: '#40595d',
  //     buttonColor: '#40595d',
  //     border: '20px solid #ffa5ba',
  //   },
  //   whitefeathercoffee: {
  //     images: [
  //       '../../../assets/demo/wfc-1.gif',
  //       '../../../assets/demo/wfc-2.gif',
  //     ],
  //     logo: '../../../assets/demo/whitefeather.svg',
  //     bg: '"../../../assets/demo/feather-bg-min.png"',
  //     bgColor: 'white',
  //     textColor: '#242424',
  //     buttonColor: '#242424',
  //     border: '20px solid #242424',
  //   },
  // };

  page: any;

  currentIndex = 0;

  nextImage() {
    this.currentIndex = (this.currentIndex + 1) % this.page.images.length;
  }

  ngOnInit(): void {
    this.currentPage = this.getName();

    this.apiService.get(`log?demo=${this.currentPage}`).subscribe({
      next: (res: any) => {},
      error: (res: HttpErrorResponse) => {},
    });

    this.mixPanel.track(Constants.analytics_keys.viewDemo, {
      Demo: this.currentPage,
    });

    this.loading = true;

    this.userService
      .getDemo(this.currentPage)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.page = res;
            this.pageValid = true;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.pageValid = false;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  getName() {
    const path = window.location.href;
    const lastSlashIndex = path.lastIndexOf('/');
    return path.substring(lastSlashIndex + 1);
  }

  next(): void {}
}
