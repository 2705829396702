export interface SocialAccount {
  handle: string;
  link?: string;
  type: SocialType;
}

export enum SocialType {
  facebook,
  instagram,
  tiktok,
}
