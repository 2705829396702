<dialog-header
  *ngIf="!loading"
  title="Subscription Confirmed"
  (onClose)="matDialogRef.close()"
></dialog-header>

<div
  mat-dialog-content
  class="mat-typography"
  style="margin-top: 20px"
  *ngIf="!loading"
>
  <p class="dialog-body" [innerHTML]="content"></p>
</div>

<div mat-dialog-actions align="end" *ngIf="!loading">
  <button
    [disabled]="false"
    (click)="matDialogRef.close()"
    mat-flat-button
    class="primary-button"
  >
    <span>Close</span>
  </button>
</div>

<loader [loading]="loading"></loader>
