import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Constants } from 'src/app/app.constants';

@Injectable({ providedIn: 'root' })
export class PermissionsService {
  constructor(private authService: AuthService, private constants: Constants) {}

  get isOwner() {
    return this.authService.getUserProperty('isOwner') ?? false;
  }

  get isMerchant() {
    return (
      this.authService.getUserProperty('userType') ==
      this.constants.userTypes.merchant
    );
  }

  get isSystemAdmin() {
    return (
      this.authService.getUserProperty('userType') ==
      this.constants.userTypes.admin
    );
  }

  get isLocationManager() {
    const userType = this.authService.getUserProperty('userType');
    const types = [this.constants.userTypes.manager];
    return types.includes(userType);
  }

  get adminOrMerchant() {
    return this.isSystemAdmin || this.isMerchant;
  }

  get ownerOrAdmin() {
    return this.isOwner || this.isSystemAdmin;
  }

  get adminOrMerchantOrManager() {
    return this.isLocationManager || this.adminOrMerchant;
  }

  permissions = {
    customers: {
      view: () => this.authService.getUserProperty('canViewCustomers') ?? true,
    },
    billing: {
      manage: () => this.adminOrMerchant,
    },
    engage: {
      view: () => this.adminOrMerchant,
    },
    team: {
      view: () => this.adminOrMerchant,
    },
    integrations: {
      manage: () => this.adminOrMerchant,
    },
    merchant: {
      manage: () => this.adminOrMerchant,
    },
    card: {
      create: () => this.adminOrMerchantOrManager,
    },
    reward: {
      create: () => this.adminOrMerchantOrManager,
    },
    location: {
      edit: () => this.adminOrMerchantOrManager,
    },
  };
}
