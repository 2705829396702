<dialog-header
  title="Automations"
  (onClose)="matDialogRef.close()"
></dialog-header>

<div
  mat-dialog-content
  *ngIf="!loading"
  style="
    padding-bottom: 20px;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    background-color: var(--app-container);
  "
>
  <mat-tab-group
    mat-align-tabs="start"
    mat-stretch-tabs="false"
    disableRipple="true"
    [animationDuration]="0"
    [(selectedIndex)]="selectedIndex"
    (selectedTabChange)="tabChanged($event.index)"
  >
    <mat-tab label="Birthday Club">
      <div style="overflow: hidden">
        <div style="margin-top: 20px" *ngIf="hasBirthdayClubAccess">
          <app-info-box
            title="Info"
            [canDismiss]="false"
            [body]="infoBoxBody"
          ></app-info-box>

          <link-text
            text="How do customers join my Birthday Club?"
            (onTapped)="openBirthdayInfoDialog()"
          ></link-text>
        </div>

        <div class="birthday-club-enabled" *ngIf="hasBirthdayClubAccess">
          <div
            class="form-field"
            style="
              margin-top: 20px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div class="form-field-label" style="margin-bottom: 0px">
              {{ birthdayClubEnabled ? "Enabled" : "Disabled" }}
            </div>
            <mat-slide-toggle
              [disabled]="false"
              [checked]="birthdayClubEnabled"
              (change)="birthdayClubEnabled = !birthdayClubEnabled"
            >
            </mat-slide-toggle>
          </div>

          <div *ngIf="birthdayClubEnabled">
            <form [formGroup]="birthdayForm">
              <div class="form-field" style="margin-top: 20px">
                <div
                  class="form-field-label"
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <div>Message<span style="color: red">*</span></div>
                  <span style="font-size: 12px; font-weight: normal"
                    >{{ birthdayPnMessageFormControl.value?.length || 0 }}/{{
                      contentMaxChar
                    }}</span
                  >
                </div>

                <textarea
                  class="form-input"
                  autocomplete="off"
                  [formControl]="birthdayPnMessageFormControl"
                  placeholder="e.g. Happy Birthday [customer_name] 🎉"
                  type="text"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  [maxlength]="contentMaxChar"
                  [rows]="3"
                  (click)="updateCursorPosition($event)"
                  (keyup)="updateCursorPosition($event)"
                  (select)="updateCursorPosition($event)"
                ></textarea>

                <div
                  style="
                    margin-top: 5px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <div
                    class="form-field-label"
                    style="
                      margin-bottom: 0px;
                      color: black;
                      font-weight: 400;
                      font-size: 12px;
                      font-style: italic;
                    "
                  >
                    Include the customer's first name by typing
                    <b>[customer_name]</b>
                  </div>

                  <div
                    class="emoji-button"
                    (click)="openEmojiPicker()"
                    style="min-width: 74px"
                  >
                    😊 Emoji
                  </div>
                </div>
              </div>

              <div class="form-field" style="margin-top: 20px">
                <single-select
                  [label]="'When to send'"
                  [formCtrl]="whenToSendFormControl"
                  property="name"
                  [options]="options"
                  [showIcon]="false"
                ></single-select>
              </div>

              <div
                class="form-field"
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <div class="form-field-label" style="margin-bottom: 0px">
                  Include a Reward
                </div>
                <mat-slide-toggle
                  [disabled]="false"
                  [checked]="birthdayIncludeReward"
                  (change)="toggleReward()"
                >
                </mat-slide-toggle>
              </div>

              <div style="margin-top: 20px" *ngIf="birthdayIncludeReward">
                <choose-reward
                  *ngIf="!birthdayRewards?.length"
                  (clicked)="openRewardDialog()"
                  [label]="'Choose Reward'"
                  [icon]="'redeem'"
                ></choose-reward>
              </div>
              <div
                class="social-container"
                *ngFor="let item of birthdayRewards"
                style="
                  margin-top: 10px;
                  display: flex;
                  justify-content: space-between;
                "
              >
                <div style="display: flex; align-items: center">
                  <div
                    class="form-field-label"
                    style="
                      margin-bottom: 0px !important;
                      color: black;
                      font-size: 16px;
                    "
                  >
                    {{ item?.reward?.title }}
                  </div>
                </div>

                <mat-icon
                  style="color: red; cursor: pointer"
                  (click)="removeReward(item)"
                  >cancel
                </mat-icon>
              </div>
            </form>
          </div>
        </div>

        <div class="birthday-club-disabled" *ngIf="!hasBirthdayClubAccess">
          <img
            src="../../../../assets/padlock.png"
            width="80px"
            style="margin-bottom: 20px"
          />

          <p style="margin-bottom: 20px; font-weight: bold; font-size: 16px">
            {{ title }}
          </p>

          <p style="margin-bottom: 20px">
            {{ subtitle }}
          </p>

          <app-primary-button
            *ngIf="canEngage"
            [title]="'Upgrade Now'"
            (tapped)="seePlans()"
            bgColor="var(--app-timber)"
            color="white"
          ></app-primary-button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<div mat-dialog-actions align="end" *ngIf="!loading && hasBirthdayClubAccess">
  <app-secondary-button
    style="margin-right: 10px"
    [title]="'Cancel'"
    [disabled]="false"
    (tapped)="matDialogRef.close()"
  ></app-secondary-button>

  <app-primary-button
    [title]="'Save'"
    (tapped)="save()"
    bgColor="var(--app-timber)"
    color="white"
  ></app-primary-button>
</div>

<loader [loading]="saving || loading"></loader>
