import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class StripeService {
  constructor(private api: ApiService) {}

  PATH = 'v1/stripe';

  createCheckoutSession(lookup_key: string) {
    return this.api.post(`${this.PATH}/create-checkout-session`, {
      lookup_key: lookup_key,
    });
  }

  getProducts() {
    return this.api.get(`${this.PATH}/products`);
  }

  getSubscriptionInfo(customerId?: string) {
    let query = '';

    if (customerId) {
      query = `?customerId=${customerId}`;
    }

    return this.api.get(`${this.PATH}/subscription${query}`);
  }
}
