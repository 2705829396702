import { User, Merchant, ChatMessage } from './index';

export interface Conversation {
  nanoid?: string;
  _id?: string;
  user: string | User;
  merchant: string | Merchant;
  lastMessage: string | ChatMessage;
  muted?: string[]; //if user or merchant mutes the other, they will be here
  blockedByMerchant: boolean;
}
