import { Component, Inject, NgZone, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { Constants } from 'src/app/app.constants';
import * as moment from 'moment';

@Component({
  selector: 'date-range-dialog',
  templateUrl: './date-range-dialog.component.html',
  styleUrls: ['./date-range-dialog.component.css'],
})
export class DateRangeDialogComponent implements OnInit {
  form!: FormGroup;
  today: string | undefined;
  twelveMonthsAgo: string | undefined;
  thirtyDaysAgo: string | undefined;

  quickFormControl = new FormControl(
    { value: null, disabled: false },
    Validators.required
  );

  exporting = false;

  options: any[] = [
    { name: 'Custom Range', value: 'custom' },
    { name: 'Today', value: 'today' },
    { name: 'Last 7 Days', value: '7' },
    { name: 'Last 30 Days', value: '30' },
    { name: 'Last Month', value: 'last' },
    { name: 'This Month', value: 'this' },
    { name: 'Month to Date', value: 'month' },
    { name: 'Year to Date', value: 'year' },
  ];

  constructor(
    public dialog: MatDialog,
    private constants: Constants,
    public fb: FormBuilder,
    public matDialogRef: MatDialogRef<DateRangeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public zone: NgZone
  ) {}

  ngOnInit() {
    this.today = new Date().toISOString().slice(0, 10);
    this.thirtyDaysAgo = new Date(new Date().setDate(new Date().getDate() - 30))
      .toISOString()
      .slice(0, 10);
    this.twelveMonthsAgo = new Date(
      new Date().setFullYear(new Date().getFullYear() - 1)
    )
      .toISOString()
      .slice(0, 10);

    this.quickFormControl.setValue(this.options[3]);

    this.form = this.fb.group({
      quick: this.quickFormControl,
      from: [this.thirtyDaysAgo, Validators.required],
      to: [this.today, Validators.required],
    });

    this.form.get('to')?.valueChanges.subscribe((to) => {
      if (to) {
        const toMoment = moment(to);
        this.twelveMonthsAgo = toMoment
          .subtract(12, 'months')
          .format('YYYY-MM-DD');
      } else {
        // Reset or handle the absence of a 'to' date as needed
        this.twelveMonthsAgo = undefined;
      }
    });
  }

  changed(): void {
    this.quickFormControl.setValue(this.options[0]);
  }

  selected(option: any) {
    let from = moment().endOf('day').toISOString();
    let to = moment().endOf('day').toISOString();

    switch (option.value) {
      case 'today':
        from = moment().startOf('day').toISOString();
        break;
      case '7':
        from = moment().subtract(7, 'days').startOf('day').toISOString();
        break;
      case '30':
        from = moment().subtract(30, 'days').startOf('day').toISOString();
        break;
      case 'last':
        from = moment().subtract(1, 'month').startOf('month').toISOString();
        to = moment().subtract(1, 'month').endOf('month').toISOString();
        break;
      case 'this':
        from = moment().startOf('month').toISOString();
        to = moment().endOf('month').toISOString();
        break;
      case 'month':
        from = moment().startOf('month').toISOString();
        break;
      case 'year':
        from = moment().startOf('year').toISOString();
        break;
      case 'custom':
        from = this.thirtyDaysAgo!;
        to = this.today!;

        break;
      default:
        console.log('Unknown option');
    }

    this.form.patchValue({
      from: from.slice(0, 10),
      to: to.slice(0, 10),
    });
  }

  get primaryEnabled(): boolean {
    if (this.form.get('from')?.value && this.form.get('to')?.value) {
      return true;
    } else {
      return false;
    }
  }

  primaryTapped(): void {
    if (this.form.invalid) {
      return;
    }

    this.save();
  }

  save(): void {
    // Retrieve the values of 'from' and 'to' fields
    const fromDate = this.form.get('from')?.value;
    const toDate = this.form.get('to')?.value;

    // Check if 'from' or 'to' dates are missing
    if (!fromDate) {
      this.constants.snack('Invalid FROM Date');
      return;
    }

    if (!toDate) {
      this.constants.snack('Invalid TO Date');
      return;
    }

    // Use moment.js for date comparison
    if (moment(fromDate).isAfter(moment(toDate))) {
      this.constants.snack("'FROM' date cannot be after the 'TO' date");
      return;
    }

    // If validation passes, proceed with saving the data
    const data = { from: fromDate, to: toDate };
    this.matDialogRef.close(data);
  }
}
