<dialog-header
  title="Select Reward"
  (onClose)="matDialogRef.close()"
></dialog-header>

<div
  mat-dialog-content
  *ngIf="!loading"
  style="
    padding-bottom: 20px;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    background-color: var(--app-container);
  "
>
  <form [formGroup]="form" style="margin-top: 20px">
    <div *ngIf="data?.stampNumbers && data?.cardType === 'stamp'">
      <single-select
        label="Stamp Number"
        [formCtrl]="numberFormControl"
        property="number"
        [options]="data?.stampNumbers"
        [showIcon]="false"
      ></single-select>
    </div>

    <div *ngIf="data?.cardType === 'point'">
      <div class="form-field" style="margin-top: 20px; margin-bottom: 20px">
        <div class="form-field-label">Points</div>
        <input
          class="form-input"
          autocomplete="off"
          placeholder="e.g. 300"
          [formControl]="pointsFormControl"
          type="number"
          [maxlength]="7"
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
        />
      </div>
    </div>

    <div class="form-field">
      <single-select
        label="Reward"
        [formCtrl]="rewardFormControl"
        [options]="rewards"
        property="title"
        [showIcon]="false"
        (click)="hasRewards()"
      ></single-select>
    </div>
  </form>
</div>

<div mat-dialog-actions align="end">
  <app-secondary-button
    style="margin-right: 10px"
    [title]="'Cancel'"
    [disabled]="false"
    (tapped)="matDialogRef.close()"
  ></app-secondary-button>

  <app-primary-button
    [title]="'Save'"
    [disabled]="false"
    (tapped)="primaryTapped()"
    bgColor="var(--app-timber)"
    color="white"
  ></app-primary-button>
</div>

<loader [loading]="loading"></loader>
