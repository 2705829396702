<dialog-header
  [title]="data.isStampIcon ? 'Select Stamp Icon' : 'Select Reward Icon'"
  (onClose)="matDialogRef.close()"
></dialog-header>

<div mat-dialog-content>
  <mat-tab-group
    mat-align-tabs="start"
    mat-stretch-tabs="false"
    disableRipple="true"
    [animationDuration]="0"
    [(selectedIndex)]="tabIndex"
  >
    <mat-tab label="Outlined">
      <mat-grid-list cols="12" rowHeight="50px" style="margin-top: 10px">
        <mat-grid-tile
          class="icon"
          *ngFor="let icon of icons; let i = index"
          (click)="selectedIcon(i)"
          [ngStyle]="
            ((data.isStampIcon && data.stampIcon === getIcon(i)) ||
              (!data.isStampIcon && data.rewardIcon === getIcon(i))) &&
            !data.filled
              ? {
                  border: '2px solid var(--app-wiskey)'
                }
              : {}
          "
        >
          <mat-icon fontSet="material-icons-outlined" style="color: black">{{
            getIcon(i)
          }}</mat-icon>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-tab>

    <mat-tab label="Filled">
      <mat-grid-list cols="12" rowHeight="50px" style="margin-top: 10px">
        <mat-grid-tile
          class="icon"
          *ngFor="let icon of filledIcons; let i = index"
          (click)="selectedIcon(i)"
          [ngStyle]="
            ((data.isStampIcon && data.stampIcon === getFilledIcon(i)) ||
              (!data.isStampIcon && data.rewardIcon === getFilledIcon(i))) &&
            data.filled
              ? {
                  border: '2px solid var(--app-wiskey)'
                }
              : {}
          "
        >
          <mat-icon fontSet="material-icons" style="color: black">{{
            getFilledIcon(i)
          }}</mat-icon>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-tab>

    <mat-tab label="Upload">
      <div style="margin-top: 10px">
        <file-upload
          [aspectRatio]="1 / 1"
          (fileSelected)="selectedImage = $event"
          [readerResult]="data.iconImage ?? ''"
          [maxFileSize]="525000"
          [resizeToHeight]="200"
        ></file-upload>
      </div>

      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <a href="https://www.flaticon.com" target="_blank"
          >Download from Flaticon
          <span
            ><mat-icon
              style="color: #0d6efd; vertical-align: middle; font-size: 16px"
              >open_in_new</mat-icon
            ></span
          ></a
        >
        <app-primary-button
          [disabled]="selectedImage == null"
          (tapped)="primaryTapped()"
          [title]="'Save'"
          bgColor="var(--app-timber)"
          color="white"
        ></app-primary-button>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
