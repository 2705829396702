import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from 'src/app/app.constants';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'confirm',
  templateUrl: 'confirm.component.html',
  styleUrls: ['./confirm.component.css'],
})
export class ConfirmComponent implements OnInit {
  options: AnimationOptions = { path: '/assets/rewards.json', loop: false };

  isMobile = false;
  email = '';
  @HostListener('window:resize', ['$event'])
  onResize = () => this.testMobile();
  testMobile = () => (this.isMobile = window.innerWidth < 768 ? true : false);

  constructor(
    public constants: Constants,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params) => {
      this.email = params['e'];
    });
  }

  ngOnInit() {
    this.testMobile();
  }

  login() {
    this.router.navigate([Constants.routes.login], {
      queryParams: { e: this.email },
    });
  }

  signup() {
    this.router.navigate([Constants.routes.signup]);
  }
}
