<demo *ngIf="currentScreen === 'demo'"></demo>

<div *ngIf="currentScreen !== 'demo'">
  <!-- <div class="toolbar" *ngIf="showTrialBanner">
    <div class="toolbar-content">
      <mat-icon
        class="menu-icon"
        style="color: white"
        (click)="hideTrialBanner()"
        >close</mat-icon
      >

      <div>
        <span style="margin-right: 20px">
          {{
            " Your free trial ends in " +
              (numberOfDaysLeft | pluralize : "day" : "days")
          }}</span
        >

        <app-primary-button
          [title]="'Upgrade Now'"
          (tapped)="seePlans()"
          bgColor="white"
          color="black"
        ></app-primary-button>
      </div>
    </div>
  </div> -->

  <div
    class="mobile-toolbar"
    *ngIf="(isHandset$ | async) && authService.isAuthenticated"
  >
    <div class="mobile-toolbar-content">
      <a routerLink="/"
        ><img class="toolbar-img" src="../assets/remylogo.png"
      /></a>
      <mat-icon class="menu-icon" (click)="toggleSidenav()">menu</mat-icon>
    </div>
  </div>

  <mat-menu #userMenu="matMenu" class="custom-menu">
    <button
      style="
        border-radius: 5px;
        cursor: inherit;
        background: none;
        border: none;
        padding: 10px;
        text-align: left;
      "
    >
      {{ userName }} <br />
      <span style="font-size: 12px; color: var(--app-text-grey)">
        {{ email }}</span
      ><br />
      <span style="font-size: 12px; color: var(--app-text-grey)">
        {{ userRole }}</span
      >
    </button>
    <div *ngFor="let item of menuOptions; let i = index">
      <button
        class="menu-list-item"
        style="border-radius: 5px"
        (click)="onClick(item.title)"
        mat-menu-item
      >
        <mat-icon
          fontSet="material-icons-outlined"
          style="
            font-size: 16px;
            vertical-align: middle;
            display: flex;
            align-items: center;
            margin-right: 5px;
          "
          >{{ item.icon }}</mat-icon
        >
        <span class="menu-list-item-label">{{ item.title }}</span>
      </button>
    </div>
  </mat-menu>

  <mat-drawer-container [hasBackdrop]="false" autosize>
    <mat-drawer
      *ngIf="userIsAuthenticated"
      [ngStyle]="{
        'width.px': collapsedSidebar ? 100 : 250,
        'padding-top.px': (isHandset$ | async) ? 60 : 0
      }"
      class="sidenav"
      fixedInViewport="true"
      #drawer
      disableClose="true"
      role="navigation"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="showSidenav"
    >
      <div class="inner-sidebar">
        <div class="nav-list">
          <div
            class="image-container"
            [ngStyle]="{
              padding: collapsedSidebar ? '20px' : '20px',
              'padding-left': '10px'
            }"
            *ngIf="!(isHandset$ | async)"
          >
            <a routerLink="/"
              ><img
                class="sidebar-img"
                [ngStyle]="{
                  width: collapsedSidebar ? '100%' : '60%'
                }"
                [src]="
                  collapsedSidebar
                    ? '../assets/remy-small.png'
                    : '../assets/remylogo.png'
                "
            /></a>
          </div>
          <div
            class="nav-list-inner"
            [ngStyle]="{
              paddingTop: !(isHandset$ | async) ? '0px' : '10px'
            }"
          >
            <mat-nav-list style="padding-bottom: 0px !important">
              <mat-list-item
                [ngStyle]="{
                  borderBottom:
                    item.title == 'Referrals' ? '1px solid #c6c6c6' : 'none'
                }"
                [disableRipple]="true"
                [ngClass]="{
                  selected: selectedMenu === item.title
                }"
                *ngFor="let item of menuList"
                (click)="closeSideNav(item.title)"
              >
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <a
                    [ngStyle]="{
                      'font-weight':
                        selectedMenu === item.title ? '600' : 'normal'
                    }"
                    style="text-decoration: none; color: black; font-size: 15px"
                    matLine
                    [routerLink]="'/' + getRoute(item.title).replace(' ', '-')"
                  >
                    <img
                      [src]="item?.image"
                      style="width: 30px; margin-right: 10px"
                    />
                    <span *ngIf="!collapsedSidebar"> {{ item.title }}</span>
                  </a>
                  <span
                    class="notification-icon"
                    *ngIf="
                      merchantService.unreadNotifications &&
                      merchantService.unreadNotifications > 0 &&
                      item.title == 'Engage'
                    "
                  ></span>
                </div>
              </mat-list-item>
            </mat-nav-list>

            <mat-nav-list style="padding-top: 0px !important">
              <hr />
              <mat-expansion-panel
                *ngIf="isAdmin"
                [expanded]="expandedMenuItem === 'quickActions'"
                (opened)="setExpanded('quickActions')"
                (closed)="setExpanded('')"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <img
                      src="../assets/menu/actions.svg"
                      style="width: 30px; margin-right: 10px"
                    />
                    <span style="font-size: 15px; font-weight: 400"
                      >Quick Actions</span
                    >
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div
                  *ngFor="let action of quickActions"
                  style="margin-left: 10px"
                >
                  <div style="display: flex">
                    <div
                      style="
                        height: 20px;
                        width: 10px;
                        margin-right: 5px;
                        border-left: 1px dashed rgb(196, 196, 196);
                        border-bottom: 1px dashed rgb(196, 196, 196);
                      "
                    ></div>
                    <div class="expansion" (click)="actionClicked(action)">
                      <!-- <mat-icon style="margin-right: 5px; font-size: 20px">{{
                      action.icon
                    }}</mat-icon> -->

                      <span style="font-size: 14px">{{ action.label }}</span>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-list-item
                [disableRipple]="true"
                [ngClass]="{
                  selected: selectedMenu === item.title
                }"
                *ngFor="let item of menuList2"
                (click)="closeSideNav(item.title)"
              >
                <a
                  [ngStyle]="{
                    'font-weight':
                      selectedMenu === item.title ? '600' : 'normal'
                  }"
                  style="text-decoration: none; color: black; font-size: 15px"
                  matLine
                  [routerLink]="'/' + getRoute(item.title).replace(' ', '-')"
                >
                  <img
                    [src]="item?.image"
                    style="width: 30px; margin-right: 10px"
                  />
                  <span *ngIf="!collapsedSidebar"> {{ item.title }}</span>
                </a>
              </mat-list-item>
            </mat-nav-list>
          </div>
        </div>

        <div>
          <!-- <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              cursor: pointer;
              margin-bottom: 5px;
            "
          >
            <mat-icon
              (click)="toggleDrawer()"
              [fontSet]="'material-icons-outlined'"
              style="rotate: 90deg; color: var(--app-timber)"
              [ngStyle]="{
                rotate: collapsedSidebar ? '-90deg' : '90deg'
              }"
              >expand_circle_down</mat-icon
            >
          </div> -->
          <div
            class="account-sidebar"
            *ngIf="userName && merchantName"
            [mat-menu-trigger-for]="userMenu"
          >
            <div class="left-content">
              <img
                *ngIf="collapsedSidebar"
                [src]="merchantLogo"
                style="width: 45px !important; border-radius: 5px"
                [ngStyle]="{ cursor: 'pointer' }"
              />

              <img
                *ngIf="!collapsedSidebar"
                [src]="merchantLogo"
                style="width: 45px !important; border-radius: 5px"
              />
              <div class="text-content" *ngIf="!collapsedSidebar">
                <p class="user-label">
                  {{ userName | truncate : 20 }}
                </p>
                <p class="merchant-label">
                  {{ merchantName | truncate : 28 }}
                </p>
              </div>
            </div>
            <mat-icon *ngIf="!collapsedSidebar" class="more-icon"
              >more_vert</mat-icon
            >
          </div>
        </div>
      </div>
    </mat-drawer>

    <mat-drawer-content class="main-content">
      <checklist *ngIf="userIsAuthenticated"></checklist>
      <div
        [ngStyle]="
          userIsAuthenticated
            ? {
                padding: '20px',
                overflow: 'hidden',
                'padding-top': (isHandset$ | async) ? '80px' : '20px'
              }
            : {}
        "
      >
        <router-outlet></router-outlet>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
