<page-header
  [title]="(editMode ? 'Edit ' : 'New ') + constants.strings.reward"
  [subtitle]="subtitle"
></page-header>

<form [formGroup]="form" *ngIf="!loading">
  <div class="row">
    <div class="col-xl-6">
      <div
        style="display: flex; justify-content: flex-end; margin-bottom: 10px"
        *ngIf="canEditReward"
      >
        <div style="display: flex">
          <app-primary-button
            [title]="constants.strings.cancel"
            border=" 1px solid var(--app-container)"
            bgColor="transparent"
            color="white"
            (tapped)="back()"
          ></app-primary-button>

          <div style="margin-left: 10px">
            <app-primary-button
              title="Save"
              [disabled]="loading || !canCreateReward"
              [tooltip]="tooltipLabel"
              (tapped)="save()"
              border=" 1px solid var(--app-container)"
            ></app-primary-button>
          </div>
        </div>
      </div>
      <div class="detail-container" id="left">
        <div class="form-field">
          <div class="form-field-label">
            Title<span style="color: red">*</span>
          </div>
          <input
            class="form-input"
            placeholder="e.g. One free coffee"
            autocomplete="off"
            [formControl]="titleFormControl"
            [maxlength]="40"
            type="text"
            [ngStyle]="{
              border:
                titleFormControl.invalid && submitted
                  ? '1px solid red'
                  : '1px solid var(--app-light-grey)'
            }"
          />
        </div>

        <div class="form-field" style="margin-top: 20px">
          <div class="form-field-label">
            Description<span style="color: red">*</span>
          </div>
          <input
            class="form-input"
            placeholder="e.g. Entitles customer to any regular sized coffee of their choice"
            autocomplete="off"
            [formControl]="descriptionFormControl"
            [maxlength]="100"
            type="text"
            [ngStyle]="{
              border:
                descriptionFormControl.invalid && submitted
                  ? '1px solid red'
                  : '1px solid var(--app-light-grey)'
            }"
          />
        </div>

        <div class="form-field" style="margin-top: 20px">
          <div class="form-field-label">
            Hidden Text<span
              ><info-icon
                text="This will only be displayed when the customer has received the reward, e.g. a discount code or further instructions"
              ></info-icon
            ></span>
          </div>
          <input
            class="form-input"
            placeholder="e.g. Enter code abc123 at checkout"
            autocomplete="off"
            [formControl]="hiddenTextFormControl"
            type="text"
            [maxlength]="100"
            [ngStyle]="{
              border:
                hiddenTextFormControl.invalid && submitted
                  ? '1px solid red'
                  : '1px solid var(--app-light-grey)'
            }"
          />
        </div>

        <div class="form-field" style="margin-top: 20px; margin-bottom: 20px">
          <div class="form-field-label">
            Value<span
              ><info-icon
                text="Giving an estimated reward value helps you gain some additional insights"
              ></info-icon
            ></span>
          </div>
          <input
            class="form-input"
            autocomplete="off"
            placeholder="e.g. 2.50"
            [formControl]="valueFormControl"
            type="number"
            [maxlength]="7"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          />
        </div>

        <div class="form-field" *ngIf="(locations ?? []).length > 1">
          <multiple-select
            [label]="'Locations'"
            [formCtrl]="locationFormControl"
            [disabled]="disabledLocations"
            [options]="locations"
            [showAll]="false"
            value="name"
            (click)="hasLocations()"
          >
          </multiple-select>
        </div>

        <div
          class="form-field"
          style="display: flex; justify-content: space-between"
        >
          <div class="form-field-label">Reward has an expiry date</div>
          <mat-slide-toggle
            [disabled]="!canEditReward"
            [checked]="hasExpiry"
            (change)="hasExpiry = !hasExpiry"
          >
          </mat-slide-toggle>
        </div>

        <div class="form-field" *ngIf="hasExpiry">
          <div style="width: 100%">
            <div class="form-field-label">
              Expiry<span style="color: red">*</span
              ><span
                ><info-icon
                  text="The expiration period for the reward, measured in days from the time the customer receives it"
                ></info-icon>
              </span>
            </div>
            <div
              style="
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                align-items: center;
              "
            >
              <input
                class="form-input"
                autocomplete="off"
                placeholder="e.g. 14"
                [formControl]="expiryFormControl"
                type="number"
                [maxlength]="4"
                min="0"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              />
              <div
                class="form-field-label"
                style="width: 50px; padding-left: 10px"
              >
                days
              </div>
            </div>
          </div>
        </div>

        <div
          class="form-field"
          style="
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
          "
        >
          <div class="form-field-label">
            Reward can be gifted<span>
              <info-icon [text]="giftTooltip"></info-icon>
            </span>
          </div>
          <mat-slide-toggle
            [disabled]="!canEditReward"
            [checked]="giftable"
            (change)="giftable = !giftable"
          >
          </mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>
</form>

<loader [loading]="loading"></loader>
