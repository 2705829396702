import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { Constants } from 'src/app/app.constants';
import { HttpErrorResponse } from '@angular/common/http';
import { ChatMessage } from '../../../models/index';
import {
  AuthService,
  ChatService,
  MerchantService,
  UserService,
} from '../../../services/index';

@Component({
  selector: 'messages_dialog',
  templateUrl: './messages_dialog.component.html',
  styleUrls: ['./messages_dialog.component.css'],
})
export class MessagesDialogComponent implements OnInit {
  error = false;
  saving = false;
  loading = false;

  messages: ChatMessage[] = [];

  messageLength = 0;
  messagePageIndex = 0;
  pageSize = 30;

  messageForm!: FormGroup;
  messageFormControl: FormControl = new FormControl();

  @ViewChild('scrollContainer') private scrollContainer!: ElementRef;

  myUserId = '';

  constructor(
    public constants: Constants,
    public matDialogRef: MatDialogRef<MessagesDialogComponent>,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private chatService: ChatService,
    private authService: AuthService,
    private merchantService: MerchantService,
    private userService: UserService
  ) {
    this.messageFormControl.setValidators([Validators.required]);
    this.messageForm = this.fb.group({
      message: this.messageFormControl,
    });

    this.myUserId = this.authService.getUserProperty('uid');
  }

  ngOnInit(): void {
    this.getMessages(true);

    this.chatService.registerConversation(this.data.conversation._id);

    this.chatService.onMessage().subscribe((message) => {
      if (
        (message as ChatMessage).authorType === this.constants.userTypes.user
      ) {
        this.messages.unshift(message);
        this.scrollToButtom();
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.scrollContainer) {
      this.scrollContainer.nativeElement.addEventListener(
        'scroll',
        this.onScroll.bind(this)
      );
    }
  }

  ngOnDestroy(): void {
    if (this.scrollContainer) {
      this.scrollContainer.nativeElement.removeEventListener(
        'scroll',
        this.onScroll.bind(this)
      );
    }
  }

  sendMessage(): void {
    if (this.saving) {
      return;
    }
    if (this.messageForm.valid) {
      this.saving = true;
      this.chatService
        .sendMessage(this.messageFormControl.value, this.data.conversation._id)
        .subscribe({
          next: (res: ChatMessage) => {
            if (res) {
              this.messages.unshift(res);
              this.messageForm.reset();
              this.scrollToButtom();
            }
          },
          error: (res: HttpErrorResponse) => {
            this.error = true;
            this.constants.snack(res.error.message);
          },
        })
        .add(() => (this.saving = false));
    }
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const now = new Date();
    if (
      date.getFullYear() === now.getFullYear() &&
      date.getMonth() === now.getMonth() &&
      date.getDate() === now.getDate()
    ) {
      return 'Today';
    } else if (
      date.getFullYear() === now.getFullYear() &&
      date.getMonth() === now.getMonth() &&
      date.getDate() === now.getDate() - 1
    ) {
      return 'Yesterday';
    } else {
      return date.toDateString();
    }
  }

  getMessages(firstLoad: boolean): void {
    if (this.loading) return;
    this.loading = true;

    let currentScrollHeight: any;

    if (!firstLoad && this.scrollContainer) {
      currentScrollHeight = this.scrollContainer.nativeElement.scrollHeight;
    }

    this.chatService
      .getMessages(
        this.pageSize,
        this.messagePageIndex,
        this.data.conversation._id
      )
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.messageLength = res.totalDocs;
            this.messages = [...this.messages, ...res.docs];

            if (!firstLoad && this.scrollContainer) {
              setTimeout(() => {
                const newScrollHeight =
                  this.scrollContainer.nativeElement.scrollHeight;
                const scrollHeightDifference =
                  newScrollHeight - currentScrollHeight;
                this.scrollContainer.nativeElement.scrollTop =
                  scrollHeightDifference;
              }, 0);
            }

            if (firstLoad) {
              this.scrollToButtom();
            }
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => {
        this.refreshNotificationCount();
        this.loading = false;
      });
  }

  refreshNotificationCount(): void {
    this.userService.getNotificationCount().subscribe({
      next: (res: any) => {
        if (res) {
          this.merchantService.unreadNotifications = res.count ?? 0;
        }
      },
      error: (res: HttpErrorResponse) => {},
    });
  }

  scrollToButtom() {
    setTimeout(() => {
      if (this.scrollContainer) {
        this.scrollContainer.nativeElement.scrollTop =
          this.scrollContainer.nativeElement.scrollHeight;
      }
    }, 100);
  }

  save(): void {}

  onScroll(): void {
    if (
      this.scrollContainer.nativeElement.scrollTop === 0 &&
      !this.loading &&
      this.messages.length < this.messageLength
    ) {
      this.messagePageIndex++;
      this.getMessages(false);
    }
  }
}
