import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { SharedModule } from './shared/shared.module';
import { LocationsComponent } from './features/location/locations/locations.component';
import { RewardsComponent } from './features/reward/rewards/rewards.component';
import { CardsComponent } from './features/card/cards/cards.component';
import { CustomersComponent } from './features/customer/customers/customers.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { LocationDetailComponent } from './features/location/location-detail/location-detail.component';
import { CustomerDetailComponent } from './features/customer/customer-detail/customer-detail.component';
import { SocialDialogComponent } from './features/location/social-dialog/social-dialog.component';
import { AddressDialogComponent } from './shared/components/address-dialog/address-dialog.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FileUploadComponent } from './shared/components/file-upload/file-upload.component';
import { RewardDetailComponent } from './features/reward/reward-detail/reward-detail.component';
import { JwtInterceptor } from './shared/jwt.interceptor';
import { ChangePasswordComponent } from './features/auth/change-password/change-password.component';
import { LoginComponent } from './features/auth/login/login.component';
import { CardDetailComponent } from './features/card/card-detail/card-detail.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { RewardDialogComponent } from './features/card/reward-dialog/reward-dialog.component';
import { CardPreviewComponent } from './features/card/card-preview/card-preview.component';
import { CommonModule } from '@angular/common';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxColorsModule } from 'ngx-colors';
import { LottieModule } from 'ngx-lottie';
import { ProfileComponent } from './features/auth/profile/profile.component';
import { RegisterComponent } from './features/auth/register/register.component';
import { ConfirmComponent } from './features/auth/confirm/confirm.component';
import { VerifyComponent } from './features/auth/verify/verify.component';
import { AutocompleteComponent } from './shared/components/autocomplete.component';
import { PlansComponent } from './features/auth/plans/plans.component';
import { PlanCardComponent } from './features/auth/plans/components/plan-card.component';
import { TeamMemberDetailComponent } from './features/team/team-member-detail/team-member-detail.component';
import { TeamMembersComponent } from './features/team/team-members/team-members.component';
import { ResetPasswordComponent } from './features/auth/reset-password/reset-password.component';
import { PluralizePipe } from './shared/pluralize.pipe';
import { TruncatePipe } from './shared/truncate.pipe';
import { EngageComponent } from './features/engagement/engage/engage.component';
import { ComposePnComponent } from './features/engagement/compose-pn/compose-pn.component';
import { NFCCardsComponent } from './features/nfc/nfc-cards/nfc-cards.component';
import { NFCCardDetailComponent } from './features/nfc/nfc-card-detail/nfc-card-detail.component';
import { IconDialogComponent } from './features/card/icon-dialog/icon-dialog.component';
import { RequestNFCDialogComponent } from './features/nfc/request-nfc-dialog/request-nfc-dialog.component';
import { NFCOptionsContainer } from './features/nfc/nfc-options-container/nfc-options-container.component';
import { OnboardComponent } from './shared/components/onboard-component/onboard.component';
import { RecipientsFilterDialog } from './features/engagement/recipients-filter-dialog/recipients-filter-dialog.component';
import { ChartComponent } from './features/dashboard/chart-component/chart.component';
import { NgChartsModule } from 'ng2-charts';
import { ButtonToggleGroupComponent } from './shared/components/button-toggle-group/button-toggle-group.component';
import { HelpComponent } from './features/help/guides/help.component';
import { VideoViewerComponent } from './features/help/video-viewer/video-viewer.component';
import { NgHcaptchaModule } from 'ng-hcaptcha';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageCropDialogComponent } from './shared/components/image-crop-dialog/image-crop-dialog.component';
import { GradientDialogComponent } from './features/card/gradient-dialog/gradient-dialog.component';
import { NFCNicknameDialogComponent } from './features/nfc/nfc-nickname-dialog/nfc-nickname.component';
import { EmojiDialogComponent } from './features/engagement/emoji-dialog/emoji-dialog.component';
import { ChangePasswordDialogComponent } from './features/auth/change-password-dialog/change-password-dialog.component';
import { CardPreviewContainerComponent } from './features/card/card-detail/card-preview-container.component';
import { InfoBoxComponent } from './shared/components/info-box/info-box.component';
import { SelectMerchantDialogComponent } from './features/auth/select-merchant-dialog/select-merchant-dialog.component';
import { DemoComponent } from './features/demo/demo.component';
import { AIDialogComponent } from './features/engagement/ai-dialog/ai-dialog.component';
import { DateRangeDialogComponent } from './shared/components/date-range-dialog/date-range-dialog.component';
import { IntegrationsComponent } from './features/auth/integrations/integrations.component';
import { ConfigurationDialogComponent } from './shared/components/configuration-dialog/configuration-dialog.component';
import { ShopifyDataDialogComponent } from './shared/components/shopify-data-dialog/shopify-data-dialog.component';
import { SearchInputComponent } from './shared/components/search-input/search-input.component';
import { AnalyticsAbstract } from './services/analytics/analytics.abstract';
import { AnalyticsImpl } from './services/analytics/analytics.impl';
import { PlanSuccessDialogComponent } from './shared/components/plan-success-dialog/plan-success-dialog.component';
import { AutomationsDialogComponent } from './features/engagement/automations_dialog/automations_dialog.component';
import { ChooseRewardComponent } from './features/engagement/choose-reward/choose-reward.component';
import { AppMaterialModule } from './app-material.module';
import { ReportsComponent } from './features/reports/reports/reports.component';
import { RowMenuOptionsPipe } from './features/engagement/engage/row-menu-options.pipe';
import { Crisp } from 'crisp-sdk-web';
import { MessagesDialogComponent } from './features/engagement/messages_dialog/messages_dialog.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { ConversationMenuOptionsPipe } from './features/engagement/engage/conversation-menu-options.pipe';
import { ConversationSettingsDialogComponent } from './features/engagement/conversation-settings/conversation-settings-dialog.component';
import { GiveRewardDialogComponent } from './features/customer/give-reward-dialog/give-reward-dialog.component';
import { ReferralsComponent } from './features/referrals/referrals/referrals.component';
import { HourlyChartComponent } from './features/dashboard/hourly-chart-component/hourly-chart.component';
import { BirthdayClubInfoComponent } from './features/engagement/birthday-club-info/birthday-club-info.component';
import { MagicLinkDialogComponent } from './features/auth/magic-link-dialog/magic-link-dialog.component';
import { MatMenuModule } from '@angular/material/menu';
import { ApiService, ExcelService, GeocodingService } from './services/index';
import { StampsPerScanComponent } from './features/card/card-detail/stamps-per-scan/stamps-per-scan.component';
import { GiveStampsDialogComponent } from './features/customer/give-stamps-dialog/give-stamps-dialog.component';

const config: SocketIoConfig = {
  url: environment.socketUrl,
  options: { autoConnect: false, transports: ['websocket'] },
};

Crisp.configure('c9362138-9fce-45a1-ad60-93f2f8619cb2');

export function playerFactory() {
  return import('lottie-web');
}

@NgModule({
  declarations: [
    MagicLinkDialogComponent,
    RowMenuOptionsPipe,
    ConversationMenuOptionsPipe,
    AppComponent,
    DashboardComponent,
    LocationsComponent,
    LocationDetailComponent,
    SocialDialogComponent,
    RewardsComponent,
    RewardDetailComponent,
    CardsComponent,
    CustomersComponent,
    CustomerDetailComponent,
    AddressDialogComponent,
    FileUploadComponent,
    ChangePasswordComponent,
    LoginComponent,
    CardDetailComponent,
    RewardDialogComponent,
    CardPreviewComponent,
    ProfileComponent,
    ConfirmComponent,
    RegisterComponent,
    VerifyComponent,
    AutocompleteComponent,
    PlansComponent,
    PlanCardComponent,
    TeamMemberDetailComponent,
    TeamMembersComponent,
    ResetPasswordComponent,
    PluralizePipe,
    TruncatePipe,
    EngageComponent,
    ComposePnComponent,
    NFCCardsComponent,
    NFCCardDetailComponent,
    IconDialogComponent,
    RequestNFCDialogComponent,
    NFCOptionsContainer,
    OnboardComponent,
    RecipientsFilterDialog,
    ChartComponent,
    ButtonToggleGroupComponent,
    HelpComponent,
    VideoViewerComponent,
    ImageCropDialogComponent,
    GradientDialogComponent,
    FileUploadComponent,
    NFCNicknameDialogComponent,
    EmojiDialogComponent,
    ChangePasswordDialogComponent,
    CardPreviewContainerComponent,
    InfoBoxComponent,
    SelectMerchantDialogComponent,
    DemoComponent,
    AIDialogComponent,
    DateRangeDialogComponent,
    IntegrationsComponent,
    ConfigurationDialogComponent,
    ShopifyDataDialogComponent,
    SearchInputComponent,
    PlanSuccessDialogComponent,
    AutomationsDialogComponent,
    ChooseRewardComponent,
    ReportsComponent,
    MessagesDialogComponent,
    ConversationSettingsDialogComponent,
    GiveRewardDialogComponent,
    ReferralsComponent,
    HourlyChartComponent,
    BirthdayClubInfoComponent,
    StampsPerScanComponent,
    GiveStampsDialogComponent,
  ],
  imports: [
    MatMenuModule,
    PickerComponent,
    ImageCropperModule,
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxSliderModule,
    SharedModule,
    NgxDatatableModule,
    AppMaterialModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    QRCodeModule,
    AppMaterialModule,
    NgxColorsModule,
    LottieModule.forRoot({ player: playerFactory }),
    NgChartsModule,
    NgHcaptchaModule.forRoot({
      siteKey: 'd0d53b3b-d57e-46fb-880f-114abdaee65b',
      languageCode: 'en',
    }),
    SocketIoModule.forRoot(config),
  ],
  providers: [
    { provide: AnalyticsAbstract, useClass: AnalyticsImpl },
    {
      provide: MAT_DIALOG_DATA,
      useValue: {},
    },
    ApiService,
    GeocodingService,
    ExcelService,
    {
      provide: MatDialogRef,
      useValue: {},
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
