import { Pipe, PipeTransform } from '@angular/core';
import { Message } from 'src/app/models/message';

@Pipe({
  name: 'rowMenuOptions',
  pure: true,
})
export class RowMenuOptionsPipe implements PipeTransform {
  transform(message: Message, constants: any): any[] {
    if (message?.draft) {
      return [
        {
          title: constants.strings.edit,
          icon: 'edit',
        },
        {
          title: constants.strings.delete,
          icon: 'delete',
        },
      ];
    } else {
      return [
        {
          title: constants.strings.duplicate,
          icon: 'copy',
        },
      ];
    }
  }
}
