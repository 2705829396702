<div *ngIf="editMode">
  <div *ngIf="!loading">
    <page-header [title]="title" [subtitle]="subtitle"></page-header>

    <div
      [ngStyle]="{
        justifyContent: !teamMember.verified ? 'space-between' : 'flex-end'
      }"
      style="display: flex; margin-bottom: 10px"
    >
      <app-primary-button
        *ngIf="!teamMember?.verified"
        [title]="constants.strings.triggerInvite"
        [disabled]="loading"
        (tapped)="triggerInvite()"
        [icon]="'send'"
      ></app-primary-button>

      <div *ngIf="!isSelf && canEditUser" style="display: flex">
        <div style="margin-right: 10px">
          <app-primary-button
            *ngIf="isTeamMember"
            [title]="teamMember.active ? 'Deactivate' : 'Activate'"
            [disabled]="saving"
            (tapped)="teamMember.active ? remove() : activate()"
            border=" 1px solid var(--app-container)"
            bgColor="transparent"
            color="white"
          ></app-primary-button>
        </div>

        <app-primary-button
          *ngIf="canEditUser"
          title="Save"
          [disabled]="loading"
          [tooltip]="tooltip"
          (tapped)="save()"
          border=" 1px solid var(--app-container)"
        ></app-primary-button>
      </div>
    </div>

    <div class="detail-container" id="left">
      <mat-tab-group
        mat-align-tabs="start"
        mat-stretch-tabs="false"
        disableRipple="true"
        [animationDuration]="0"
        (selectedTabChange)="tabChanged($event.index)"
        [(selectedIndex)]="selectedIndex"
      >
        <mat-tab label="Overview">
          <ng-template matTabContent>
            <div class="row">
              <div class="col-lg-6">
                <div style="margin-top: 20px">
                  <div class="form-field-label-subtitle">Name</div>
                  <div class="form-field-label-title">
                    {{ teamMember.name }}
                  </div>
                </div>

                <div style="margin-top: 20px">
                  <div class="form-field-label-subtitle">Email</div>
                  <div class="form-field-label-title">
                    {{ teamMember.email }}
                  </div>
                </div>

                <div style="margin-top: 20px" *ngIf="!canEditUser">
                  <div class="form-field-label-subtitle">Role</div>
                  <div class="form-field-label-title">
                    {{ role | titlecase }}
                  </div>
                </div>

                <form [formGroup]="editForm">
                  <div
                    class="form-field"
                    style="margin-top: 20px"
                    *ngIf="canEditUser"
                  >
                    <hr />
                    <single-select
                      label="Role"
                      [infoText]="roleTooltip"
                      [formCtrl]="roleFormControl"
                      [options]="roles"
                      property="label"
                      [showIcon]="false"
                      (selected)="roleSelected()"
                    ></single-select>
                  </div>

                  <div
                    class="form-field"
                    style="margin-top: 20px"
                    *ngIf="
                      canEditUser &&
                      roleFormControl.value &&
                      roleFormControl.value?.value !== 'merchant' &&
                      (locations ?? []).length > 1
                    "
                  >
                    <multiple-select
                      label="Locations"
                      [infoText]="locationTooltip"
                      [formCtrl]="locationFormControl"
                      [options]="locations"
                      allWord="Locations"
                      value="name"
                    >
                    </multiple-select>
                  </div>

                  <div
                    class="form-field"
                    style="margin-top: 20px"
                    *ngIf="
                      canEditUser &&
                      roleFormControl.value &&
                      roleFormControl.value?.value !== 'merchant'
                    "
                  >
                    <hr />
                    <div class="form-field-label">Permissions<span></span></div>
                    <div class="custom-slider">
                      <mat-slide-toggle [disabled]="true" [checked]="true"
                        ><span class="section-subtitle" style="text-wrap: wrap">
                          {{ "Give stamps in Remy Connect" }}</span
                        >
                      </mat-slide-toggle>
                    </div>
                    <div class="custom-slider">
                      <mat-slide-toggle
                        [disabled]="false"
                        [checked]="canViewCustomers"
                        (change)="canViewCustomers = !canViewCustomers"
                        ><span class="section-subtitle" style="text-wrap: wrap">
                          {{ "Customer List" }}</span
                        >
                      </mat-slide-toggle>
                    </div>
                    <div
                      class="custom-slider"
                      *ngIf="roleFormControl.value?.value === 'staff'"
                    >
                      <mat-slide-toggle
                        [disabled]="false"
                        [checked]="canViewDashboard"
                        (change)="canViewDashboard = !canViewDashboard"
                        ><span class="section-subtitle" style="text-wrap: wrap">
                          {{ "Dashboard Insights" }}</span
                        >
                      </mat-slide-toggle>
                    </div>
                  </div>

                  <div class="form-field" style="margin-top: 20px">
                    <hr />
                    <div
                      class="form-field-label"
                      style="
                        color: black;
                        font-weight: bold;
                        margin-bottom: 5px;
                      "
                    >
                      Email Reports
                    </div>
                    <div
                      class="form-field-label"
                      style="
                        color: var(--app-text-grey);
                        font-weight: normal;
                        font-size: 14px;
                      "
                    >
                      This Team Member can receive activity reports by email at
                      a frequency of your choosing
                    </div>
                    <div class="form-field" style="margin-top: 10px">
                      <mat-checkbox
                        style="margin-right: 10px"
                        [checked]="weeklyReports"
                        (change)="reportChecked($event.checked, 'weekly')"
                        >Weekly<span
                          style="
                            color: var(--app-text-grey);
                            margin-left: 5px;
                            font-style: italic;
                          "
                          >(Monday 9am GMT)</span
                        ></mat-checkbox
                      ><br />
                      <mat-checkbox
                        [checked]="monthlyReports"
                        (change)="reportChecked($event.checked, 'monthly')"
                        >Monthly<span
                          style="
                            color: var(--app-text-grey);
                            margin-left: 5px;
                            font-style: italic;
                          "
                          >(1st of month, 9am GMT)</span
                        ></mat-checkbox
                      >
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab label="Stamps">
          <ng-template matTabContent>
            <ngx-datatable
              #stampsTable
              class="material"
              [headerHeight]="45"
              [rows]="stampEvents"
              [scrollbarV]="false"
              [messages]="{ emptyMessage: 'No stamps to display' }"
              [scrollbarH]="true"
              [footerHeight]="50"
              [columnMode]="'force'"
              [rowHeight]="48"
              [externalPaging]="true"
              [count]="stampLength"
              [offset]="stampIndex"
              (page)="stampPageEvent($event)"
              (scroll)="handleScroll()"
              [limit]="stampSize"
            >
              <ngx-datatable-column name="Date" [resizeable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row?.createdAt | date : constants.longestDate }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Card" [resizeable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <a
                    [routerLink]="['/card', row?.card?.nanoid]"
                    (click)="$event.stopPropagation()"
                  >
                    {{ row?.card?.title }}
                  </a>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Customer" [resizeable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <ng-container *ngIf="iCanViewCustomers; else noLink">
                    <a
                      [routerLink]="['/customer', row?.customer?.nanoid]"
                      (click)="$event.stopPropagation()"
                    >
                      {{ row?.user?.name }}
                    </a>
                  </ng-container>
                  <ng-template #noLink>
                    {{ row?.user?.name }}
                  </ng-template>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Stamp #" [resizeable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row?.stampNumber }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Reward" [resizeable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <mat-icon
                    class="status-icon"
                    style="color: green"
                    *ngIf="row.hasReward"
                    >check_circle</mat-icon
                  >
                  <mat-icon
                    class="status-icon"
                    style="color: red"
                    *ngIf="!row.hasReward"
                    >cancel</mat-icon
                  >
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="Location"
                [resizeable]="false"
                *ngIf="isLocationNotString(stampEvents)"
              >
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <a
                    [routerLink]="['/location', row.location?.nanoid]"
                    (click)="$event.stopPropagation()"
                  >
                    {{ row?.location?.name }}
                  </a>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-footer>
                <ng-template
                  ngx-datatable-footer-template
                  let-rowCount="rowCount"
                  let-pageSize="pageSize"
                  let-offset="offset"
                  let-isVisible="isVisible"
                >
                  <div class="page-count" *ngIf="stampLength > 0">
                    <span>{{ stampRange }}</span>
                  </div>
                  <datatable-pager
                    [pagerLeftArrowIcon]="'datatable-icon-left'"
                    [pagerRightArrowIcon]="'datatable-icon-right'"
                    [pagerPreviousIcon]="'datatable-icon-prev'"
                    [pagerNextIcon]="'datatable-icon-skip'"
                    [page]="stampIndex + 1"
                    [size]="pageSize"
                    [count]="stampLength"
                    [hidden]="!(rowCount / pageSize > 1)"
                    (change)="stampsTable.onFooterPage($event)"
                  >
                  </datatable-pager>
                </ng-template>
              </ngx-datatable-footer>
            </ngx-datatable>
          </ng-template>
        </mat-tab>

        <mat-tab label="Points">
          <ng-template matTabContent>
            <ngx-datatable
              #pointsTable
              class="material"
              [headerHeight]="45"
              [rows]="pointsEvents"
              [scrollbarV]="false"
              [messages]="{ emptyMessage: 'No points to display' }"
              [scrollbarH]="true"
              [footerHeight]="50"
              [columnMode]="'force'"
              [rowHeight]="48"
              [externalPaging]="true"
              [count]="pointsLength"
              [offset]="pointsIndex"
              (page)="pointsPageEvent($event)"
              (scroll)="handleScroll()"
              [limit]="pointsSize"
            >
              <ngx-datatable-column name="Date" [resizeable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row?.createdAt | date : constants.longestDate }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Card" [resizeable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <a
                    [routerLink]="['/card', row?.card?.nanoid]"
                    (click)="$event.stopPropagation()"
                  >
                    {{ row?.card?.title }}
                  </a>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Customer" [resizeable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <ng-container *ngIf="iCanViewCustomers; else noLink">
                    <a
                      [routerLink]="['/customer', row?.customer?.nanoid]"
                      (click)="$event.stopPropagation()"
                    >
                      {{ row?.user?.name }}
                    </a>
                  </ng-container>
                  <ng-template #noLink>
                    {{ row?.user?.name }}
                  </ng-template>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Points" [resizeable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row?.points ?? "-" }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Total Points" [resizeable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{
                    row?.totalPoints === 0
                      ? row?.card?.points?.totalPoints
                      : row?.totalPoints
                  }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Reward" [resizeable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <mat-icon
                    class="status-icon"
                    style="color: green"
                    *ngIf="row.hasReward"
                    >check_circle</mat-icon
                  >
                  <mat-icon
                    class="status-icon"
                    style="color: red"
                    *ngIf="!row.hasReward"
                    >cancel</mat-icon
                  >
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="Location"
                [resizeable]="false"
                *ngIf="isLocationNotString(pointsEvents)"
              >
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <a
                    [routerLink]="['/location', row.location?.nanoid]"
                    (click)="$event.stopPropagation()"
                  >
                    {{ row?.location?.name }}
                  </a>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-footer>
                <ng-template
                  ngx-datatable-footer-template
                  let-rowCount="rowCount"
                  let-pageSize="pageSize"
                  let-offset="offset"
                  let-isVisible="isVisible"
                >
                  <div class="page-count" *ngIf="pointsLength > 0">
                    <span>{{ pointsRange }}</span>
                  </div>
                  <datatable-pager
                    [pagerLeftArrowIcon]="'datatable-icon-left'"
                    [pagerRightArrowIcon]="'datatable-icon-right'"
                    [pagerPreviousIcon]="'datatable-icon-prev'"
                    [pagerNextIcon]="'datatable-icon-skip'"
                    [page]="pointsIndex + 1"
                    [size]="pageSize"
                    [count]="pointsLength"
                    [hidden]="!(rowCount / pageSize > 1)"
                    (change)="pointsTable.onFooterPage($event)"
                  >
                  </datatable-pager>
                </ng-template>
              </ngx-datatable-footer>
            </ngx-datatable>
          </ng-template>
        </mat-tab>

        <mat-tab label="Redemptions">
          <ng-template matTabContent>
            <ngx-datatable
              #redemptionsTable
              class="material"
              [headerHeight]="45"
              [rows]="redemptions"
              [scrollbarV]="false"
              [messages]="{ emptyMessage: 'No redemptions to display' }"
              [scrollbarH]="true"
              [footerHeight]="50"
              [columnMode]="'force'"
              [rowHeight]="48"
              [externalPaging]="true"
              [count]="redemptionLength"
              [offset]="redemptionIndex"
              (scroll)="handleScroll()"
              (page)="redemptionPageEvent($event)"
              [limit]="redemptionSize"
            >
              <ngx-datatable-column name="Redemption Date" [resizeable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row?.createdAt | date : constants.longestDate }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Reward" [resizeable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <a
                    [routerLink]="['/reward', row?.reward?.nanoid]"
                    (click)="$event.stopPropagation()"
                  >
                    {{ row?.reward?.title }}
                  </a>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Source" [resizeable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{
                    row?.card?.title
                      ? row?.card?.title
                      : row?.birthdayClub
                      ? "🎉 Birthday Club"
                      : "Push Notification"
                  }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Customer" [resizeable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <ng-container *ngIf="iCanViewCustomers; else noLink">
                    <a
                      [routerLink]="['/customer', row?.customer?.nanoid]"
                      (click)="$event.stopPropagation()"
                    >
                      {{ row?.user?.name }}
                    </a>
                  </ng-container>
                  <ng-template #noLink>
                    {{ row?.user?.name }}
                  </ng-template>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="Location"
                [resizeable]="false"
                *ngIf="isLocationNotString(redemptions)"
              >
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <a
                    [routerLink]="['/location', row.location?.nanoid]"
                    (click)="$event.stopPropagation()"
                  >
                    {{ row?.location?.name }}
                  </a>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-footer>
                <ng-template
                  ngx-datatable-footer-template
                  let-rowCount="rowCount"
                  let-pageSize="pageSize"
                  let-offset="offset"
                  let-isVisible="isVisible"
                >
                  <div class="page-count" *ngIf="redemptionLength > 0">
                    <span>{{ redemptionRange }}</span>
                  </div>
                  <datatable-pager
                    [pagerLeftArrowIcon]="'datatable-icon-left'"
                    [pagerRightArrowIcon]="'datatable-icon-right'"
                    [pagerPreviousIcon]="'datatable-icon-prev'"
                    [pagerNextIcon]="'datatable-icon-skip'"
                    [page]="redemptionIndex + 1"
                    [size]="pageSize"
                    [count]="redemptionLength"
                    [hidden]="!(rowCount / pageSize > 1)"
                    (change)="redemptionsTable.onFooterPage($event)"
                  >
                  </datatable-pager>
                </ng-template>
              </ngx-datatable-footer>
            </ngx-datatable>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>

<div *ngIf="!editMode">
  <page-header
    [title]="(editMode ? 'Edit ' : 'New ') + constants.strings.teamMember"
    [subtitle]="subtitle"
  ></page-header>

  <form [formGroup]="form" *ngIf="!loading">
    <div class="row">
      <div class="col-lg-6">
        <div
          style="display: flex; justify-content: flex-end; margin-bottom: 10px"
        >
          <div style="display: flex">
            <app-primary-button
              [title]="constants.strings.cancel"
              border=" 1px solid var(--app-container)"
              bgColor="transparent"
              color="white"
              (tapped)="back()"
            ></app-primary-button>

            <div style="margin-left: 10px">
              <app-primary-button
                title="Save"
                [disabled]="loading || !canCreateTeamMember"
                [tooltip]="tooltip"
                (tapped)="save()"
                border=" 1px solid var(--app-container)"
              ></app-primary-button>
            </div>
          </div>
        </div>

        <div class="detail-container" id="left">
          <!-- <app-info-box
            *ngIf="!hideNotice"
            title="Important"
            [canDismiss]="false"
            [body]="infoBoxBody"
          ></app-info-box> -->
          <div class="row">
            <div class="col-lg-6">
              <div class="form-field">
                <div class="form-field-label">
                  First name<span style="color: red">*</span>
                </div>
                <input
                  class="form-input"
                  autocomplete="off"
                  [formControl]="firstNameFormControl"
                  type="text"
                  [ngStyle]="{
                    border:
                      firstNameFormControl.invalid && submitted
                        ? '1px solid red'
                        : '1px solid var(--app-light-grey)'
                  }"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-field">
                <div class="form-field-label">
                  Last name<span style="color: red">*</span>
                </div>
                <input
                  class="form-input"
                  autocomplete="off"
                  [formControl]="lastNameFormControl"
                  type="text"
                  [ngStyle]="{
                    border:
                      lastNameFormControl.invalid && submitted
                        ? '1px solid red'
                        : '1px solid var(--app-light-grey)'
                  }"
                />
              </div>
            </div>
          </div>

          <div class="form-field" style="margin-top: 20px">
            <div class="form-field-label">
              Email<span style="color: red">*</span>
            </div>
            <input
              class="form-input"
              autocomplete="off"
              [formControl]="emailFormControl"
              type="text"
              [ngStyle]="{
                border:
                  lastNameFormControl.invalid && submitted
                    ? '1px solid red'
                    : '1px solid var(--app-light-grey)'
              }"
            />
          </div>

          <div class="form-field" style="margin-top: 20px">
            <single-select
              label="Role"
              [infoText]="roleTooltip"
              [formCtrl]="roleFormControl"
              [options]="roles"
              property="label"
              [showIcon]="false"
              (selected)="roleSelected()"
            ></single-select>
          </div>

          <div
            class="form-field"
            style="margin-top: 20px"
            *ngIf="
              roleFormControl.value &&
              roleFormControl.value?.value !== 'merchant' &&
              (locations ?? []).length > 1
            "
          >
            <multiple-select
              label="Locations"
              [infoText]="locationTooltip"
              [formCtrl]="locationFormControl"
              [options]="locations"
              [showAll]="false"
              value="name"
            >
            </multiple-select>
          </div>

          <div
            class="form-field"
            style="margin-top: 20px"
            *ngIf="
              roleFormControl.value &&
              roleFormControl.value?.value !== 'merchant'
            "
          >
            <div
              class="form-field-label"
              [ngStyle]="{
                'margin-bottom': '8px'
              }"
            >
              Permissions<span> </span>
            </div>

            <div class="custom-slider">
              <mat-slide-toggle
                [disabled]="false"
                [checked]="canViewCustomers"
                (change)="canViewCustomers = !canViewCustomers"
                ><span class="section-subtitle" style="text-wrap: wrap">
                  {{ "Customer List" }}</span
                >
              </mat-slide-toggle>
            </div>

            <div class="custom-slider">
              <mat-slide-toggle
                [disabled]="false"
                [checked]="canViewDashboard"
                (change)="canViewDashboard = !canViewDashboard"
                ><span class="section-subtitle" style="text-wrap: wrap">
                  {{ "Dashboard Insights" }}</span
                >
              </mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<loader [loading]="loading"></loader>
<loader [loading]="saving || paging || nextLoading || toggling"></loader>
