<dialog-header
  [title]="data.title"
  (onClose)="matDialogRef.close()"
></dialog-header>

<div mat-dialog-content>
  <ngx-datatable
    #myTable
    class="material"
    [headerHeight]="45"
    [rows]="orders"
    [scrollbarV]="false"
    [messages]="{ emptyMessage: emptyString }"
    [scrollbarH]="true"
    [footerHeight]="50"
    [columnMode]="'force'"
    [rowHeight]="48"
    [externalPaging]="true"
    [count]="length"
    [offset]="pageIndex"
    (page)="pageEvent($event)"
    (scroll)="handleScroll()"
    [limit]="pageSize"
  >
    <ngx-datatable-column name="Date" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.createdAt | date : constants.longDate }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [name]="'ID #'" [resizeable]="true">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.orderNo }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Value" [resizeable]="true">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.value | number : "1.2-2" }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="Type"
      [resizeable]="true"
      *ngIf="data.type == 'zapier'"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.source }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="Type"
      [resizeable]="true"
      *ngIf="data.type !== 'zapier'"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.type | titlecase }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="SKU"
      [resizeable]="true"
      *ngIf="
        data.type !== 'zapier' &&
        data.type !== 'eposnow' &&
        data.type !== 'storekit' &&
        data.type !== 'squarespace' &&
        data.type !== 'acuity'
      "
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.sku ?? "-" }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Customer" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <a
          [routerLink]="['/customer', row?.customer?.nanoid]"
          (click)="$event.stopPropagation(); matDialogRef.close()"
        >
          {{ row?.user?.name }}
        </a>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-footer>
      <ng-template
        ngx-datatable-footer-template
        let-rowCount="rowCount"
        let-pageSize="pageSize"
        let-offset="offset"
        let-isVisible="isVisible"
      >
        <div class="page-count" *ngIf="length > 0">
          <span>{{ ordersRange }}</span>
        </div>
        <datatable-pager
          [pagerLeftArrowIcon]="'datatable-icon-left'"
          [pagerRightArrowIcon]="'datatable-icon-right'"
          [pagerPreviousIcon]="'datatable-icon-prev'"
          [pagerNextIcon]="'datatable-icon-skip'"
          [page]="pageIndex + 1"
          [size]="pageSize"
          [count]="length"
          [hidden]="!(rowCount / pageSize > 1)"
          (change)="table.onFooterPage($event)"
        >
        </datatable-pager>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>
</div>

<loader [loading]="loading"></loader>
