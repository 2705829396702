import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.css'],
})
export class SearchInputComponent implements OnInit {
  typing = false;
  @Input() searchTerm: string | any;

  @Input() isDirectSearch: boolean = false;
  @Input() inputWidth: number | undefined;
  @Input() backgroundColor = 'var(--app-container)';
  @Output() onChanged: EventEmitter<any> = new EventEmitter();
  @Output() onSubmit: EventEmitter<any> = new EventEmitter();
  @Output() onCancel: EventEmitter<any> = new EventEmitter();

  isMobile = false;
  isSearching = false;
  form: FormGroup;

  @HostListener('window:resize', ['$event'])
  onResize = () => this.testMobile();
  testMobile = () => (this.isMobile = window.innerWidth < 768 ? true : false);

  constructor() {
    this.form = new FormGroup({});
  }

  ngOnInit(): void {
    this.testMobile();

    if (this.searchTerm?.length > 0) {
      this.isSearching = true;
    }
  }

  inputChanged(event: any) {
    let term = event.target.value;

    this.isSearching = false;
    this.onChanged.emit(term);

    this.typing = term.length ? true : false;
    if (this.isDirectSearch && this.typing) {
      this.isSearching = true;
    }
  }

  searchButtonTapped() {
    if (!this.searchTerm?.length) {
      return;
    }

    if (this.isSearching) {
      this.searchTerm = '';
      this.typing = false;
      this.onCancel.emit();
    } else {
      this.onSubmit.emit();
    }

    this.isSearching = !this.isSearching;
  }

  searchAction() {
    if (this.searchTerm.length) {
      this.onSubmit.emit();
      this.isSearching = true;
    } else {
      this.searchTerm = '';
      this.onCancel.emit();
    }
  }
}
