import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { Subscription } from 'rxjs';
import { Constants } from 'src/app/app.constants';
import { AnimationOptions } from 'ngx-lottie';
import { OnboardComponent } from 'src/app/shared/components/onboard-component/onboard.component';
import { MatDialog } from '@angular/material/dialog';
import { SelectMerchantDialogComponent } from '../select-merchant-dialog/select-merchant-dialog.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { AnalyticsAbstract } from 'src/app/services/analytics/analytics.abstract';
import { MagicLinkDialogComponent } from '../magic-link-dialog/magic-link-dialog.component';

import { AuthService, StorageService } from '../../../services/index';

@Component({
  selector: 'login',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  options: AnimationOptions = { path: '/assets/rewards.json', loop: false };
  hidePassword: boolean = true;
  isMobile = false;

  resetPasswordSub!: Subscription;
  loginSub!: Subscription;

  loginForm!: FormGroup;
  registerForm!: FormGroup;

  testLoginFormControl = new FormControl(
    { value: '', disabled: false },
    Validators.required
  );
  emailFormControl = new FormControl(
    { value: '', disabled: false },
    Validators.required
  );
  passwordFormControl = new FormControl(
    { value: '', disabled: false },
    Validators.required
  );

  pageLoading = true;
  loadingOne = false;

  @HostListener('window:resize', ['$event'])
  onResize = () => this.testMobile();
  testMobile = () => (this.isMobile = window.innerWidth < 768 ? true : false);

  loginImage = '../assets/login/login-1.jpg';

  labels = [
    'Turn visits into habits',
    'Reward loyalty effortlessly',
    'Engage customers instantly',
    'Grow your loyal community',
    'Keep customers coming back',
  ];

  loginLabel = this.labels[0];

  constructor(
    private dialog: MatDialog,
    public constants: Constants,
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private mixpanel: AnalyticsAbstract
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params['e']) {
        this.emailFormControl.setValue(params['e']);
      }

      if (params['token']) {
        this.login(params['token']);
      }
    });

    const randBetween1And5 = Math.floor(Math.random() * 5) + 1;
    this.loginLabel = this.labels[randBetween1And5 - 1];
    this.loginImage = `../assets/login/login-${randBetween1And5}.jpg`;
  }

  ngOnInit() {
    this.testMobile();

    this.loginForm = this.formBuilder.group({
      email: this.emailFormControl,
      password: this.passwordFormControl,
    });

    setTimeout(() => {
      this.pageLoading = false;
    }, 500);
  }

  get loginF() {
    return this.loginForm.controls;
  }

  get registerF() {
    return this.registerForm.controls;
  }

  login(token?: string) {
    if (this.loginForm?.invalid) {
      this.loginForm.markAllAsTouched();
      return;
    }

    this.loadingOne = true;

    this.mixpanel.track(Constants.analytics_keys.login, {
      Email: this.loginForm?.value?.email,
      'Magic Link': token !== null,
    });

    this.loginSub = this.authService
      .getAuthStatusListener()
      .subscribe((result) => {
        this.loginSub.unsubscribe();
        this.loadingOne = false;
        if (result.auth) {
          this.mixpanel.track(Constants.analytics_keys.loginSuccess, {
            Email: this.loginForm.value.email,
          });

          this.openMerchantPicker();

          this.navigate(token !== null);
        } else {
          this.authService.logout();
        }
      });
    this.authService.login(
      this.loginForm?.value?.email,
      this.loginForm?.value?.password,
      token
    );
  }

  openMerchantPicker(): void {
    if (this.authService.getUserProperty('userType') === 'admin') {
      const dialogRef = this.dialog.open(SelectMerchantDialogComponent, {
        autoFocus: false,
        disableClose: true,
        width: '600px',
        scrollStrategy: new NoopScrollStrategy(),
      });

      dialogRef.afterClosed().subscribe((merchant: any) => {
        if (merchant) {
          if (this.storageService.get('user')) {
            let user = JSON.parse(this.storageService.get('user'));
            user.merchant = merchant;
            this.storageService.set('user', JSON.stringify(user));

            window.location.reload();
          }
        }
      });
    }
  }

  navigate(viaToken: boolean) {
    const redirectUrl =
      this.route.snapshot.queryParams['redirect'] || '/dashboard';

    this.router.navigateByUrl(redirectUrl).then(() => {
      if (
        this.authService.getUserProperty('onboarded') === false &&
        this.authService.getUserProperty('userType') ===
          this.constants.userTypes.merchant
      ) {
        this.openOnboarding();
      } else {
        if (
          this.authService.getUserProperty('userType') !==
          this.constants.userTypes.admin
        ) {
          setTimeout(
            () => {
              window.location.reload();
            },
            viaToken ? 300 : 0
          );
        }
      }
    });
  }

  openOnboarding(): void {
    const dialogRef = this.dialog.open(OnboardComponent, {
      autoFocus: false,
      disableClose: true,
      maxWidth: '500px',
      scrollStrategy: new NoopScrollStrategy(),
    });
    dialogRef.afterClosed().subscribe((option: boolean) => {
      window.location.reload();
    });
  }

  signup() {
    this.mixpanel.track(Constants.analytics_keys.signUp);

    this.router.navigate([Constants.routes.signup]);
  }

  navigateToForgotPassword(): void {
    this.mixpanel.track(Constants.analytics_keys.resetPassword);

    this.router.navigate([Constants.routes.resetPassword]);
  }

  magicLink() {
    this.mixpanel.track(Constants.analytics_keys.magicLink, {});

    const dialogRef = this.dialog.open(MagicLinkDialogComponent, {
      data: {
        email: this.emailFormControl.value,
      },
      autoFocus: false,
      width: '400px',
      disableClose: true,
      panelClass: 'custom-dialog',
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialogRef.afterClosed().subscribe((state: any) => {
      if (state) {
        this.mixpanel.track(Constants.analytics_keys.magicLink, {
          email: state.email,
        });
        this.constants.snack('Please check your emails for a magic link', true);
      }
    });
  }
}
