export interface Reward {
  _id?: string;
  nanoid?: string;
  createdAt?: Date;
  title?: string;
  description?: string;
  hiddenText?: string;
  value?: number;
  active?: boolean;
  expiry?: number;
  giftable?: boolean;
  locations?: any[];
  _canManage?: boolean;
}
